import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { GeneralDropdown } from "../../components";
import { numToSSColumn } from "../../config";
import { getApiData } from "../../Services";
import SVG from "react-inlinesvg";
import { gradientColors } from "../../../gradientColor";
import { split } from "lodash";

export default function ({ location }) {
  const history = useHistory();

  const [scrollWing, setScrollWing] = useState(null);
  const [buildingId, setBuildingId] = useState(null);
  const [buildingName, setBuildingName] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [buildingAddr, setBuildingAddr] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [tapriList, setTapriList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedTapri, setSelectedTapri] = useState(null);
  const [buildingObj, setBuildingObj] = useState({});
  const [buildingSaving, setBuildingSaving] = useState(false);
  const [wingSaving, setWingSaving] = useState(false);
  const [activeOffices, setActiveOffices] = useState({});

  useEffect(() => {
    getStateList();
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLon(position.coords.longitude);
        },
        (error) => {
          toast.error("Location permission issue");
        }
      );
    }
  };

  useEffect(() => {
    if (location.state && location.state.id) {
      const state = location.state;
      setBuildingId(state.id);
      getBuildingDetails(state.id);
      setBuildingName(state.name);
      setBuildingAddr(state.address);
    }
  }, [location]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      getAreaByCity(selectedCity.value);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedArea && selectedArea.value) {
      getTapriByArea(selectedArea.value);
    }
  }, [selectedArea]);

  useEffect(() => {
    if (scrollWing) {
      const divElement = document.getElementById(scrollWing);
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollWing(null);
    }
  }, [scrollWing]);

  const getBuildingDetails = (id) => {
    getApiData(`building/v1/${id}/`).then((res) => {
      if (res.status) {
        try {
          setLat(res.data.latitude);
          setLon(res.data.longitude);
        } catch (error) { }
        setBuildingObj(addUnderscores(res.data.floors_and_wings));
        setActiveOffices(res.data.active_offices);
        setSelectedState(res.data.state);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const addUnderscores = (data) => {
    let newObj = {};

    Object.keys(data).map((wing, i) => {
      let wingName = i + 1 + "_" + wing;
      let floorObj = {};
      Object.keys(data[wing]).map((floor, index) => {
        let floorName = index + 1 + "_" + floor;
        floorObj = { ...floorObj, [floorName]: data[wing][floor] };
      });
      newObj = { ...newObj, [wingName]: floorObj };
    });
    return newObj;
  };

  const getAreaByCity = (city) => {
    return getApiData(`administration/v1/areas?city=${city}`).then((res) => {
      if (res.status) {
        let area = [];
        res.data.map((item) => {
          area = [...area, { label: item.name, value: item.id }];
        });
        setAreaList(area);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  }

  const getTapriByArea = (area) => {
    getApiData(`business/v1/?area=${area}`).then((res) => {
      if (res.status) {
        let tapri = [];
        res.data.map((item) => {
          tapri = [...tapri, { label: item.name, value: item.id }];
        });
        setTapriList(tapri);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  useEffect(() => {
    if (stateList.length && location.state) {
      stateList.map((item) => {
        if (item.value === location.state.state) {
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList]);

  useEffect(() => {
    if (cityList.length && location.state) {
      cityList.map((item) => {
        if (item.value === location.state.city) {
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList]);

  useEffect(() => {
    if (areaList.length && location.state) {
      areaList.map((item) => {
        if (item.value === location.state.area) {
          setSelectedArea(item);
          return;
        }
      });
    }
  }, [areaList]);

  useEffect(() => {
    if (tapriList.length && location.state) {
      tapriList.map((item) => {
        if (item.value === location.state.tapri) {
          setSelectedTapri(item);
          return;
        }
      });
    }
  }, [tapriList]);

  const getStateList = () => {
    getApiData("administration/v1/location").then((res) => {
      if (res.status) {
        let state = [];
        let location = res.data.location;
        Object.keys(location).map((item) => {
          state = [
            ...state,
            { label: item, value: item, city: location[item] },
          ];
        });
        setStateList(state);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      } else if (!res.data.location) {
        let state = [];
        let dummyData = {
          location: {
            Gujarat: [
              "Vadodara",
              "Ahmedabad"
            ]
          }
        }
        let location = dummyData.location;
        Object.keys(location).map((item) => {
          state = [...state, { label: item, value: item, city: location[item] }];
        });
        return state;
      } else {
        let state = [];
        let dummyData = {
          location: {
            Gujarat: [
              "Vadodara",
              "Ahmedabad"
            ]
          }
        }
        let location = dummyData.location;
        Object.keys(location).map((item) => {
          state = [...state, { label: item, value: item, city: location[item] }];
        });
        return state;
      }
    });
  };

  const onFloorChange = (operation, wing) => {
    let newObj = { ...buildingObj };
    let floorLength = Object.keys(newObj[wing]).length;
    if (operation === "incr") {
      let keyName = floorLength + 1 + "_";
      const modify = { ...newObj[wing], [keyName]: [] };
      newObj[wing] = modify;
    } else {
      let keys = Object.keys(newObj[wing]);
      if (keys.length > 1) {
        delete newObj[wing][keys[keys.length - 1]];
      }
    }
    setBuildingObj(newObj);
  };

  const onOfficeChange = (operation, wing, floor, index) => {
    let newObj = { ...buildingObj };
    let office = newObj[wing][floor];

    if (operation === "incr") {
      if (office.length === 0) {
        const floorNumber = parseInt(floor.replace('_', '')) == 0 ? 1 : parseInt(floor.replace('_', ''));
        const newElement = (floorNumber * 100) + 1;
        newObj[wing][floor] = [...office, newElement];
      } else {
        const lastElement = parseInt(office[office.length - 1]);;
        const incrementedNumber = lastElement + 1;
        const newElement = incrementedNumber.toString();

        newObj[wing][floor] = [...office, newElement];
      }
    } else {
      office.splice(index, 1);
      newObj[wing][floor] = office;
    }

    setBuildingObj(newObj);
  };

  const onWingChange = (operation) => {
    let newObj = { ...buildingObj };
    let wingLength = Object.keys(newObj).length;
    let scroll = wingLength;
    if (operation === "incr") {
      wingLength = wingLength + 1;
      let wingName = wingLength + "_" + numToSSColumn(wingLength);
      newObj[wingName] = { "0_": [] };
    } else {
      scroll = scroll - 2;
      let keys = Object.keys(newObj);
      delete newObj[keys[keys.length - 1]];
    }
    setScrollWing(`wing_${scroll}`);
    setBuildingObj(newObj);
  };

  const saveBuilding = () => {
    if (buildingName && selectedArea && buildingAddr && lon) {
      let body = {
        name: buildingName,
        // business: selectedTapri ? selectedTapri.value : "",
        area: selectedArea ? selectedArea.value : "",
        address: buildingAddr,
        latitude: lat,
        longitude: lon,
      };

      let apiURL = "building/v1/";
      if (buildingId) {
        apiURL = `building/v1/${buildingId}/`;
      }
      setBuildingSaving(true);

      getApiData(apiURL, buildingId ? "put" : "post", body).then((res) => {
        setBuildingSaving(false);

        if (res.status) {
          if (res.data.building_id) {
            setBuildingId(res.data.building_id);
          }
          toast.success(res.message);
        } else if (res.detail === "Invalid token.") {
          history.push("/logout");
        } else {
          if (typeof res.message === "string") {
            toast.error(res.message);
          } else {
            toast.error("Something went wrong!");
          }
        }
      });
    }
  };

  const isActiveInput = (wing, floor, office) => {
    if (wing.includes("_")) {
      wing = wing.split("_")[1];
    }
    if (floor.includes("_")) {
      floor = floor.split("_")[1];
    }

    if (
      activeOffices &&
      Object.keys(activeOffices).length &&
      activeOffices[wing] &&
      activeOffices[wing][floor] &&
      activeOffices[wing][floor].includes(office)
    ) {
      return "active-input";
    }
    return "";
  };

  const getTotalOffice = (wing) => {
    if (wing.includes("_")) {
      wing = wing.split("_")[1];
    }

    if (
      activeOffices &&
      Object.keys(activeOffices).length &&
      activeOffices["total_offices"] &&
      activeOffices["total_offices"][wing]
    ) {
      return activeOffices["total_offices"][wing];
    }
    return 0;
  };

  const hasDuplicates = (arr) => {
    return arr.some(function (item) {
      return arr.indexOf(item) !== arr.lastIndexOf(item);
    });
  };

  const modifyBuildingObj = () => {
    let newObj = {};
    Object.keys(buildingObj)
      .sort()
      .map((wing) => {
        let wingName = "";
        let floorObj = {};
        if (wing.includes("_")) {
          wingName = wing.split("_")[1];
        }
        Object.keys(buildingObj[wing])
          .sort()
          .map((floor) => {
            let floorName = "";
            if (wing.includes("_")) {
              floorName = floor.split('_')[0] == 0 ? 1 : floor.split('_')[0];
              console.log("floorName >>>>", floorName);
              console.log("floor >>>>", floor);
            }
            floorObj = { ...floorObj, [floorName]: buildingObj[wing][floor] };
          });
        newObj = { ...newObj, [wingName]: floorObj };
      });
    return newObj;
  };

  const saveWing = () => {
    // setWingSaving(true);

    let message = "";
    let buildingObj = modifyBuildingObj();

    Object.keys(buildingObj).map((item) => {
      let array = [];
      Object.values(buildingObj[item]).map((office) => {
        array = [...array, ...office];
      });
      if (hasDuplicates(array)) {
        message = `Wing ${item} has contains 2 or more same office number`;
      }
    });
    if (message) {
      setWingSaving(false);
      toast.error(message);
    } else {
      let body = {
        floors_and_wings: buildingObj,
      };
      getApiData(`building/v1/${buildingId}/`, "put", body).then((res) => {
        setWingSaving(false);
        if (res.status) {
          toast.success(res.message);
        } else if (res.detail === "Invalid token.") {
          history.push("/logout");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-5 mb-xl-10">
            <div className="card-body">
              <div className=" row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={buildingName}
                          onChange={(e) => {
                            setBuildingName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={buildingAddr}
                          onChange={(e) => {
                            setBuildingAddr(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <div className="d-flex">
                          <label className="fw-bolder mb-1">Latitude</label>
                          <a
                            className="ml-auto"
                            onClick={() => {
                              getCurrentLocation();
                            }}
                          >
                            <i
                              className="fa fa-crosshairs fs-7 mr-2"
                              style={{ color: "black" }}
                            />
                            Get Current
                          </a>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={lat}
                          onChange={(e) => {
                            setLat(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 ">
                      <div className="form-group">
                        <label className="fw-bolder mb-1">Longitude</label>
                        <input
                          type="number"
                          className="form-control"
                          value={lon}
                          onChange={(e) => {
                            setLon(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building State
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedState}
                          handleChange={(obj) => {
                            setSelectedCity(null);
                            setSelectedArea(null);
                            setSelectedTapri(null);
                            setSelectedState(obj);
                          }}
                          list={stateList}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building City
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedCity}
                          handleChange={(obj) => {
                            setSelectedArea(null);
                            setSelectedTapri(null);
                            setSelectedCity(obj);
                          }}
                          list={cityList}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Building Area
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedArea}
                          handleChange={(obj) => {
                            setSelectedTapri(null);
                            setSelectedArea(obj);
                          }}
                          list={areaList}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label className="fw-bolder mb-1" htmlFor>
                          Select Tapri
                        </label>
                        <GeneralDropdown
                          selectedOption={selectedTapri}
                          handleChange={(obj) => {
                            setSelectedTapri(obj);
                          }}
                          list={tapriList}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <button
                    style={{ width: "120px", textAlign: "center" }}
                    className="btn-primary custom-button px-10"
                    onClick={saveBuilding}
                    disabled={!buildingName || !buildingAddr || !lat || !lon || !selectedArea || buildingSaving}
                  >
                    Save
                    {buildingSaving && (
                      <Spinner animation="border" size="sm" className="ml-1" />
                    )}
                  </button>
                  <Link
                    className="btn custom-btn-light px-10 ms-3"
                    to="/master/building"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        {buildingId ? (
          <div className="card-header pt-0">
            <h3 className="card-title align-items-center ">
              <span className="card-label fw-bolder fs-3 mb-1 w-150px">
                No Of Wings:
              </span>
              {/*begin::Dialer*/}
              <div className="position-relative w-lg-150px text-center">
                {/*begin::Decrease control*/}
                <button
                  type="button"
                  className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                  onClick={() => onWingChange("decr")}
                >
                  {/*begin::Svg Icon | path: icons/duotune/general/gen042.svg*/}
                  <span className="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="black"
                      />
                      <rect
                        x="6.01041"
                        y="10.9247"
                        width={12}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                  {/*end::Svg Icon*/}
                </button>
                {/*end::Decrease control*/}
                {/*begin::Input control*/}
                <input
                  type="text"
                  className="form-control fs-5 text-center ps-0"
                  data-kt-dialer-control="input"
                  readOnly="readonly"
                  value={Object.keys(buildingObj).length}
                />
                <button
                  type="button"
                  className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                  onClick={() => onWingChange("incr")}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.3"
                        x={2}
                        y={2}
                        width={20}
                        height={20}
                        rx={10}
                        fill="black"
                      />
                      <rect
                        x="10.8891"
                        y="17.8033"
                        width={12}
                        height={2}
                        rx={1}
                        transform="rotate(-90 10.8891 17.8033)"
                        fill="black"
                      />
                      <rect
                        x="6.01041"
                        y="10.9247"
                        width={12}
                        height={2}
                        rx={1}
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </h3>
            <div className="card-toolbar">
              <div className="mt-0 ml-2">
                <button
                  style={{ width: "110px" }}
                  className="btn-primary custom-button px-10 fs-6"
                  onClick={saveWing}
                  disabled={wingSaving}
                >
                  Save
                  {wingSaving && (
                    <Spinner
                      animation="border"
                      size="sm"
                      className="ml-1"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {Object.keys(buildingObj)
        .sort((a, b) => {
          let val1 = a;
          let val2 = b;
          if (a.includes("_")) {
            val1 = a.split("_")[0];
          }
          if (b.includes("_")) {
            val2 = b.split("_")[0];
          }
          return parseInt(val1) - parseInt(val2);
        })
        .map((wing, i) => {
          let floorData = Object.keys(buildingObj[wing]);
          let totalOffice = 0;
          let id = "";
          let value = "";

          Object.values(buildingObj[wing]).map((floor) => {
            totalOffice = totalOffice + floor.length;
          });

          try {
            let spilt = wing.split("_");
            id = spilt[0];
            value = spilt[1];
          } catch (error) {
            value = wing;
          }
          return (
            <div className="row" id={`wing_${i}`}>
              <div className="col-md-12">
                <div className="card mb-5 mt-5 mb-xl-10">
                  <div className="card-header pt-0">
                    <h3 className="card-title align-items-center ">
                      <div className="d-flex align-items-center">
                        <div style={{ display: "inline-grid" }}>
                          <span className="card-label fw-bolder fs-3">
                            Wing
                          </span>
                          <small style={{ marginTop: "3px" }}>
                            <b style={{ color: "red" }} title="Used Offices">
                              {getTotalOffice(wing)}
                            </b>
                            /<span title="Total Offices">{totalOffice}</span>
                          </small>
                        </div>

                        <div className="col-md-6 ">
                          <div className="form-group">
                            <label className="fw-bolder mb-2">Wing Name</label>
                            <input
                              value={value}
                              id={id}
                              className="form-control w-150px text-uppercase"
                              onChange={(e) => {
                                if (e.target.value) {
                                  // let obj = { ...buildingObj };
                                  // obj[e.target.value] = obj[wing];
                                  // delete obj[wing];
                                  // setBuildingObj(obj);
                                  const newObject = {};
                                  delete Object.assign(newObject, buildingObj, {
                                    [e.target.id +
                                      "_" +
                                      e.target.value]: buildingObj[wing],
                                  })[wing];
                                  setBuildingObj(newObject);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      :
                      {/*begin::Dialer*/}
                      <div className="col-md-6 ">
                        <div className="form-group">
                          <label className="fw-bolder mb-2">No Of Floor</label>
                          <div className="position-relative w-lg-150px text-center">
                            {/*begin::Decrease control*/}
                            <button
                              type="button"
                              className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                              onClick={() => {
                                onFloorChange("decr", wing);
                              }}
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen042.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="black"
                                  />
                                  <rect
                                    x="6.01041"
                                    y="10.9247"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                            {/*end::Decrease control*/}
                            {/*begin::Input control*/}
                            <input
                              type="text"
                              className="form-control fs-5 text-center ps-0"
                              value={floorData.length}
                            />
                            {/*end::Input control*/}
                            {/*begin::Increase control*/}
                            <button
                              type="button"
                              className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                              onClick={() => {
                                onFloorChange("incr", wing);
                              }}
                            >
                              {/*begin::Svg Icon | path: icons/duotune/general/gen041.svg*/}
                              <span className="svg-icon svg-icon-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.3"
                                    x={2}
                                    y={2}
                                    width={20}
                                    height={20}
                                    rx={10}
                                    fill="black"
                                  />
                                  <rect
                                    x="10.8891"
                                    y="17.8033"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    transform="rotate(-90 10.8891 17.8033)"
                                    fill="black"
                                  />
                                  <rect
                                    x="6.01041"
                                    y="10.9247"
                                    width={12}
                                    height={2}
                                    rx={1}
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              {/*end::Svg Icon*/}
                            </button>
                            {/*end::Increase control*/}
                          </div>
                        </div>
                      </div>
                      {/*end::Dialer*/}
                    </h3>
                    <div className="card-toolbar">
                      <button
                        className="btn btn-icon custom-btn-light btn-hover-danger btn-sm"
                        onClick={() => {
                          // setSelectedRow(row);
                          // setDeleteModal(true);
                          let obj = { ...buildingObj };
                          // obj[e.target.value] = obj[wing];
                          delete obj[wing];
                          setBuildingObj(obj);
                        }}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                          />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className=" row">
                      {floorData.length &&
                        floorData
                          .sort((a, b) => {
                            let val1 = a;
                            let val2 = b;
                            if (a.includes("_")) {
                              val1 = a.split("_")[0];
                            }
                            if (b.includes("_")) {
                              val2 = b.split("_")[0];
                            }
                            return parseInt(val1) - parseInt(val2);
                          })
                          .map((floor) => {
                            let id = "";
                            let value = "";
                            try {
                              let spilt = floor.split("_");
                              id = spilt[0];
                              if (spilt[0] == 0) {
                                value = spilt[1] + 1 ? spilt[1] + 1 : floor.slice(0, -1);
                              } else {
                                value = spilt[1] ? spilt[1] : floor.slice(0, -1);
                              }
                              // value = spilt[1]
                              // if (floor.endsWith("_")) {
                              //   value = spilt[1] ? split[1] : floor.slice(0, -1);
                              // }
                            } catch (error) {
                              value = floor;
                            }
                            return (
                              <div className="col-md-12">
                                <div className="row mb-3">
                                  <div className="col-md-1  text-left mt-auto mb-auto">
                                    <label className="fw-bolder mb-1 fs-5 mt-3 d-flex align-items-center">
                                      Floor
                                      <input
                                        value={value}
                                        id={id}
                                        className="form-control ml-2 w-50px mr-1 text-uppercase"

                                        onChange={(e) => {
                                          const newObject = {};
                                          let wingObj = buildingObj[wing];
                                          delete Object.assign(
                                            newObject,
                                            wingObj,
                                            {
                                              [e.target.id +
                                                "_" +
                                                e.target.value]: wingObj[floor],
                                            }
                                          )[floor];

                                          let obj = { ...buildingObj };
                                          obj[wing] = newObject;
                                          setBuildingObj(obj);
                                        }}
                                        style={{ fontSize: "10px" }}
                                      />
                                    </label>
                                  </div>
                                  {buildingObj[wing][floor].map((office, i) => {
                                    return (
                                      <div className="col ">
                                        <div
                                          className={`input-group ${isActiveInput(
                                            wing,
                                            floor,
                                            office
                                          )}`}
                                          style={{
                                            minWidth: "155px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <input
                                            id={`${i}_${Math.random()}`}
                                            type="text"
                                            className={`form-control`}
                                            placeholder="Office Number"
                                            // defaultValue={office}
                                            defaultValue={office}
                                            onChange={(e) => {
                                              buildingObj[wing][floor][i] =
                                                e.target.value;
                                            }}
                                          />
                                          <span
                                            className="input-group-text bg-white cursor-pointer"
                                            onClick={() => {
                                              onOfficeChange(
                                                "remove",
                                                wing,
                                                floor,
                                                i
                                              );
                                            }}
                                          >
                                            <i className="far fa-trash-alt fs-7" />
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="col-md-1 ">
                                    <a
                                      onClick={() => {
                                        onOfficeChange("incr", wing, floor);
                                      }}
                                      className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger px-5 "
                                      style={{ marginTop: "10px" }}
                                    >
                                      <i className="fa fa-plus fs-4 text-danger pe-0" />{" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
