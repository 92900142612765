import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../../components";
import {
  convertDate,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../../config";
import { getApiData } from "../../../../Services";
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import toast from "react-hot-toast";

export default function Table({ filters }) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);


  const getTableRecords = () => {
    const params = getParams(filters, currentPage, sizePerPage);

    getApiData(`building/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const changeIsDefault = async (id, status) => {
    // setLoading(true);
    return await getApiData(`building/v1/${id}/`, "put", {
      is_active: status ? true : false,
    }).then((res) => {
      if (!res.status) {
        toast.error(res.message);
      } else {
        getTableRecords();
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ?
            <>
              <div className="row g-5">
                {data.map((row) => (
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-5 position-relative">
                        <div className="ms-0  bg-gray-light rounded p-3 mb-0">
                          <div className="fs-6">Building Name: <b>{row.name}</b></div>
                          <div className="fs-7">Address: <a className="fw-bold">{row.address}</a></div>
                        </div>
                        {/* <div className="fs-7 mt-2">Requested At: <b>{convertDate(row.created_at)}</b>({timeSince(row.created_at)})</div> */}
                        <div className="separator separator-dashed mt-3 mb-2" />
                        {/* <div className="fs-6">Customer Name: <b>{row.customer_name}</b></div> */}
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <div className="fs-8 me-3">Phone: <a href><b>{row.customer_number}</b></a>, </div>
                            <span className="fs-8 me-3">State: <b>{row.state}</b>,</span> <span className="fs-8">City: <b>{row.city}</b></span>
                          </div>
                          <div className="d-flex  align-items-center mr-5">
                            <div className="form-check form-switch form-check-custom form-check-solid me-3 mt-0">
                              <input
                                className="form-check-input h-20px w-30px"
                                type="checkbox"
                                onChange={(e) => {
                                  toast.promise(changeIsDefault(row.id, e.target.checked), {
                                    loading: "Updating...",
                                    success: "Success!",
                                    error: "Failed.",
                                  });
                                }}
                                checked={row.is_active} />
                              <label className="form-check-label" htmlFor="flexSwitch20x30" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                }
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
            </div>
          }
        </div>
      )}
    </>
  );
}
