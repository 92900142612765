import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getApiData } from "../../../Services";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
import { getStateList, getAreaByCity } from "../../../config";

export default function () {

  const [filters, setFilters] = useState({
    business: "",
    startDate: "",
    endDate: "",
    search: "",
    state: "",
    city: "",
    area: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [businessList, setBusinessList] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  useEffect(() => {
    getBusinessList();
  }, []);

  const getBusinessList = () => {
    getApiData(`business/v1`).then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setBusinessList(array);
      }
    })
  }

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "state") {
      obj.city = "";
      obj.area = "";
      setAreaList([]);
    } else if (key === "city") {
      obj.area = "";
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        <TableFilter
          businessList={businessList}
          stateList={stateList}
          cityList={cityList}
          areaList={areaList}
          value={filters}

          onStateSelect={(selectedState) => {
            if (selectedState.city && selectedState.city.length) {
              updateFilter(selectedState.value, "state");
              let city = [];
              selectedState.city.map(
                (item) => (city = [...city, { label: item, value: item }])
              );
              setCityList(city);

            }
          }}
          onCitySelect={async (value) => {
            updateFilter(value, "city");
            const area = await getAreaByCity(value);
            setAreaList(area);
          }}
          onAreaSelect={async (value) => {
            updateFilter(value, "area");
          }}

          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          setFilters={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
  
          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}
  
          setStatusFilter={updateFilter}

          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
              state: "",
              city: "",
              area: "",
              business: "",
              startDate: "",
              endDate: "",
            });
          }}

          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}
          togglePopup={togglePopup}
        />

        <Table
          filters={filters}
          managerList={[]}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
      </div>
    </>
  );
}

