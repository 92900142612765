import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls";
import { PayoutDetail } from "./modals";
import { gradientColors } from "../../../../gradientColor";
import { getApiData } from "../../../Services";
import { Loader } from "../../../components";

export default function () {
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const [businessName, setBusinessName] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [refferalCode, setRefferalCode] = useState("");

    const [userModal, setUserModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        setLoading(true);
        getData();
    }, []);

    const getData = async () => {
        try {
            getApiData(`business/v1/seller-details`, 'get').then((res) => {
                if (res.status) {
                    setLoading(false);
                    setData(res.data);
                    setBusinessName(res?.data?.first_name);
                    setGstNumber(res?.data?.identification_no);
                    setAddress1(res?.data?.address_1);
                    setAddress2(res?.data?.address_2);
                    setCity(res?.data?.city);
                    setState(res?.data?.state);
                    setZip(res?.data?.zip_code);
                    setCountry(res?.data?.country);
                    setRefferalCode(res?.data?.refferal_code);
                }
            });
        } catch (e) {
            console.warn(e);
        }
    }

    const onClickSave = () => {
        if (businessName) {
            const body = {
                first_name: businessName,
                identification_no: gstNumber,
                address_1: address1,
                address_2: address2,
                city: city,
                state: state,
                country: country,
                zip_code: zip,
            };

            getApiData(`business/v1/seller-details/`, 'post', body).then((res) => {
                if (res.status) {
                    toast.success(res.message);
                    getData();
                } else {
                    toast.error(res.message);
                }
            });
        }
    }

    return (
        <>
            <PayoutDetail
                show={userModal}
                groupList={[]}
                data={selectedRow}
                onHide={() => setUserModal(!userModal)}
                onSuccess={(message) => {
                    setUserModal(false);
                    toast.success(message);
                    getData();
                }}
                onError={(message) => {
                    toast.error(message);
                }}
            />

            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className=" d-flex gap-4 mb-5">
                        <div className="position-relative mb-0 mb-md-0">
                            <button
                                className="custom-button btn-primary h-35px fs-7 fw-bold "
                                onClick={() => {
                                    setUserModal(true);
                                }}>
                                Payout Details
                            </button>
                        </div>
                    </div>
                    <div className="row g-5 mb-5">
                        <div className="col-md-12">
                            <div className="card" style={{ borderTop: '2px solid #eb3a22' }}>
                                <div className="card-body p-5 position-relative">
                                    <div className="d-flex flex-stack align-items-center">
                                        <div className=" d-flex align-items-center  w-100 flex-column  text-start">
                                            <div className="ms-0  bg-gray-light rounded p-3 w-100 mb-0 d-flex ">
                                                <div className="ms-0">
                                                    <div className="fs-4 mb-1">Business Name: <b>{businessName ? businessName : '-'}</b></div>
                                                    <div className="fs-7">GST: <a href><b>{gstNumber ? gstNumber : '-'}</b></a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator separator-dashed mt-4" />
                                    <div className="d-flex flex-stack   gap-3 mt-3">
                                        <div className="fs-8 ">Created At: <b>{data?.createAt ? data?.createAt : '-'}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-5">
                            {
                                <div className="card">
                                    <div className="card-header px-7 border-bottom-0">
                                        <h3 className="card-title fw-bold fs-2">Seller Details</h3>
                                    </div>
                                    <div className="card-body px-7 pt-0 position-relative">
                                        <div className="row g-5">
                                            <div className="col-md-12">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">Business Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please enter business name"
                                                    value={businessName}
                                                    onChange={(text) => setBusinessName(text?.target?.value)} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">GST Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please enter GST number"
                                                    value={gstNumber}
                                                    onChange={(text) => setGstNumber(text?.target?.value)} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">Current Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please Enter address"
                                                    value={address1}
                                                    onChange={(text) => setAddress1(text?.target?.value)} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">permanent Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please Enter address"
                                                    value={address2}
                                                    onChange={(text) => setAddress2(text?.target?.value)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">City</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please Enter city"
                                                    value={city}
                                                    onChange={(text) => setCity(text?.target?.value)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">State</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please enter state"
                                                    value={state}
                                                    onChange={(text) => setState(text?.target?.value)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">Pin Code</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please enter pin code"
                                                    value={zip}
                                                    onChange={(text) => setZip(text?.target?.value)} />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor className=" fs-6 fw-bold mb-2">Country</label>
                                                <input
                                                    type="text"
                                                    className="form-control fs-7"
                                                    placeholder="Please enter country"
                                                    value={country}
                                                    onChange={(text) => setCountry(text?.target?.value)} />
                                            </div>
                                            <div className="col-12 text-center" onClick={() => { onClickSave() }}>
                                                <button className="custom-button btn-primary h-35px fs-7 fw-bold ">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )}
        </>
    )
}