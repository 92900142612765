import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";
import toast from "react-hot-toast";

export default function ({ data, show, onHide, onSuccess, onError }) {
    const [sending, setSending] = useState(false);

    const [buildingList, setBuildingList] = useState([]);
    const [wingList, setWingList] = useState([]);
    const [floorList, setFloorList] = useState([]);
    const [officeList, setOfficeList] = useState([]);

    const [officeName, setOfficeName] = useState("");
    const [buildingId, setBuildingId] = useState("");
    const [wingId, setWingId] = useState("");
    const [FloorId, setFloorId] = useState("");
    const [officeNumber, setOfficeNumber] = useState("");

    useEffect(() => {
        getBuildingList();
    }, [data]);

    const getBuildingList = () => {
        getApiData("building/v1/").then((res) => {
            if (res.status) {
                let array = [];
                res.data.map((item) => {
                    array = [
                        ...array,
                        { label: item.name, value: item.id },
                    ];
                });
                setBuildingList(array);
            }
        });
    };

    const getWingByBuilding = async (building) => {
        setBuildingId(building);
        const body = {
            building_id: building,
        }
        return getApiData(`building/v1/details/`, 'post', body).then((res) => {
            if (res.status) {
                let wing = [];
                Object.keys(res.data).map((item) => {
                    wing = [...wing, { label: item, value: item }];
                });
                return wing;
            }
        });
    }

    const getFloorByWing = async (wing) => {
        setWingId(wing);
        const body = {
            building_id: buildingId,
            wing: wing
        }
        return getApiData(`building/v1/details/`, 'post', body).then((res) => {
            console.log("res >>>>>", res);
            if (res.status) {
                let floor = [];
                Object.keys(res.data).map((item) => {
                    floor = [...floor, { label: item, value: item }];
                });
                return floor;
            }
        });
    }

    const getOfficeByFloor = async (floor) => {
        setFloorId(floor);
        const body = {
            building_id: buildingId,
            wing: wingId,
            floor: floor,
        }
        return getApiData(`building/v1/details/`, 'post', body).then((res) => {
            if (res.status) {
                let office = [];
                res.data.map((item) => {
                    office = [...office, { label: item, value: item }];
                });
                return office;
            }
        });
    }

    const onBuildingSelect = async (value) => {
        const wing = await getWingByBuilding(value);
        setWingList(wing);
    }

    const onWingSelect = async (value) => {
        const floor = await getFloorByWing(value);
        setFloorList(floor);
    }

    const onFloorSelect = async (value) => {
        const office = await getOfficeByFloor(value);
        const updatedArray = office.map(item => ({
            ...item,
            value: item.value.toString(),
            label: item.label.toString()
        }));
        setOfficeList(updatedArray);
    }

    const onSubmit = () => {
        if (!officeName) {
            toast.error("Please enter office name");
        } else if (!buildingId) {
            toast.error("Please select building");
        } else if (!wingId) {
            toast.error("Please select wing");
        } else if (!FloorId) {
            toast.error("Please select floor");
        } else if (!officeNumber) {
            toast.error("Please select office number");
        } else {
            setSending(true);
            const body = {
                name: officeName,
                building: buildingId,
                wing: wingId,
                floor: FloorId,
                office_number: officeNumber,
                customer_id: data.customer
            };

            getApiData(`office/v1/`, "post", body).then((res) => {
                setSending(false);
                if (res.status) {
                    onSuccess(res.message);
                    onHide();
                } else {
                    if (typeof res.message === "string") {
                        onError(res.message);
                    } else {
                        onError("Something went wrong!");
                    }
                }
            });
        };
    };

    const clearInput = () => {
        setOfficeName("");
        setBuildingId("");
        setWingId("");
        setFloorId("");
        setOfficeNumber("");
    }

    return (
        <Modal show={show} onHide={onHide} dialogClassName="bottom-popup">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add Customer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="scroll-popup">
                <div className="row">
                    <div className="form-group mb-4">
                        <label htmlFor className=" fs-6 fw-bold mb-2 ">Office Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={officeName}
                            onChange={(e) => {
                                setOfficeName(e.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor className=" fs-6 fw-bold mb-2 ">Building</label>
                        <GeneralDropdown
                            className="form-select fs-7 "
                            list={buildingList}
                            handleChange={(obj) => {
                                onBuildingSelect(obj.value);
                            }}
                            inModal
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor className=" fs-6 fw-bold mb-2 ">Wing</label>
                        <GeneralDropdown
                            className="form-select fs-7 "
                            list={wingList}
                            handleChange={(obj) => {
                                onWingSelect(obj.value);
                            }}
                            inModal
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor className=" fs-6 fw-bold mb-2 ">Floor</label>
                        <GeneralDropdown
                            className="form-select fs-7 "
                            list={floorList}
                            handleChange={(obj) => {
                                onFloorSelect(obj.value);
                            }}
                            inModal
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor className=" fs-6 fw-bold mb-2 ">Office</label>
                        <GeneralDropdown
                            className="form-select fs-7 "
                            list={officeList}
                            handleChange={(obj) => {
                                setOfficeNumber(obj.value);
                            }}
                            inModal
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={() => {
                            onHide();
                            clearInput();
                        }}
                        className="custom-custom-btn-light px-10"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={onSubmit}
                        disabled={!sending ? false : true}
                        style={{ width: "100px" }}
                        className="btn-primary custom-button font-weight-bold"
                    >
                        Save
                        {sending && (
                            <Spinner animation="border" size="sm" className="ml-1" />
                        )}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
