import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ({ show, onHide, data, onSuccess, onError }) {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        console.log("data >>>>>", data);
    }, [data]);

    return (
        <Modal size="lg" show={show} onHide={onHide} dialogClassName="bottom-popup">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg" className="w-100">
                    <h3 className="modal-title">Payout Details</h3>
                    <small>
                        <b>ID: </b>
                        {data?.payout_details?.id ? data?.payout_details?.id : data?.id}
                    </small>
                    <br />
                    <small>
                        <b>Payment Status: </b>
                        <span className="fs-base text-primary fw-bold">
                            {data?.payout_details?.status ?
                                data?.payout_details?.status
                                : data?.payment_status ? 'Success' : 'Pending'}
                        </span>
                    </small>
                </Modal.Title>
                <button
                    type="button"
                    className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
                    onClick={onHide}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    <i className="ki-outline ki-cross fs-1" />
                </button>
            </Modal.Header>

            <Modal.Body className="pt-3 scroll-popup">
                <div className="modal-body pb-7 pt-7 ps-lg-7 pe-0">
                    <div className="pe-7 ps-3" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div className="row g-5 mb-0 align-items-center">
                            <div className="col-md-12">
                                <div className="row g-5 mb-0 align-items-center">
                                    <div className="col-md-12">
                                        <div className="fs-6 mb-2">Business Partner: <b>{data?.first_name}</b></div>
                                        <div className="fs-7 mb-2">Phone: <a href><b>{data?.phone_number}</b></a></div>
                                        {data?.account_holder_name && <div className="fs-7 mb-2">Account Holder Name: <b>{data?.account_holder_name}</b></div>}
                                        {data?.account_number && <div className="fs-7 mb-2">Account Number.: <b>{data?.account_number}</b></div>}
                                        {data?.upi_holder_name && <div className="fs-7 mb-2">UPI Holder Name: <b>{data?.upi_holder_name}</b></div>}
                                        {data?.upi && <div className="fs-7 mb-2">UPI Id : <b>{data?.upi}</b></div>}
                                        {data?.payout_details?.fees && <div className="mb-2">Fees: <b>₹{data?.payout_details?.fees ? data?.payout_details?.fees : 0}</b></div>}
                                        {data?.payout_details?.tax && <div className="mb-2">Tax: <b>₹{data?.payout_details?.tax ? data?.payout_details?.tax : 0}</b></div>}
                                        {data?.payout_details?.utr && <div className="mb-2">UTR: <b>{data?.payout_details?.utr}</b></div>}
                                    </div>
                                    <div className="separator separator-dashed border-gray-300" />
                                    <div className="col-md-12">
                                        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
                                            <thead>
                                                <tr className="text-left text-uppercase">
                                                    <th className="pl-4">#</th>
                                                    <th className="text-center">Order Id</th>
                                                    <th className="text-right pr-4">Price</th>
                                                </tr>
                                            </thead>
                                            <>
                                                {data?.orders ? (
                                                    Object.keys(data?.orders).map((orderId, i) => {
                                                        return (
                                                            <tr>
                                                                <td className="pl-4">{++i}</td>
                                                                <td className="text-center">{orderId}</td>
                                                                <td className="fw-bold text-right pr-4">₹{data?.orders[orderId]}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="9">
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                            <tfoot>
                                                <tr style={{ borderTop: '1px dashed #ddd' }}>
                                                    <th />
                                                    <th />
                                                    <th className="text-end fs-6 pr-4"><b>Amount: ₹{data.amount}</b></th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
