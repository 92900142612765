import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LoaderPopup } from "../../../../components";
import { convertDate, PER_PAGE } from "../../../../config";
import { getApiData } from "../../../../Services";
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export default function ({ data, show, onHide }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    console.log("data >>>>", data);
    if (data.id) {
      setLoading(true);
      getList();
    }
  }, [data, activePage]);

  const getList = () => {
    let param = "";
    let offset = (activePage - 1) * PER_PAGE;
    param += `?limit=${PER_PAGE}&offset=${offset}`;
    getApiData(`order/v1/history/previous${param}&customer_id=${data.customer}`).then(
      (res) => {
        setLoading(false);
        if (res.status) {
          let apiData = res.data;
          apiData.length &&
            apiData.map((item, i) => {
              let value = ++i;
              if (activePage > 1)
                value = PER_PAGE * activePage - PER_PAGE + value;
              item.sr_no = value;
            });
          setTotalRecords(res.count);
          setList(apiData);
        }
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Body className="scroll-popup">
        <Modal.Title id="example-modal-sizes-title-lg">
          Order History
        </Modal.Title>
        <>
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3 mt-5">
            <thead>
              <tr className="text-left text-uppercase">
                <th className="pl-4">#</th>
                <th>Order ID</th>
                <th>Delivery Boy</th>
                <th>Building</th>
                <th>Order Type</th>
                <th>Total</th>
                <th>Payment Method</th>
                <th>Delivery Date</th>
              </tr>
            </thead>
            {loading ? (
              <tr>
                <td colSpan="9">
                  <LoaderPopup />
                </td>
              </tr>
            ) : (
              <>
                {list.length ? (
                  list.map((item, i) => {
                    return (
                      <tr>
                        <td className="pl-4">{item.sr_no}</td>
                        <td>{item.order_id}</td>
                        <td>{item.delivery_boy}</td>
                        <td>
                          {item.building} ({item.office})
                        </td>
                        <td style={{ minWidth: '100px' }}>
                          {item.order_type == 1 ?
                            <span>{'Scheduled'}<br /></span>
                            : item.order_type == 2 ?
                              <span>{'Manual'}<br /></span> :
                              item.order_type == 3 ?
                                <span>{'Pre Order'}<br /></span> :
                                item.order_type == 4 ?
                                  <span>{'Settlement'}<br /></span> :
                                  <span></span>
                          }
                          {item.is_on_tap && <span>{"On Tap"}<br /></span>}
                          {item.is_custom && <span>{"Custom"}</span>}
                          {item.order_type == 3 &&
                            <>
                              {item.is_takeaway ? <span>{"Take Away"}</span> : <span>{"Dine In"}</span>}
                            </>
                          }
                        </td>
                        <td style={{ fontWeight: '700' }}>{`₹${item.total}`}</td>
                        <td>{item.payment_method}</td>
                        <td>
                          <span>{convertDate(item.delivered_at)}</span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            )}
          </table>
          {totalRecords > 0 && (
            <div className="mt-2 float-right mr-3">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={PER_PAGE}
                totalItemsCount={totalRecords}
                pageRangeDisplayed={5}
                itemClass="d-flex justify-content-between align-items-center flex-wrap"
                linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                onChange={(pageNumber) => {
                  setActivePage(pageNumber);
                }}
              />
            </div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn custom-btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
