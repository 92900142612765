import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Loader, LoaderPopup } from "../../../../components";
import { getApiData } from "../../../../Services";
import { convertDate, PER_PAGE } from "../../../../config";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Pagination from "react-js-pagination";

export default function ({ data, show, onHide }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (data.id) {
      setLoading(true);
      getList();
    }
  }, [data]);

  const getList = () => {
    let param = "";
    let offset = (activePage - 1) * PER_PAGE;
    param += `?limit=${PER_PAGE}&offset=${offset}`;
    getApiData(`order/v1/invoice${param}&customer=${data.customer}`).then(
      (res) => {
        setLoading(false);
        if (res.status) {
          let apiData = res.data;
          apiData.length &&
            apiData.map((item, i) => {
              let value = ++i;
              if (activePage > 1)
                value = PER_PAGE * activePage - PER_PAGE + value;
              item.sr_no = value;
            });
          setTotalRecords(res.count);
          setList(apiData);
        }
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Body className="scroll-popup">
        <Modal.Title id="example-modal-sizes-title-lg">
          Invoice History
        </Modal.Title>
        <>
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3 mt-5">
            <thead>
              <tr className="text-left text-uppercase">
                <th className="pl-4">#</th>
                <th>Month</th>
                <th>Invoice Id</th>
                <th>Payment Method</th>
                <th>Total</th>
                <th>Date</th>
              </tr>
            </thead>
            {loading ? (
              <tr>
                <td colSpan="9">
                  <LoaderPopup />
                </td>
              </tr>
            ) : (
              <>
                {Object.keys(list).length ? Object.keys(list).map((month, index) => (
                  list[month].map((order, subIndex) => {
                    return (
                      <>
                        <tr key={`${index}_${subIndex}`}>
                          <td className="pl-4">{index * list[month].length + subIndex + 1}</td>
                          <td>{month}</td>
                          <td>{order.order_id}</td>
                          <td>{order.payment_method == 1 ? 'Online' : 'Pay on Delivery'}</td>
                          <td style={{ fontWeight: '700' }}>{`₹${order.total}`}</td>
                          <td>{convertDate(order.created_at)}</td>
                        </tr>
                      </>
                    )
                  })
                )) :
                  <tr>
                    <td colSpan="9">
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                      </div>
                    </td>
                  </tr>
                }
              </>
            )}
          </table>
          {totalRecords > 0 && (
            <div className="mt-2 float-right mr-3">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={PER_PAGE}
                totalItemsCount={totalRecords}
                pageRangeDisplayed={5}
                itemClass="d-flex justify-content-between align-items-center flex-wrap"
                linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                onChange={(pageNumber) => {
                  setActivePage(pageNumber);
                }}
              />
            </div>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn custom-btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
