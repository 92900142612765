import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Loader } from "../../../../components";
import {
  convertDate,
  formateAmount,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../../config";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import {
  AddCreditModal,
  CreditModal,
  InvoiceHistoryModal,
  OfficeModal,
  OrderHistoryModal,
  TransactionHistoryModal,
  AddCustomer,
  AddOffice,
} from "./../modals";
import Pagination from "react-js-pagination";

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [creditModal, setCreditModal] = useState(false);
  const [addCreditModal, setAddCreditModal] = useState(false);
  const [officeModal, setOfficeModal] = useState(false);
  const [transactionHistoryModal, setTransactionHistoryModal] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [invoiceHistoryModal, setInvoiceHistoryModal] = useState(false);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [addOfficeModal, setAddOfficeModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`business/v1/customers/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        props.setTotalDues(res.total_dues);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        // alert(authToken);
        history.push("/logout");
      }
    });
  };

  const onClickKhataToggle = (data, flag) => {
    setLoading(true);
    const body = {
      customer: data.customer,
      credit_amount: flag ? 5000 : 0,
    };

    getApiData(`business/v1/customers/`, "post", body).then((res) => {
      setLoading(false);
      if (res.status) {
        toast.success(res.message);
        getTableRecords();
      } else {
        if (typeof res.message === "string") {
          toast.error(res.message);
        } else {
          toast.error("Something went wrong!");
        }
      }
    });
  };

  const settleAmount = (customerId) => {
    getApiData(`customer/v1/dues/${customerId}/`, "put").then((res) => {
      if (res.status) {
        toast.success(res.message);
        getTableRecords();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <CreditModal
        show={creditModal}
        data={selectedRow}
        onHide={() => setCreditModal(false)}
        onSuccess={(message) => {
          setCreditModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <AddCreditModal
        show={addCreditModal}
        data={selectedRow}
        onHide={() => setAddCreditModal(false)}
        onSuccess={(message) => {
          setAddCreditModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {transactionHistoryModal && (
        <TransactionHistoryModal
          show={transactionHistoryModal}
          data={selectedRow}
          onHide={() => setTransactionHistoryModal(false)}
        />
      )}
      {orderHistoryModal && (
        <OrderHistoryModal
          show={orderHistoryModal}
          data={selectedRow}
          onHide={() => setOrderHistoryModal(false)}
        />
      )}
      {invoiceHistoryModal && (
        <InvoiceHistoryModal
          show={invoiceHistoryModal}
          data={selectedRow}
          onHide={() => setInvoiceHistoryModal(false)}
        />
      )}
      {officeModal && (
        <OfficeModal
          show={officeModal}
          data={selectedRow}
          onHide={() => setOfficeModal(false)}
        />
      )}

      <AddCustomer
        show={addCustomerModal || props.isPopupOpen}
        data={selectedRow}
        onHide={() => {
          setAddCustomerModal(false);
          props.setIsPopupOpen(false);
        }}
        onSuccess={(message) => {
          setAddCreditModal(false);
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <AddOffice
        show={addOfficeModal}
        data={selectedRow}
        onHide={() => {
          setAddOfficeModal(false);
        }}
        onSuccess={(message) => {
          setAddOfficeModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {data.length !== 0 ? (
            <>
              <div className="row g-5">
                <div className="col-md-12">
                  {data.map((row) => (
                    <div className="card mb-5">
                      <div className="card-body p-5 position-relative">
                        <div className="d-flex flex-stack align-items-center">
                          <div className=" d-flex align-items-center min-w-150px">
                            <div className="d-flex flex-column">
                              <div
                                className="form-check form-switch form-check-custom form-check-solid me-5 mt-0 mb-2"
                                style={{ paddingLeft: "0rem" }}
                              >
                                <label
                                  className="form-check-label me-3 text-800 fw-bolder ml-0"
                                  htmlFor="flexSwitch20x30"
                                >
                                  Khata
                                </label>
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    onClickKhataToggle(row, e.target.checked);
                                  }}
                                  checked={
                                    row.negative_upto !== 0 ? true : false
                                  }
                                />
                              </div>
                              <div className="ms-0 bg-gray-light rounded p-3">
                                <div className="fs-6">
                                  Name: <b>{row.first_name}</b>
                                </div>
                                <div className="fs-8">
                                  Phone:{" "}
                                  <a href>
                                    <b>{row.phone_number}</b>
                                  </a>
                                </div>
                                <div className="fs-8 mt-2">
                                  Area: <b>{row?.office?.area ?? "-"}</b>
                                </div>
                                <div className="fs-8">
                                  Building:{" "}
                                  <b>{row?.office?.building ?? "-"}</b>
                                </div>
                                <div className="fs-8">
                                  Office Name: <b>{row?.office?.name ?? "-"}</b>
                                </div>
                                <div className="fs-8">
                                  Wing: <b>{row?.office?.wing ?? "-"}</b>,
                                  Office No. :
                                  <b>
                                    <>
                                      {row?.office?.office ?? "-"}
                                      {row.office_count > 1 && (
                                        <>
                                          <br />
                                          <a
                                            className="cursor-pointer "
                                            onClick={() => {
                                              setOfficeModal(true);
                                              setSelectedRow(row);
                                            }}
                                            href="#"
                                          >
                                            {row.office_count} more offices
                                          </a>
                                        </>
                                      )}
                                    </>
                                  </b>
                                </div>
                                {!row?.office?.name && (
                                  <div>
                                    <a
                                      className="custom-button btn-primary mt-2 h-30px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center"
                                      onClick={() => {
                                        setAddOfficeModal(true);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      Add Office
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="min-w-150px ml-2">
                            <div className="rounded text-start mb-1 text-right">
                              Wallet balance:{" "}
                              <span className="fs-6 fw-bolder">
                                {row.available_credit
                                  ? "₹ " + row.available_credit
                                  : "₹0"}
                              </span>
                            </div>
                            <div className="rounded text-start mb-1 text-right">
                              Used from khata:{" "}
                              <span className="fs-6 fw-bolder">{`${
                                row.used_credit ? "₹" + row.used_credit : "₹0"
                              }`}</span>
                              {row.used_credit > 0 && (
                                <button
                                  className="custom-button h-35px btn-primary ml-3"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want settle this amount?"
                                      )
                                    ) {
                                      settleAmount(row.customer);
                                    }
                                  }}
                                >
                                  Settle
                                </button>
                              )}
                            </div>
                            {/* <div className="rounded text-start mb-1 text-right">Invoice Dues: <span className="fs-6 fw-bolder text-danger">
                              <span
                                className={`${row.invoice_dues > 0 ? "text-danger font-weight-bold text-right" : ""}`}>
                                <span>{row.invoice_dues == 0 ? '' : ''}</span>
                                ₹{row.invoice_dues}
                              </span>
                            </span></div> */}
                            <div className="rounded text-start mb-3 text-right">
                              Khata:{" "}
                              <span className="fs-6 fw-bolder text-danger">
                                {`${
                                  row.negative_upto !== 0
                                    ? "₹" + row.negative_upto
                                    : "₹0"
                                }`}
                              </span>
                              <span
                                className="svg-icon svg-icon-sm ml-2 cursor-pointer"
                                onClick={() => {
                                  setCreditModal(true);
                                  setSelectedRow(row);
                                }}
                              >
                                <i className="ki-outline ki-pencil text-primary fs-1.5x ml-1" />
                              </span>
                            </div>
                            {/* <div className="fs-8 text-right">Transaction:{" "}
                              <a
                                href
                                className="text-danger"
                                onClick={() => {
                                  setTransactionHistoryModal(true);
                                  setSelectedRow(row);
                                }}>
                                <b>View</b>
                              </a>
                            </div> */}
                            <div className="fs-8 text-right">
                              Order:{" "}
                              <a
                                href
                                className="text-danger"
                                onClick={() => {
                                  setOrderHistoryModal(true);
                                  setSelectedRow(row);
                                }}
                              >
                                <b>View</b>
                              </a>
                            </div>
                            <div className="fs-8 text-right">
                              Invoice:{" "}
                              <a
                                href
                                className="text-danger"
                                onClick={() => {
                                  setInvoiceHistoryModal(true);
                                  setSelectedRow(row);
                                }}
                              >
                                <b>View</b>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* <div className="separator separator-dashed mt-4" />
                        <div className=" row mt-3 align-items-center">
                          <div className="col-9">
                            <div className="fs-8 ">Created At: <b>{convertDate(row.created_at)}</b> ({timeSince(row.created_at)})</div>
                          </div>
                          <div className="col-3 my-1">
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={20}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/logos/no-data.png")}
                height={"150px"}
                width={"150px"}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
