import React, { useState } from "react";
import { gradientColors } from "../../../../../gradientColor";
import { GeneralDropdown } from "../../../../components";

export default function (props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = useState(null);

  const statusList = [
    { value: "1", label: "Enabled" },
    { value: "0", label: "Disabled" },
  ];

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="w-100px ">
          <GeneralDropdown
            className="form-select border-0 fs-7"
            data-placeholder="Select Status"
            list={statusList}
            handleChange={(obj) => {
              props.setValue("product_status", obj.value);
            }}
            // selectedOption={props.value.status ? props.value.status : null}
            clearFilter={clearFilter}
          />
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-100 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by product name & Description..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button
              className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}
            >
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>

        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button
            className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setClearFilter(Math.random());
            }}
          >
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
        <div className="position-relative mb-0 mb-md-0">
          <button
            className="custom-button btn-primary w-125px h-35px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center"
            onClick={() => {
              props.togglePopup();
            }}
          >
            Add New Product
          </button>
        </div>
        <div className="position-relative mb-0 mb-md-0">
          <button
            className="custom-button btn-primary w-125px h-35px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center"
            onClick={() => {
              props.showGst();
            }}
          >
            Bulk GST
          </button>
        </div>
      </div>
    </>
  );
}
