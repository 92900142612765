import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { Link } from "react-router-dom";
import { QRCodeModal } from "./modals";
import { connect, useSelector, shallowEqual } from "react-redux";
import { getApiData } from "../../../../app/Services";

function Topbar(props) {
  const [isShowQR, setIsShowQR] = useState(false);

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [temporarilyClosed, setTemporarilyClosed] = useState(false);

  const history = useHistory();
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  useEffect(() => {
    if (user?.user_type !== "admin") {
      getUserInfo();
    }
  }, [user]);

  const getUserInfo = () => {
    getApiData("business/v1/profile/").then((res) => {
      if (res.status) {
        setTemporarilyClosed(res.data.temporarily_closed);
      }
    });
  };

  const changeStatus = async () => {
    // // setLoading(true);
    const body = {
      business: user?.id,
    };
    return await getApiData(
      `business/v1/temporarily-closed/`,
      "post",
      body
    ).then((res) => {
      toast.dismiss();
      if (!res.status) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        getUserInfo();
      }
    });
  };

  return (
    <div className="topbar col-md-12">
      <QRCodeModal show={isShowQR} onHide={() => setIsShowQR(!isShowQR)} />
      <div className="col-md-6 d-flex align-items-center float-left">
        {user?.user_type !== "admin" && (
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              paddingRight: "60px",
              paddingLeft: "10px",
            }}
          >
            <label
              className="col-form-label "
              style={{
                fontSize: "0.95rem",
              }}
            >
              Temporarily Closed
            </label>
            <div
              className="form-check  form-switch form-check-custom form-check-solid"
              style={{
                paddingLeft: "1rem",
              }}
            >
              <input
                className="form-check-input h-25px w-50px"
                type="checkbox"
                onChange={(e) => {
                  toast.loading("updating...");
                  changeStatus();
                }}
                checked={temporarilyClosed}
              />
              <label className="form-check-label" htmlFor="flexSwitch20x30" />
            </div>
          </div>
        )}
        {/* {localStorage.getItem("adminData") && (
          <>
            <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
              <button style={{ height: "35px" }} className="btn btn-sm btn-primary mt-1 mr-3">
                <span className="svg-icon svg-icon-md svg-icon-white ml-2">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M6 1v2H5c-1.11 0-2 .89-2 2v14a2 2 0 002 2h6.1c1.26 1.24 2.99 2 4.9 2 3.87 0 7-3.13 7-7 0-1.91-.76-3.64-2-4.9V5a2 2 0 00-2-2h-1V1h-2v2H8V1M5 5h14v2H5m0 2h14v.67c-.91-.43-1.93-.67-3-.67-3.87 0-7 3.13-7 7 0 1.07.24 2.09.67 3H5m11-7.85c2.68 0 4.85 2.17 4.85 4.85 0 2.68-2.17 4.85-4.85 4.85-2.68 0-4.85-2.17-4.85-4.85 0-2.68 2.17-4.85 4.85-4.85M15 13v3.69l3.19 1.84.75-1.3-2.44-1.41V13z" />
                  </svg>
                </span>
              </button>
            </Link>
          </>
        )} */}
      </div>

      <div
        className="col-md-6 float-right"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignSelf: "center",
        }}
      >
        {user.user_type !== "admin" && (
          <button
            // style={{ width: "140px", height: "35px" }}
            className="custom-button btn-primary fs-9 mr-3 py-0"
            onClick={() => {
              setIsShowQR(true);
            }}
          >
            QR Code
          </button>
        )}

        {localStorage.getItem("adminData") && (
          // <div className="col-8">
          <button
            className="custom-button btn-primary fs-9 mr-3 py-0"
            onClick={() => {
              props.backToAdmin();
              toast.success("Success");
              localStorage.setItem("userEvent", Date.now().toString());
              history.push("/dashboard");
              localStorage.removeItem("websocket");
              // window.location.reload();
            }}
          >
            Back to admin
          </button>
          // </div>
        )}

        {/* {localStorage.getItem("adminData") && (
          <button
            // style={{ width: "140px", height: "35px" }}
            className="custom-button btn-primary fs-9 mr-3 py-0"
            onClick={() => {
              setIsShowQR(true);
            }}
          >
            QR Code
          </button>
        )} */}

        {/* {localStorage.getItem("adminData") && (
          // <div className="col-8">
          <button
            className="custom-button btn-primary fs-9 mr-3 py-0"
            onClick={() => {
              props.backToAdmin();
              toast.success("Success");
              history.push("/dashboard");
              localStorage.removeItem('websocket');
              // window.location.reload();
            }}
          >
            Back to admin
          </button>
          // </div>
        )} */}

        {/* <LanguageSelectorDropdown /> */}

        {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

        {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

        {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

        {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

        {layoutProps.viewQuickPanelDisplay && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
          >
            <div
              className="topbar-item"
              data-toggle="tooltip"
              title="Quick panel"
              data-placement="right"
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1"
                id="kt_quick_panel_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
              </div>
            </div>
          </OverlayTrigger>
        )}

        {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(Topbar));
