import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { PER_PAGE, getParams, sizePerPageList } from "../../../../config";
import SVG from "react-inlinesvg";

function AddProduct(props) {
    const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            setLoading(true);
            getTableRecords();
        }
    }, [props.filters]);

    useEffect(() => {
        setLoading(true);
        getTableRecords();
    }, [sizePerPage, currentPage]);

    const getTableRecords = () => {
        // const params = getParams(props.filters, currentPage, sizePerPage);
        // getApiData(`business/v1/dashboard/${params}`).then((res) => {
        //   setLoading(false);
        //   if (res.status) {
        //     let apiData = res.data;
        //     // let pagination = res.data.pagination;
        //     apiData.length &&
        //       apiData.map((item, i) => {
        //         let value = ++i;
        //         if (currentPage > 1)
        //           value = sizePerPage * currentPage - sizePerPage + value;
        //         item.sr_no = value;
        //       });
        //     setTotalRecords(res.data.length);
        //     setData(res.data);
        //   }
        // });
        const res = {
            status: true,
            data: [
                {
                    category: {
                        id: 'a1b2',
                        name: "Lunch",
                        image: "/media/svg/icons/Food/spaghetti.svg",
                        options: 8,
                        products: [
                            { id: '123', name: 'T-Bone Stake', image: "/media/food/img-2.jpg", minutes_cook: 22, price: 150, quantity: 1, is_available: true },
                            { id: '234', name: 'Chef’s Salmon', image: "/media/food/img-3.jpg", minutes_cook: 25, price: 130, quantity: 1, is_available: true },
                            { id: '456', name: 'Tenderlion Steak', image: "/media/food/img-4.jpg", minutes_cook: 20, price: 120, quantity: 1, is_available: true },
                            { id: '789', name: 'Breakfast', image: "/media/food/img-10.jpg", minutes_cook: 10, price: 180, quantity: 1, is_available: true },
                            { id: '901', name: 'Sweety', image: "/media/food/img-11.jpg", minutes_cook: 12, price: 120, quantity: 1, is_available: false },
                        ],
                    },
                },
                {
                    category: {
                        id: 'b2c3',
                        name: "Salad",
                        image: "/media/svg/icons/Food/salad.svg",
                        options: 8,
                        products: [
                            { id: 'abc', name: 'Pancakes', image: "/media/food/img-3.jpg", minutes_cook: 16, price: 119, quantity: 1, is_available: false },
                            { id: 'bcd', name: 'Sweety', image: "/media/food/img-7.jpg", minutes_cook: 14, price: 199, quantity: 1, is_available: true },
                            { id: 'cde', name: 'Chef’s Salmon', image: "/media/food/img-9.jpg", minutes_cook: 18, price: 189, quantity: 1, is_available: true },
                            { id: 'def', name: 'Breakfast', image: "/media/food/img-11.jpg", minutes_cook: 12, price: 165, quantity: 1, is_available: true },
                            { id: 'efg', name: 'T-Bone Stake', image: "/media/food/img-2.jpg", minutes_cook: 19, price: 150, quantity: 1, is_available: true },
                        ],
                    },
                },
                {
                    category: {
                        id: 'c3d4',
                        name: "Burger",
                        image: "/media/svg/icons/Food/cheeseburger.svg",
                        options: 8,
                        products: [
                            { id: 'a1b2', name: 'Breakfast', image: "/media/food/img-4.jpg", minutes_cook: 15, price: 150, quantity: 1, is_available: true },
                            { id: 'b2c3', name: 'Pancakes', image: "/media/food/img-8.jpg", minutes_cook: 25, price: 110, quantity: 1, is_available: false },
                            { id: 'c3d4', name: 'T-Bone Stake', image: "/media/food/img-11.jpg", minutes_cook: 20, price: 90, quantity: 1, is_available: true },
                            { id: 'd4e5', name: 'Chef’s Salmon', image: "/media/food/img-3.jpg", minutes_cook: 10, price: 130, quantity: 1, is_available: true },
                            { id: 'e5f6', name: 'Pancakes', image: "/media/food/img-9.jpg", minutes_cook: 30, price: 150, quantity: 1, is_available: true },
                        ],
                    },
                },
                {
                    category: {
                        id: 'd4e5',
                        name: "Coffee",
                        image: "/media/svg/icons/Food/coffee.svg",
                        options: 8,
                        products: [
                            { id: '11aa', name: 'Pancakes', image: "/media/food/img-5.jpg", minutes_cook: 25, price: 150, quantity: 1, is_available: true },
                            { id: '22bb', name: 'Chef’s Salmon', image: "/media/food/img-10.jpg", minutes_cook: 36, price: 180, quantity: 1, is_available: false },
                            { id: '33cc', name: 'Breakfast', image: "/media/food/img-4.jpg", minutes_cook: 46, price: 140, quantity: 1, is_available: true },
                            { id: '44dd', name: 'Sweety', image: "/media/food/img-8.jpg", minutes_cook: 26, price: 220, quantity: 1, is_available: true },
                            { id: 'ee55', name: 'T-Bone Stake', image: "/media/food/img-7.jpg", minutes_cook: 16, price: 240, quantity: 1, is_available: true },
                        ],
                    },
                },
                {
                    category: {
                        id: 'e5f6',
                        name: "Dessert",
                        image: "/media/svg/icons/Food/cheesecake.svg",
                        options: 8,
                        products: [
                            { id: 'abc1', name: 'T-Bone Stake', image: "/media/food/img-2.jpg", minutes_cook: 10, price: 140, quantity: 1, is_available: true },
                            { id: 'xyz2', name: 'Sweety', image: "/media/food/img-4.jpg", minutes_cook: 16, price: 160, quantity: 1, is_available: true },
                            { id: 'efg3', name: 'Breakfast', image: "/media/food/img-7.jpg", minutes_cook: 50, price: 120, quantity: 1, is_available: true },
                            { id: 'psk4', name: 'Chef’s Salmon', image: "/media/food/img-9.jpg", minutes_cook: 14, price: 140, quantity: 1, is_available: false },
                            { id: 'sm5', name: 'Pancakes', image: "/media/food/img-3.jpg", minutes_cook: 13, price: 150, quantity: 1, is_available: true },
                        ],
                    }
                },
            ],
        }

        if (res.status) {
            let apiData = res.data;
            // let pagination = res.data.pagination;
            apiData.length &&
                apiData.map((item, i) => {
                    let value = ++i;
                    if (currentPage > 1)
                        value = sizePerPage * currentPage - sizePerPage + value;
                    item.sr_no = value;
                });
            setTotalRecords(res.data.length);
            setData(res.data);
        }
    };

    const onClickCategory = (index) => {
        const updatedData = data.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    category: {
                        ...item.category,
                        is_active: true,
                    },
                };
            } else {
                const { is_active, ...category } = item.category;
                return {
                    ...item,
                    category: category,
                };
            }
        });
        setData(updatedData);
    }

    const handleClick = (product) => {
        const isSelected = selectedProducts.some(selectedProduct => selectedProduct.id === product.id);

        if (isSelected) {
            const updatedProducts = selectedProducts.filter(selectedProduct => selectedProduct.id !== product.id);
            setSelectedProducts(updatedProducts);
        } else {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleIncreaseQuantity = (item) => {
        const updatedItem = { ...item, quantity: item.quantity + 1 };
        updateItem(updatedItem);
    };

    const handleDecreaseQuantity = (item) => {
        if (item.quantity > 1) {
            const updatedItem = { ...item, quantity: item.quantity - 1 };
            updateItem(updatedItem);
        }
    };

    const updateItem = (updatedItem) => {
        const updatedProducts = selectedProducts.map((product) => {
            if (product.id === updatedItem.id) {
                return updatedItem;
            }
            return product;
        });
        setSelectedProducts(updatedProducts);
    };

    const subtotal = selectedProducts.reduce((total, product) => {
        return total + (product.price * product.quantity);
    }, 0);

    const handleDelete = (index) => {
        const updatedProducts = [...selectedProducts];
        updatedProducts.splice(index, 1);
        setSelectedProducts(updatedProducts);
    };

    return (
        <div className="d-flex mb-7 mt-8">
            <div className="col-md-8">
                <div className="card card-flush bg-transparent card-p-0 border-0">
                    <div className="card-body">
                        <ul className="nav nav-pills d-flex justify-content-center nav-pills-custom gap-5 mb-6" role="tablist">
                            {data &&
                                data.map((item, index) => {
                                    return (
                                        <>
                                            <li className="nav-item mb-3 me-0" role="presentation" key={item.category.id}>
                                                <>
                                                    <button
                                                        className="nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg "
                                                        style={{ width: "138px", height: "180px", border: item?.category?.is_active ? "3px solid #eb3a22" : "3px solid #DBDFE9", }}
                                                        onClick={() => onClickCategory(index)}
                                                    >
                                                        <div className="mb-3">
                                                            <img src={toAbsoluteUrl(`${item?.category?.image}`)} className="w-50px" alt="" />
                                                        </div>
                                                        <div>
                                                            <span className="text-primary fw-bold fs-2 d-block">{item?.category?.name}</span>
                                                            <span className="text-primary fw-semibold fs-7">{`${item?.category?.options} Options`}</span>
                                                        </div>
                                                    </button>
                                                </>
                                            </li>
                                        </>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="tab-content bg-transparent">
                    <div className="tab-pane fade active show" id="kt_pos_food_content_1" role="tabpanel">
                        <div className="d-flex flex-wrap d-grid gap-5 gap-xxl-9">
                            <ul className="nav nav-pills d-flex justify-content-center nav-pills-custom gap-5 mb-6" role="tablist">
                                {data &&
                                    data.map((item, index) => {
                                        const activeCategories = data.filter((item) => item.category.is_active);
                                        if (activeCategories.length !== 0) {
                                            return (
                                                item?.category?.is_active && item?.category?.products?.map((product) => {
                                                    const isSelected = selectedProducts.some(selectedProduct => selectedProduct.id === product.id);
                                                    return (
                                                        <li className="card card-flush flex-row-fluid p-6 pb-5 mw-100" key={product.id} style={{ backgroundColor: isSelected ? "#F8F8F8" : "#FFF" }}>
                                                            {!product?.is_available &&
                                                                <div style={{ position: 'absolute', top: 0, right: 0, alignSelf: "end", margin: 5 }}>
                                                                    <span style={{ backgroundColor: "red", color: "#FFF", fontSize: 10, fontWeight: "600", padding: 2, paddingLeft: 5, paddingRight: 5, borderRadius: 10 }}>{"Out of stock"}</span>
                                                                </div>
                                                            }
                                                            <button onClick={() => handleClick(product)} disabled={!product?.is_available} className="card-body text-center" style={{ border: "none", background: "none" }}>
                                                                <img src={toAbsoluteUrl(`${product.image}`)} className="rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px" alt="" />
                                                                <div className="mb-2">
                                                                    <div className="text-center">
                                                                        <span className="fw-bold text-800 cursor-pointer text-hover-primary fs-3 fs-xl-1">{product.name}</span>
                                                                        <span className="text-gray-500 fw-semibold d-block fs-6 mt-n1">{`${product.minutes_cook} mins to cook`}</span>
                                                                    </div>
                                                                </div>
                                                                <span className="text-primary text-end fw-bold fs-1">{`₹ ${product.price}`}</span>
                                                            </button>
                                                        </li>
                                                    )
                                                })
                                            )
                                        } else {
                                            return (
                                                item?.category?.products?.map((product) => {
                                                    const isSelected = selectedProducts.some(selectedProduct => selectedProduct.id === product.id);
                                                    return (
                                                        <li className="card card-flush flex-row-fluid p-6 pb-5 mw-100" key={product.id} style={{ backgroundColor: isSelected ? "#F8F8F8" : "#FFF" }}>
                                                            {!product?.is_available &&
                                                                <div style={{ position: 'absolute', top: 0, right: 0, alignSelf: "end", margin: 5 }}>
                                                                    <span style={{ backgroundColor: "red", color: "#FFF", fontSize: 10, fontWeight: "600", padding: 2, paddingLeft: 5, paddingRight: 5, borderRadius: 10 }}>{"Out of stock"}</span>
                                                                </div>
                                                            }
                                                            <button onClick={() => handleClick(product)} disabled={!product?.is_available} className="card-body text-center" style={{ border: "none", background: "none" }}>
                                                                <img src={toAbsoluteUrl(`${product.image}`)} className="rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px" alt="" />
                                                                <div className="mb-2">
                                                                    <div className="text-center">
                                                                        <span className="fw-bold text-800 cursor-pointer text-hover-primary fs-3 fs-xl-1">{product.name}</span>
                                                                        <span className="text-gray-500 fw-semibold d-block fs-6 mt-n1">{`${product.minutes_cook} mins to cook`}</span>
                                                                    </div>
                                                                </div>
                                                                <span className="text-primary text-end fw-bold fs-1">{`₹ ${product.price}`}</span>
                                                            </button>
                                                        </li>
                                                    )
                                                })
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-row-auto col-md-4">
                <div className="card card-flush bg-body" id="kt_pos_form">
                    <div className="card-header pt-5">
                        <h3 className="card-title fw-bold text-800 fs-2qx">Current Order</h3>
                        <div className="card-toolbar">
                            <button onClick={() => { setSelectedProducts([]) }} className="custom-custom-btn-light px-10">Clear All</button>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive mb-8">
                            <table className="table align-middle gs-0 gy-4 my-0">
                                <thead>
                                    <tr>
                                        <th className="min-w-175px"></th>
                                        <th className="w-125px"></th>
                                        <th className="w-60px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedProducts.map((item, index) => (
                                        <tr key={item.id} data-kt-pos-element="item" data-kt-pos-item-price="33">
                                            <td className="pe-0">
                                                <div className="d-flex align-items-center">
                                                    <img src={toAbsoluteUrl(item.image)} className="w-50px h-50px rounded-3 me-3" alt="" />
                                                    <span className="fw-bold text-800 cursor-pointer text-hover-primary fs-6 me-1">{item.name}</span>
                                                </div>
                                            </td>
                                            <td className="pe-0">
                                                <div className="position-relative d-flex align-items-center" data-kt-dialer="true" data-kt-dialer-min="1" data-kt-dialer-max="10" data-kt-dialer-step="1" data-kt-dialer-decimals="0">
                                                    <button onClick={() => handleDecreaseQuantity(item)} type="button" className="btn btn-icon btn-sm custom-btn-light btn-icon-gray-500" data-kt-dialer-control="decrease">
                                                        <span className="svg-icon menu-icon">
                                                            <svg
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                viewBox="0 0 24 24"
                                                                height="1em"
                                                                width="1em"
                                                            >
                                                                <path d="M5 12h14" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    <input
                                                        type="text"
                                                        className="form-control border-0 text-center px-0 fs-3 fw-bold text-800 w-30px"
                                                        data-kt-dialer-control="input"
                                                        placeholder="Amount"
                                                        name="manageBudget"
                                                        value={item.quantity} />
                                                    <button onClick={() => handleIncreaseQuantity(item)} type="button" className="btn btn-icon btn-sm custom-btn-light btn-icon-gray-500" data-kt-dialer-control="increase">
                                                        <span className="svg-icon menu-icon">
                                                            <svg
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth={2}
                                                                viewBox="0 0 24 24"
                                                                height="1em"
                                                                width="1em"
                                                            >
                                                                <path d="M12 5v14M5 12h14" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="pe-0">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="fw-bold text-primary fs-2 mt-2 mr-4" data-kt-pos-element="item-total">{`₹${item.price * item.quantity}`}</span>
                                                </div>
                                            </td>
                                            <td className="pe-0">
                                                <button onClick={() => handleDelete(index)} className="btn btn-icon custom-btn-light btn-hover-danger btn-sm mt-2">
                                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                        <div className="d-flex flex-stack rounded-3 p-6 mb-11" style={{ backgroundColor: '#17c653' }}>
                            <div className="fs-6 fw-bold text-white">
                                <span className="d-block lh-1 mb-2">Subtotal</span>
                                <span className="d-block mb-2">Discounts</span>
                                <span className="d-block mb-9">Tax(0%)</span>
                                <span className="d-block fs-2qx lh-1">Total</span>
                            </div>
                            <div className="fs-6 fw-bold text-white text-end">
                                <span className="d-block lh-1 mb-2" data-kt-pos-element="total">{`₹ ${subtotal}`}</span>
                                <span className="d-block mb-2" data-kt-pos-element="discount">-₹0.00</span>
                                <span className="d-block mb-9" data-kt-pos-element="tax">₹0.00</span>
                                <span className="d-block fs-2qx lh-1" data-kt-pos-element="grant-total">{`₹ ${subtotal}`}</span>
                            </div>
                        </div>
                        <div className="m-0">
                            <h1 className="fw-bold text-800 mb-5">Payment Method</h1>
                            <div className="d-flex flex-equal gap-5 gap-xxl-9 px-0 mb-12" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]" data-kt-initialized="1">
                                <label className="btn bg-gray-light btn-color-gray-600 btn-active-text-800 border border-3 border-gray-100 border-active-primary btn-active-light-primary w-100 px-4" data-kt-button="true">
                                    <input className="btn-check" type="radio" name="method" value="0" />
                                    <span className="ki-outline fs-2hx mb-2 pe-0">
                                        <svg
                                            viewBox="0 0 1024 1024"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                        >
                                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm47.7-395.2l-25.4-5.9V348.6c38 5.2 61.5 29 65.5 58.2.5 4 3.9 6.9 7.9 6.9h44.9c4.7 0 8.4-4.1 8-8.8-6.1-62.3-57.4-102.3-125.9-109.2V263c0-4.4-3.6-8-8-8h-28.1c-4.4 0-8 3.6-8 8v33c-70.8 6.9-126.2 46-126.2 119 0 67.6 49.8 100.2 102.1 112.7l24.7 6.3v142.7c-44.2-5.9-69-29.5-74.1-61.3-.6-3.8-4-6.6-7.9-6.6H363c-4.7 0-8.4 4-8 8.7 4.5 55 46.2 105.6 135.2 112.1V761c0 4.4 3.6 8 8 8h28.4c4.4 0 8-3.6 8-8.1l-.2-31.7c78.3-6.9 134.3-48.8 134.3-124-.1-69.4-44.2-100.4-109-116.4zm-68.6-16.2c-5.6-1.6-10.3-3.1-15-5-33.8-12.2-49.5-31.9-49.5-57.3 0-36.3 27.5-57 64.5-61.7v124zM534.3 677V543.3c3.1.9 5.9 1.6 8.8 2.2 47.3 14.4 63.2 34.4 63.2 65.1 0 39.1-29.4 62.6-72 66.4z" />
                                        </svg>
                                    </span>
                                    <span className="fs-7 fw-bold d-block">Cash</span>
                                </label>
                                <label className="btn btn-color-gray-600 btn-active-text-800 w-100 px-4 active" style={{ border: "3px solid #eb3a22", backgroundColor: "#FFF" }} data-kt-button="true">
                                    <input className="btn-check" type="radio" name="method" value="1" />
                                    <span className="ki-outline fs-2hx mb-2 pe-0">
                                        <svg
                                            viewBox="0 0 576 512"
                                            fill="#eb3a22"
                                            height="1em"
                                            width="1em"
                                        >
                                            <path d="M168 336c13.3 0 24 10.7 24 24s-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24s10.7-24 24-24h48zm192 0c13.3 0 24 10.7 24 24s-10.7 24-24 24H248c-13.3 0-24-10.7-24-24s10.7-24 24-24h112zM512 32c35.3 0 64 28.65 64 64v320c0 35.3-28.7 64-64 64H64c-35.35 0-64-28.7-64-64V96c0-35.35 28.65-64 64-64h448zm0 48H64c-8.84 0-16 7.16-16 16v32h480V96c0-8.84-7.2-16-16-16zm16 144H48v192c0 8.8 7.16 16 16 16h448c8.8 0 16-7.2 16-16V224z" />
                                        </svg>
                                    </span>
                                    <span className="fs-7 fw-bold d-block" style={{ color: "#eb3a22" }}>Card</span>
                                </label>
                                <label className="btn bg-gray-light btn-color-gray-600 btn-active-text-800 border border-3 border-gray-100 border-active-primary btn-active-light-primary w-100 px-4" data-kt-button="true">
                                    <input className="btn-check" type="radio" name="method" value="2" />
                                    <span className="ki-outline fs-2hx mb-2 pe-0">
                                        <svg
                                            viewBox="0 0 512 512"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinejoin="round"
                                                strokeWidth={32}
                                                d="M96 144 H416 A48 48 0 0 1 464 192 V384 A48 48 0 0 1 416 432 H96 A48 48 0 0 1 48 384 V192 A48 48 0 0 1 96 144 z"
                                            />
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinejoin="round"
                                                strokeWidth={32}
                                                d="M411.36 144v-30A50 50 0 00352 64.9L88.64 109.85A50 50 0 0048 159v49"
                                            />
                                            <path d="M368 320a32 32 0 1132-32 32 32 0 01-32 32z" />
                                        </svg>
                                    </span>
                                    <span className="fs-7 fw-bold d-block">E-Wallet</span>
                                </label>
                            </div>
                            <button className="btn btn-primary w-100 py-4" style={{ fontSize: 18 }}>Print Bills</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default injectIntl(connect(null, auth.actions)(AddProduct));