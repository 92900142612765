import React, { useEffect, useState } from "react";
import { Card, CardBody } from '../../../_metronic/_partials/controls'
import { getApiData } from "../../Services";
import TableFilter from "./Filter";
import Table from "./Table";
import { update, values } from "lodash";
import { orderTimeSlots } from "../../config";

export default function () {
  const [filters, setFilters] = useState({
    building: "",
    startDate: "",
    endDate: "",
    order_time: "",
  });

  const [businessList, setBusinessList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [wingList, setWingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [chhotuList, setChhotuList] = useState([]);

  useEffect(() => {
    getBusinessList();
    getBuildingList();
    getProductList();
    getChhotuList();
  }, []);

  useEffect(() => {
    // if (filters.building) {
    //   getOfficeByBuilding();
    // }
  }, [filters.building]);

  const getBusinessList = () => {
    getApiData(`business/v1`).then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setBusinessList(array);
      }
    })
  }

  const getProductList = () => {
    getApiData("products/v1/?limit=1000").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.name, value: item.id }];
        });
        setProductList(array);
      }
    });
  };

  const getBuildingList = () => {
    getApiData("cluster/v1/buildings").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [
            ...array,
            { label: item.name, value: item.id },
          ];
        });
        setBuildingList(array);
      }
    });
  };

  const getWingByBuilding = async (building) => {
    const body = {
      building_id: building,
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let wing = [];
        Object.keys(res.data).map((item) => {
          wing = [...wing, { label: item, value: item }];
        });
        return wing;
      }
    });
  }

  const getFloorByWing = async (wing) => {
    const body = {
      building_id: filters.building,
      wing: wing
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let floor = [];
        Object.keys(res.data).map((item) => {
          floor = [...floor, { label: item, value: item }];
        });
        return floor;
      }
    });
  }

  const getOfficeByFloor = async (floor) => {
    const body = {
      building_id: filters.building,
      wing: filters.wing,
      floor: floor,
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let office = [];
        res.data.map((item) => {
          office = [...office, { label: item, value: item }];
        });
        return office;
      }
    });
  }

  const getChhotuList = () => {
    getApiData("delivery/v1/").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setChhotuList(array);
      }
    });
  };

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "partner") {
      obj.partner = "";
    } else if (key === "building") {
      obj.wing = "";
      obj.floor = "";
      obj.office = "";
      setWingList([]);
      setFloorList([]);
      setOfficeList([]);
    } else if (key === "wing") {
      obj.floor = "";
      obj.office = "";
      setFloorList([]);
      setOfficeList([]);
    } else if (key === "floor") {
      obj.office = "";
      setOfficeList([]);
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        <TableFilter
          businessList={businessList}
          buildingList={buildingList}
          wingList={wingList}
          floorList={floorList}
          officeList={officeList}
          productList={productList}
          chhotuList={chhotuList}
          orderTimeSlots={orderTimeSlots}
          value={filters}

          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          onBuildingSelect={async (value) => {
            updateFilter(value, "building");
            const wing = await getWingByBuilding(value);
            setWingList(wing);
          }}

          onWingSelect={async (value) => {
            updateFilter(value, "wing");
            const floor = await getFloorByWing(value);
            setFloorList(floor);
          }}

          onFloorSelect={async (value) => {
            updateFilter(value, "floor");
            const office = await getOfficeByFloor(value);
            setOfficeList(office);
          }}

          onOfficeSelect={async (value) => {
            updateFilter(value, "office");
          }}

          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}

          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
              building: "",
              order_time: "",
            });
          }}
          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}
        />
        <Table filters={filters} setFilters={(val) => setFilters(val)} />
      </div>
    </>
  );
}
