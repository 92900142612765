import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { getApiData } from "../../../../Services";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { LoaderPopup } from "../../../../components";

export default function ({ show, onHide, onSuccess, onError, groupList }) {

    const [selectedOption, setSelectedOption] = useState('Bank');
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    const [accountantName, setAccountantName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setComfirmAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');

    const [upiHolderName, setUpiHolderName] = useState('');
    const [upiId, setUpiId] = useState('');
    const [confirmUpiId, setConfirmUpiId] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [mobileNumber, setMobileNumber] = useState('');

    const [bankCheckConfirm, setBankCheckConfirm] = useState(false);
    const [upiCheckConfirm, setUpiCheckConfirm] = useState(false);

    const [bankAccount, setBankAccount] = useState(null);
    const [upiAccount, setUpiAccount] = useState(null);

    const [showUpdateBankDetail, setShowUpdateBankDetail] = useState(false);
    const [showUpdateUPIDetail, setShowUpdateUPIDetail] = useState(false);

    useEffect(() => {
        if (show) {
            setLoading(true);
            getPayoutDetails();
        }
    }, [show, selectedOption]);

    const getPayoutDetails = () => {
        getApiData(`administration/v1/bank-information`, 'get').then((res) => {
            setLoading(false);
            if (res.status) {
                setData(res?.data);
                if (res?.data?.bank_account) {
                    setBankAccount(res?.data?.bank_account);
                    setAccountantName(res?.data?.bank_account?.account_holder_name);
                    setAccountNumber(res?.data?.bank_account?.account_number);
                    setComfirmAccountNumber(res?.data?.bank_account?.account_number);
                    setIfscCode(res?.data?.bank_account?.ifsc_code);
                }
                if (res?.data?.vpa) {
                    setUpiAccount(res?.data?.vpa);
                    setUpiHolderName(res?.data?.vpa?.upi_holder_name);
                    setUpiId(res?.data?.vpa?.upi);
                    setConfirmUpiId(res?.data?.vpa?.upi);
                    setMobileNumber(res?.data?.vpa?.phone_number);
                }
            }
        });
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setUpiId(value);

        const upiRegex = /^[a-zA-Z0-9.\-_]+@[a-zA-Z0-9]+$/;
        setIsValid(upiRegex.test(value));
    };

    const handleAccountChange = (event) => {
        const activeId = event.target.value === 'bank_account' ? bankAccount?.id : upiAccount?.id;
        if (activeId) {
            setSending(true);

            getApiData(`administration/v1/bank-information/${activeId}/`, 'put').then((res) => {
                setSending(false);
                if (res.status) {
                    getPayoutDetails();
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            })
        }
    }

    const onClickVerify = () => {
        if (selectedOption === 'Bank') {
            if (accountNumber !== confirmAccountNumber) {
                toast.error("Bank account number does not match. Please verify and try again.");
            } else if (!bankCheckConfirm) {
                toast.error("Please confirm the checkbox for confirmation");
            }
            else if (accountantName && accountNumber && ifscCode) {
                setSending(true);

                const body = {
                    account_holder_name: accountantName,
                    account_number: accountNumber,
                    ifsc_code: ifscCode,
                };

                getApiData(`administration/v1/bank-information/`, 'post', body).then((res) => {
                    setSending(false);
                    if (res.status) {
                        onSuccess(res.message);
                        setShowUpdateBankDetail(false);
                        onHide();
                    } else {
                        onError(res.message);
                    }
                });
            } else {
                toast.error("Please enter required fields");
            }
        }
        if (selectedOption === 'UPI') {
            if (upiId !== confirmUpiId) {
                toast.error("UPI Id does not match. Please verify and try again.");
            } else if (!upiCheckConfirm) {
                toast.error("Please confirm the checkbox for confirmation");
            } else if (upiHolderName && upiId && mobileNumber) {
                setSending(true);
                const body = {
                    upi_holder_name: upiHolderName,
                    upi: upiId,
                    phone_number: mobileNumber,
                };

                getApiData(`administration/v1/bank-information/`, 'post', body).then((res) => {
                    setSending(false);
                    if (res.status) {
                        onSuccess(res.message);
                        setShowUpdateUPIDetail(false);
                        onHide();
                    } else {
                        onError(res.message);
                    }
                });
            } else {
                toast.error("Please enter required fields");
            }
        }
    }

    const backgroundImageUrl = toAbsoluteUrl("/media/svg/shapes/circle-1.svg");

    const radioStyle = {
        backgroundColor: selectedOption === "Bank" ? '#eb3a22' : '#ccc',
        backgroundImage: `url("${backgroundImageUrl}")`,
        flexShrink: 0,
        width: '1.75rem',
        height: '1.75rem',
        marginTop: '-.125rem',
        verticalAlign: 'top',
        appearance: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    };

    const upiRadioStyle = {
        backgroundColor: selectedOption === "UPI" ? '#eb3a22' : '#ccc',
        backgroundImage: `url("${backgroundImageUrl}")`,
        flexShrink: 0,
        width: '1.75rem',
        height: '1.75rem',
        marginTop: '-.125rem',
        verticalAlign: 'top',
        appearance: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    }

    const isActiveBank = {
        backgroundColor: bankAccount?.is_active ? '#eb3a22' : '#ccc',
        backgroundImage: `url("${backgroundImageUrl}")`,
        flexShrink: 0,
        width: '1.75rem',
        height: '1.75rem',
        marginTop: '-.125rem',
        verticalAlign: 'top',
        appearance: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    };

    const isActiveUPI = {
        backgroundColor: upiAccount?.is_active ? '#eb3a22' : '#ccc',
        backgroundImage: `url("${backgroundImageUrl}")`,
        flexShrink: 0,
        width: '1.75rem',
        height: '1.75rem',
        marginTop: '-.125rem',
        verticalAlign: 'top',
        appearance: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',

    }

    return (
        <Modal size="lg" show={show} onHide={onHide} dialogClassName="bottom-popup">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className="modal-title">Payout Details Dev</h3>
                </Modal.Title>
                <button className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onHide}>
                    <i className="ki-outline ki-cross fs-1" />
                </button>
            </Modal.Header>

            <Modal.Body className="pt-0">
                <div className="modal-body">
                    <div className="row g-5 mb-0 align-items-center">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center mb-2">
                                <label className="fs-6 fw-bold ml-5">Payout Type</label>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        style={radioStyle}
                                        value="Bank"
                                        checked={selectedOption === 'Bank'}
                                        onChange={handleOptionChange}
                                    />
                                    <label className="form-check-label fs-5 fw-bold text-800 ml-10" htmlFor="flexRadioDefault">
                                        Bank
                                    </label>
                                </div>
                                <div className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        style={upiRadioStyle}
                                        value="UPI"
                                        checked={selectedOption === 'UPI'}
                                        onChange={handleOptionChange}
                                    />
                                    <label className="form-check-label fs-5 fw-bold text-800 ml-10" htmlFor="flexRadioDefault">
                                        UPI
                                    </label>
                                </div>
                            </div>
                        </div>
                        {(bankAccount || upiAccount) &&
                            <div className="col-md-6">
                                <div className="d-flex justify-content-end align-items-center mb-2">
                                    <label className="fs-6 fw-bold">Select Active Account for Payout</label>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <>
                                        {bankAccount &&
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    style={isActiveBank}
                                                    value="bank_account"
                                                    checked={bankAccount?.is_active}
                                                    onChange={handleAccountChange}
                                                />
                                                <label className="form-check-label fs-5 fw-bold text-800 ml-10" htmlFor="flexRadioDefault">
                                                    Bank Account
                                                </label>
                                            </div>
                                        }
                                    </>
                                    {upiAccount &&
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                style={isActiveUPI}
                                                value="vpa"
                                                checked={upiAccount?.is_active}
                                                onChange={handleAccountChange}
                                            />
                                            <label className="form-check-label fs-5 fw-bold text-800 ml-10" htmlFor="flexRadioDefault">
                                                UPI Account
                                            </label>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <div className="separator separator-dashed border-gray-300" />
                        {selectedOption === 'Bank' ?
                            <div>
                                {bankAccount &&
                                    <>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end mb-5">
                                                <button
                                                    className="btn custom-button py-2"
                                                    onClick={() => {
                                                        if (!showUpdateBankDetail) {
                                                            setShowUpdateBankDetail(true)
                                                        } else {
                                                            onClickVerify();
                                                        }
                                                    }}>
                                                    {showUpdateBankDetail ? 'Save Detail' : 'Update Detail'}
                                                </button>
                                            </div>
                                            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
                                                <thead>
                                                    <tr className="text-left text-uppercase">
                                                        <th className="pl-4">Account Holder Name</th>
                                                        <th>Account Number</th>
                                                        <th>IFSC Code</th>
                                                    </tr>
                                                </thead>
                                                {loading ? (
                                                    <tr>
                                                        <td colSpan="9">
                                                            <LoaderPopup />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <>
                                                        {bankAccount ? (
                                                            <tr>
                                                                <td className="pl-4">{bankAccount?.account_holder_name}</td>
                                                                <td>{bankAccount?.account_number}</td>
                                                                <td>{bankAccount?.ifsc_code}</td>
                                                            </tr>
                                                        )
                                                            : (
                                                                <tr>
                                                                    <td colSpan="9">
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                            <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </>
                                                )}
                                            </table>
                                        </div>
                                    </>
                                }
                                {(!bankAccount || showUpdateBankDetail) &&
                                    <div className="row g-5">
                                        {bankAccount && <div className="separator separator-dashed border-gray-300" />}
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">Account Holder Name:</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                value={accountantName}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const regex = /^[a-zA-Z\s-]*$/;
                                                    if (regex.test(inputValue)) {
                                                        setAccountantName(inputValue);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">Account Number:</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                value={accountNumber}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    setAccountNumber(input);
                                                }} />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">Confirm Account Number:</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                value={confirmAccountNumber}
                                                onChange={(e) => {
                                                    const input = e.target.value;
                                                    setComfirmAccountNumber(input);
                                                }} />

                                            {confirmAccountNumber && accountNumber !== confirmAccountNumber && <span className="text-primary">Bank account number does not match</span>}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">IFSC Code:</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                value={ifscCode}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    setIfscCode(inputValue);
                                                }} />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="d-flex align-items-center">
                                                <div className="form-check ml-4 mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setBankCheckConfirm(e.target.checked);
                                                        }}
                                                        defaultChecked={bankCheckConfirm}
                                                    />
                                                </div>
                                                <label htmlFor className=" fs-6 fw-bold">I confirm that the entered details are correct.</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                {upiAccount &&
                                    <>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-end mb-5">
                                                <button
                                                    className="btn custom-button py-2"
                                                    onClick={() => {
                                                        if (!showUpdateUPIDetail) {
                                                            setShowUpdateUPIDetail(true)
                                                        } else {
                                                            onClickVerify();
                                                        }
                                                    }}>
                                                    {!showUpdateUPIDetail ? 'Update Detail' : 'Save Detail'}
                                                </button>
                                            </div>
                                            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
                                                <thead>
                                                    <tr className="text-left text-uppercase">
                                                        <th className="pl-4">UPI Holder Name</th>
                                                        <th>UPI Id</th>
                                                        <th>Mobile Number</th>
                                                    </tr>
                                                </thead>
                                                {loading ? (
                                                    <tr>
                                                        <td colSpan="9">
                                                            <LoaderPopup />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <>
                                                        {
                                                            upiAccount ? (
                                                                <tr>
                                                                    <td className="pl-4">{upiAccount?.upi_holder_name}</td>
                                                                    <td>{upiAccount?.upi}</td>
                                                                    <td>{upiAccount?.phone_number}</td>
                                                                </tr>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="9">
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                            <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </table>
                                        </div>
                                    </>
                                }
                                {(!upiAccount || showUpdateUPIDetail) &&
                                    <div className="row g-5">
                                        {upiAccount && <div className="separator separator-dashed border-gray-300" />}
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">UPI Holder Name:</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                value={upiHolderName}
                                                onChange={(text) => setUpiHolderName(text?.target?.value)}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">UPI Id:</label>
                                            <input
                                                type="text"
                                                className={`form-control fs-7 ${isValid ? '' : 'is-invalid'}`}
                                                value={upiId}
                                                onChange={handleChange}
                                            />
                                            {!isValid && <div className="invalid-feedback">Invalid UPI ID format.</div>}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">Confirm UPI Id:</label>
                                            <input
                                                type="text"
                                                className={`form-control fs-7`}
                                                value={confirmUpiId}
                                                onChange={(e) => {
                                                    setConfirmUpiId(e.target.value);
                                                }}
                                            />
                                            {confirmUpiId && upiId !== confirmUpiId && <span className="text-primary">UPI Id does not match</span>}
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor className=" fs-6 fw-bold mb-2 required">Mobile Number:</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                value={mobileNumber}
                                                onChange={(text) => {
                                                    if (text.target.value.length <= 10)
                                                        setMobileNumber(text?.target?.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="d-flex align-items-center">
                                                <div className="form-check ml-4 mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            setUpiCheckConfirm(e.target.checked);
                                                        }}
                                                        defaultChecked={upiCheckConfirm}
                                                    />
                                                </div>
                                                <label htmlFor className=" fs-6 fw-bold">I confirm that the entered details are correct.</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn custom-btn-light fs-7" onClick={onHide}>Close</button>
                    {selectedOption == 'Bank' && !bankAccount &&
                        <button type="button" className="custom-button btn-primary fs-7 px-7" onClick={() => { onClickVerify() }}>
                            Save
                            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
                        </button>
                    }
                    {selectedOption == 'UPI' && !upiAccount &&
                        <button type="button" className="custom-button btn-primary fs-7 px-7" onClick={() => { onClickVerify() }}>
                            Save
                            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
                        </button>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}
