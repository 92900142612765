import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [status, setStatus] = useState(true);
  const [count, setCount] = useState(1);
  const isAdmin = user && user.user_type === "admin" ? true : false;

  useEffect(() => {
    getStatus();
  }, []);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${
          !hasSubmenu && "menu-item-active"
        } menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const getStatus = () => {
    // setLoading(true);
    // return getApiData(`tapri/v1/status`).then((res) => {
    //   if (res.status) {
    //     setStatus(res.data.status);
    //   } else if (res.detail === "Invalid token.") {
    //     history.push("/logout");
    //   }
    // });

    let res = {
      code: 200,
      data: {},
      message: "You are not manager of any Business Partner",
      status: false,
    };
    if (res.status) {
      setStatus(res.data.status);
    } else if (res.detail === "Invalid token.") {
      history.push("/logout");
    }
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {isAdmin ? (
          <>
            <>
              <li className="menu-section ">
                <h4 className="menu-text">ADMIN</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              <div className="app-sidebar-separator separator mb-6"></div>
            </>
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-graph-3 fs-1"></i>
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/master",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/master">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-home-2 fs-2"></i>
                </span>
                <span className="menu-text">Master Tables</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/business-partner",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/business-partner"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Business Partner</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/building",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/building"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Building</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/building-request",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/building-request"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Building Request</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/area/",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/area/"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Area</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/area-managers",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/area-managers"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Area Managers</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/coupons",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/coupons"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Promo Code</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/master/products-analytics",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/master/products-analytics"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Products Analytics</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/live-orders",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/live-orders">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-calendar-tick fs-1"></i>
                </span>
                <span className="menu-text">Live Orders</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/order-history",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/order-history">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-watch fs-1"></i>
                </span>
                <span className="menu-text">Order History</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/delivery-boy",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/delivery-boy">
                <span className="svg-icon menu-icon">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M16 1.2c-1 0-1.8.8-1.8 1.8S15 4.8 16 4.8 17.8 4 17.8 3 17 1.2 16 1.2m-3.6 2.9c-.47 0-.9.19-1.2.5L7.5 8.29C7.19 8.6 7 9 7 9.5c0 .63.33 1.16.85 1.47L11.2 13v5H13v-6.5l-2.25-1.65 2.32-2.35L14.8 10H19V8.2h-3.2l-1.94-3.27c-.29-.5-.86-.83-1.46-.83M10 3H3c-.55 0-1-.45-1-1s.45-1 1-1h9.79c-.21.34-.38.71-.47 1.11-.86.02-1.67.34-2.32.89m-5 9c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5m0 8.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5M19 12c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5m0 8.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5M5.32 11H1c-.552 0-1-.45-1-1s.448-1 1-1h4.05c-.02.16-.05.33-.05.5 0 .53.12 1.04.32 1.5M6 7H2c-.55 0-1-.45-1-1s.45-1 1-1h5.97L6.09 6.87C6.05 6.91 6 6.96 6 7z" />
                  </svg>
                </span>
                <span className="menu-text">Delivery Boy</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/customers", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/customers">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-profile-user fs-1"></i>
                </span>
                <span className="menu-text">Customers</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive("/settlement", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/settlement">
                <span className="svg-icon menu-icon">
                  <svg
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M13 6h2v11h-2zm4-3h2v14h-2zM9 9h2v8H9zM4 19h16v2H4zm1-7h2v5H5z" />
                  </svg>
                </span>
                <span className="menu-text">Settlement</span>
              </NavLink>
            </li>

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/payout",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/payout">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-wallet fs-1"></i>
                </span>
                <span className="menu-text">Payout</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/business-payout",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/business-payout"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Business Payout</span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/driver-payout",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink
                      className="menu-link menu-toggle"
                      to="/driver-payout"
                    >
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Driver Payout</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/banner", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/banner">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-colors-square fs-1"></i>
                </span>
                <span className="menu-text">Banner</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/notifications",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/notifications">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-notification fs-1"></i>
                </span>
                <span className="menu-text">Notifications</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/reviews", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/reviews">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-messages fs-1"></i>
                </span>
                <span className="menu-text">Reviews</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/personal-information",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/personal-information">
                <span className="svg-icon menu-icon">
                  <svg
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={32}
                      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit={10}
                      strokeWidth={32}
                      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                    />
                  </svg>
                </span>
                <span className="menu-text">My Profile</span>
              </NavLink>
            </li>
          </>
        ) : (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">USER</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <div className="app-sidebar-separator separator mb-6"></div>
            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-graph-3 fs-1"></i>
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/analytics",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/analytics">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-home-2 fs-2"></i>
                </span>
                <span className="menu-text">Master Table</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/cluster",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/cluster">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Set Building</span>
                    </NavLink>
                  </li> */}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/delivery-boy",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/delivery-boy">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Delivery Boy</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/products",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/products">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Products</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/product-category",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/product-category">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Product categories</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/coupons",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/coupons">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Promo Code</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/products-analytics",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/products-analytics">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Products Analytics</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive("/cluster", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/cluster">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-chart fs-1"></i>
                </span>
                <span className="menu-text">Clusters</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/live-orders",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/live-orders">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-calendar-tick fs-1"></i>
                </span>
                <span className="menu-text">Live Orders</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/order-history",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/order-history">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-watch fs-1"></i>
                </span>
                <span className="menu-text">Order History</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/customers-business",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/customers-business">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-profile-user fs-1"></i>
                </span>
                <span className="menu-text">Customers</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/analytics",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/analytics">
                <span className="svg-icon menu-icon">
                  <i class="ki-outline ki-gear fs-2"></i>
                </span>
                <span className="menu-text">Settings</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payout-history",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payout-history">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Payout History</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/driver-payout-history",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/driver-payout-history">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Driver Payout History</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payout-settings",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payout-settings">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Payout Settings</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/notification-business",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/notification-business">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Notification</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/support",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/support">
                      <span
                        className="svg-icon menu-icon ml-0"
                        style={{ flex: 0 }}
                      >
                        <svg
                          fill="currentColor"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                        >
                          <path d="M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg>
                      </span>
                      <span className="menu-text">Support</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/personal-information",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/personal-information">
                <span className="svg-icon menu-icon">
                  <svg
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={32}
                      d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit={10}
                      strokeWidth={32}
                      d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                    />
                  </svg>
                </span>
                <span className="menu-text">My Profile</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
