import React, { useState } from "react";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";

export default function (props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="position-relative me-0 mb-0 mb-md-0 w-25">
          <GeneralDropdown
            placeholder={'State'}
            clearFilter={clearFilter}
            selectedOption={props.value.state ? props.value.state : null}
            list={props.stateList}
            handleChange={(obj) => {
              props.onStateSelect(obj);
            }}
          />
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-25">
          <GeneralDropdown
            placeholder={'City'}
            clearFilter={clearFilter}
            selectedOption={props.value.city ? props.value.city : null}
            list={props.cityList}
            handleChange={(obj) => {
              props.onCitySelect(obj.value);
            }}
          />
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-25 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by area name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setClearFilter(Math.random());
            }}>
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
        <div className="position-relative mb-0 mb-md-0">
          <button
            className="custom-button btn-primary w-85px h-35px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center"
            onClick={() => {
              props.togglePopup();
            }}
          >
            Add Area
          </button>
        </div>
      </div>
    </>
  );
}