import React from "react";
import { useSelector } from "react-redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { AuthInit } from "./modules/Auth";
import { WebSocketNotifier } from "./config/WebSocketNotifier";

import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query';

const queryClient = new QueryClient();

export default function App({ store, persistor, basename }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <BrowserRouter basename={basename}>
              <MaterialThemeProvider>
                <I18nProvider>
                  <AuthInit>
                    <WebSocketNotifier />
                    <Routes />
                  </AuthInit>
                </I18nProvider>
              </MaterialThemeProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}