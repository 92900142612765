import React, { useState } from "react";
import { GeneralDropdown } from "../../../../components";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DateRangeSettings } from "../../../../config";

export default function (props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  const statusList = [
    { value: '0', label: 'Pending' },
    { value: '1', label: 'Success' },
  ];

  const handleApply = (event, picker) => {
    props.setDate(
      picker.startDate.format("MM/DD/YYYY"),
      picker.endDate.format("MM/DD/YYYY")
    );
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="btn btn-sm bg-white d-flex align-items-center px-2 fs-7">
          <DateRangePicker
            initialSettings={DateRangeSettings}
            onApply={handleApply}
            onCancel={handleCancel}
          >
            <input
              type="text"
              className="form-control w-100"
              style={{ borderWidth: 0, backgroundColor: 'transparent', height: '12px' }}
              placeholder="Select date range"
              id="date-range"
              autoComplete="off"
            />
          </DateRangePicker>
          <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
          </i>
        </div>
        <div className="w-155px">
          <GeneralDropdown
            placeholder="Status..."
            className="form-select border-0 fs-7"
            list={statusList}
            handleChange={(obj) => {
              props.setValue("payment_settled", obj.value);
            }}
            clearFilter={clearFilter}
          />
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-100 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by Business name or phone..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button
            className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setClearFilter(Math.random());
              window.$("#date-range").val("");
            }}
          >
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
      </div>
    </>
  );
}
