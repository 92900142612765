import React, { useState } from "react";
import { formateAmount } from "../../../../config";
import { GeneralDropdown } from "../../../../components";

export default function (props) {
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  const creditUsers = [
    { value: true, label: 'Credit Users' },
    // {value: false, label: 'Regular Users'},
  ]


  return (
    <>
      <div className=" d-flex flex-column">
        <div className="mb-8">
          <a
            className="float-right custom-button btn-primary w-180px h-35px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center"
            onClick={() => {
              props.togglePopup();
            }}
          >
            Add Customer
          </a>
        </div>
        <div className="d-flex gap-4 mb-5">
          <div className="min-w-125px">
            <div className="d-flex justify-content-center align-items-center bg-white py-2 ps-2" style={{ borderRadius: 5 }}>
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => {
                    props.setValue("credit_users", e.target.checked);
                    setIsChecked(prevState => !prevState);
                  }}
                  checked={isChecked}
                />
                <label className="fs-7 mb-0 mt-1" style={{ color: '#7e8299', marginLeft: '5px' }}>Credit User</label>
              </div>
            </div>
          </div>
          <div className="position-relative me-0 mb-0 mb-md-0 w-100 d-flex">
            <input
              type="text"
              className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              placeholder="Search by customer name or phone..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setSearchString(search);
                }
              }}
            />
            <div className="position-relative mb-0 mb-md-0 d-flex">
              <button className="btn custom-btn-light-primary btn-icon"
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => {
                  props.setSearchString(search);
                }}>
                <i className="ki-outline ki-magnifier fs-2" />
              </button>
            </div>
          </div>
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button
              className="btn custom-btn-light-primary btn-icon w-35px h-35px"
              onClick={() => {
                props.onClearFilter();
                setSearch("");
                setClearFilter(Math.random());
                setIsChecked(false);
              }}>
              <i className="ki-outline ki-arrows-circle fs-2" />
            </button>
          </div>
        </div>
        <div className="border border-primary d-flex mb-5 flex-stack px-7 p-3 rounded border-dashed" style={{ backgroundColor: "#fce2de", borderStyle: 'dashed', }}>
          <div>
            <div className="form-check">
              <input
                className="checkbox form-check-input border border-primary"
                type="checkbox"
                checked={props.dues}
                onChange={(e) => {
                  props.setDues(e.target.checked);
                }} />
              <label className="form-check-label text-800 fw-bold" htmlFor="flexCheckDefault">
                Invoice Due Customers
              </label>
            </div>
          </div>
          <div className="fs-4 fw-bolder">Total Dues: <span className="text-primary">{formateAmount(props.totalDues)}</span></div>
        </div>
      </div>
    </>
  );
}
