import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SOCKET_URL } from '.';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

export function WebSocketNotifier() {
  const { user } = useSelector((state) => state.auth);
  const notiIcon = toAbsoluteUrl('/media/logos/favicon-taprocket.png');
  const history = useHistory();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    if (!isMobile) {
      if (!user) return;

      const storedWebSocket = JSON.parse(localStorage.getItem('websocket'));
      const socketUrl = `${SOCKET_URL}/ws/taprocket/delivery/${user.id}/`;
      const url = user && socketUrl ? socketUrl : storedWebSocket ? storedWebSocket.socketUrl : null;

      if (!url) return;

      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }

      const socket = new WebSocket(url);

      socket.addEventListener('message', event => {
        showNotification('New Message', event.data);
      });

      return () => {
        socket.close();
      };
    };
  }, [user]);

  const showNotification = (title, body) => {
    const message = JSON.parse(body);
    const messageType = message.socket_type;
    if (messageType !== 'track_order') {
      if (Notification.permission === 'granted') {
        const notification = new Notification('TapRocket', { body: 'New order received', icon: notiIcon });

        notification.addEventListener('click', () => {
          history.push('/live-orders');
        });
      }
    }
  };

  return null;
}