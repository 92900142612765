import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { Loader } from "../../../components";
import { getParams, PER_PAGE, timeSince } from "../../../config";
import { getApiData } from "../../../Services";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  CollectModal,
  AddIncentive,
  AddNewChhotu,
  EditChhotu,
  IncentiveModal,
} from "./../modals";
import Pagination from "react-js-pagination";

function Table({ filters, dispatch, setFilters, isPopupOpen, setIsPopupOpen }) {
  const { showing } = useSelector((state) => state.auth);
  const [collectModal, setCollectModal] = useState(false);
  const [newChhotuModal, setNewChhotuModal] = useState(false);
  const [editChhotuModal, setEditChhotuModal] = useState(false);
  const [incentiveModal, setIncentiveModal] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedActionRow, setSelectedActionRow] = useState({});
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showIncentiveModal, setShowIncentiveModal] = useState(false);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = (filters_) => {
    const params = getParams(filters, currentPage, sizePerPage);

    getApiData(`delivery/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  const changeDuty = (item) => {
    const body = {
      on_duty: item.on_duty ? false : true,
    };
    getApiData(`delivery/v1/${item.id}/`, "put", body).then((res) => {
      if (res.status) {
        getTableRecords(filters);
        toast.dismiss();
        toast.success(res.message);
      } else {
        toast.error(res.message ? res.message : "Something went wrong!");
      }
    });
  };

  const secondsToMinSecPadded = (time) => {
    const minutes = "0" + Math.floor(time / 60);
    const seconds = "0" + (time - minutes * 60);
    return minutes.substr(-2) + ":" + seconds.substr(-2);
  };

  const changeIsDefault = async (item) => {
    const body = {
      on_tap_available: item.on_tap_available ? false : true,
    };
    getApiData(`delivery/v1/${item.id}/`, "put", body).then((res) => {
      if (res.status) {
        getTableRecords(filters);
        toast.dismiss();
        toast.success(res.message);
      } else {
        toast.error(res.message ? res.message : "Something went wrong!");
      }
    });
  };

  return (
    <div>
      <CollectModal
        show={collectModal}
        data={selectedRow}
        onHide={() => setCollectModal(false)}
        onSuccess={(message) => {
          setCollectModal(false);
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <IncentiveModal
        show={showIncentiveModal}
        data={selectedRow}
        onHide={() => setShowIncentiveModal(false)}
        onSuccess={(message) => {
          setShowIncentiveModal(false);
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <AddIncentive
        show={incentiveModal}
        data={selectedRow}
        onHide={() => setIncentiveModal(false)}
        onSuccess={(message) => {
          setIncentiveModal(false);
          setSelectedRow({});
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <AddNewChhotu
        show={newChhotuModal || isPopupOpen}
        data={{}}
        onHide={() => {
          setNewChhotuModal(false);
          setIsPopupOpen(false);
        }}
        onSuccess={(message) => {
          setIsPopupOpen(false);
          setNewChhotuModal(false);
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <EditChhotu
        show={editChhotuModal}
        data={selectedActionRow}
        onHide={() => setEditChhotuModal(false)}
        onSuccess={(message) => {
          setEditChhotuModal(false);
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <div className="row g-5 mb-5">
        <div className="col-md-12">
          {loading ? (
            <div className="p-4">
              <Loader />
            </div>
          ) : (
            <>
              {data.length ? (
                <>
                  <div>
                    {data.map((item) => (
                      <div className="card mb-5 mt-5">
                        <div className="card-body p-5 position-relative">
                          <div className="d-flex flex-stack align-items-center">
                            <div className=" d-flex align-items-center min-w-150px flex-column  text-start">
                              <div className="ms-0  bg-gray-light rounded p-3 min-w-150px mb-2 d-flex">
                                <div className="symbol symbol-35px symbol-circle">
                                  {item?.profile_image ? (
                                    <img src={item?.profile_image} alt="" />
                                  ) : (
                                    <img
                                      src={toAbsoluteUrl(
                                        "/media/logos/blank.png"
                                      )}
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="ms-3">
                                  <div className="fs-6">
                                    Name: <b>{item.first_name}</b>
                                  </div>
                                  <div className="fs-8">
                                    Phone:{" "}
                                    <a href>
                                      <b>{item.phone_number}</b>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="fs-7 text-start w-100">
                                {" "}
                                Avg Delivery Time:
                                <b>
                                  {item.avg_delivery_time
                                    ? secondsToMinSecPadded(
                                        item.avg_delivery_time
                                      )
                                    : 0}
                                </b>
                              </div>
                              <div className="fs-7 text-start w-100 mt-2 d-flex align-items-center">
                                Status:
                                <span className="badge badge-primary ms-2">
                                  {item.on_duty ? "On Duty" : "Off Duty"}
                                </span>
                                {item.on_duty ? (
                                  <a
                                    href
                                    className="d-flex ms-2"
                                    onClick={() => {
                                      changeDuty(item);
                                    }}
                                  >
                                    <i className="ki-duotone ki-toggle-on-circle text-primary fs-2x">
                                      <span className="path1" />
                                      <span className="path2" />
                                    </i>
                                  </a>
                                ) : (
                                  <a
                                    href
                                    className="d-flex ms-2"
                                    onClick={() => {
                                      changeDuty(item);
                                    }}
                                  >
                                    <i
                                      class="ki-duotone ki-toggle-off-circle fs-2x"
                                      style={{ color: "#ccc" }}
                                    >
                                      <span class="path1"></span>
                                      <span class="path2"></span>
                                    </i>
                                  </a>
                                )}
                              </div>
                            </div>
                            <div className="min-w-150px d-flex flex-column align-items-end">
                              {/* {!item.incentive && (
                                <button
                                  className="bg-transparent d-flex align-items-center justify-content-center mb-1"
                                  style={{ borderWidth: 0 }}
                                  onClick={() => {
                                    setIncentiveModal(true);
                                    setSelectedRow(item);
                                  }}
                                >
                                  <span className="svg-icon text-blue">
                                    <svg
                                      viewBox="0 0 1024 1024"
                                      fill="currentColor"
                                      height="1em"
                                      width="1em"
                                    >
                                      <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" />
                                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                    </svg>
                                  </span>
                                  <span className="ml-1 text-blue">
                                    Add Incentive
                                  </span>
                                </button>
                              )} */}
                              <div className="rounded fs-7 mb-1 text-right">
                                Cash Collection: <b>₹{item.cash_in_hand}</b>
                              </div>
                              <button
                                className="bg-transparent d-flex align-items-center justify-content-center ml-auto"
                                style={{ borderWidth: 0 }}
                                onClick={() => {
                                  setShowIncentiveModal(true);
                                  setSelectedRow(item);
                                }}
                              >
                                <span className="svg-icon text-blue">
                                  <svg
                                    viewBox="0 0 1024 1024"
                                    fill="currentColor"
                                    height="1em"
                                    width="1em"
                                  >
                                    <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" />
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                  </svg>
                                </span>
                                <span className="ml-1 text-blue">
                                  Add Incentive
                                </span>
                              </button>
                              {/* {item.incentive > 0 && item.max_incentive && (
                                <div className="ms-0  bg-gray-light rounded p-3 min-w-150px mb-2 d-flex align-items-center ml-2">
                                  <div>
                                    {item.incentive && (
                                      <div className="rounded fs-7 mb-1 text-right">
                                        Order Percentage:{" "}
                                        <b>{item.incentive}%</b>
                                      </div>
                                    )}
                                    {item.max_incentive && (
                                      <div className="rounded fs-7 mb-1 text-right">
                                        Max Amount: <b>₹{item.max_incentive}</b>
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <span
                                      className="svg-icon svg-icon-sm ml-2 cursor-pointer"
                                      onClick={() => {
                                        setIncentiveModal(true);
                                        setSelectedRow(item);
                                      }}
                                    >
                                      <i className="ki-outline ki-pencil text-blue fs-1.5x ml-1" />
                                    </span>
                                  </div>
                                </div>
                              )} */}
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-4" />
                          <div className="d-flex flex-stack gap-3 mt-3">
                            <div>
                              <div className="form-check form-switch form-check-custom form-check-solid mt-0 me-3">
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    toast.promise(
                                      changeIsDefault(item, e.target.checked),
                                      {
                                        loading: "Updating...",
                                        success: "Success!",
                                        error: "Failed.",
                                      }
                                    );
                                  }}
                                  defaultChecked={item.on_tap_available}
                                />
                                <label
                                  className="form-check-label min-w-100px  text-800 fw-bold ml-11"
                                  htmlFor="flexSwitch20x30"
                                >
                                  OnTap Available
                                </label>
                              </div>
                            </div>
                            {item.cash_in_hand > 0 && (
                              <div className="fs-7 d-flex align-items-center">
                                Cash Collection
                                <a
                                  className="d-flex"
                                  href
                                  onClick={() => {
                                    setCollectModal(true);
                                    setSelectedRow(item);
                                  }}
                                >
                                  <i className="ki-outline ki-plus-square text-primary fs-2x ms-2" />
                                </a>
                              </div>
                            )}
                            <div className>
                              <a
                                className="d-flex"
                                href
                                onClick={() => {
                                  setEditChhotuModal(true);
                                  setSelectedActionRow(item);
                                }}
                              >
                                <i className="ki-outline ki-pencil text-primary fs-2x" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 float-right mr-3">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={20}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={20}
                      itemClass="d-flex justify-content-between align-items-center flex-wrap"
                      linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                      activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                      onChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={toAbsoluteUrl("/media/logos/no-data.png")}
                    height={"150px"}
                    width={"150px"}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default injectIntl(connect(null, auth.actions)(Table));
