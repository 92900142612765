import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import {
  API_URL,
  getAreaByCity,
  getStateList,
  redirection,
} from "../../../config";
import imageCompression from "browser-image-compression";
import { GeneralDropdown } from "../../../components";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";

export default function ({ show, onHide, data, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [bannerName, setBannerName] = useState("");
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedRedirection, setSelectedRedirection] = useState("");
  const [couponId, setCouponId] = useState("");
  const [screenName, setScreenName] = useState("");
  const [phone, setPhone] = useState("");
  const [link, setLink] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  const redirectionOptions = [
    { value: "0", label: "Apply Coupon" },
    { value: "1", label: "Business Menu" },
    { value: "2", label: "Custom Screen" },
    { value: "3", label: "Call" },
    { value: "4", label: "Link" },
  ];

  useEffect(() => {
    if (show) {
      if (data && data.id) {
        setBannerName(data.name);
        setImagePreview(data.image);
        setSelectedRedirection(data.banner_redirection?.toString());
        // setCouponId(data?.coupon_code)
        if (data?.banner_redirection === 0) {
          setCouponId(data?.coupon_code);
        } else if (data?.banner_redirection === 1) {
          setSelectedBusiness(data?.business?.id);
        } else if (data?.banner_redirection === 2) {
          setScreenName(data?.custom_screen);
        } else if (data?.banner_redirection === 3) {
          setPhone(data?.phone_number);
        } else {
          setLink(data?.link);
        }
      } else {
        clearInput();
      }
    }
  }, [show, data]);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  useEffect(() => {
    if (stateList.length && data.state) {
      stateList.map((item) => {
        if (item.value === data.state) {
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (areaList.length && data.area) {
      let array = [];
      areaList.map((item) => {
        data.area.map((area) => {
          if (item.value === area) {
            array = [...array, item];
          }
        });
      });
      console.log("setSelectedArea", array);

      setSelectedArea(array);
    }
  }, [areaList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length) {
      cityList.map((item) => {
        if (item.value === data.city) {
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  useEffect(() => {
    const getAreas = async () => {
      if (selectedCity && selectedCity.value) {
        const area = await getAreaByCity(
          selectedCity.value,
          selectedState.value
        );
        setAreaList(area);
      }
    };
    getAreas();
  }, [selectedCity]);

  useEffect(() => {
    if (selectedArea?.length) {
      getBusiness();
    }
  }, [selectedArea]);

  const getBusiness = () => {
    const list = selectedArea.map((item) => item.value).join(",");
    getApiData(`business/v1/?area=${list}&limit=1000`).then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setBusinessList(array);
      }
    });
  };

  const clearInput = () => {
    setBannerName("");
    setFile(null);
    setImagePreview("");
    setSelectedState(null);
    setSelectedCity(null);
    setSelectedArea(null);
    setSelectedRedirection("");
    setSelectedBusiness(null);
    setCouponId("");
    setPhone("");
    setLink("");
    setScreenName("");
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const compressedFile = await compressImage(selectedFile);
      const finalFile = new File([compressedFile], selectedFile.name, {
        type: compressedFile.type,
      });

      setFile(finalFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(finalFile);
    }
  };

  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedImage = await imageCompression(image, options);
      return compressedImage;
    } catch (error) {
      console.error("Image compression failed:", error);
      return image; // Return original image if compression fails
    }
  };

  const onSubmit = () => {
    if (
      bannerName &&
      selectedRedirection &&
      selectedArea?.length &&
      (file || imagePreview)
    ) {
      if (selectedRedirection === "0" && !couponId) {
        toast.error("Please enter coupon id!");
        return;
      } else if (selectedRedirection === "1" && !selectedBusiness) {
        toast.error("Please select business!");
        return;
      } else if (selectedRedirection === "2" && !screenName) {
        toast.error("Please select screen!");
        return;
      } else if (selectedRedirection === "3" && !phone) {
        toast.error("Please enter phone number!");
        return;
      }

      setSending(true);

      const body = new FormData();
      body.append("name", bannerName);
      body.append("image", file ? file : imagePreview);

      if (selectedRedirection === "0") {
        body.append("coupon_code", couponId);
      } else if (selectedRedirection === "1") {
        body.append("business", selectedBusiness);
      } else if (selectedRedirection === "2") {
        body.append("custom_screen", screenName);
      } else if (selectedRedirection === "3") {
        body.append("phone_number", phone);
      } else {
        body.append("link", link);
      }
      if (selectedArea?.length) {
        const areas = selectedArea.map((item) => item.value).join(",");
        body.append("area", areas);
      }
      if (selectedState?.value) {
        body.append("state", selectedState.value);
      }
      if (selectedCity?.value) {
        body.append("city", selectedCity.value);
      }
      body.append("banner_redirection", selectedRedirection);

      let dataToken = JSON.parse(localStorage.getItem("persist:userInfo"));
      let token = `${JSON.parse(dataToken.authToken)}`;
      let bannerId = data.id ? data.id + "/" : "";
      let method = data.id ? "put" : "post";

      fetch(`${API_URL}banner/v1/${bannerId}`, {
        body: body,
        method: method,
        headers: { Authorization: `Token ${token}` },
      })
        .then((response) => {
          setSending(false);
          if (response.status) {
            response.json().then((data) => {
              if (data.status) {
                clearInput();
                onHide();
                onSuccess(data.message);
              } else {
                onError(data.message);
              }
            });
          }
        })
        .catch((error) => {
          console.error("Something went wrong!", error);
        });
    } else {
      if (!bannerName) {
        toast.error("Please enter banner name!");
      } else if (!file && !imagePreview) {
        toast.error("Please select image!");
      } else if (!selectedArea?.length) {
        toast.error("Please select area!");
      } else if (!selectedRedirection) {
        toast.error("Please select redirection!");
      }
    }
  };

  const onPressClose = () => {
    onHide();
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {data.id ? "Edit" : "Add"} Banner
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-0 scroll-popup">
        <div className="col-md-12">
          <div className="form-group is-invalid mb-0 mt-5">
            <label>
              Banner Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={bannerName}
              onChange={(e) => {
                setBannerName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row col-md-12 pr-0">
          <div className="col-md-4 pr-0">
            <label className="form-group is-invalid mb-0 mt-5">
              Select State
            </label>
            <GeneralDropdown
              selectedOption={selectedState}
              list={stateList}
              handleChange={(obj) => {
                setSelectedState(obj);
                setSelectedCity(null);
                setSelectedArea(null);
                if (obj && obj.city && obj.city.length) {
                  let city = [];
                  obj.city.map((item) => {
                    city = [...city, { label: item, value: item }];
                  });
                  setCityList(city);
                }
                //   props.onStateSelect(obj);
              }}
              inModal
            />
          </div>
          <div className="col-md-4 pr-0">
            <label className="form-group is-invalid mb-0 mt-5">
              Select City
            </label>
            <GeneralDropdown
              selectedOption={selectedCity}
              list={cityList}
              handleChange={(obj) => {
                setSelectedCity(obj);
                setSelectedArea(null);
              }}
              inModal
            />
          </div>
          <div className="col-md-4 pr-0">
            <label className=" form-group is-invalid mb-0 mt-5">
              Select Area
            </label>
            <GeneralDropdown
              isMulti
              inModal
              selectedOption={selectedArea}
              list={areaList}
              handleChange={(obj) => {
                setSelectedArea(obj);
                setSelectedBusiness(null);
              }}
            />
          </div>
        </div>
        {/* 
        <div className="col-md-12">
          <div className="form-group is-invalid mb-0 mt-5">
            <label>Banner Detail</label>
            <input
              type="text"
              className="form-control"
              value={bannerDetail}
              onChange={(e) => {
                setBannerDetail(e.target.value);
              }}

            />
          </div>
        </div> */}
        <div className="col-md-12">
          <div className="form-group is-invalid mb-0 mt-5">
            <label>
              Banner Image <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex justify-content-center">
              {imagePreview ? (
                <div className="">
                  <div
                    className="image-input image-input-outline"
                    id="kt_profile_avatar"
                    style={{
                      backgroundImage: "url(assets/media/users/blank.png)",
                    }}
                  >
                    <img
                      className="mx-auto"
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        height: "150px",
                        width: "400px",
                        objectFit: "unset",
                        // marginTop: 20
                      }}
                    />
                    <label
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="change"
                      data-toggle="tooltip"
                      title
                      data-original-title="Change avatar"
                    >
                      <i className="fa fa-pen icon-sm text-muted" />
                      <input
                        type="file"
                        name="profile_avatar"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                      />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>

                    <span
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="cancel"
                      data-toggle="tooltip"
                      title
                      data-original-title="Cancel avatar"
                    >
                      <i className="ki ki-bold-close icon-xs text-muted" />
                    </span>
                    <span
                      className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      onClick={() => {
                        setFile(null);
                        setImagePreview("");
                      }}
                    >
                      <i className="ki ki-bold-close icon-xs text-muted" />
                    </span>
                  </div>
                </div>
              ) : (
                <input
                  className="form-control"
                  type="file"
                  onChange={handleFileChange}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group is-invalid mb-0 mt-5">
            <label>
              Banner Redirection<span style={{ color: "red" }}>*</span>
            </label>
            <GeneralDropdown
              className="form-control"
              selectedOption={selectedRedirection}
              list={redirectionOptions}
              handleChange={(obj) => {
                setSelectedRedirection(obj.value);
              }}
              inModal
            />
          </div>
        </div>
        {selectedRedirection ? (
          <div className="col-md-12">
            {selectedRedirection === "0" && (
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Coupon Id <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={couponId}
                  onChange={(e) => {
                    setCouponId(e.target.value);
                  }}
                />
              </div>
            )}
            {selectedRedirection === "1" && (
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Business <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  className="form-control"
                  selectedOption={selectedBusiness}
                  list={businessList}
                  handleChange={(obj) => {
                    setSelectedBusiness(obj.value);
                  }}
                  inModal
                />
                {/* <input
                  type="text"
                  className="form-control"
                  value={restaurantId}
                  onChange={(e) => {
                    setRestaurantId(e.target.value);
                  }}
                /> */}
              </div>
            )}
            {selectedRedirection === "2" && (
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Redirection <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  className="form-control"
                  selectedOption={screenName}
                  list={redirection}
                  handleChange={(obj) => {
                    setScreenName(obj.value);
                  }}
                  inModal
                />
              </div>
            )}
            {selectedRedirection === "3" && (
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Mobile<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            )}
            {selectedRedirection === "4" && (
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Link<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="url"
                  className="form-control"
                  value={link}
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        ) : null}
        {/* <div className="col-md-12">
          <div className="form-group is-invalid mb-0 mt-5">
            <label>
              Link <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={onClickUrl}
              onChange={(e) => {
                setOnClickUrl(e.target.value);
              }}
            />
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          style={{ width: "14%", textAlign: "center" }}
          className="btn-primary custom-button px-5 font-weight-bold"
          onClick={onSubmit}
          disabled={!sending ? false : true}
        >
          Save
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>

        <button
          type="button"
          className="custom-custom-btn-light px-10"
          onClick={onPressClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
