import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Loader } from "../../../components";
import {
  getParams,
  PER_PAGE,
  sizePerPageList,
} from "../../../config";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../Services";
import { GeneralDropdown } from "../../../components";
import { PaymentSettlement, PayoutDetails, CSVModal } from '../modals';
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

import Pagination from "react-js-pagination";

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showPaymentPop, setShowPaymentPop] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showPayoutDetail, setShowPayoutDetail] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const statusList = [
    { label: 'Pending', value: 'pending' },
    { label: 'Success', value: 'success' },
  ]

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`order/v1/business-payouts/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };

  const changeStatus = (row) => {
    if (!row.payment_status) {
      toast.dismiss();
      setShowPaymentPop(true);
      setSelectedRow(row);
    }
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "first_name",
      text: "Business Name",
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (_, row) => (
        <span className="fw-bold">₹{row?.amount ? row?.amount : '-'}</span>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (_, row) => (
        <>

          <span class="switch switch-sm">
            <label>
              <input
                type="checkbox"
                onChange={(e) => {
                  toast.loading("Please wait...");
                  changeStatus(row, e.target.checked);
                }}
                checked={row?.payout_details?.status ? true : row?.payment_status}
                disabled={row.payment_status || row?.payout_details?.status}
              />
              <span></span>
            </label>
          </span>
        </>
      ),
    },
    {
      dataField: "date",
      text: "Create At",
      formatter: (_, row) => (
        <span className="">{row?.date ? row?.date : '-'}</span>
      ),
    },
    {
      dataField: "action",
      text: "Details",
      formatter: (_, row) => (
        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
          onClick={() => {
            setShowPayoutDetail(true);
            setSelectedRow(row);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-secondary">
            <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
              <path
                fill="currentColor"
                d="M2 8a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 12a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM3 15a1 1 0 100 2h12a1 1 0 100-2H3z"
              />
            </svg>
          </span>
        </button>
      ),
    },
  ];

  return (
    <>
      <PaymentSettlement
        show={showPaymentPop}
        onHide={() => setShowPaymentPop(false)}
        data={selectedRow}
        onSuccess={(message) => {
          toast.success(message);
          setShowPaymentPop(false);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <PayoutDetails
        show={showPayoutDetail}
        onHide={() => setShowPayoutDetail(false)}
        data={selectedRow}
        onSuccess={(message) => {

        }}
        onError={(message) => {

        }}
      />

      <CSVModal
        show={showCSVModal}
        onHide={() => setShowCSVModal(false)}
        data={selectedRow}
      />

      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ?
            <>
              <div className="d-flex align-items-center justify-content-end mb-3">
                <button
                  className="btn custom-button py-2"
                  onClick={() => setShowCSVModal(true)}
                >
                  Export CSV
                </button>
              </div>
              <div className="row g-5">
                {data.map((row) => {
                  const status = { label: row.payment_status ? 'Success' : 'Pending', value: row.payment_status ? 1 : 0 };
                  let getColor = '';

                  if (row.payment_status) {
                    getColor = '#5dac51'
                  } else {
                    getColor = 'red'
                  }

                  const styles = {
                    control: (base, state) => ({
                      ...base,
                      border: `1px solid ${getColor}`,
                      boxShadow: "none",
                      "&:hover": {
                        border: `1px solid ${getColor}`,
                      },
                      backgroundColor: "#FFF",
                      width: '110px'
                    }),
                    indicatorSeparator: (base) => ({
                      ...base,
                      backgroundColor: getColor,
                    }),
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      color: getColor,
                      "&:hover": {
                        color: getColor,
                      },
                    }),
                    clearIndicator: (base, state) => ({
                      ...base,
                      color: getColor,
                      "&:hover": {
                        color: getColor,
                      },
                    }),
                  }
                  return (
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body p-5 position-relative">
                          <div className="ms-0 bg-gray-light rounded p-3">
                            <div className="fs-5">Business Name: <b>{row.first_name}</b></div>
                            <div className="rounded text-start mb-0 fs-7">Phone: <a href className="fs-7 fw-bolder ">{row.phone_number}</a></div>
                            <div className="rounded text-start mb-0 fs-7 ">
                              {row.account_number && <>Account No: <span className="fs-7 fw-bolder"
                                onClick={() => {
                                  toast.success("Text Copied");
                                  navigator.clipboard.writeText(row.account_number);
                                }}>
                                {row.account_number}</span>, </>
                              }
                              {row.ifsc_code && <> IFSC Code: <span className="fs-7 fw-bolder "
                                onClick={() => {
                                  toast.success("Text Copied");
                                  navigator.clipboard.writeText(row.ifsc_code);
                                }}>
                                {row.ifsc_code}</span>, <br /></>
                              }
                              {row.upi_holder_name && <>UPI Name: <span className="fs-7 fw-bolder "
                                onClick={() => {
                                  navigator.clipboard.writeText(row.upi_holder_name);
                                  toast.success("Text Copied");
                                }}>
                                {row.upi_holder_name}</span>,</>
                              }
                              {row.upi && <> UPI id: <a href className="fs-7 fw-bolder "
                                onClick={() => {
                                  toast.success("Text Copied");
                                  navigator.clipboard.writeText(row.upi);
                                }}>
                                {row.upi}</a></>
                              }
                            </div>
                          </div>
                          <div className="d-flex flex-stack align-items-center mt-2">
                            <div className=" d-flex align-items-center ">
                              <div className="rounded text-start mb-1">Amount: <span className="fs-6 fw-bolder text-primary">₹ {row.amount}</span>
                              </div>
                            </div>
                            <div className>
                              <div className="d-flex align-items-center">
                                <div className="fs-6 me-2">Status:</div>
                                <GeneralDropdown
                                  className="form-select form-select-transparent text-primary fs-base fw-bold py-0"
                                  selectedOption={status}
                                  list={statusList}
                                  handleChange={(obj) => {
                                    if (obj.value == 'success') {
                                      setShowPaymentPop(true);
                                      setSelectedRow(row);
                                    }
                                  }}
                                  disabled={row.payment_status}
                                  styles={styles}
                                  showClearButton={false}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-2" />
                          <div className=" row mt-3 align-items-center">
                            <div className="col-9">
                              <div className="fs-8 ">Created At: <b>{row.date}</b></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
            </div>
          }
        </div>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));