import React, { useEffect, useState } from "react";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";

export default function (props) {
  const [clearFilter, setClearFilter] = React.useState(null);
  const [search, setSearch] = useState("");

  const userStatus = [
    { value: '1', label: 'Active' },
    { value: '0', label: 'Inactive' },
  ];

  const userDuty = [
    { value: '1', label: 'On Duty' },
    { value: '0', label: 'Off Duty' },
  ];

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="position-relative me-0 mb-0 mb-md-0 w-15 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="w-10">
          <GeneralDropdown
            className="form-select border-0 fs-7"
            placeholder="Status..."
            clearFilter={clearFilter}
            list={userStatus}
            handleChange={(obj) => {
              props.setValue("status", obj.value);
            }}
            selectedOption={props.filters.status ? props.filters.status : null}
          />
        </div>
        <div className="w-10">
          <GeneralDropdown
            className="form-select border-0 fs-7"
            placeholder="Duty..."
            clearFilter={clearFilter}
            list={userDuty}
            handleChange={(obj) => {
              props.setValue("on_duty", obj.value);
            }}
            selectedOption={props.filters.on_duty ? props.filters.on_duty : null}
          />
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setClearFilter(Math.random());
              setSearch("");
            }}>
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
        <div className="position-relative mb-0 mb-md-0">
          <button
            className="custom-button btn-primary w-125px h-35px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center"
            onClick={() => {
              props.togglePopup();
            }}
          >
            Add Delivery Boy
          </button>
        </div>
      </div>
    </>
  );
}
