import React, { useState, useEffect, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { LoaderPopup } from "../../../components";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { timeSince } from "../../../config";

export default function ({ show, onHide, data, onSuccess, onError }) {
    const [loading, setLoading] = useState(false);
    const printRef = useRef();

    useEffect(() => {
        console.log("data >>>>>", data);
    }, [data]);

    const handleDownloadPdf = async () => {
        const printContent = printRef.current.innerHTML;

        const printWindow = window.open('', '', 'height=600,width=800');

        const styles = Array.from(document.styleSheets)
            .map((styleSheet) => {
                try {
                    return Array.from(styleSheet.cssRules)
                        .map((rule) => rule.cssText)
                        .join('');
                } catch (error) {
                    console.warn('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
                    return '';
                }
            })
            .join('\n');

        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
                ${styles}
                /* Additional styles for the print content */
                body {
                  font-family: Arial, sans-serif;
                }
                .print-section {
                  padding: 20px; 
                }
              </style>
            </head>
            <body>
              <div class="print-section">${printContent}</div>
            </body>
          </html>
        `);

        printWindow.document.close();
        printWindow.print();
    };


    return (
        <Modal size="lg" show={show} onHide={onHide} dialogClassName="bottom-popup">
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg" className="w-100">
                    <h3 className="modal-title">Order Details</h3>
                    <small>
                        <b>Order ID: </b>
                        {data.order_id ? data.order_id : '-'}
                    </small>
                    <br />
                    <small>
                        <b>Payment Method: </b>
                        {data.payment_method
                            ? data.payment_method == "Business credit"
                                ? "Khata"
                                : data.payment_method
                            : '-'}
                    </small>
                    <div>
                        <small>
                            <b>Payment Status: </b>
                            <span
                                className="ml-2 px-3 py-1 badge fs-base"
                                style={
                                    data?.payment_status
                                        ? { backgroundColor: '#DFFFEA', color: '#17C653', textAlign: 'center' }
                                        : { backgroundColor: '#ffcccc', color: '#ff3333', textAlign: 'center' }
                                }
                            >
                                {data.payment_status ? 'Success' : 'Pending'}
                            </span>
                        </small>
                    </div>
                </Modal.Title>
                <button
                    type="button"
                    className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
                    onClick={onHide}
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                    <i className="ki-outline ki-cross fs-1" />
                </button>
            </Modal.Header>

            <Modal.Body className="pt-3 scroll-popup">
                <div className="modal-body pb-7 pt-7 ps-lg-7 pe-0">
                    <div className="pe-7 ps-3" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div className="row g-5 mb-0 align-items-center">
                            <div className="col-md-12">
                                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
                                    <thead>
                                        <tr className="text-left text-uppercase">
                                            <th className="pl-4">#</th>
                                            <th>Product</th>
                                            <th>Product Price</th>
                                            <th>Quantity</th>
                                            <th className="text-end pr-5">Total</th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <tr>
                                            <td colSpan="9">
                                                <LoaderPopup />
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {data.order_details ? (
                                                data.order_details.map((item, i) => {
                                                    return (
                                                        <tr>
                                                            <td className="pl-4">{++i}</td>
                                                            <td>
                                                                Product:<b>{item.name}</b><br />
                                                                Description:<b>{item.description ? item.description : '-'}</b>
                                                            </td>
                                                            <td>Rs.{item.price}</td>
                                                            <td>{item.quantity}</td>
                                                            <td className="text-end pr-5">Rs.{item.price * item.quantity}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="9">
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                    <tfoot>
                                        <tr style={{ borderTop: '1px dashed #ddd' }}>
                                            <th />
                                            <th />
                                            <th />
                                            <th className="text-end">Promo code applied</th>
                                            <th className="pr-5 text-end">Rs.{data.promo_record ? data.promo_record.discount_amount : 0}</th>
                                        </tr>
                                        <tr style={{ borderTop: '1px dashed #ddd' }}>
                                            <th />
                                            <th />
                                            <th />
                                            <th className="text-end">Delivery charge</th>
                                            <th className="pr-5 text-end">Rs.{data.delivery_charges}</th>
                                        </tr>
                                        <tr>
                                            <th />
                                            <th />
                                            <th />
                                            <th className="text-end"><b>Total Amount</b></th>
                                            <th className="pr-5 text-end"><b>Rs.{data.total}</b></th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <div>
                                    {data.note &&
                                        <div>
                                            <label htmlFor className="fs-6 fw-bold mb-2">Note: <span className="text-primary">{data.note}</span></label>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={printRef} className="pt-3 scroll-popup no-display">
                    <div className="modal-body pb-7 pt-7 ps-lg-7 pe-0">
                        <div className="pe-7 ps-3">
                            <div className="row g-5 mb-0 align-items-center">
                                <div className="d-flex justify-content-center w-100">
                                    <div className="d-flex flex-column align-items-center">
                                        <div>
                                            <h3 className="modal-title">TapRocket</h3>
                                        </div>
                                        <div>
                                            <h3 className="modal-title">Order Details</h3>
                                        </div>
                                        <div>
                                            <span className="fs-5 text-center">Order Id: <b>{data.order_id ? data.order_id : '-'}</b></span>
                                        </div>
                                        <div>
                                            <span className="fs-6 text-center">Cafe Name: <b>{data?.business?.first_name ? data?.business?.first_name : '-'}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                                <div className="fs-7 mb-2">Date:
                                    <span className="ml-1">
                                        <b>{new Date(data.placed_at).toLocaleString()} ({timeSince(data.placed_at)})</b>
                                    </span>
                                </div>
                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                                <div className="fs-7 mb-2">Delivery Boy:
                                    <span className="ml-1">
                                        <b>{data.delivery_boy ? data.delivery_boy : '-'}</b>
                                    </span>
                                </div>
                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                                <div className="col-md-12">
                                    <div className="fs-7 mb-2">Name: <b>{data?.customer?.first_name ? data?.customer?.first_name : '-'}</b></div>
                                    <div className="fs-7 mb-2">Phone: <a href><b>{data?.customer?.phone_number ? data?.customer?.phone_number : '-'}</b></a></div>
                                    <div className="fs-7 mb-2">Address: Office Name: <b>{data.office}</b>,
                                        <span>Office No.: <b>{data.office_number}</b></span>,
                                        <span>Building: <b>{data.building}</b></span>,
                                        <span>Wing: <b>{data.wing}</b></span>
                                    </div>
                                    <div className="mb-2">Status: <b>{data.order_status}</b></div>
                                    <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                                    <div className="mb-2">Payment Method: <b>
                                        {data.payment_method
                                            ? data.payment_method == "Business credit"
                                                ? "Khata"
                                                : data.payment_method
                                            : '-'}
                                    </b></div>
                                    <div className="mb-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <span>Order Type:{" "}</span>
                                        <b style={{ marginLeft: '3px' }}>
                                            {data.order_type === 1 && <span> Scheduled </span>}
                                            {data.order_type === 2 && <span> Manual </span>}
                                            {data.order_type === 3 && <span> Pre Order </span>}
                                            {data.order_type === 4 && <span> Settlement </span>}
                                            {data.is_on_tap && <span> On Tap </span>}
                                            {data.is_custom && <span> Custom </span>}
                                        </b>
                                    </div>
                                    <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                                </div>
                                <div className="col-md-12">
                                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
                                        <thead>
                                            <tr className="text-left text-uppercase">
                                                <th className="pl-4">#</th>
                                                <th>Product</th>
                                                <th>Product Price</th>
                                                <th>Quantity</th>
                                                <th className="text-end pr-5">Total</th>
                                            </tr>
                                        </thead>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="9">
                                                    <LoaderPopup />
                                                </td>
                                            </tr>
                                        ) : (
                                            <>
                                                {data.order_details ? (
                                                    data.order_details.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <td className="pl-4">{++i}</td>
                                                                <td>
                                                                    Product:<b>{item.name}</b><br />
                                                                    Description:<b>{item.description ? item.description : '-'}</b>
                                                                </td>
                                                                <td>Rs.{item.price}</td>
                                                                <td>{item.quantity}</td>
                                                                <td className="text-end pr-5">Rs.{item.price * item.quantity}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="9">
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                        <tfoot>
                                            <tr style={{ borderTop: '1px dashed #ddd' }}>
                                                <th />
                                                <th />
                                                <th />
                                                <th className="text-end">Promo code applied</th>
                                                <th className="pr-5 text-end">Rs.{data.promo_record ? data.promo_record.discount_amount : 0}</th>
                                            </tr>
                                            <tr style={{ borderTop: '1px dashed #ddd' }}>
                                                <th />
                                                <th />
                                                <th />
                                                <th className="text-end">Delivery charge</th>
                                                <th className="pr-5 text-end">Rs.{data.delivery_charges}</th>
                                            </tr>
                                            <tr>
                                                <th />
                                                <th />
                                                <th />
                                                <th className="text-end"><b>Total Amount</b></th>
                                                <th className="pr-5 text-end"><b>Rs.{data.total}</b></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div>
                                        {data.note &&
                                            <div>
                                                <label htmlFor className="fs-6 fw-bold mb-2">Note: <span className="text-primary">{data.note}</span></label>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <button
                        className="custom-button btn py-2 px-5"
                        onClick={handleDownloadPdf}>
                        Print
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
