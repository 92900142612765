import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";
import { getAreaByCity } from "../../../../config";

export default function ({
  show,
  onHide,
  onSuccess,
  onError,
  data,
  chhotuList,
}) {
  const [name, setName] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [minCharges, setMinCharges] = useState(0);
  const [chhotus, setChhotus] = useState([]);
  const [sending, setSending] = useState(false);
  const [chhotuObj, setChhotuObj] = useState({});

  const [areaList, setAreaList] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  // const [options, setOptions] = useState([]);
  const [isTRBoy, setIsTRBoy] = useState(false);
  const [buildingList, setBuildingList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false);

  // useEffect(() => {
  //   console.log("buildingList");
  //   console.log(buildingList);
  // }, []);

  // const [buildings, setBuildings] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [selectedWings, setSelectedWings] = useState({});

  useEffect(() => {
    if (show) {
      if (data && data.id) {
        setName(data.name);
        setDeliveryCharge(data.delivery_charges);
        setMinCharges(data.min_order_value);
        setIsTRBoy(data.tr_delivery_boys);
        setSelectedWings(data.wings);

        if (data.delivery_boys) {
          let chhotus_ = [];
          data.delivery_boys.map((item) => {
            chhotus_ = [...chhotus_, { label: item.name, value: item.id }];
          });
          setChhotus(chhotus_);
        }
        if (data.chhotu_building_data) {
          setChhotuObj(data.chhotu_building_data);
        }
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  useEffect(() => {
    if (
      data.buildings?.length > 0 &&
      !initialLoad &&
      buildingList?.length > 0
    ) {
      const buildings = data.buildings.map((item) => ({
        value: item.id,
        label: item.name,
        wings: buildingList.find((elem) => elem.value === item.id)?.wings,
      }));
      // console.log("buildings");
      // console.log(buildings);
      setSelectedBuildings(buildings);
      setInitialLoad(true);
    }
  }, [data, show, buildingList, initialLoad]);

  useEffect(() => {
    getAreaList();
  }, []);

  const getAreaList = async () => {
    const area = await getAreaByCity("", "");
    setAreaList(area);
  };

  useEffect(() => {
    getBuildingList();
  }, [selectedArea]);

  useEffect(() => {
    if (areaList.length && data.area) {
      let array = [];
      areaList.map((item) => {
        data.area.map((area) => {
          if (item.value === area) {
            array = [...array, item];
          }
        });
      });
      console.log("setSelectedArea", array);

      setSelectedArea(array);
    }
  }, [areaList, data]);

  const getBuildingList = () => {
    const list = selectedArea.map((item) => item.value);

    getApiData(`cluster/v1/buildings/?area_list=${JSON.stringify(list)}`).then(
      (res) => {
        if (res.status) {
          let result = [];
          res.data.forEach((building) => {
            const buildingName = building.name;
            const floorsAndWings = building.floors_and_wings;
            result.push({
              label: buildingName,
              value: building.id,
              wings: Object.keys(floorsAndWings),
            });
          });
          // console.log("result");
          // console.log(result);
          // if (!initialLoad) {
          //   setInitialLoad(true);
          // const buildings = data.buildings?.map((item) => ({
          //   value: item.id,
          //   label: item.name,
          //   wings: result.find((elem) => elem.value === item.id)?.wings,
          // }));
          // console.log("buildings");
          // console.log(buildings);
          // setSelectedBuildings(buildings);
          // }

          setBuildingList(result);
        }
      }
    );
  };

  // useEffect(() => {
  //   const filteredBuildingList = buildingList.filter((building) => {
  //     return !selectedItems.some(
  //       (selected) => selected.label === building.label
  //     );
  //   });

  //   const newArray = filteredBuildingList.map((obj) => ({
  //     id: generateRandomId(),
  //     label: obj.label,
  //     value: obj.value,
  //   }));

  //   setOptions(newArray);
  // }, [buildingList, selectedItems]);

  // function generateRandomId() {
  //   return Math.random().toString(36).substring(2, 10);
  // }

  const clearInput = () => {
    setName("");
    setDeliveryCharge(0);
    setMinCharges(0);
    // setSelectedItems([]);
    setChhotus([]);
    setChhotuObj({});
    setIsTRBoy(false);
  };

  const onSubmit = () => {
    if (!name) {
      toast.error("Please enter cluster name");
    } else if (selectedBuildings.length == 0) {
      toast.error("Please select building");
    } else if (!isTRBoy && chhotus.length == 0) {
      toast.error("Please select delivery boy");
    } else {
      setSending(true);

      let chhotus_ = [];
      chhotus.map((item) => {
        chhotus_ = [...chhotus_, item.value];
      });

      const body = {
        name: name,
        buildings: Object.keys(selectedWings),
        // area: selectedArea.map((item) => item.value),
        wings: selectedWings,
        delivery_boys: chhotus_,
        delivery_charges: deliveryCharge,
        min_order_value: minCharges,
        tr_delivery_boys: isTRBoy,
      };

      // const buildingsMap = {};
      // selectedItems.forEach((item) => {
      //   const buildingId = item.id;
      //   if (!buildingsMap[buildingId]) {
      //     body.buildings.push(buildingId);
      //     buildingsMap[buildingId] = [];
      //   }

      //   const parts = item.label.split(" - ");
      //   const wingName = parts[parts.length - 1];
      //   buildingsMap[buildingId].push(wingName);
      // });

      // body.wings = Object.entries(buildingsMap).map(([buildingId, wings]) => {
      //   return { [buildingId]: wings };
      // });

      let method = data.id ? "PUT" : "POST";
      let id = data.id ? data.id + "/" : "";

      getApiData(`cluster/v1/${id}`, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          clearInput();
          onHide();
          onSuccess(res.message);
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError(Object.values(res.message)[0]);
          }
        }
      });
    }
  };

  return (
    <Modal size="lg" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Manage Cluster</h3>
        </Modal.Title>
        <div className="d-flex ml-auto">
          <div
            className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
            onClick={onHide}
          >
            <i className="ki-outline ki-cross fs-1" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Name
              </label>
              <input
                type="text"
                className="form-control fs-7"
                placeholder="Enter cluster name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className="fs-6 fw-bold mb-2 required">
                  Select Area
                </label>
                <GeneralDropdown
                  selectedOption={selectedArea}
                  list={areaList}
                  inModal
                  isMulti
                  handleChange={(obj) => {
                    setSelectedArea(obj);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Select Building
                </label>
                <GeneralDropdown
                  className="form-select fs-7"
                  selectedOption={selectedBuildings}
                  list={buildingList}
                  handleChange={(buildings) => {
                    console.log(buildings);

                    let wings_ = { ...selectedWings };

                    // Create a Set of the current building values (to keep track of valid keys)
                    const buildingValues = new Set(
                      buildings.map((building) => building.value)
                    );

                    // console.log("buildingValues : ", buildingValues);

                    Object.keys(wings_).forEach((key) => {
                      if (!buildingValues.has(key)) {
                        delete wings_[key];
                      }
                    });

                    buildings.forEach((item) => {
                      if (!wings_[item.value]) {
                        wings_[item.value] = item.wings;
                      }
                    });

                    // console.log("Updated wings_:", wings_);

                    // Update state
                    setSelectedBuildings(buildings);
                    setSelectedWings(wings_);
                  }}
                  inModal
                  isMulti
                />
              </div>
            </div>
            {selectedBuildings.length > 0 &&
              selectedBuildings.map((item, i) => {
                return (
                  <div
                    className="col-md-12 row mt-2 align-items-center"
                    key={i}
                  >
                    <div className="col-md-4">{item.label}</div>
                    <div className="col-md-6 row">
                      {item.wings?.map((wing, index) => {
                        let selected =
                          selectedWings[item.value]?.includes(wing);
                        return (
                          <button
                            className={`custom-button h-50px w-50px mr-2 mb-1`}
                            style={{
                              backgroundColor: selected ? "#EB3A22" : "#f3f6f9",
                              borderColor: selected ? "#EB3A22" : "#f3f6f9",
                              color: selected ? "#fff" : "#000",
                            }}
                            key={index}
                            onClick={() => {
                              const wings = { ...selectedWings };
                              if (selected) {
                                wings[item.value] = selectedWings[
                                  item.value
                                ].filter((item) => item !== wing);
                              } else {
                                wings[item.value] = [
                                  ...(selectedWings[item.value] ?? []),
                                  wing,
                                ];
                              }
                              console.log("wings", wings);
                              setSelectedWings(wings);
                            }}
                          >
                            {wing}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            {/* <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">Select Building</label>
              <GeneralDropdown
                className="form-select fs-7"
                selectedOption={selectedItems}
                list={options.map(option => ({ value: option.id, label: option.label, id: option.value }))}
                handleChange={(selectedIds) => {
                  setSelectedItems(selectedIds);
                }}
                inModal
                isMulti
              />
            </div> */}
            <div className="col-md-12 d-flex align-items-center">
              <label htmlFor className=" fs-6 fw-bold">
                Use TapRocket Delivery Services:
              </label>
              <div
                className="form-check ml-7 mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => {
                    setIsTRBoy(e.target.checked);
                    setChhotus([]);
                  }}
                  defaultChecked={data?.tr_delivery_boys}
                />
              </div>
            </div>
            {!isTRBoy && (
              <div className="col-md-12">
                <label htmlFor className={`fs-6 fw-bold mb-2 required`}>
                  Select Delivery Boy{" "}
                </label>
                <GeneralDropdown
                  className="form-select fs-7"
                  selectedOption={chhotus}
                  list={chhotuList}
                  handleChange={(array) => {
                    // if (array && array.length) {
                    setChhotus(array);

                    let chhotuObj_ = {};
                    array.map((item) => {
                      if (
                        chhotuObj[item.value] &&
                        Object.keys(chhotuObj[item.value]).length
                      ) {
                        chhotuObj_ = {
                          ...chhotuObj_,
                          [item.value]: chhotuObj[item.value],
                        };
                      }
                    });
                    setChhotuObj(chhotuObj_);
                    // }
                  }}
                  inModal
                  isMulti
                />
              </div>
            )}
            <div className="row mt-5">
              <div className="col-md-6">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Delivery Charge
                </label>
                <input
                  type="number"
                  className="form-control fs-7"
                  value={deliveryCharge}
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      setDeliveryCharge(e.target.value);
                    }
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Minimum Order Value
                </label>
                <input
                  type="number"
                  className="form-control fs-7"
                  value={minCharges}
                  onChange={(e) => {
                    if (e.target.value.length <= 3) {
                      setMinCharges(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn custom-btn-light fs-7"
            onClick={() => {
              onHide();
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save{" "}
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
