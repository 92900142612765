import React, { useEffect, useState } from "react";
import { CardBody, Card } from "../../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {
  const [filters, setFilters] = useState({
    search: "",
    filter: "",
    dues: false,
    startDate: "",
    endDate: "",
  });
  const [totalDues, setTotalDues] = useState(0);

  useEffect(() => {
    // getList('marketplaces')
  }, []);

  return (
    <>
      <div>
        <TableFilter
          totalDues={totalDues}
          dues={filters.dues}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}

          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}

          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
              business: "",
              startDate: "",
              endDate: "",
            });
          }}
        />
        <Table
          filters={filters}
          setTotalDues={(dues) => {
            setTotalDues(dues);
          }}
        />
      </div>
    </>
  );
}