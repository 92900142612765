import React, { useCallback, useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { DeleteModal, Loader } from "../../../../components";
import { formatTime, getParams, isOldTimeLessthenToNewTime, PER_PAGE, SOCKET_URL } from "../../../../config";
// import { onMessageListener } from "../../../../config/firebase";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import { ChhotuModal } from "./../modals";
import { timeSince } from "../../../../config";
import { OrderDetails } from './../modals';
import Pagination from "react-js-pagination";

function UserTable({ filters, dispatch, setFilters }) {
  const { authToken } = useSelector((state) => state.auth);
  const backgroundImageUrl = toAbsoluteUrl("/media/svg/shapes/circle-2.svg");

  const history = useHistory();
  const [chhotuModal, setChhotuModal] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [timerStates, setTimerStates] = useState({});

  const { user } = useSelector((state) => state.auth);
  const storedWebSocket = JSON.parse(localStorage.getItem('websocket'));
  const socketUrl = `${SOCKET_URL}/ws/taprocket/delivery/${user.id}/`;
  const hasCalled = useRef(false);

  useEffect(() => {
    let url = socketUrl ? socketUrl : storedWebSocket.socketUrl
    const socket = new WebSocket(url);

    socket.onopen = () => {
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("message live order", message);
      if (message.new_order || message?.socket_type == 'track_order') {
        setLoading(true);
        getTableRecords(filters);
      }
    };

    return () => {
      socket.close();
    };
  }, [dispatch]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !hasCalled.current) {
        setLoading(true);
        getTableRecords(filters);
        hasCalled.current = true;

        setTimeout(() => {
          hasCalled.current = false;
        }, 1000);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [filters]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords(filters);
    }
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords(filters);
  }, [sizePerPage, currentPage, sizePerPage]);

  const getTableRecords = (filters_) => {
    const params = getParams(filters_, currentPage, sizePerPage);

    getApiData(
      `order/v1/history/live/${params}`,
      "get",
      {},
      true,
      authToken
    ).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        // alert(authToken);
        history.push("/logout");
      }
    });
  };

  const completePreOrder = (item) => {
    if (item.order_id) {
      const body = {
        order_id: item.order_id
      }
      getApiData(`order/v1/history/`, 'post', body).then((res) => {
        if (res.status) {
          getTableRecords(filters);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      });
    }
  }

  const onClickNotification = (item) => {
    if (item) {
      getApiData(`order/v1/history/${item.order_id}/`, 'put').then((res) => {
        if (res.status) {
          toast.success(res.message);
          getTableRecords(filters);
          startTimerForItem(item.order_id);
        } else {
          toast.error(res.message);
        }
      })
    }
  }

  const startTimerForItem = (itemId) => {
    setTimerStates(prevState => ({
      ...prevState,
      [itemId]: true, // Set timer state for the item to true
    }));
  };

  // Function to stop the timer for a specific item
  const stopTimerForItem = (itemId) => {
    setTimerStates(prevState => ({
      ...prevState,
      [itemId]: false, // Set timer state for the item to false
    }));
  };

  const commonTrack = {
    backgroundImage: `url("${backgroundImageUrl}")`,
    flexShrink: 0,
    width: '2rem',
    height: '2rem',
    borderRadius: '1rem',
    verticalAlign: 'top',
    appearance: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  };


  return (
    <>
      <ChhotuModal
        show={chhotuModal}
        data={selectedRow}
        onHide={() => setChhotuModal(!chhotuModal)}
        onSuccess={(message) => {
          setChhotuModal(false);
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <OrderDetails
        show={showOrderDetails}
        onHide={() => setShowOrderDetails(!showOrderDetails)}
        data={selectedRow}
      />

      {selectedRow?.business?.id && (
        <DeleteModal
          show={deleteModal}
          api="tapri/v1/orders/live/cancel"
          title="Delete order"
          isReason={true}
          message="Are you sure to want delete this order?"
          onHide={() => setDeleteModal(false)}
          id={selectedRow.order_id}
          onSuccess={(message) => {
            setDeleteModal(false);
            getTableRecords();
            toast.success(message);
          }}
          onFailed={(message) => {
            setDeleteModal(false);
            toast.error(message);
          }}
        />
      )}
      <div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {data.length !== 0 ?
              <>
                <div className="row g-5">
                  <div className="col-md-12">
                    {data.map((item, index) => (
                      <div className="card mb-5">
                        <div className="card-body p-5 position-relative pb-4">
                          <div className="d-flex flex-stack align-items-center">
                            <div className=" d-flex align-items-center min-w-150px">
                              <span className="bg-primary ordno mb-0">{item.sr_no}</span>
                              <div className="ms-3  bg-gray-light rounded p-3">
                                <div className="fs-6">Name: <b>{item.customer.first_name}</b></div>
                                <div className="fs-8">Phone: <a href><b>{item?.customer?.phone_number}</b></a></div>
                                <div className="fs-8">Office Name: <b>{item?.office}</b></div>
                                <div className="fs-8">Office No.: <b>{item?.office_number}</b></div>
                                <div className="fs-8">Building: <b>{item?.building}</b></div>
                                <div className="fs-8">Wing : <b>{item?.wing}</b></div>
                              </div>
                            </div>
                            <div className="min-w-100px ml-3" style={{}}>
                              <div className="rounded fs-6 fw-bolder mb-1 text-right">Total: ₹ {item?.total}</div>
                              <div className=" mb-4 fs-7 fw-bolder text-primary text-right">
                                <a
                                  href
                                  onClick={() => {
                                    setShowOrderDetails(true);
                                    setSelectedRow(item);
                                  }}>
                                  Order Details
                                </a>
                              </div>
                              <div className="d-flex align-items-center justify-content-end fs-8">
                                <i className="ki-outline ki-watch text-primary fs-2 me-2" />
                                <div>
                                  <span>{timeSince(item.created_at)}</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end fs-8 mt-2">
                                <div className="fs-8">Order Id: <b>{item.order_id}</b></div>
                              </div>
                              <div className={`mt-3 d-flex align-items-center ${item?.delivery_boy && 'mr-10'}`}>
                                {item.order_type !== 3 &&
                                  <button
                                    className="custom-button btn-sm btn-primary fw-bold fs-7"
                                    onClick={() => {
                                      setSelectedRow(item);
                                      setChhotuModal(true);
                                    }}>
                                    <div>
                                      <span>
                                        {item?.delivery_boy ? (
                                          item.delivery_boy
                                        ) : (
                                          <a
                                            style={{ color: '#FFF' }}
                                            onClick={() => {
                                              setSelectedRow(item);
                                              setChhotuModal(true);
                                            }}
                                          >
                                            {" "}
                                            Assign
                                          </a>
                                        )}
                                      </span>
                                      {item?.delivery_boy && (
                                        <a style={{ color: "#f5f5f5" }}
                                          onClick={() => {
                                            setSelectedRow(item);
                                            setChhotuModal(true);
                                          }}>
                                          {" "}(Change)
                                        </a>
                                      )}
                                    </div>
                                  </button>
                                }
                                <button
                                  className="btn btn-icon custom-btn-light btn-sm ms-3"
                                  style={{ marginRight: '-25px' }}
                                  onClick={() => {
                                    setSelectedRow(item);
                                    setDeleteModal(true);
                                  }}>
                                  <i className="ki-outline ki-trash fs-1" />
                                </button>
                                {item.order_type == 3 &&
                                  <button
                                    className="btn btn-icon btn-sm ms-10" style={{ marginRight: 0, backgroundColor: "#a9d9aa" }}
                                    onClick={() => {
                                      completePreOrder(item);
                                    }}>
                                    <span className="svg-icon svg-icon-md" style={{ color: 'green' }}>
                                      <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        viewBox="0 0 24 24"
                                        height="1em"
                                        width="1em"
                                      >
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                                        <path d="M22 4L12 14.01l-3-3" />
                                      </svg>
                                    </span>
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-start justify-content-center mt-3 mb-3">

                            <div className="d-flex flex-column w-25">
                              <div className="d-flex align-items-center mt-1">
                                <div style={{ ...commonTrack, backgroundColor: '#f75f48', }} />
                                <div style={{ height: '4px', width: '100%', backgroundColor: '#f75f48' }} />
                              </div>
                              <div className="d-flex flex-column align-items-start mt-2">
                                <div className="fs-5 fw-semibold">Order Placed</div>
                                <div className="fs-6">{formatTime(item.placed_at)}</div>
                              </div>
                            </div>

                            <div className="d-flex flex-column w-25">
                              <div className="d-flex align-items-center mt-1">
                                <div style={{ ...commonTrack, backgroundColor: isOldTimeLessthenToNewTime(item.placed_at, item.assigned_at) ? '#f75f48' : '#fdc1b7' }} />
                                <div style={{ height: '4px', width: '100%', backgroundColor: isOldTimeLessthenToNewTime(item.placed_at, item.assigned_at) ? '#f75f48' : '#fdc1b7' }} />
                              </div>
                              <div className="d-flex flex-column align-items-start mt-2">
                                <div className="fs-5 fw-semibold">Order Assigned</div>
                                <div className="fs-6">{isOldTimeLessthenToNewTime(item.placed_at, item.assigned_at) && formatTime(item.assigned_at)}</div>
                              </div>
                            </div>

                            <div className="d-flex flex-column w-25">
                              <div className="d-flex align-items-center mt-1">
                                <div style={{ ...commonTrack, backgroundColor: isOldTimeLessthenToNewTime(item.assigned_at, item.reached_at_business) ? '#f75f48' : '#fdc1b7' }} />
                                <div style={{ height: '4px', width: '100%', backgroundColor: isOldTimeLessthenToNewTime(item.assigned_at, item.reached_at_business) ? '#f75f48' : '#fdc1b7' }} />
                              </div>
                              <div className="d-flex flex-column align-items-start mt-2">
                                <div className="fs-5 fw-semibold">Reached At Business</div>
                                <div className="fs-6">{isOldTimeLessthenToNewTime(item.assigned_at, item.reached_at_business) && formatTime(item.reached_at_business)}</div>
                              </div>
                            </div>

                            <div className="d-flex flex-column w-25">
                              <div className="d-flex align-items-center mt-1">
                                <div style={{ ...commonTrack, backgroundColor: isOldTimeLessthenToNewTime(item.reached_at_business, item.out_for_delivery) ? '#f75f48' : '#fdc1b7' }} />
                                <div style={{ height: '4px', width: '100%', backgroundColor: isOldTimeLessthenToNewTime(item.reached_at_business, item.out_for_delivery) ? '#f75f48' : '#fdc1b7' }} />
                              </div>
                              <div className="d-flex flex-column align-items-start mt-2">
                                <div className="fs-5 fw-semibold">Order Dispatched</div>
                                <div className="fs-6">{isOldTimeLessthenToNewTime(item.reached_at_business, item.out_for_delivery) && formatTime(item.out_for_delivery)}</div>
                              </div>
                            </div>

                            <div className="d-flex flex-column w-25">
                              <div className="d-flex align-items-center mt-1">
                                <div style={{ ...commonTrack, backgroundColor: isOldTimeLessthenToNewTime(item.out_for_delivery, item.reached_at) ? '#f75f48' : '#fdc1b7' }} />
                                <div style={{ height: '4px', width: '100%', backgroundColor: isOldTimeLessthenToNewTime(item.out_for_delivery, item.reached_at) ? '#f75f48' : '#fdc1b7' }} />
                                <div style={{ ...commonTrack, backgroundColor: isOldTimeLessthenToNewTime(item.reached_at, item.delivered_at) ? '#f75f48' : '#fdc1b7' }} />
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex flex-column align-items-start mt-2">
                                  <div className="fs-5 fw-semibold">Reached At</div>
                                  <div className="fs-6">{isOldTimeLessthenToNewTime(item.out_for_delivery, item.reached_at) && formatTime(item.reached_at)}</div>
                                </div>
                                <div className="d-flex flex-column align-items-start mt-2">
                                  <div className="d-flex fs-5 fw-semibold text-right">Order Delivered</div>
                                  <div className="fs-6">{isOldTimeLessthenToNewTime(item.reached_at, item.delivered_at) && formatTime(item.delivered_at)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                          <div className="d-flex flex-stack">
                            <div>
                              {
                                <>
                                  <div>
                                    <span>{new Date(item.created_at).toLocaleString()}</span>
                                    <br />
                                  </div>
                                  <div className="mt-2">
                                    <span>Order Status: </span>
                                    <div
                                      className="fs-8 badge fs-base"
                                      style={item?.order_status == "In Process" ? { backgroundColor: '#DFFFEA', color: '#17C653' } : { backgroundColor: '#ffcccc', color: '#ff3333' }}>
                                      {item?.order_status}
                                    </div>
                                  </div>

                                  <div className="d-flex flex-column">
                                    {item.delivery_boy &&
                                      <div>
                                        <span>Delivery Boy: </span>
                                        <div className="fs-8 badge fs-base">{item.delivery_boy}</div>
                                      </div>
                                    }

                                    {item?.delivery_boy_phone_number &&
                                      <div>
                                        <span>Delivery Boy Phone Number: </span>
                                        <div className="fs-8 badge fs-base">{item?.delivery_boy_phone_number ?? item?.delivery_boy_phone_number}</div>
                                      </div>
                                    }
                                  </div>
                                </>
                              }
                            </div>
                            <div className="d-flex flex-column">
                              <div className="fs-6 text-right">
                                Order Type: {" "}
                                <b className="text-primary">
                                  <div className="text-right">
                                    {item.order_type == 1 ?
                                      <span>{'Scheduled'}<br /></span>
                                      : item.order_type == 2 ?
                                        <span>{'Manual'}<br /></span> :
                                        item.order_type == 3 ?
                                          <span>{'Pre Order'}<br /></span> :
                                          item.order_type == 4 ?
                                            <span>{'Settlement'}<br /></span> :
                                            <span></span>
                                    }
                                    {item.is_on_tap && <span>{"On Tap"}<br /></span>}
                                    {item.is_custom && <span>{"Custom"}<br /></span>}
                                    {item.order_type == 3 &&
                                      <>
                                        {item.is_takeaway ? <span>{"Take Away"}</span> : <span>{"Dine In"}</span>}
                                      </>
                                    }
                                  </div>
                                </b>
                              </div>
                              {(item?.order_type == 1 && item?.deliverying_slots.length !== 0) &&
                                <div className="fs-6 text-right">
                                  Deliver By: {" "}
                                  <b className="text-primary">
                                    <div className="text-right">
                                      <span>{item?.deliverying_slots[0] ?? item?.deliverying_slots[0]}</span>
                                    </div>
                                  </b>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-2 float-right mr-3">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={20}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={5}
                    itemClass="d-flex justify-content-between align-items-center flex-wrap"
                    linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                    activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                    onChange={(pageNumber) => {
                      setCurrentPage(pageNumber);
                    }}
                  />
                </div>
              </>
              :
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
              </div>
            }
          </>
        )}
      </div >
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
