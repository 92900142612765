import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import toast from "react-hot-toast";

export default function ({ data, show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [contactPhoneNo, setContactPhoneNo] = useState("");
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {

  }, [data]);

  const onSubmit = () => {
    if (!customerName) {
      toast.error("Please enter name");
    } else if (!contactPhoneNo) {
      toast.error("Please enter phone number");
    } else {
      setSending(true);
      const body = {
        first_name: customerName,
      };

      getApiData(`business/v1/customers/${contactPhoneNo}/`, "put", body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message);
          onHide();
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError("Something went wrong!");
          }
        }
      });
    }
  };

  const clearInput = () => {
    setContactPhoneNo("");
    setCustomerName("");
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add Customer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="row">
          <div className="form-group is-invalid mb-2">
            <label>Customer Name </label>
            <input
              type="text"
              className="form-control"
              value={customerName}
              onChange={(e) => {
                setCustomerName(e.target.value);
              }}
            />
          </div>
          <div className="form-group is-invalid mb-0">
            <label>Customer Phone Number </label>
            <input
              type="number"
              className="form-control"
              value={contactPhoneNo}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  setContactPhoneNo(e.target.value);
              }}
              maxLength={10}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => {
              onHide();
              clearInput();
            }}
            className="custom-custom-btn-light px-10"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            disabled={!sending ? false : true}
            style={{ width: "100px" }}
            className="btn-primary custom-button font-weight-bold"
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
