import React, { useEffect, useState } from "react";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    business: "",
  });

  return (
    <>
      <div>
        <TableFilter
          value={filters}

          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}

          onClearFilter={() => {
            setFilters({
              search: "",
              startDate: "",
              endDate: "",
            });
          }}

          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}
        />
        <Table filters={filters} />
      </div>
    </>
  );
}