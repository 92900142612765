import React from 'react'
import './loaderCards.css'
// const Style = {}
export default function LoaderCards() {
    return (
        <>
            <div className="row g-2 mb-3">
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                {/* <div className="col-md-2 col-sm-2 line"></div> */}
            </div>
            <div className="row g-2 mb-3">
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                <div className="col-md-2 col-sm-2 line"></div>
                {/* <div className="col-md-2 col-sm-2 line"></div> */}
            </div>
        </>
    )
}
