import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components";
import {
  convertDate,
  getParams,
  PER_PAGE,
  timeSince,
} from "../../../config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Pagination from "react-js-pagination";
import { getApiData } from "../../../Services";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`feedback/v1/feedback-list${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ?
            <>
              <div className="row g-5">
                {data.map((row) => (
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-5 position-relative">
                        <div className="ms-0  bg-gray-light rounded p-3 mb-2 d-flex">
                          <div className="symbol symbol-40px me-4">
                            <span className="symbol-label bg-skin-primary">
                              <i className="ki-duotone ki-message-text-2 text-primary fs-3x"><span className="path1" /><span className="path2" /><span className="path3" /></i>
                            </span>
                          </div>
                          <div className="ms-3">
                            <div className="fs-6">Customer Name: <b className>{row?.customer?.first_name}</b></div>
                            <div className="fs-7">Phone: <a className="fw-bold">{row?.customer?.phone_number}</a></div>
                          </div>
                        </div>
                        {row.bug && <div className="fs-6">Bug: <b><span className="text-primary">{row.bug}</span></b></div>}
                        {row.suggestion && <div className="fs-7"><b>Suggestion:</b> <span className>{row.suggestion}</span></div>}
                        <div className="separator separator-dashed mt-4" />
                        <div className="d-flex flex-stack align-items-center mt-3">
                          <div>
                            <div className="fs-8 ">Created At: <b>{convertDate(row?.created_at)}</b> ({timeSince(row?.created_at)})</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
            </div>
          }
        </div >
      )
      }
    </>
  );
}
