import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { getParams } from "../../../config";
import * as auth from '../../../modules/Auth/_redux/authRedux';
import { getApiData } from "../../../Services";
import '../../../../my-style.css';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DateRangeSettings } from "../../../config";
import { LoaderCards, LoaderPopup } from "../../../components";

function UserTable(props) {
    const { authToken } = useSelector((state) => state.auth);

    const [sizePerPage, setSizePerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState(null);

    const [salesLoading, setSalesLoading] = useState(false);
    const [totalSales, setTotalSales] = useState(null);

    const [merchantLoading, setMerchantLoading] = useState(false);
    const [merchantSummary, setMerchantSummary] = useState(null);

    const [cashInLoading, setCashInLoading] = useState(false);
    const [cashInflow, setCashInflow] = useState(null);

    const [earningLoading, setEarningLoading] = useState(false);
    const [earningSummary, setEarningSummary] = useState(null);

    const [khataLoading, setKhataLoading] = useState(false);
    const [khataBalance, setKhataBalance] = useState(null);

    const [creditLoading, setCreditLoading] = useState(false);
    const [creditBalance, setCreditBalance] = useState(null);

    useEffect(() => {
        setLoading(true);
        getTableRecords();
    }, [props.filters]);

    useEffect(() => {
        setSalesLoading(true);
        getSalesRecords();
    }, [props.salesFilters]);

    useEffect(() => {
        setMerchantLoading(true);
        getMerchantRecords();
    }, [props.merchantFilters]);

    useEffect(() => {
        setCashInLoading(true);
        getCashInRecords();
    }, [props.cashInFilters]);

    useEffect(() => {
        setEarningLoading(true);
        getEarningRecords();
    }, [props.earningFilters]);

    useEffect(() => {
        setKhataLoading(true);
        getKhataRecords();
    }, [props.khataFilters]);

    useEffect(() => {
        setCreditLoading(true);
        getCreditRecords();
    }, [props.creditFilters]);

    const getTableRecords = () => {
        const params = getParams(props.filters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard${params}`,).then((res) => {
            setLoading(false);
            if (res.status) {
                setDashboardData(res.data);
            }
        });
    };

    const getSalesRecords = () => {
        const params = getParams(props.salesFilters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard/total_sales${params}`).then((res) => {
            setSalesLoading(false);
            if (res.status) {
                setTotalSales(res?.data);
            }
        });
    }

    const getMerchantRecords = () => {
        const params = getParams(props.merchantFilters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard/merchant_summary${params}`).then((res) => {
            setMerchantLoading(false);
            if (res.status) {
                setMerchantSummary(res?.data);
            }
        });
    }

    const getCashInRecords = () => {
        const params = getParams(props.cashInFilters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard/cashflow_statement${params}`).then((res) => {
            setCashInLoading(false);
            if (res.status) {
                setCashInflow(res?.data);
            }
        });
    }

    const getEarningRecords = () => {
        const params = getParams(props.earningFilters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard/earning_summary${params}`).then((res) => {
            setEarningLoading(false);
            if (res.status) {
                setEarningSummary(res?.data);
            }
        });
    }

    const getKhataRecords = () => {
        const params = getParams(props.khataFilters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard/khata_balance${params}`).then((res) => {
            setKhataLoading(false);
            if (res.status) {
                setKhataBalance(res?.data);
            }
        });
    }

    const getCreditRecords = () => {
        const params = getParams(props.creditFilters, currentPage, sizePerPage);
        getApiData(`business/v1/admin-dashboard/credit_balance${params}`).then((res) => {
            setCreditLoading(false);
            if (res.status) {
                setCreditBalance(res?.data);
            }
        });
    }

    const handleApplyTotalSales = (event, picker) => {
        let obj = { ...props.salesFilters };
        obj.startDate = picker.startDate.format("MM/DD/YYYY");
        obj.endDate = picker.endDate.format("MM/DD/YYYY");
        props.setSalesFilters(obj);

        picker.element.val(
            picker.startDate.format("MM/DD/YYYY") +
            " - " +
            picker.endDate.format("MM/DD/YYYY")
        );
    };

    const handleApplyMerchant = (event, picker) => {
        let obj = { ...props.merchantFilters };
        obj.startDate = picker.startDate.format("MM/DD/YYYY");
        obj.endDate = picker.endDate.format("MM/DD/YYYY");
        props.setMerchantFilters(obj);

        picker.element.val(
            picker.startDate.format("MM/DD/YYYY") +
            " - " +
            picker.endDate.format("MM/DD/YYYY")
        );
    };

    const handleApplyCashIn = (event, picker) => {
        let obj = { ...props.cashInFilters };
        obj.startDate = picker.startDate.format("MM/DD/YYYY");
        obj.endDate = picker.endDate.format("MM/DD/YYYY");
        props.setCashInFilters(obj);

        picker.element.val(
            picker.startDate.format("MM/DD/YYYY") +
            " - " +
            picker.endDate.format("MM/DD/YYYY")
        );
    };

    const handleApplyEarning = (event, picker) => {
        let obj = { ...props.earningFilters };
        obj.startDate = picker.startDate.format("MM/DD/YYYY");
        obj.endDate = picker.endDate.format("MM/DD/YYYY");
        props.setEarningFilters(obj);

        picker.element.val(
            picker.startDate.format("MM/DD/YYYY") +
            " - " +
            picker.endDate.format("MM/DD/YYYY")
        );
    };

    const handleApplyKhata = (event, picker) => {
        let obj = { ...props.khataFilters };
        obj.startDate = picker.startDate.format("MM/DD/YYYY");
        obj.endDate = picker.endDate.format("MM/DD/YYYY");
        props.setKhataFilters(obj);

        picker.element.val(
            picker.startDate.format("MM/DD/YYYY") +
            " - " +
            picker.endDate.format("MM/DD/YYYY")
        );
    };

    const handleApplyCredit = (event, picker) => {
        let obj = { ...props.creditFilters };
        obj.startDate = picker.startDate.format("MM/DD/YYYY");
        obj.endDate = picker.endDate.format("MM/DD/YYYY");
        props.setCreditFilters(obj);

        picker.element.val(
            picker.startDate.format("MM/DD/YYYY") +
            " - " +
            picker.endDate.format("MM/DD/YYYY")
        );
    };


    const handleCancel = (event, picker) => {
        picker.element.val("");
    }

    const CommonRow = ({ type, title, amount }) => (
        <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center justify-content-center">
                {type == 'plus' ?
                    <span className="mr-2 mb-2 fs-2 w-15" style={{ color: 'green' }}>
                        <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                            <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                        </svg>
                    </span>
                    : type == 'minus' ?
                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                            <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                            </svg>
                        </span>
                        :
                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                            <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                            </svg>
                        </span>
                }
                <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6">{title}</span>
            </div>
            <h3 className="amount-card-title fw-bold text-700 fs-3">
                ₹{amount}
            </h3>
        </div>
    );

    const OverviewRow = ({ title, amount, isRuppee }) => (
        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6">
            <div className="card" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <div className="card-body p-6">
                    <div className="d-flex flex-column">
                        <span className="amount-card-body fw-semibold d-block mb-1 text-600 text-left">{title}</span>
                        <h3 className="amount-card-title fw-bolder mb-2 text-right fs-3">{isRuppee ? '₹' : ''}{amount}</h3>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="row g-5 mb-5">
                <div className="col-md-8">
                    {
                        loading ? (
                            <div className="ml-4">
                                <LoaderCards />
                            </div>
                        ) : (
                            <div className="d-flex flex-column justify-content-center">
                                <div className="row g-2 mb-3">
                                    <OverviewRow title={'Total Sales'} amount={dashboardData?.total_sales ? dashboardData?.total_sales.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Customers'} amount={dashboardData?.customers ? dashboardData?.customers.toLocaleString() : 0} isRuppee={false} />
                                    <OverviewRow title={'Merchants'} amount={dashboardData?.merchants ? dashboardData?.merchants.toLocaleString() : 0} isRuppee={false} />
                                    <OverviewRow title={'T/R Riders'} amount={dashboardData?.tr_riders ? dashboardData?.tr_riders.toLocaleString() : 0} isRuppee={false} />
                                    <OverviewRow title={'Wallet Balance'} amount={dashboardData?.wallet_balance ? dashboardData?.wallet_balance.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Cash Inflow'} amount={dashboardData?.cash_inflow ? dashboardData?.cash_inflow.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Net Earning'} amount={dashboardData?.net_earning ? dashboardData?.net_earning.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Total Orders'} amount={dashboardData?.total_orders ? dashboardData?.total_orders.toLocaleString() : 0} isRuppee={false} />
                                    <OverviewRow title={'Payout'} amount={dashboardData?.merchant_payout ? dashboardData?.merchant_payout.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Rider Incentive'} amount={dashboardData?.rider_incentive ? dashboardData?.rider_incentive.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Khata Limits'} amount={dashboardData?.khata_limits ? dashboardData?.khata_limits.toLocaleString() : 0} isRuppee={true} />
                                    <OverviewRow title={'Cash Outflow'} amount={dashboardData?.cash_outflow ? dashboardData?.cash_outflow.toLocaleString() : 0} isRuppee={true} />
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body p-3 d-flex flex-column w-100">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Top Merchant</h3>
                            </div>
                            {loading ? (
                                <LoaderPopup />
                            ) : (
                                <>
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="amount-card-body fw-bold d-block mb-1 text-800 fs-6">Total Sales</span>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6">1. Tiku Cafe</span>
                                        <h3 className="amount-card-title fw-bold text-700 fs-3">
                                            ₹{dashboardData?.order_summery?.live_order ? dashboardData?.order_summery?.live_order.toLocaleString() : 0}
                                        </h3>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="fw-semibold d-block mb-1 text-800 fs-6">2. The Signature Cafe</span>
                                        <h3 className="amount-card-title fw-bold text-700 fs-3">
                                            ₹{dashboardData?.order_summery?.live_order ? dashboardData?.order_summery?.live_order.toLocaleString() : 0}
                                        </h3>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="fw-semibold d-block mb-1 text-800 fs-6">3. Brimies</span>
                                        <h3 className="amount-card-title fw-bold text-700 fs-3">
                                            ₹{dashboardData?.order_summery?.live_order ? dashboardData?.order_summery?.live_order.toLocaleString() : 0}
                                        </h3>
                                    </div>
                                    <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span className="amount-card-body fw-bold d-block mb-1 text-800 fs-6">Avgerage Order Value</span>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6">1. Tiku Cafe</span>
                                        <h3 className="amount-card-title fw-bold text-700 fs-3">
                                            {dashboardData?.order_summery?.live_order ? dashboardData?.order_summery?.live_order.toLocaleString() : 0}
                                        </h3>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6">2. The Signature Cafe</span>
                                        <h3 className="amount-card-title fw-bold text-700 fs-3">
                                            {dashboardData?.order_summery?.live_order ? dashboardData?.order_summery?.live_order.toLocaleString() : 0}
                                        </h3>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6">3. Brimies</span>
                                        <h3 className="amount-card-title fw-bold text-700 fs-3">
                                            {dashboardData?.order_summery?.live_order ? dashboardData?.order_summery?.live_order.toLocaleString() : 0}
                                        </h3>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-2">
                <div className="col-12">
                    <div className="flex-column w-100">
                        <div className="row g-5 mb-5">
                            <div className="col-md-4">
                                <div className="card" style={{ minHeight: "330px" }}>
                                    <div className="card-body p-3 d-flex flex-column w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Total Sales</h3>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex gap-4 mb-5">
                                                    <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={DateRangeSettings}
                                                                onApply={handleApplyTotalSales}
                                                                onCancel={handleCancel}
                                                                max
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control z-index-9"
                                                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                                                    placeholder="Select date range"
                                                                    id="sales-date-range"
                                                                    autoComplete="off"
                                                                />
                                                            </DateRangePicker>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                                                        <span className="path1" />
                                                                        <span className="path2" />
                                                                        <span className="path3" />
                                                                        <span className="path4" />
                                                                        <span className="path5" />
                                                                        <span className="path6" />
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="position-relative mb-0 mb-md-0 d-flex">
                                                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                                            onClick={() => {
                                                                props.setSalesFilters({
                                                                    search: "",
                                                                    filter: "",
                                                                    startDate: "",
                                                                    endDate: "",
                                                                });
                                                                window.$("#sales-date-range").val("");
                                                            }}>
                                                            <i className="ki-outline ki-arrows-circle fs-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {salesLoading ? (
                                            <LoaderPopup />
                                        ) : (
                                            <>
                                                <CommonRow type={'none'} title={'Online'} amount={totalSales?.online ? totalSales?.online.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'COD (SD)'} amount={totalSales?.cod_sd ? totalSales?.cod_sd.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'COD (T/R)'} amount={totalSales?.cod_tr ? totalSales?.cod_tr.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Khata'} amount={totalSales?.business_credit ? totalSales?.business_credit.toLocaleString() : 0} />

                                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                                            <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" {...props}>
                                                                <path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 00-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 00-11.3 0L266.3 586.7a8.03 8.03 0 000 11.3l39.5 39.7z" />
                                                            </svg>
                                                        </span>
                                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6"><b>Total Sales</b></span>
                                                    </div>
                                                    <h3 className="amount-card-title fw-bold text-dark fs-2">
                                                        ₹{totalSales?.total_sales ? totalSales?.total_sales.toLocaleString() : 0}
                                                    </h3>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ minHeight: "330px" }}>
                                    <div className="card-body p-3 d-flex flex-column w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Merchant Summary</h3>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex gap-4 mb-5">
                                                    <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={DateRangeSettings}
                                                                onApply={handleApplyMerchant}
                                                                onCancel={handleCancel}
                                                                max
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control z-index-9"
                                                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                                                    placeholder="Select date range"
                                                                    id="merchant-date-range"
                                                                    autoComplete="off"
                                                                />
                                                            </DateRangePicker>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                                                        <span className="path1" />
                                                                        <span className="path2" />
                                                                        <span className="path3" />
                                                                        <span className="path4" />
                                                                        <span className="path5" />
                                                                        <span className="path6" />
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="position-relative mb-0 mb-md-0 d-flex">
                                                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                                            onClick={() => {
                                                                props.setMerchantFilters({
                                                                    search: "",
                                                                    filter: "",
                                                                    startDate: "",
                                                                    endDate: "",
                                                                });
                                                                window.$("#merchant-date-range").val("");
                                                            }}>
                                                            <i className="ki-outline ki-arrows-circle fs-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {merchantLoading ? (
                                            <LoaderPopup />
                                        ) : (
                                            <>
                                                <CommonRow type={'none'} title={'Total Sales'} amount={merchantSummary?.total_sales ? merchantSummary?.total_sales.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Refunds Initiated'} amount={merchantSummary?.refunds_initiated ? merchantSummary?.refunds_initiated.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Self Delivery Earning'} amount={merchantSummary?.self_delivery_earning ? merchantSummary?.self_delivery_earning.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Taprocket Fees'} amount={merchantSummary?.taprocket_fees ? merchantSummary?.taprocket_fees.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Direct Cash Collection'} amount={merchantSummary?.direct_cash_collection ? merchantSummary?.direct_cash_collection.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Khata Earning'} amount={merchantSummary?.business_credit ? merchantSummary?.business_credit.toLocaleString() : 0} />

                                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                                            <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
                                                                <path d="M13 6h2v11h-2zm4-3h2v14h-2zM9 9h2v8H9zM4 19h16v2H4zm1-7h2v5H5z" />
                                                            </svg>
                                                        </span>
                                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6"><b>Next Settlement Amount</b></span>
                                                    </div>
                                                    <h3 className="amount-card-title fw-bold text-dark fs-2">
                                                        ₹{merchantSummary?.next_settlement_amount ? merchantSummary?.next_settlement_amount.toLocaleString() : 0}
                                                    </h3>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ minHeight: "330px" }}>
                                    <div className="card-body p-3 d-flex flex-column w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Cashflow Statement</h3>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex gap-4 mb-5">
                                                    <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={DateRangeSettings}
                                                                onApply={handleApplyCashIn}
                                                                onCancel={handleCancel}
                                                                max
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control z-index-9"
                                                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                                                    placeholder="Select date range"
                                                                    id="cashIn-date-range"
                                                                    autoComplete="off"
                                                                />
                                                            </DateRangePicker>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                                                        <span className="path1" />
                                                                        <span className="path2" />
                                                                        <span className="path3" />
                                                                        <span className="path4" />
                                                                        <span className="path5" />
                                                                        <span className="path6" />
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="position-relative mb-0 mb-md-0 d-flex">
                                                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                                            onClick={() => {
                                                                props.setCashInFilters({
                                                                    search: "",
                                                                    filter: "",
                                                                    startDate: "",
                                                                    endDate: "",
                                                                });
                                                                window.$("#cashIn-date-range").val("");
                                                            }}>
                                                            <i className="ki-outline ki-arrows-circle fs-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {cashInLoading ? (
                                            <LoaderPopup />
                                        ) : (
                                            <>
                                                <CommonRow type={'none'} title={'Sales Remittance'} amount={cashInflow?.sales_remittance ? cashInflow?.sales_remittance.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Khata Remittance'} amount={cashInflow?.khata_remittance ? cashInflow?.khata_remittance.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Credit Remittance'} amount={cashInflow?.credit_remittance ? cashInflow?.credit_remittance.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Rider Cash Collection'} amount={cashInflow?.rider_cash_collection ? cashInflow?.rider_cash_collection.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Merchant Payout'} amount={cashInflow?.merchant_payouts ? cashInflow?.merchant_payouts.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Rider Payout'} amount={cashInflow?.rider_payout ? cashInflow?.rider_payout.toLocaleString() : 0} />

                                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                                            <svg viewBox="0 0 1003.265 1000" fill="currentColor" height="1em" width="1em" {...props}>
                                                                <path d="M33.75 566c-28-6.667-38.667-25.333-32-56 6.667-28 24.667-38.667 54-32l98 24-52 80-68-16m890 12c9.333-8 20.333-11.667 33-11 12.667.667 23 5.667 31 15 21.333 21.333 20.667 42.667-2 64l-252 226c-8 8-18 12-30 12-9.333 0-18.667-3.333-28-10l-286-220-54-14 50-80 36 8c8 2.667 13.333 5.333 16 8l264 204 222-202m-490-220l-350 550c-8 14.667-20.667 22-38 22-8 0-16-2.667-24-8-10.667-6.667-17.333-16.333-20-29-2.667-12.667-.667-23.667 6-33l374-588c5.333-10.667 14.667-17.333 28-20 12-4 24-2 36 6l246 156 226-326c6.667-10.667 16-17 28-19s23.333 1 34 9c25.333 16 29.333 36.667 12 62l-252 362c-16 24-36.667 28-62 12l-244-156" />
                                                            </svg>
                                                        </span>
                                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6"><b>Net Cashflow</b></span>
                                                    </div>
                                                    <h3 className="amount-card-title fw-bold text-dark fs-2">
                                                        ₹{cashInflow?.net_cashflow ? cashInflow?.net_cashflow.toLocaleString() : 0}
                                                    </h3>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row g-2">
                <div className="col-12">
                    <div className="flex-column w-100">
                        <div className="row g-5 mb-5">
                            <div className="col-md-4">
                                <div className="card" style={{ minHeight: "330px" }}>
                                    <div className="card-body p-3 d-flex flex-column w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Earning Summary</h3>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex gap-4 mb-5">
                                                    <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={DateRangeSettings}
                                                                onApply={handleApplyEarning}
                                                                onCancel={handleCancel}
                                                                max
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control z-index-9"
                                                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                                                    placeholder="Select date range"
                                                                    id="earning-date-range"
                                                                    autoComplete="off"
                                                                />
                                                            </DateRangePicker>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                                                        <span className="path1" />
                                                                        <span className="path2" />
                                                                        <span className="path3" />
                                                                        <span className="path4" />
                                                                        <span className="path5" />
                                                                        <span className="path6" />
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="position-relative mb-0 mb-md-0 d-flex">
                                                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                                            onClick={() => {
                                                                props.setEarningFilters({
                                                                    search: "",
                                                                    filter: "",
                                                                    startDate: "",
                                                                    endDate: "",
                                                                });
                                                                window.$("#earning-date-range").val("");
                                                            }}>
                                                            <i className="ki-outline ki-arrows-circle fs-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {earningLoading ? (
                                            <LoaderPopup />
                                        ) : (
                                            <>
                                                <CommonRow type={'none'} title={'Gross Sales'} amount={earningSummary?.gross_sales ? earningSummary?.gross_sales.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Discount Given'} amount={earningSummary?.discount_given ? earningSummary?.discount_given.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'T/R Delivery Earning'} amount={earningSummary?.tr_delivery_earning ? earningSummary?.tr_delivery_earning.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Rider Incentive'} amount={earningSummary?.rider_incentive ? earningSummary?.rider_incentive.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Refund Issued'} amount={earningSummary?.refund_issued ? earningSummary?.refund_issued.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Merchant Payouts'} amount={earningSummary?.merchant_payouts ? earningSummary?.merchant_payouts.toLocaleString() : 0} />

                                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                                            <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
                                                                <path d="M13 6h2v11h-2zm4-3h2v14h-2zM9 9h2v8H9zM4 19h16v2H4zm1-7h2v5H5z" />
                                                            </svg>
                                                        </span>
                                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6"><b>Net Earning</b></span>
                                                    </div>
                                                    <h3 className="amount-card-title fw-bold text-dark fs-2">
                                                        ₹{earningSummary?.net_earning ? earningSummary?.net_earning.toLocaleString() : 0}
                                                    </h3>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ minHeight: "330px" }}>
                                    <div className="card-body p-3 d-flex flex-column w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Khata Balance</h3>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex gap-4 mb-5">
                                                    <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={DateRangeSettings}
                                                                onApply={handleApplyKhata}
                                                                onCancel={handleCancel}
                                                                max
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control z-index-9"
                                                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                                                    placeholder="Select date range"
                                                                    id="khata-date-range"
                                                                    autoComplete="off"
                                                                />
                                                            </DateRangePicker>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                                                        <span className="path1" />
                                                                        <span className="path2" />
                                                                        <span className="path3" />
                                                                        <span className="path4" />
                                                                        <span className="path5" />
                                                                        <span className="path6" />
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="position-relative mb-0 mb-md-0 d-flex">
                                                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                                            onClick={() => {
                                                                props.setKhataFilters({
                                                                    search: "",
                                                                    filter: "",
                                                                    startDate: "",
                                                                    endDate: "",
                                                                });
                                                                window.$("#khata-date-range").val("");
                                                            }}>
                                                            <i className="ki-outline ki-arrows-circle fs-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {khataLoading ? (
                                            <LoaderPopup />
                                        ) : (
                                            <>
                                                <CommonRow type={'none'} title={'Opening Khata Balance'} amount={khataBalance?.opening_khata_balance ? khataBalance?.opening_khata_balance.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Khata Limit Added'} amount={khataBalance?.khata_limit_added ? khataBalance?.khata_limit_added.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Khata Settlement Received'} amount={khataBalance?.khata_settlement_received ? khataBalance?.khata_settlement_received.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Refund Balance Added'} amount={khataBalance?.refund_balance_added ? khataBalance?.refund_balance_added.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Khata Limit Removed'} amount={khataBalance?.khata_limit_removed ? khataBalance?.khata_limit_removed.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Khata Limit Utilised'} amount={khataBalance?.khata_limit_utilised ? khataBalance?.khata_limit_utilised.toLocaleString() : 0} />
                                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                                            <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props} >
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path d="M11 2.05v2.012a8.001 8.001 0 105.906 14.258l1.423 1.423A9.966 9.966 0 0112 22C6.477 22 2 17.523 2 12c0-5.185 3.947-9.449 9-9.95zM21.95 13a9.948 9.948 0 01-2.207 5.328l-1.423-1.422A7.962 7.962 0 0019.938 13h2.013zM13.002 2.05a10.004 10.004 0 018.95 8.95h-2.013a8.005 8.005 0 00-6.937-6.938V2.049z" />
                                                            </svg>
                                                        </span>
                                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6"><b>Net Fundflow</b></span>
                                                    </div>
                                                    <h3 className="amount-card-title fw-bold text-dark fs-2">
                                                        ₹{khataBalance?.net_fundflow ? khataBalance?.net_fundflow.toLocaleString() : 0}
                                                    </h3>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{ minHeight: "330px" }}>
                                    <div className="card-body p-3 d-flex flex-column w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3 className="amount-card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Credit Balance</h3>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex gap-4 mb-5">
                                                    <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                                                        <div className="input-group">
                                                            <DateRangePicker
                                                                initialSettings={DateRangeSettings}
                                                                onApply={handleApplyCredit}
                                                                onCancel={handleCancel}
                                                                max
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="form-control z-index-9"
                                                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                                                    placeholder="Select date range"
                                                                    id="credit-date-range"
                                                                    autoComplete="off"
                                                                />
                                                            </DateRangePicker>
                                                            <div className="input-group-append">
                                                                <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                                                        <span className="path1" />
                                                                        <span className="path2" />
                                                                        <span className="path3" />
                                                                        <span className="path4" />
                                                                        <span className="path5" />
                                                                        <span className="path6" />
                                                                    </i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="position-relative mb-0 mb-md-0 d-flex">
                                                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                                            onClick={() => {
                                                                props.setCreditFilters({
                                                                    search: "",
                                                                    filter: "",
                                                                    startDate: "",
                                                                    endDate: "",
                                                                });
                                                                window.$("#credit-date-range").val("");
                                                            }}>
                                                            <i className="ki-outline ki-arrows-circle fs-2" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {creditLoading ? (
                                            <LoaderPopup />
                                        ) : (
                                            <>
                                                <CommonRow type={'none'} title={'Opening Credit Balance'} amount={creditBalance?.opening_credit_balance ? creditBalance?.opening_credit_balance.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Credit Added by customer'} amount={creditBalance?.credit_added_by_customer ? creditBalance?.credit_added_by_customer.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Promotional Credits Added'} amount={creditBalance?.promotional_credits_added ? creditBalance?.promotional_credits_added.toLocaleString() : 0} />
                                                <CommonRow type={'plus'} title={'Refund Credits Added'} amount={creditBalance?.refund_credits_added ? creditBalance?.refund_credits_added.toLocaleString() : 0} />
                                                <CommonRow type={'minus'} title={'Credit Utilised'} amount={creditBalance?.credit_utilised ? creditBalance?.credit_utilised.toLocaleString() : 0} />
                                                <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" height="1em" width="1em" {...props} >
                                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                                <path d="M13 12 A1 1 0 0 1 12 13 A1 1 0 0 1 11 12 A1 1 0 0 1 13 12 z" />
                                                                <path d="M16.924 11.132a5 5 0 10-4.056 5.792M3 12a9 9 0 109-9" />
                                                            </svg>
                                                        </span>
                                                        <span className="amount-card-body fw-semibold d-block mb-1 text-800 fs-6"><b>Net Fundflow</b></span>
                                                    </div>
                                                    <h3 className="amount-card-title fw-bold text-dark fs-2">
                                                        ₹{creditBalance?.net_fundflow ? creditBalance?.net_fundflow.toLocaleString() : 0}
                                                    </h3>
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default injectIntl(connect(null, auth.actions)(UserTable));