import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as Yup from "yup";
import { API_URL } from "../../../config";
import * as auth from "../_redux/authRedux";
import { gradientColors } from "../../../../gradientColor";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const initialValues = {
  phone_number: "",
};

function Login(props) {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const [phoneNumber, setPhoneNumber] = useState();
  const [isVerifyOTP, setIsVerifyOTP] = useState(false);

  useEffect(() => {
    (function (d) {
      var a = d.createElement("script");
      a.type = "text/javascript";
      a.async = true;
      a.id = "loginJs";
      a.src = "/js/login-general.js";
      d.getElementById("script-root").appendChild(a);
    })(document);
  }, []);

  const handleOnChange = (index, e) => {
    const value = e.target.value;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    if (!value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const { intl } = props;

  const [loading, setLoading] = useState(false);
  // const [isValid, setIsValid] = useState(true)
  const [status, setStatus] = useState(false);

  const LoginSchema = Yup.object().shape({
    phone_number: Yup.string()
      .min(3, "Minimum 3 numbers")
      .max(10, "Maximum 10 numbers")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const veryOPT = () => {
    enableLoading();
    const otpValue = otp.join("");
    fetch(`${API_URL}administration/v1/signin/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone_number: phoneNumber,
        otp: otpValue,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        disableLoading();
        if (res.status) {
          const userObj = {
            user_data: {
              name: res.data.is_admin ? "admin" : res.data.first_name,
              user_type: res.data.is_admin ? "admin" : "tapri",
              email: res.data.is_admin ? res.data.email : res.data.email,
              phone_number: res.data.phone_number,
              username: res.data.username,
              id: res?.data?.id,
              is_admin: res?.data?.user_type === 1 ? true : false,
            },
            auth_token: res.data.token,
          };

          localStorage.setItem("userEvent", Date.now().toString());
          props.login(userObj);
        } else {
          disableLoading();
          setStatus(res.message);
        }
      })
      .catch((err) => {
        disableLoading();
        setStatus(
          intl.formatMessage({
            id: "AUTH.VALIDATION.INVALID_LOGIN",
          })
        );
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setStatus(null);

      enableLoading();

      setPhoneNumber(values.phone_number);
      disableLoading();

      fetch(`${API_URL}administration/v1/signin/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone_number: values.phone_number,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            setIsVerifyOTP(true);
            disableLoading();
          } else {
            setSubmitting(false);
            disableLoading();
            setStatus(res.message);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          disableLoading();
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
        });
    },
  });

  return (
    <>
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <div className="w-100 p-10">
            {!isVerifyOTP ? (
              <form
                className="form w-100"
                noValidate="novalidate"
                id="kt_sign_in_form"
                data-kt-redirect-url="otp.html"
                action="#"
              >
                <div className="text-center mb-11">
                  <h1 className="text-900 fw-bolder mb-3">
                    Sign In to TapRocket
                  </h1>
                </div>
                {status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                )}

                <div className="fv-row mb-8 position-relative">
                  <i className="ki-outline ki-phone position-absolute top-50 translate-middle-y ms-4 fs-2x text-primary" />
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    autoComplete="off"
                    className="form-control bg-transparent h-60px ps-15 fw-bold fs-6"
                    maxLength={10}
                    pattern="[0-9]*"
                    name="Phone Number"
                    {...formik.getFieldProps("phone_number")}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.phone_number}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="d-grid mb-10">
                  <button
                    href
                    type="submit"
                    id="kt_sign_in_submit"
                    className="custom-button btn-primary h-60px d-flex align-items-center justify-content-center"
                    disabled={
                      formik.getFieldProps("phone_number").value !== "" &&
                      !formik.isSubmitting
                        ? false
                        : true
                    }
                    onClick={formik.handleSubmit}
                  >
                    <span className="indicator-label fw-bold">
                      Sign In{" "}
                      <i
                        className="ki-outline ki-arrow-right fs-5"
                        style={{ position: "relative", top: "2px" }}
                      />
                    </span>
                  </button>
                </div>
              </form>
            ) : (
              <>
                <form
                  className="form w-100 mb-13"
                  noValidate="novalidate"
                  data-kt-redirect-url="index.html"
                  id="kt_sing_in_two_factor_form"
                >
                  <div className="d-flex w-100 justify-content-end">
                    <div className="py-7 py-lg-15 px-5 px-md-15 ">
                      <a
                        href
                        className="d-flex align-items-center fs-2 fw-bold"
                        onClick={() => {
                          setIsVerifyOTP(false);
                          setOtp(["", "", "", ""]);
                        }}
                      >
                        <i className="ki-outline ki-arrow-left fs-3x text-primary me-1" />{" "}
                        Back
                      </a>
                    </div>
                  </div>
                  <div className="text-center mb-10">
                    <img
                      alt="Logo"
                      className="mh-125px"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Tools/smartphone-2.svg"
                      )}
                    />
                  </div>
                  <div className="text-center mb-10">
                    <h1 className="text-gray-900 mb-3">
                      Two-Factor Verification
                    </h1>
                    <div className="text-muted fw-semibold fs-5 mb-5">
                      Enter the verification code we sent to
                    </div>
                    <div className="fw-bold text-gray-900 fs-3">
                      {phoneNumber}
                    </div>
                  </div>
                  <div className="mb-10">
                    {status && (
                      <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">
                          {status}
                        </div>
                      </div>
                    )}
                    <div className="fw-bold text-center text-gray-900 fs-6 mb-2 ms-1">
                      Type your 4 digit security code
                    </div>

                    <div className="d-flex flex-wrap flex-stack justify-content-center">
                      {[0, 1, 2, 3].map((index) => (
                        <input
                          key={index}
                          type="password"
                          name={`code_${index}`}
                          maxLength={1}
                          className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                          inputMode="text"
                          ref={inputRefs[index]}
                          onChange={(e) => handleOnChange(index, e)} // Pass the index of the input field
                        />
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-center">
                    <button
                      type="button"
                      id="kt_sing_in_two_factor_submit"
                      className="custom-button btn-lg btn-primary fw-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        veryOPT();
                      }}
                    >
                      <span className="indicator-label">Verify OTP</span>
                    </button>
                  </div>
                </form>
                <div className="text-center fw-semibold fs-5">
                  <span className="text-muted me-1">Didn’t get the code ?</span>
                  <a href="#" className="link-primary fs-5 me-1">
                    Resend
                  </a>
                  <span className="text-muted me-1">or</span>
                  <a href="#" className="link-primary fs-5">
                    Call Us
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
