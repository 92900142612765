import React, { useState, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import toast from "react-hot-toast";

export default function ({ show, onHide }) {
  const [sending, setSending] = useState(false);

  const qrCodeRef = useRef(null);
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const downloadQRCode = () => {
    htmlToImage.toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = dataUrl;
        link.click();
        toast.success('QR Code downloaded successfully');
      });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup align-items-center">
      <Modal.Header closeButton>
        <Modal.Title>QR Code</Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className=''>
          <div className='card card-custom card-stretch gutter-b mb-5' style={{ borderTop: '2px solid #eb3a22' }}>
            <div className='card-body p-4 py-5 pb-7'>
              <div className="">
                <div className=' text-center'  ref={qrCodeRef}>
                  {user?.id && (
                    <QRCode
                      title="GeeksForGeeks"
                      value={user?.id}
                      bgColor={'#FFF'}
                      fgColor={'fore'}
                      size={100}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="custom-button btn-sm btn-primary font-weight-bold px-10"
          onClick={downloadQRCode}
        >
          Download
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button type="button" className="custom-custom-btn-light px-10" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
