import React, { useState, useRef } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { GeneralDropdown } from "../../components";
import { DateRangeSettings, orderStatusList, orderTypeList, paymentMethods } from "../../config";

export default function (props) {
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isSearching2, setIsSearching2] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);
  const [isShowAdvance, setIsShowAdvance] = useState(false);

  const handleApply = (event, picker) => {
    props.setDate(
      picker.startDate.format("MM/DD/YYYY"),
      picker.endDate.format("MM/DD/YYYY")
    );
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const onClickAdvance = () => {
    setIsShowAdvance(!isShowAdvance);
  }

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="min-w-125px ">
          <div className="input-group">
            <DateRangePicker
              initialSettings={DateRangeSettings}
              onApply={handleApply}
              onCancel={handleCancel}
              max
              inModal
            >
              <input
                type="text"
                className="form-control"
                placeholder="Select date range"
                id="date-range"
                autoComplete="off"
              />
            </DateRangePicker>
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
              </span>
            </div>
          </div>
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-75 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by customer name or product name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setSearch2("");
              setClearFilter(Math.random());
              window.$("#date-range").val("");
            }}>
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>

        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn btn-primary btn-icon w-35px h-35px" onClick={onClickAdvance}>
            <i className="ki-outline ki-text-align-right fs-2" />
          </button>
        </div>

        {/* <div style={{}}>
          <button
            style={{ width: "90px" }}
            className="btn-primary custom-button float-right"
            // onClick={() => { history.push("/not"); }}
          >
            Export
          </button>
        </div> */}

        {isShowAdvance &&
          <div className="sidebar-container">
            <div className="sidebar" style={{}}>
              <div className="card w-100 rounded-0">
                <div className="card-header pe-5">
                  <div className="card-title">
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1">Advanced Filter</a>
                    </div>
                  </div>
                  <div className="card-toolbar">
                    <button onClick={onClickAdvance} className="btn btn-sm btn-icon btn-active-light-primary">
                      <i className="ki-outline ki-cross fs-1" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Business Partner</label>
                    <GeneralDropdown
                      className="form-select border-0 fs-7"
                      list={props.businessList}
                      handleChange={(obj) => {
                        props.setValue("business", obj.value);
                      }}
                      selectedOption={props?.value?.business ? props?.value?.business : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Payment Method</label>
                    <GeneralDropdown
                      className="form-select border-0 fs-7" data-placeholder="Select Payment Method"
                      list={paymentMethods}
                      handleChange={(obj) => {
                        props.setValue("payment_method", obj.value);
                      }}
                      selectedOption={props.value.payment_method ? props.value.payment_method : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Order Status</label>
                    <GeneralDropdown
                      className="form-select border-0 fs-7" data-placeholder="Select Status"
                      list={orderStatusList}
                      handleChange={(obj) => {
                        props.setValue("order_status", obj.value);
                      }}
                      selectedOption={props.value.order_status ? props.value.order_status : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Building</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.buildingList}
                      handleChange={(obj) => {
                        props.onBuildingSelect(obj.value);
                      }}
                      selectedOption={props.value.building ? props.value.building : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Wing</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.wingList}
                      handleChange={(obj) => {
                        props.onWingSelect(obj.value);
                      }}
                      selectedOption={props.value.wing ? props.value.wing : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Floor</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.floorList}
                      handleChange={(obj) => {
                        props.onFloorSelect(obj.value);
                      }}
                      selectedOption={props.value.floor ? props.value.floor : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Office</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.officeList}
                      handleChange={(obj) => {
                        props.onOfficeSelect(obj.value, "office");
                      }}
                      selectedOption={props.value.office ? props.value.office : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Products</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.productList}
                      handleChange={(obj) => {
                        props.setValue("product", obj.value);
                      }}
                      selectedOption={props.value.product ? props.value.product : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Delivery Boy</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.chhotuList}
                      handleChange={(obj) => {
                        props.setValue("delivery_boy_id", obj.value);
                      }}
                      selectedOption={props.value.delivery_boy_id ? props.value.delivery_boy_id : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Order Type</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={orderTypeList}
                      handleChange={(obj) => {
                        props.setValue("order_type", obj.value);
                      }}
                      selectedOption={props.value.order_type ? props.value.order_type : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Time</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.orderTimeSlots}
                      handleChange={(obj) => {
                        props.setValue("minutes", obj.value);
                      }}
                      selectedOption={props.value.minutes ? props.value.minutes : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  {/* <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mobile number"
                      value={search2}
                      onChange={(e) => {
                        setSearch2(e.target.value);
                        if (e.target.value.length > 2) {
                          setIsSearching2(true);
                          props.setValue("mobile_no", e.target.value);
                        } else {
                          if (isSearching2) {
                            props.setValue("mobile_no", "");
                            setIsSearching2(false);
                          }
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setIsSearching2(true);
                          props.setValue("mobile_no", search2);
                        }
                      }}
                    />
                  </div> */}
                </div>
                {/* <div className="card-footer py-4 justify-content-end d-flex">
                  <button className="btn btn-primary fw-bold fs-7">Apply Filter</button>
                </div> */}
              </div>

            </div>
          </div>
        }
      </div>
    </>
  );
}
