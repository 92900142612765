import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Loader } from "../../../components";
import { getParams, PER_PAGE, sizePerPageList } from "../../../config";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../Services";
import { GeneralDropdown } from "../../../components";
import { PaymentSettlement, PayoutDetails, CSVModal } from "../modals";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

import {
  Pagination,
  CardBody,
  Card,
} from "../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showPaymentPop, setShowPaymentPop] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showPayoutDetail, setShowPayoutDetail] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const statusList = [
    { label: "Pending", value: "pending" },
    { label: "Success", value: "success" },
  ];

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`order/v1/business-payouts/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };

  const changeStatus = (row) => {
    if (!row.payment_status) {
      toast.dismiss();
      setShowPaymentPop(true);
      setSelectedRow(row);
    }
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "first_name",
      text: "Business Name",
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (_, row) => (
        <span className="fw-bold">₹{row?.amount ? row?.amount : "-"}</span>
      ),
    },
    {
      dataField: "status",
      text: "Payment Status",
      formatter: (_, row) => (
        <>
          <span class="switch switch-sm">
            <label>
              <input
                type="checkbox"
                onChange={(e) => {
                  toast.loading("Please wait...");
                  changeStatus(row, e.target.checked);
                }}
                checked={
                  row?.payout_details?.status ? true : row?.payment_status
                }
                disabled={row.payment_status || row?.payout_details?.status}
              />
              <span></span>
            </label>
          </span>
        </>
      ),
    },
    {
      dataField: "date",
      text: "Create At",
      formatter: (_, row) => (
        <span className="">{row?.date ? row?.date : "-"}</span>
      ),
    },
    {
      dataField: "action",
      text: "Details",
      formatter: (_, row) => (
        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
          onClick={() => {
            setShowPayoutDetail(true);
            setSelectedRow(row);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-secondary">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              {...props}
            >
              <path
                fill="currentColor"
                d="M2 8a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM2 12a1 1 0 011-1h18a1 1 0 110 2H3a1 1 0 01-1-1zM3 15a1 1 0 100 2h12a1 1 0 100-2H3z"
              />
            </svg>
          </span>
        </button>
      ),
    },
  ];

  return (
    <>
      <PaymentSettlement
        show={showPaymentPop}
        onHide={() => setShowPaymentPop(false)}
        data={selectedRow}
        onSuccess={(message) => {
          toast.success(message);
          setShowPaymentPop(false);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <PayoutDetails
        show={showPayoutDetail}
        onHide={() => setShowPayoutDetail(false)}
        data={selectedRow}
        onSuccess={(message) => {}}
        onError={(message) => {}}
      />

      <CSVModal
        show={showCSVModal}
        onHide={() => setShowCSVModal(false)}
        data={selectedRow}
      />

      {loading ? (
        <Loader />
      ) : (
        <div>
          <Card>
            <CardBody>
              <div>
                {data.length !== 0 ? (
                  <>
                    <div className="d-flex align-items-center justify-content-end mb-3">
                      <button
                        className="btn custom-button py-2"
                        onClick={() => setShowCSVModal(true)}
                      >
                        Export CSV
                      </button>
                    </div>

                    <PaginationProvider
                      pagination={paginationFactory(paginationOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <Pagination
                            isLoading={false}
                            paginationProps={paginationProps}
                          >
                            <BootstrapTable
                              remote
                              onTableChange={() => {}}
                              wrapperClasses="table-responsive"
                              bordered={false}
                              classes="table table-head-custom table-vertical-center overflow-hidden"
                              id="userTable"
                              bootstrap4
                              keyField="id"
                              data={data === null ? [] : data}
                              columns={columns}
                              noDataIndication={() => {
                                return "No Data to Display";
                              }}
                              defaultSorted={[
                                { dataField: "id", order: "asc" },
                              ]}
                              {...paginationTableProps}
                            ></BootstrapTable>
                          </Pagination>
                        );
                      }}
                    </PaginationProvider>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={toAbsoluteUrl("/media/logos/no-data.png")}
                      height={"150px"}
                      width={"150px"}
                    />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
