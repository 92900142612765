import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getStateList } from "../../../config";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    state: "",
    city: "",
  });

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "state") {
      obj.city = null;
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        <TableFilter
          stateList={stateList}
          cityList={cityList}
          value={filters}
          onStateSelect={(selectedState) => {
            if (selectedState.city && selectedState.city.length) {
              updateFilter(selectedState.value, "state");
              let city = [];
              selectedState.city.map((item) => {
                city = [...city, { label: item, value: item }];
              });
              setCityList(city);
            }
          }}
          onCitySelect={async (value) => {
            updateFilter(value, "city");
          }}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}
          onClearFilter={() => {
            setFilters({
              search: "",
              state: "",
              city: "",
            });
          }}
        />
        <Table filters={filters} />
      </div>
    </>
  );
}
