import React, { useState, useEffect } from "react";
import Filter from "./Table/Filter";
// import Items from "./Items";
import { getStateList, getAreaByCity, getTapriByArea } from "../../config";
import { getApiData } from "../../Services";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import Table from "./Table/index";

export default function () {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [tapriList, setTapriList] = useState([]);

  const [buildingList, setBuildingList] = useState([]);
  const [wingList, setWingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [officeList, setOfficeList] = useState([]);

  const [businessList, setBusinessList] = useState([]);

  const [totalDues, setTotalDues] = useState(0);

  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
    city: "",
    area: "",
    tapri: "",
    business: "",
    building: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  useEffect(() => {
    getBusinessList();
    getBuildingList();
  }, []);

  const getBusinessList = () => {
    getApiData(`business/v1`).then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setBusinessList(array);
      }
    })
  }

  const getBuildingList = () => {
    getApiData("building/v1/").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [
            ...array,
            { label: item.name, value: item.id },
          ];
        });
        setBuildingList(array);
      }
    });
  };

  const getWingByBuilding = async (building) => {
    const body = {
      building_id: building,
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let wing = [];
        Object.keys(res.data).map((item) => {
          wing = [...wing, { label: item, value: item }];
        });
        return wing;
      }
    });
  }

  const getFloorByWing = async (wing) => {
    const body = {
      building_id: filters.building,
      wing: wing
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let floor = [];
        Object.keys(res.data).map((item) => {
          floor = [...floor, { label: item, value: item }];
        });
        return floor;
      }
    });
  }

  const getOfficeByFloor = async (floor) => {
    const body = {
      building_id: filters.building,
      wing: filters.wing,
      floor: floor,
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let office = [];
        res.data.map((item) => {
          office = [...office, { label: item.toString(), value: item.toString() }];
        });
        return office;
      }
    });
  }

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "partner") {
      obj.partner = "";
    } else if (key === "state") {
      obj.city = null;
      obj.area_name = "";
      obj.tapri = "";
      setAreaList([]);
      setTapriList([]);
    } else if (key === "city") {
      obj.area_name = "";
      obj.tapri = "";
      setTapriList([]);
    } else if (key === "area_name") {
      obj.tapri = "";
      setTapriList([]);
    } else if (key === "building") {
      obj.wing = "";
      obj.floor = "";
      obj.office = "";
      setWingList([]);
      setFloorList([]);
      setOfficeList([]);
    } else if (key === "wing") {
      obj.floor = "";
      obj.office = "";
      setFloorList([]);
      setOfficeList([]);
    } else if (key === "floor") {
      obj.office = "";
      setOfficeList([]);
    }
    setFilters(obj);
  };

  return (
    <div>
      <Filter
        stateList={stateList}
        cityList={cityList}
        areaList={areaList}
        tapriList={tapriList}

        buildingList={buildingList}
        wingList={wingList}
        floorList={floorList}
        officeList={officeList}
        businessList={businessList}

        value={filters}

        onStateSelect={(selectedState) => {
          if (selectedState.city && selectedState.city.length) {
            updateFilter(selectedState.value, "state");
            let city = [];
            selectedState.city.map(
              (item) => (city = [...city, { label: item, value: item }])
            );
            setCityList(city);
          }
        }}
        onCitySelect={async (value) => {
          updateFilter(value, "city");
          const area = await getAreaByCity(value);
          setAreaList(area);
        }}
        onAreaSelect={async (value) => {
          updateFilter(value, "area");
          const tapri = await getTapriByArea(value);
          setTapriList(tapri);
        }}

        onBuildingSelect={async (value) => {
          updateFilter(value, "building");
          const wing = await getWingByBuilding(value);
          setWingList(wing);
        }}

        onWingSelect={async (value) => {
          updateFilter(value, "wing");
          const floor = await getFloorByWing(value);
          setFloorList(floor);
        }}

        onFloorSelect={async (value) => {
          updateFilter(value, "floor");
          const office = await getOfficeByFloor(value);
          setOfficeList(office);
        }}

        onOfficeSelect={async (value) => {
          updateFilter(value, "office");
        }}

        setSearchString={(value) => {
          let obj = { ...filters };
          obj.search = value;
          setFilters(obj);
        }}

        setFilters={(value) => {
          let obj = { ...filters };
          obj.search = value;
          setFilters(obj);
        }}

        setValue={(key, value) => {
          let obj = { ...filters };
          obj[key] = value;
          setFilters(obj);
        }}

        setStatusFilter={updateFilter}
        setTapriFilter={updateFilter}

        onClearFilter={() => {
          setFilters({
            search: "",
            status: "",
            state: "",
            city: "",
            area: "",
            tapri: "",
            business: "",
            filter: "",
          });
        }}

        setDate={(startDate, endDate) => {
          let obj = { ...filters };
          obj.startDate = startDate;
          obj.endDate = endDate;
          setFilters(obj);
        }}
      />

      <div className="mt-7">
        <Table
          filters={filters}
          setTotalDues={(dues) => {
            setTotalDues(dues);
          }}
        />
      </div>
    </div>
  );
}
