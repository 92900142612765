import React, { useState } from "react";
import { GeneralDropdown } from "../../../components";
import { Link, useHistory } from "react-router-dom";

export default function (props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isShowAdvance, setIsShowAdvance] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  const statusList = [
    { value: '1', label: 'Enabled' },
    { value: '0', label: 'Disabled' },
  ];

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="position-relative me-0 mb-0 mb-md-0 w-100 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by building name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button
            className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setClearFilter(Math.random());
            }}>
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn btn-dark btn-icon w-35px h-35px bg-primary" onClick={()=> setIsShowAdvance(true)}>
            <i className="ki-outline ki-text-align-right fs-2" />
          </button>
        </div>

        <Link
          to="/master/building/create"
          style={{ width: "160px", textAlign: "center" }}
        >
          <div className="position-relative mb-0 mb-md-0">
            <button
              className="btn btn-primary w-100px h-35px fs-7 fw-bold px-0 d-flex align-items-center justify-content-center">
              Add Building
            </button>
          </div>
        </Link>

        {isShowAdvance &&
          <div className="sidebar-container">
            <div className="sidebar" style={{}}>
              <div className="card w-100 rounded-0">
                <div className="card-header pe-5">
                  <div className="card-title">
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1">Advanced Filter</a>
                    </div>
                  </div>
                  <div className="card-toolbar">
                    <button onClick={()=> setIsShowAdvance(false)} className="btn btn-sm btn-icon btn-active-light-primary">
                      <i className="ki-outline ki-cross fs-1" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group mb-4">
                      <label htmlFor className=" fs-6 fw-bold mb-2">Select State</label>
                      <GeneralDropdown
                        className="form-select fs-7"
                        clearFilter={clearFilter}
                        selectedOption={props.value.state ? props.value.state : null}
                        list={props.stateList}
                        handleChange={(obj) => {
                          props.onStateSelect(obj);
                        }}
                        inModal
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor className=" fs-6 fw-bold mb-2">Select City </label>
                      <GeneralDropdown
                        className="form-select fs-7"
                        clearFilter={clearFilter}
                        selectedOption={props.value.city ? props.value.city : null}
                        list={props.cityList}
                        handleChange={(obj) => {
                          props.onCitySelect(obj.value);
                        }}
                        inModal
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor className=" fs-6 fw-bold mb-2">Select Area</label>
                      <GeneralDropdown
                        className="form-select fs-7"
                        clearFilter={clearFilter}
                        list={props.areaList}
                        selectedOption={props.value.area ? props.value.area : null}
                        handleChange={(obj) => {
                          props.onAreaSelect(obj.value);
                        }}
                        inModal
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor className=" fs-6 fw-bold mb-2 ">Select Business Partner</label>
                      <GeneralDropdown
                        className="form-select fs-7"
                        clearFilter={clearFilter}
                        list={props.tapriList}
                        selectedOption={props.value.tapri ? props.value.tapri : null}
                        handleChange={(obj) => {
                          props.setTapriFilter(obj.value, "tapri");
                        }}
                        inModal
                      />
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor className=" fs-6 fw-bold mb-2 ">Select Status</label>
                      <GeneralDropdown
                        className="form-select border-0 fs-7" data-placeholder="Select Status"
                        list={statusList}
                        handleChange={(obj) => {
                          props.setValue("building_status", obj.value);
                        }}
                        selectedOption={props.value.building_status ? props.value.building_status : null}
                        clearFilter={clearFilter}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
