import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";
import { API_URL } from "../../../config";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [addProof, setAddProof] = useState(false);
  const [proofText, setProofText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [fileType, setFileType] = useState("");
  const [proofTypeText, setProofTypeText] = useState(true);
  const fileInputRef = useRef(null);

  useEffect(() => {
    console.log("data >>>>", data);
  }, [data, show]);

  const clearInput = () => {
    setAddProof(false);
    setProofText("");
    setSelectedFile(null);
    setPreview(null);
    setFileType("");
  };

  const onClickSave = (event) => {
    event.preventDefault();

    if (addProof && !selectedFile && !proofText) {
      toast.error("Please attach any supporting proof");
    } else {
      setSending(true);
      const dateKey = data.date;
      const parts = dateKey.split("-");
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      const businessId = data.id;

      const body = new FormData();
      body.append("settlement_date", formattedDate);
      body.append("business", businessId);

      body.append("orders", JSON.stringify(data.orders));

      if (addProof && proofText) {
        body.append("business_settlement_proof", proofText);
      }
      if (addProof && selectedFile) {
        body.append("business_settlement_proof", selectedFile);
        body.append("is_file", selectedFile.type == "application/pdf" ? 1 : 0);
      }

      let dataToken = JSON.parse(localStorage.getItem("persist:userInfo"));
      let token = `${JSON.parse(dataToken.authToken)}`;

      fetch(`${API_URL}order/v1/business-payouts/`, {
        body: body,
        method: "POST",
        headers: { Authorization: `Token ${token}` },
      })
        .then((response) => {
          setSending(false);
          if (response.status) {
            response.json().then((data) => {
              if (data.status) {
                onSuccess(data.message);
                onHide();
                clearInput();
              } else {
                onError(data.message);
              }
            });
          }
        })
        .catch((error) => {
          console.error("Something went wrong!", error);
        });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileType(file.type);

      // Create a preview for images
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setPreview(null);
      }
    }
  };

  const handleClick = () => {
    setProofTypeText(false);
    setProofText("");
    fileInputRef.current.click();
  };

  return (
    <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Payment Settlement</h3>
        </Modal.Title>
        <button
          type="button"
          className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={() => {
            onHide();
            clearInput();
          }}
        >
          <i className="ki-outline ki-cross fs-1" />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <div className="rounded fs-7 mb-1">
                Could you kindly confirm the payment settlement amount of
                <b> ₹{data.amount}?</b>
              </div>
            </div>
            <div className="col-md-12 d-flex align-items-center">
              <div
                className="form-check mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={(e) => {
                    setAddProof(e.target.checked);
                  }}
                />
              </div>
              <label htmlFor className=" fs-6 fw-bold">
                You may attach any supporting proof in the form of text, image,
                or PDF:
              </label>
            </div>
            {addProof && (
              <>
                {proofTypeText && (
                  <div className="col-md-12 mt-2">
                    <label className="fs-6 mb-2">Proof</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Please enter proof"
                      value={proofText}
                      onChange={(e) => {
                        setProofText(e.target.value);
                      }}
                    />
                  </div>
                )}

                <input
                  type="file"
                  accept=".jpg,.jpeg,.png,.pdf"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <a
                  className="text-center"
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                >
                  (Attach image or file)
                </a>
                {fileType.startsWith("image/") && preview && (
                  <div>
                    <img
                      src={preview}
                      alt="Image preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                )}
                {fileType === "application/pdf" && selectedFile && (
                  <div>
                    <p>
                      Selected PDF: <b>{selectedFile.name}</b>
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn custom-btn-light fs-7"
            onClick={() => {
              onHide();
              clearInput();
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onClickSave}
            disabled={!sending ? false : true}
          >
            Save{" "}
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
