import React, { useState, useEffect } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import Table from "./Table";
import TableFilter from "./Table/Filter";
import { getApiData } from "../../../Services";

export default function () {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    state: "",
    status: "1",
    on_duty: "1",
  });
  const [isFiltersSet, setIsFiltersSet] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    setFilters({ ...filters, status: '1', on_duty: '1' });
    setIsFiltersSet(true);
  }, []);

  return (
    <div>
      <TableFilter
        filters={filters}
        setValue={(key, value) => {
          let obj = { ...filters };
          obj[key] = value;
          setFilters(obj);
        }}
        setSearchString={(value) => {
          let obj = { ...filters };
          obj.search = value;
          setFilters(obj);
        }}
        onClearFilter={() => {
          setFilters({
            search: "",
            state: "",
            status: "1",
            on_duty: "1",
          });
        }}
        togglePopup={togglePopup}
      />
      {isFiltersSet && (
        <Table
          filters={filters}
          setFilters={(value) => setFilters(value)}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
    </div>
  );
}
