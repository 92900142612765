import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { DeliveryBoys, Loader } from "../../../../components";
import { getParams, PER_PAGE } from "../../../../config";
import { getApiData } from "../../../../Services";
import {
  EditProduct,
  ProductModal,
  VariantsPopup,
  DevVariantsPopup,
  GSTModal,
} from "../Modal";
import Pagination from "react-js-pagination";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";

export default function Table(props) {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editProductModal, setEditProductModal] = useState(false);
  const [variantModal, setVariantModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [deliveryModal, setDeliveryModal] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const handleCheckBoxChange = async (id, key) => {
    const body = {
      product_id: id,
      action: key == "pin" ? 1 : key == "tap" ? 2 : 3,
    };

    return await getApiData(`products/v1/actions/`, "post", body).then(
      (res) => {
        if (res.status) {
          toast.success(res.message);
          getTableRecords();
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const changeIsDefault = async (id) => {
    // setLoading(true);
    return await getApiData(`products/v1/${id}/`, "delete").then((res) => {
      if (res.status) {
        toast.success(res.message);
        getTableRecords();
      } else {
        toast.error(res.message);
      }
    });
  };

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);
    getApiData(`products/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  return (
    <>
      <ProductModal
        show={props.isPopupOpen}
        onHide={() => {
          props.setIsPopupOpen(false);
        }}
        onSuccess={(message) => {
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <GSTModal
        show={props.showGst}
        onHide={() => {
          props.hideGst(false);
        }}
        onSuccess={(message) => {
          props.hideGst(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <EditProduct
        show={editProductModal}
        onHide={() => setEditProductModal(!editProductModal)}
        data={selectedRow}
        selectedRow={selectedRow}
        onSuccess={(message) => {
          setEditProductModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {variantModal && (
        <VariantsPopup
          show={variantModal}
          onHide={() => {
            setSelectedRow({});
            setVariantModal(false);
          }}
          data={selectedRow}
          onSuccess={(message) => {
            setVariantModal(false);
            toast.success(message);
            getTableRecords();
          }}
          onError={(message) => {
            toast.error(message);
          }}
        />
      )}
      <DeliveryBoys
        show={deliveryModal}
        // data={selectedRow}
        deliveryBoy={selectedRow?.product_holder_id}
        keyName="product_holder"
        api={`products/v1/${selectedRow?.id}/`}
        onHide={() => setDeliveryModal(!deliveryModal)}
        onSuccess={(message) => {
          setDeliveryModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {/* {
        variantModal &&
        <DevVariantsPopup
          show={variantModal}
          onHide={() => {
            setSelectedRow({});
            setVariantModal(false);
          }}
          data={selectedRow}
          onSuccess={(message) => {
            setVariantModal(false);
            toast.success(message);
            getTableRecords();
          }}
          onError={(message) => {
            toast.error(message);
          }}
        />
      } */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {data.length !== 0 ? (
            <>
              <div className="row g-5 mb-5">
                {data.map((row) => (
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-5 position-relative">
                        <div className="d-flex flex-stack align-items-center">
                          <div className=" d-flex align-items-center  w-100 flex-column  text-start">
                            <div
                              className="ms-0  rounded p-3 w-100 mb-0 d-flex align-items-center justify-content-between"
                              style={{ backgroundColor: "rgba(249, 249, 249)" }}
                            >
                              <div className="d-flex">
                                <div className="symbol symbol-105px">
                                  <img
                                    src={
                                      row.image
                                        ? row.image
                                        : "https://t4.ftcdn.net/jpg/05/35/51/85/360_F_535518598_fviKNslzN3NTzWTkAn5WpoH9FmiVciAu.jpg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="ms-3">
                                  <div className="fs-5 mb-1">
                                    <b>
                                      {row.name}{" "}
                                      {row?.product_holder &&
                                        `(${row.product_holder})`}
                                    </b>
                                  </div>
                                  <div className="fs-7 mb-1">
                                    Description:{" "}
                                    <a href>
                                      <b>
                                        {row.description
                                          ? row.description
                                          : "-"}
                                      </b>
                                    </a>
                                  </div>
                                  <div className="fs-7 mb-1">
                                    Category:{" "}
                                    <a href>
                                      <b>{row.category_name}</b>
                                    </a>
                                  </div>
                                  {/* <div className="fs-7">
                                    GST:{" "}
                                    <span>
                                      <b>{row.gst_rate}%</b>
                                    </span>
                                  </div> */}
                                  <div className="fs-5">
                                    Price:{" "}
                                    <span>
                                      <b>₹{row.price}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {row.variants ? (
                                  <button
                                    className="bg-transparent d-flex align-items-center justify-content-center"
                                    style={{ borderWidth: 0 }}
                                    onClick={() => {
                                      setVariantModal(true);
                                      setSelectedRow(row);
                                    }}
                                  >
                                    <span className="svg-icon text-blue">
                                      <svg
                                        viewBox="0 0 1024 1024"
                                        fill="currentColor"
                                        height="1em"
                                        width="1em"
                                      >
                                        <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" />
                                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                      </svg>
                                    </span>
                                    <span className="ml-1 text-blue">
                                      {row.variants.length > 0
                                        ? row.variants.length
                                        : "Add"}{" "}
                                      Variant
                                    </span>
                                  </button>
                                ) : (
                                  <div>
                                    <button
                                      className="bg-transparent d-flex align-items-center justify-content-center"
                                      style={{ borderWidth: 0 }}
                                      onClick={() => {
                                        setVariantModal(true);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      <span className="svg-icon text-blue">
                                        <svg
                                          viewBox="0 0 1024 1024"
                                          fill="currentColor"
                                          height="1em"
                                          width="1em"
                                        >
                                          <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" />
                                          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                        </svg>
                                      </span>
                                      <span className="ml-1 text-blue">
                                        Add Variant
                                      </span>
                                    </button>
                                    {user?.is_admin && (
                                      <button
                                        className="bg-transparent d-flex align-items-center justify-content-center  mt-3"
                                        style={{ borderWidth: 0 }}
                                        onClick={() => {
                                          setDeliveryModal(true);
                                          setSelectedRow(row);
                                        }}
                                      >
                                        <span className="svg-icon text-blue">
                                          <svg
                                            viewBox="0 0 1024 1024"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                          >
                                            <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" />
                                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                          </svg>
                                        </span>
                                        <span className="ml-1 text-blue">
                                          Select Delivery Partner
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed mt-3" />
                        <div className="d-flex flex-stack gap-3 mt-3 px-5">
                          <div className>
                            <div
                              className="form-check"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e) => {
                                  handleCheckBoxChange(
                                    row.id,
                                    "pin",
                                    e.target.checked
                                  );
                                }}
                                checked={row.pin_on_top}
                              />
                              <label
                                className="form-check-label text-800 fw-bolder mt-1"
                                htmlFor="flexCheckDefault"
                              >
                                Recommended
                              </label>
                            </div>
                          </div>
                          <div className="ml-3">
                            <div
                              className="form-check"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e) => {
                                  handleCheckBoxChange(
                                    row.id,
                                    "tap",
                                    e.target.checked
                                  );
                                }}
                                checked={row.on_tap_available}
                              />
                              <label
                                className="form-check-label text-800 fw-bolder mt-1"
                                htmlFor="flexCheckDefault"
                              >
                                On Tap
                              </label>
                            </div>
                          </div>
                          <div className="ml-3">
                            <div
                              className="form-check"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={(e) => {
                                  handleCheckBoxChange(
                                    row.id,
                                    "b2b",
                                    e.target.checked
                                  );
                                }}
                                checked={row.b2b_product}
                              />
                              <label
                                className="form-check-label text-800 fw-bolder mt-1"
                                htmlFor="flexCheckDefault"
                              >
                                B2B Product
                              </label>
                            </div>
                          </div>
                          <div
                            className
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="d-flex  align-items-center justify-content-end">
                              <div className="form-check form-switch form-check-custom form-check-solid me-5 mt-0">
                                <label
                                  className="form-check-label me-3 text-800 fw-bolder"
                                  htmlFor="flexSwitch20x30"
                                >
                                  Status
                                </label>
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    changeIsDefault(row.id, e.target.checked);
                                  }}
                                  checked={row.is_active}
                                />
                              </div>
                              <a
                                className="d-flex"
                                href
                                onClick={() => {
                                  setEditProductModal(true);
                                  setSelectedRow(row);
                                }}
                              >
                                <i className="ki-outline ki-pencil text-primary fs-2x ml-7" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/logos/no-data.png")}
                height={"150px"}
                width={"150px"}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
