import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Loader } from "../../../../components";
import { getParams, PER_PAGE } from "../../../../config";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import Pagination from "react-js-pagination";
import toast from "react-hot-toast";
import { PayoutHistory } from '../modals';

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [showHistoryPop, setShowHistoryPop] = useState(false);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`order/v1/business-payouts${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        props.setTotalDues(res.total_dues);
        setData(apiData);
      }
    });
  };

  return (
    <>
      <PayoutHistory
        show={showHistoryPop}
        onHide={() => setShowHistoryPop(!showHistoryPop)}
        data={selectedRow}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {
            data.length !== 0 ?
              <>
                <div className="row g-5">
                  <div className="col-md-12">
                    {data.map((row) => (
                      <div className="card mb-5">
                        <div className="card-body p-5 position-relative">
                          <div className="ms-0 bg-gray-light rounded p-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div className="fs-5">Business Name: <b>{row.first_name}</b></div>
                                <div className="rounded text-start mb-3 fs-7">Phone: <a href className="fs-7 fw-bolder ">{row.phone_number}</a></div>
                                <div className="rounded text-start mb-0 fs-7 ">
                                  {row.account_number && <>Account No: <span className="fs-7 fw-bolder"
                                    onClick={() => {
                                      toast.success("Text Copied");
                                      navigator.clipboard.writeText(row.account_number);
                                    }}>
                                    {row.account_number}</span>, </>
                                  }
                                  {row.ifsc_code && <> IFSC Code: <span className="fs-7 fw-bolder "
                                    onClick={() => {
                                      toast.success("Text Copied");
                                      navigator.clipboard.writeText(row.ifsc_code);
                                    }}>
                                    {row.ifsc_code}</span>, <br /></>
                                  }
                                  {row.upi_holder_name && <>UPI Name: <span className="fs-7 fw-bolder "
                                    onClick={() => {
                                      navigator.clipboard.writeText(row.upi_holder_name);
                                      toast.success("Text Copied");
                                    }}>
                                    {row.upi_holder_name}</span>,</>
                                  }
                                  {row.upi && <> UPI id: <a href className="fs-7 fw-bolder "
                                    onClick={() => {
                                      toast.success("Text Copied");
                                      navigator.clipboard.writeText(row.upi);
                                    }}>
                                    {row.upi}</a></>
                                  }
                                </div>
                              </div>
                              {/* <div className="ml-2">
                                <a
                                  href
                                  onClick={() => {
                                    setShowHistoryPop(true);
                                    setSelectedRow(row);
                                  }}
                                  className="fw-bolder d-flex align-items-center fs-7 justify-content-end">
                                  View History
                                  <i className="ki-outline ki-arrow-right text-primary fs-4" />
                                </a>
                              </div> */}
                            </div>
                          </div>
                          <div className="d-flex flex-stack align-items-center mt-2">
                            <div className=" d-flex align-items-center ">
                              <div className="rounded text-start mb-1">Amount: <span className="fs-6 fw-bolder text-primary">₹ {row.amount}</span>
                              </div>
                            </div>
                            <div className>
                              <div className="rounded text-start mb-1">Status: <span className={`badge ${row.payment_status ? 'badge-primary' : 'badge-secondary' }`}>{row.payment_status ? 'Success' : 'Pending'}</span></div>
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-2" />
                          <div className=" row mt-3 align-items-center">
                            <div className="col-9">
                              <div className="fs-8 ">Created At: <b>{row.date}</b></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    }
                  </div>
                </div>
                <div className="mt-2 float-right mr-3">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={20}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={5}
                    itemClass="d-flex justify-content-between align-items-center flex-wrap"
                    linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                    activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                    onChange={(pageNumber) => {
                      setCurrentPage(pageNumber);
                    }}
                  />
                </div>
              </>

              :
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
              </div>
          }
        </>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));