import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);

  useEffect(()=>{
    console.log("data >>>>", data);
  },[data]);

  const onClickSave = async () => {
    setSending(true);
    return await getApiData(`business/v1/profile/${data.id}`, "get").then((res) => {
      toast.dismiss();
      setSending(false);
      if (res.status) {
        onSuccess(res.message);
        onHide();
      } else {
        onError(res.message);
      }
    });
  }

  return (
    <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Manage Business Partner Status</h3>
        </Modal.Title>
        <button type="button" className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onHide}>
          <i className="ki-outline ki-cross fs-1" />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <div className="rounded fs-7 mb-1">Your status is currently disabled. Please activate your status before making this switch.</div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn custom-btn-light fs-7" onClick={onHide}>Close</button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onClickSave}
            disabled={!sending ? false : true}
          >
            Save{" "}
            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
          </button>
        </div>

      </Modal.Body>
    </Modal>
  );
}
