import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data, stateList }) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (show) {
      if (data && data.id) {
        setName(data.name);
        setZipCode(data.zip_code);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setSelectedState(data.state);
        setSelectedCity(data.city);
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  const getCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          toast.error("Location permission issue");
        }
      );
    }
  };

  const clearInput = () => {
    setName("");
    setZipCode("");
    setLatitude("");
    setLongitude("");
    setSelectedState(null);
    setSelectedCity(null);
  }

  useEffect(() => {
    if (stateList.length && data.state) {
      stateList.map((item) => {
        if (item.value === data.state) {
          // console.log(item);
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length && data.city) {
      cityList.map((item) => {
        if (item.value === data.city) {
          console.log("City ");
          console.log(item);
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  const onSubmit = () => {
    if (zipCode.length < 6) {
      toast.error("Please enter valid zip code");
    } else {
      setSending(true);
      const body = {
        name: name,
        state: selectedState.value,
        city: selectedCity.value,
        zip_code: zipCode,
        latitude: latitude == '' ? 0.0 : latitude,
        longitude: longitude == '' ? 0.0 : longitude,
      };

      let method = data.id ? "put" : "post";
      let param = data.id ? data.id + "/" : "";
      getApiData(`administration/v1/areas/${param}`, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message, res.data);
        } else {
          onError(res.message);
        }
      });
    }
  };

  function onPressClose() {
    onHide();
  }

  return (
    <Modal size="lg" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Manage Area</h3>
        </Modal.Title>
        <div className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onHide}>
          <i className="ki-outline ki-cross fs-1" />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-6">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Area Name <span style={{ color: 'red' }}>*</span> </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Zip Code <span style={{ color: 'red' }}>*</span></label>
                <input
                  type="number"
                  className="form-control"
                  value={zipCode}
                  onChange={(e) => {
                    if (e.target.value.length <= 6)
                      setZipCode(e.target.value);
                  }}
                  maxLength={6}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Latitude</label>
                <a
                  className="ml-2"
                  onClick={() => {
                    getCurrentLocation();
                  }}
                >
                  <i
                    className="fa fa-crosshairs fs-7 mr-2"
                    style={{ color: "black" }}
                  />
                  Get Current
                </a>
                <input
                  type="number"
                  className="form-control"
                  value={latitude}
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Longitude</label>
                <input
                  type="number"
                  className="form-control"
                  value={longitude}
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Select State <span style={{ color: 'red' }}>*</span></label>
                <GeneralDropdown
                  selectedOption={data.state ? data.state : null}
                  list={stateList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedState(obj);
                    setSelectedCity(null);
                    if (obj && obj.city && obj.city.length) {
                      let city = [];
                      obj.city.map((item) => {
                        city = [...city, { label: item, value: item }];
                      });
                      setCityList(city);
                    }
                    //   props.onStateSelect(obj);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>Select City <span style={{ color: 'red' }}>*</span></label>
                <GeneralDropdown
                  selectedOption={data.city ? data.city : null}
                  list={cityList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedCity(obj);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={
              name && selectedCity && selectedState && !sending ? false : true
            }
          >
            Save
            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
          </button>
          <button type="button" className="btn custom-btn-light fs-7" onClick={onPressClose}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}