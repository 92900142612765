import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../Services";
import { gradientColors } from "../../gradientColor";
import toast from "react-hot-toast";

export default function ({
  id,
  show,
  onHide,
  onSuccess,
  onFailed,
  api,
  isReason,
  title = "Delete Record",
  message = "Are you sure to permanently delete this Record?",
}) {
  const [sending, setSending] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {

  }, [id]);

  const deleteRecord = () => {
    setSending(true);
    if (id) {
      getApiData(`order/v1/history/${id}/?cancellation_reason=${reason}`, 'delete').then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message);
          onHide();
          setReason("");
        } else {
          onFailed(res.message);
        }
      })
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      dialogClassName="bottom-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{message}</span>

        <div className="row mt-5">
          <div className="col-md-12">
            <label className="fs-8 fw-semibold">Reason (Optional)</label>
            <input
              className="form-control"
              value={reason}
              onChange={(e) => {
                setReason(e?.target?.value);
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="custom-custom-btn-light px-10"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRecord}
            disabled={sending}
            // className="btn btn-danger btn-elevate"
            className="custom-button btn-primary font-weight-bold"
          >
            Delete
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
