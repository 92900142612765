import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Loader } from "../../../../components";
import { getParams, PER_PAGE } from "../../../../config";
import { getApiData } from "../../../../Services";
import { EditProductCategory, ProductCategoryModal } from "../Modal";
import Pagination from "react-js-pagination";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [productModal, setProductModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const changeIsDefault = async (id) => {
    return await getApiData(`products/v1/category/${id}/`, "delete").then((res) => {
      if (res.status) {
        toast.success(res.message);
        getTableRecords();
      } else {
        toast.error(res.message);
      }
    });
  };

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`products/v1/category/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };
 
  return (
    <>
      <ProductCategoryModal
        show={props.isPopupOpen}
        onHide={() => {
          props.setIsPopupOpen(false);
        }}
        onSuccess={(message) => {
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <EditProductCategory
        show={editModal}
        onHide={() => setEditModal(!editModal)}
        data={selectedRow}
        selectedRow={selectedRow}
        onSuccess={(message) => {
          setEditModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {data.length !== 0 ?
            <>
              <div className="row g-5">
                <div className="col-md-12">
                  {data.map((row) => (
                    <div className="card mb-5">
                      <div className="card-body p-5 position-relative">
                        <div className="d-flex flex-stack align-items-center">
                          <div className="d-flex align-items-center  w-100 flex-column  text-start">
                            <div className="ms-0 rounded p-3 w-100 mb-0 d-flex align-items-center" style={{ backgroundColor: 'rgba(249, 249, 249)' }}>
                              <div className="symbol symbol-45px">
                                <img src={row.image ? row.image : 'https://www.shutterstock.com/image-vector/food-logo-smile-label-company-260nw-1271590297.jpg'} alt="" />
                              </div>
                              <div className="ms-3">
                                <div className="fs-7">Category Name:</div>
                                <div className="fs-5 mb-1 text-primary"><b>{row.name}</b></div>
                              </div>
                            </div>
                          </div>
                          <div className="min-w-125px ms-4 pe-5">
                            <div className="d-flex  align-items-center justify-content-end">
                              <div className="form-check form-switch form-check-custom form-check-solid me-5 mt-0">
                                <label className="form-check-label me-3 text-800 fw-bolder " htmlFor="flexSwitch20x30" />
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    changeIsDefault(row.id, e.target.checked)
                                  }}
                                  checked={row.is_active}
                                />
                              </div>
                              <a
                                className="d-flex ml-10"
                                href
                                onClick={() => {
                                  setEditModal(true);
                                  setSelectedRow(row);
                                }}>
                                <i className="ki-outline ki-pencil text-primary fs-2x" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
            </div>
          }
        </>
      )}
    </>
  );
}
