import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../components";
import { getApiData } from "../../../Services";
import { gradientColors } from "../../../../gradientColor";
import toast from "react-hot-toast";

export default function ({ show, onHide, data, onSuccess, onError }) {
  const [selectedId, setSelectedId] = useState("");
  const [selectedDBoy, setSelectedDBoy] = useState(null);
  const [list, setList] = useState([]);
  const [sending, setSending] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getList();
  }, [data, selectedFilter]);

  const getList = () => {
    setLoading(true);
    getApiData(`delivery/v1/?my_delivery_boy=${selectedFilter}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let list = [];
        res.data.map((item) => {
          list = [...list, { label: item.first_name, value: item.id }];
        });
        setList(list);
      }
    });
  };

  useEffect(() => {
    if (list.length && data.delivery_boy) {
      list.map((item) => {
        if (item.label === data.delivery_boy) {
          console.log(item);
          setSelectedDBoy(item);
          return;
        }
      });
    }
  }, [list, data]);

  const onSubmit = () => {
    if (selectedDBoy) {
      const body = {
        delivery_boy: selectedDBoy.value,
      };
      setSending(true);
      getApiData(`order/v1/actions/${data.order_id}/`, "put", body).then(
        (res) => {
          setSending(false);
          if (res.status) {
            setSelectedDBoy(null);
            onHide();
            onSuccess(res.message);
          } else {
            onError(res.message);
          }
        }
      );
      setSending(false);
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Assign Delivery Boy
        </Modal.Title>
        {loading && <div className="spinner spinner-primary"></div>}

        <div className="ml-auto">
          <select
            className="form-control"
            value={selectedFilter}
            onChange={(e) => {
              setSelectedFilter(e.target.value);
            }}
          >
            <option selected disabled>
              My Delivery boys
            </option>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div style={{}}>
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <div className="row">
                <label htmlFor className=" fs-6 fw-bold mb-2">
                  Select Delivery Boy
                </label>
              </div>
              <div className="input-group mb-0">
                <span className="input-group-text" id="basic-addon1">
                  <i className="ki-outline ki-courier fs-2x" />
                </span>
                <div style={{ width: "85%" }}>
                  <GeneralDropdown
                    selectedOption={selectedDBoy}
                    inModal
                    list={list}
                    handleChange={(obj) => {
                      setSelectedDBoy(obj);
                    }}
                    isDisabled={true}
                    searchByQuery={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn custom-btn-light fs-7"
              onClick={onHide}
            >
              Close
            </button>
            <button
              type="button"
              className="custom-button btn-primary fs-7"
              onClick={onSubmit}
              disabled={selectedDBoy && !sending ? false : true}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
