import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { API_URL } from "../../../../config";
import imageCompression from "browser-image-compression";

export default function ({ show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const compressedFile = await compressImage(selectedFile);
      const finalFile = new File([compressedFile], selectedFile.name, { type: compressedFile.type });

      setFile(finalFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(finalFile);
    }
  };

  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true
    };

    try {
      const compressedImage = await imageCompression(image, options);
      return compressedImage;
    } catch (error) {
      console.error("Image compression failed:", error);
      return image; // Return original image if compression fails
    }
  };


  const clearInput = () => {
    setName("");
    setFile(null);
    setImagePreview("");
  };

  const onSubmit = () => {
    if (name) {
      setSending(true);

      const body = new FormData();
      body.append('name', name);
      body.append('image', file ? file : 'https://d3kgder6bz7utn.cloudfront.net/system-files/category.png');

      let data = JSON.parse(localStorage.getItem("persist:userInfo"));
      let token = `${JSON.parse(data.authToken)}`;

      fetch(`${API_URL}products/v1/category/`, { body: body, method: "POST", headers: { Authorization: `Token ${token}` } }).then(response => {
        setSending(false);
        if (response.status) {
          response.json().then(data => {
            if (data.status) {
              clearInput();
              onHide();
              onSuccess(data.message);
            } else {
              onError(data.message);
            }
          })
        }
      })
        .catch(error => {
          console.error('Something went wrong!', error);
        });
    };
  };

  const onPressClose = () => {
    clearInput();
    onHide();
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title>Add New Product category</Modal.Title>
        <button className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" type="button" onClick={onHide}>
          <i className="ki-outline ki-cross fs-1"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">Product Name </label>
              <input
                type="text"
                className="form-control fs-7"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">Product category Image</label>
              <div className="col-md-12 d-flex justify-content-center">
                {imagePreview ? (
                  <div className="">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: "url(assets/media/users/blank.png)",
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          backgroundImage: `url("${(imagePreview)}")`,
                        }}
                      />
                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted" />
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          // onChange={(e) => handleFileRead(e)}
                          onChange={handleFileChange}
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>

                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        onClick={() => {
                          setFile(null);
                          setImagePreview("");
                        }}
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>

                    </div>
                    <span className="form-text text-muted">
                      Allowed file types: png, jpg, jpeg.
                    </span>
                  </div>
                ) :
                  <input className="form-control" type="file" onChange={handleFileChange} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn custom-btn-light fs-7" onClick={onPressClose}>Close</button>
          <button type="button" className="btn btn-primary fs-7" onClick={onSubmit} disabled={!sending ? false : true}>
            Save
            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
