import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";
import { getStateList, getAreaByCity, getCategoryList, API_URL } from "../../../../config";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

export default function ({ show, onHide, data, onSuccess, onError }) {
  const [sending, setSending] = useState(false);

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [contactName, setContactName] = useState("");
  const [contactPhoneNo, setContactPhoneNo] = useState("");
  const [loginPhoneNo, setLoginPhoneNo] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [rootCategory, setRootCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [selectedImages, setSelectedImages] = useState([]);


  useEffect(() => {
    if (show) {
      if (data && data.id) {
        setBusinessName(data.first_name);
        setEmail(data.email);
        setContactName(data.business_owner);
        setContactPhoneNo(data.owner_phone_number);
        setLoginPhoneNo(data.phone_number);
        setLatitude(data.latitude ? data.latitude : '');
        setLongitude(data.longitude ? data.longitude : '');
        setSelectedState(data.state);
        setSelectedCity(data.city);
        setSelectedArea(data.area);
        setImagePreview(data.profile_image);
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  const clearInput = () => {
    setImagePreview("");
    setBusinessName("");
    setEmail("");
    setContactName("");
    setContactPhoneNo("");
    setLoginPhoneNo("");
    setLatitude("");
    setLongitude("");
    setSelectedState(null);
    setSelectedCity(null);
    setSelectedArea(null);
  }

  const getCurrentLocation = () => {
    const location = window.navigator && window.navigator.geolocation;

    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          toast.error("Location permission issue");
        }
      );
    }
  };

  useEffect(() => {
    (async () => {
      const category = await getCategoryList();
      setCategoryList(category);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  useEffect(() => {
    if (stateList.length && data.state) {
      stateList.map((item) => {
        if (item.value === data.state) {
          // console.log(item);
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length && data.city) {
      cityList.map((item) => {
        if (item.value === data.city) {
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  useEffect(() => {
    if (selectedCity) {
      const getAreaList = async () => {
        const area = await getAreaByCity(selectedCity.value);
        setAreaList(area);
      }
      getAreaList();
    }
  }, [selectedCity]);

  useEffect(() => {
    if (areaList.length && data.area) {
      areaList.map((item) => {
        if (item.label == data.area) {
          console.log(">>>>", item.label, data.area);
          setSelectedArea(item);
          return
        }
      })
    }
  }, [areaList, data]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const compressedFile = await compressImage(selectedFile);
      const finalFile = new File([compressedFile], selectedFile.name, { type: compressedFile.type });

      setFile(finalFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(finalFile);
    }
  };

  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true
    };

    try {
      const compressedImage = await imageCompression(image, options);
      return compressedImage;
    } catch (error) {
      console.error("Image compression failed:", error);
      return image; // Return original image if compression fails
    }
  };

  const onSubmit = () => {
    if (!businessName) {
      toast.error("Please enter business name");
    } else if (!email) {
      toast.error("Please enter email");
    } else if (!isValidEmail) {
      toast.error("Please enter valid email");
    } else if (!contactName) {
      toast.error("Please enter contact person name");
    } else if (!contactPhoneNo) {
      toast.error("Please enter contact phone number");
    } else if (contactPhoneNo.length < 10) {
      toast.error("Please enter valid contact number");
    } else if (!loginPhoneNo) {
      toast.error("Please enter login phone number");
    } else if (loginPhoneNo.length < 10) {
      toast.error("Please enter valid login number");
    } else if (!latitude) {
      toast.error("Please enter latitude");
    } else if (!longitude) {
      toast.error("Please enter longitude");
    } else if (!selectedArea) {
      toast.error("Please select area");
    } else {
      setSending(true);

      const body = new FormData();
      body.append('first_name', businessName);
      body.append('profile_image', file ? file : imagePreview);
      body.append('email', email);
      body.append('business_owner', contactName);
      body.append('phone_number', loginPhoneNo);
      body.append('owner_phone_number', contactPhoneNo);
      body.append('latitude', latitude);
      body.append('longitude', longitude);
      body.append('area', typeof selectedArea === 'object' ? selectedArea.value : selectedArea);
      body.append('business_id', data.id ? data.id : "");
      body.append('monthly_order', 1);

      let tokenData = JSON.parse(localStorage.getItem("persist:userInfo"));
      let token = `${JSON.parse(tokenData.authToken)}`;
      let apiEndUrl = data.id ? 'business/v1/profile/' : 'business/v1/';

      fetch(`${API_URL}${apiEndUrl}`, { body: body, method: "POST", headers: { Authorization: `Token ${token}` } }).then(response => {
        setSending(false);
        if (response.status) {
          response.json().then(data => {
            if (data.status) {
              onHide();
              onSuccess(data.message, data.data);
            } else {
              onError(data.message);
            }
          })
        }
      })
        .catch(error => {
          console.error('Something went wrong!', error);
        });
    };
  }

  const onPressClose = () => {
    onHide();
    // clearInput();
  }

  return (
    <Modal size="lg" show={show} onHide={onHide} dialogClassName="bottom-popup" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {data.id ? "Edit" : "Add"} Business Partner
        </Modal.Title>
        <div className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => onPressClose()}>
          <i className="ki-outline ki-cross fs-1" />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 scroll-popup">
        <div className="row">
          <div className="col-md-12 mt-3">
            <label>Profile Image</label>
            <div className="mb-0">
              <div className="d-flex justify-content-center">
                {imagePreview ? (
                  <div className="">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: "url(assets/media/users/blank.png)",
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          backgroundImage: `url("${(imagePreview)}")`,
                        }}
                      />
                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted" />
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          // onChange={(e) => handleFileRead(e)}
                          onChange={handleFileChange}
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>

                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        onClick={() => {
                          setFile(null);
                          setImagePreview("");
                        }}
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>

                    </div>
                    <span className="form-text text-muted">
                      Allowed file types: png, jpg, jpeg.
                    </span>
                  </div>
                ) :
                  <input className="form-control" type="file" onChange={handleFileChange} />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Business Name <span style={{ color: 'red' }}>*</span> </label>
              <input
                type="text"
                className="form-control"
                // placeholder="Please enter business name"
                value={businessName}
                onChange={(e) => {
                  setBusinessName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Email <span style={{ color: 'red' }}>*</span> </label>
              <input
                type="email"
                className="form-control"
                // placeholder="Please enter email"
                value={email}
                onChange={(e) => {
                  const { value } = e.target;
                  setEmail(value);
                  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  setIsValidEmail(emailPattern.test(value));
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Contact Person Name <span style={{ color: 'red' }}>*</span> </label>
              <input
                type="text"
                className="form-control"
                // placeholder="Please enter contact person name"
                value={contactName}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Contact Person Number <span style={{ color: 'red' }}>*</span> </label>
              <input
                type="number"
                className="form-control"
                // placeholder="Please enter contact person number"
                value={contactPhoneNo}
                onChange={(e) => {
                  if (e.target.value.length <= 10)
                    setContactPhoneNo(e.target.value);
                }}
                maxLength={10}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Login Phone Number <span style={{ color: 'red' }}>*</span> </label>
              <input
                type="number"
                className="form-control"
                // placeholder="Please enter login phone number"
                value={loginPhoneNo}
                onChange={(e) => {
                  if (e.target.value.length <= 10)
                    setLoginPhoneNo(e.target.value);
                }}
                maxLength={10}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Latitude <span style={{ color: 'red' }}>*</span></label>
              <a
                className="ml-2"
                onClick={() => {
                  getCurrentLocation();
                }}
              >
                <i
                  className="fa fa-crosshairs fs-7 mr-2"
                  style={{ color: "black" }}
                />
                Get Current
              </a>
              <input
                type="text"
                className="form-control"
                // placeholder="Please enter latitude"
                value={latitude}
                onChange={(e) => {
                  setLatitude(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Longitude <span style={{ color: 'red' }}>*</span></label>
              <input
                type="number"
                className="form-control"
                // placeholder="Please enter longitude"
                value={longitude}
                onChange={(e) => {
                  setLongitude(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Select State <span style={{ color: 'red' }}>*</span></label>
              <GeneralDropdown
                selectedOption={data.state ? data.state : null}
                list={stateList}
                inModal
                handleChange={(selectedState) => {
                  setSelectedCity(null);
                  setSelectedArea(null);
                  if (selectedState.city && selectedState.city.length) {
                    let city = [];
                    selectedState.city.map(
                      (item) => (city = [...city, { label: item, value: item }])
                    );
                    setCityList(city);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Select City <span style={{ color: 'red' }}>*</span></label>
              <GeneralDropdown
                selectedOption={selectedCity}
                list={cityList}
                inModal
                handleChange={async (value) => {
                  setSelectedCity(value);
                  const area = await getAreaByCity(value.value);
                  setAreaList(area);
                  setSelectedArea(null);
                }}
              />
            </div>
          </div>
          <div className="col-md-4 mb-7">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Select Area <span style={{ color: 'red' }}>*</span></label>
              <GeneralDropdown
                selectedOption={selectedArea}
                list={areaList}
                inModal
                handleChange={(obj) => {
                  setSelectedArea(obj.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Select Root Category <span style={{ color: 'red' }}>*</span></label>
              <GeneralDropdown
                // selectedOption={data.city ? data.city : null}
                list={categoryList}
                inModal
                handleChange={(selectedCategory) => {
                  if (selectedCategory.subCategory && selectedCategory.subCategory.length) {
                    let subCategory = [];
                    selectedCategory.subCategory.map(
                      (item) => (subCategory = [...subCategory, { label: item, value: item }])
                    );
                    setSubCategoryList(subCategory);
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group is-invalid mb-0 mt-5">
              <label>Select Sub Category <span style={{ color: 'red' }}>*</span></label>
              <GeneralDropdown
                // selectedOption={selectedArea}
                list={subCategoryList}
                inModal
                handleChange={(obj) => {
                  setSubCategory(obj.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-7">
          <div className="col-md-12">
            <label>Select Images</label>
            <div className="mb-0">
              <input className="form-control" type="file" multiple onChange={handleImageChange} />
              <div className="mt-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {selectedImages.map((image, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    alt={`Image ${index}`}
                    style={{ width: '100px', height: '100px', margin: '5px', borderRadius: 5 }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div> */}
        <div className="modal-footer">
          <button type="button" className="btn custom-btn-light fs-7" onClick={() => onPressClose()}>Close</button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save
            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
