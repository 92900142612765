import React, { useState } from "react";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";

export default function (props) {
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isSearching2, setIsSearching2] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="w-105px">
          <GeneralDropdown
            className="form-select border-0 fs-7"
            list={props.onTapTypes}
            handleChange={(obj) => {
              if (obj) {
                props.setOnTapType(obj.value);
              }
            }}
            clearFilter={clearFilter}
          />
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-75 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by customer name or product name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
            }}
          >
            <i className="ki-outline ki-arrows-circle fs-2" /></button>
        </div>
      </div>
    </>
  );
}
