import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { Loader } from "../../../../components";
import {
  getParams,
  getPrice,
  PER_PAGE,
  sizePerPageList,
} from "../../../../config";
import { ManageModal } from "./../Modal";
import { getApiData } from "../../../../Services";
import { getStateList } from "../../../../config";
import Pagination from "react-js-pagination";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [areaModal, setAreaModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`manager/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  return (
    <>
      <ManageModal
        show={areaModal || props.isPopupOpen}
        onHide={() => {
          setAreaModal(false);
          props.setIsPopupOpen(false);
          setSelectedRow({});
        }}
        data={selectedRow}
        stateList={stateList}
        cityList={props.cityList}
        onSuccess={(message) => {
          setAreaModal(false);
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onFailed={(message) => {
          toast.error(message);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ? (
            <>
              <div className="row g-5">
                {data.map((row) => (
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-5 position-relative">
                        <div className="ms-0 bg-gray-light rounded p-3 mb-2">
                          <div className="fs-6">
                            Manage: <b>{row.first_name}</b>
                            <div className="fs-8">
                              Phone:{" "}
                              <a href={`tel:${row.phone_number}`}>
                                <b>{row.phone_number}</b>
                              </a>
                            </div>
                          </div>
                          <div className="fs-7">
                            Basic Salary:{" "}
                            <span className="fw-bold">
                              {getPrice(row.basic_salary)}
                            </span>
                          </div>
                        </div>
                        <div className="separator separator-dashed mt-4" />
                        <div className="d-flex flex-stack align-items-center mt-3">
                          <div>
                            <div className="fs-7">Areas:</div>
                            {row?.area?.map((item, i) => {
                              return (
                                <p className="fw-bold mb-0" key={i}>
                                  • {item.name}
                                </p>
                              );
                            })}
                          </div>
                          <div>
                            <div className="fs-7">Buildings:</div>
                            {row?.buildings?.map((item, i) => {
                              return (
                                <p className="fw-bold mb-0" key={i}>
                                  • {item.name}
                                </p>
                              );
                            })}
                          </div>
                          <div>
                            <div className="fs-7">Businesses:</div>
                            {row?.business?.map((item, i) => {
                              return (
                                <p className="fw-bold mb-0" key={i}>
                                  • {item.first_name}
                                </p>
                              );
                            })}
                          </div>
                          <div>
                            <div className="fs-7">Delivery boys:</div>
                            {row?.delivery_boy?.map((item, i) => {
                              return (
                                <p className="fw-bold mb-0" key={i}>
                                  • {item.first_name}
                                </p>
                              );
                            })}
                          </div>

                          <div className="d-flex  align-items-center justify-content-end">
                            <a
                              className="d-flex ml-8"
                              href
                              onClick={() => {
                                setAreaModal(true);
                                setSelectedRow(row);
                              }}
                            >
                              <i className="ki-outline ki-pencil text-primary fs-2x" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/logos/no-data.png")}
                height={"150px"}
                width={"150px"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
