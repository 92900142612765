import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { API_URL, SelectStyle, convertBase64, gsts } from "../../../../config";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";
import Select from "react-select";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

export default function ({ show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");

  const [isB2BProduct, setIsB2BProduct] = useState(false);
  const [quantityUnit, setQuantityUnit] = useState(null);
  const [quantity, setQuantity] = useState("");

  const [showAdvanceOption, setShowAdvanceOption] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedGST, setSelectedGST] = useState("");
  const [selectedExclusiveGst, setSelectedExclusiveGst] = useState("0");

  const hours = [
    { value: "1", label: "01" },
    { value: "2", label: "02" },
    { value: "3", label: "03" },
    { value: "4", label: "04" },
    { value: "5", label: "05" },
  ];

  const exclusiveGst = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const quantityUnitArray = [
    { value: "1", label: "Grams" },
    { value: "2", label: "Kilo grams" },
    { value: "3", label: "Liters" },
    { value: "4", label: "Cups" },
    { value: "5", label: "Tea spoons" },
    { value: "6", label: "Table spoons" },
    { value: "7", label: "Pieces" },
    { value: "8", label: "Units" },
    { value: "9", label: "Dozens" },
    { value: "10", label: "Packs" },
    { value: "11", label: "Bunches" },
    { value: "12", label: "Cans" },
    { value: "13", label: "Bottles" },
    { value: "14", label: "Boxes" },
  ];

  useEffect(() => {
    getApiData(`products/v1/category/`).then((res) => {
      let list = res.data.map((item) => ({ label: item.name, value: item.id }));
      setCategoryList(list);
    });
  }, []);

  useEffect(() => {
    getApiData("business/v1/profile/").then((res) => {
      const formattedSlots = Object.entries(res.data.slots).map(
        ([value, label]) => ({
          value,
          label,
        })
      );
      setTimeSlots(formattedSlots);
    });
  }, []);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const compressedFile = await compressImage(selectedFile);
      const finalFile = new File([compressedFile], selectedFile.name, {
        type: compressedFile.type,
      });

      setFile(finalFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(finalFile);
    }
  };

  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedImage = await imageCompression(image, options);
      return compressedImage;
    } catch (error) {
      console.error("Image compression failed:", error);
      return image; // Return original image if compression fails
    }
  };

  const clearInput = () => {
    setName("");
    setFile(null);
    setImagePreview("");
    setSelectedValue("");
    setPrice("");
    setDescription("");
  };

  const submitData = () => {
    setSending(true);

    const valueOfSlots = selectedSlots.map((str) => parseInt(str.value, 10));
    const slots_ = JSON.stringify(valueOfSlots);

    const body = new FormData();
    body.append("name", name);
    body.append(
      "image",
      file
        ? file
        : "https://d3kgder6bz7utn.cloudfront.net/system-files/product.jpg"
    );
    body.append("category", selectedValue);
    body.append("price", price);
    body.append("description", description);
    body.append("book_before", selectedHour);
    body.append("slots", slots_);
    body.append("gst_rate", selectedGST);
    body.append(
      "is_exclusive_gst",
      selectedExclusiveGst === "1" ? true : false
    );

    if (isB2BProduct) {
      body.append("b2b_product", isB2BProduct);
    }
    if (quantity) {
      body.append("quantity", quantity);
    }
    if (quantityUnit) {
      body.append("quantity_unit", quantityUnit);
    }

    let dataToken = JSON.parse(localStorage.getItem("persist:userInfo"));
    let token = `${JSON.parse(dataToken.authToken)}`;

    fetch(`${API_URL}products/v1/`, {
      body: body,
      method: "POST",
      headers: { Authorization: `Token ${token}` },
    })
      .then((response) => {
        setSending(false);
        if (response.status) {
          response.json().then((data) => {
            if (data.status) {
              clearInput();
              onHide();
              onSuccess(data.message);
            } else {
              onError(data.message);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const onSubmit = () => {
    if (!name) {
      toast.error("Please enter name");
    } else if (!selectedValue) {
      toast.error("Please select category");
    } else if (!price) {
      toast.error("Please enter price");
    } else if (!selectedGST) {
      toast.error("Please select GST");
    } else if (!description) {
      toast.error("Please enter description");
    } else if (isB2BProduct) {
      if (!quantityUnit) {
        toast.error("Please select the quantity unit");
      } else if (!quantity) {
        toast.error("please add the quantity");
      } else {
        submitData();
      }
    } else if (showAdvanceOption) {
      if (selectedSlots.length > 0 && !selectedHour) {
        toast.error("Please select book before hour");
      } else if (selectedHour && selectedSlots.length == 0) {
        toast.error("Please select time slots");
      } else {
        submitData();
      }
    } else {
      submitData();
    }
  };

  const onPressClose = () => {
    clearInput();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Body className="scroll-popup">
        <div className="d-flex align-items-center justify-content-between">
          <Modal.Title>Add New Product</Modal.Title>
          <div className="d-flex ml-auto">
            <span className="mr-3 mt-1" style={{ fontWeight: "600" }}>
              Advance Options
            </span>
            <span class="switch switch-sm mr-3">
              <label>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setShowAdvanceOption(!showAdvanceOption);
                  }}
                  checked={showAdvanceOption}
                />
                <span></span>
              </label>
            </span>
            <div
              className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
              onClick={onHide}
            >
              <i className="ki-outline ki-cross fs-1" />
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Product Name{" "}
              </label>
              <input
                type="text"
                className="form-control fs-7"
                placeholder="Product Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Select category{" "}
              </label>
              <GeneralDropdown
                className="form-select fs-7"
                selectedOption={selectedValue ? selectedValue : null}
                list={categoryList}
                handleChange={(obj) => {
                  setSelectedValue(obj.value);
                }}
                inModal
              />
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Price
                </label>
                <input
                  className="form-control fs-7"
                  type="number"
                  placeholder="Enter Price"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  GST
                </label>
                <GeneralDropdown
                  className="form-control"
                  selectedOption={selectedGST}
                  list={gsts}
                  handleChange={(obj) => {
                    setSelectedGST(obj.value);
                  }}
                  inModal
                />
              </div>
              <div className="col-md-4">
                <label htmlFor className=" fs-6 fw-bold mb-2 required">
                  Exclusive GST
                </label>
                <GeneralDropdown
                  className="form-control"
                  selectedOption={selectedExclusiveGst}
                  list={exclusiveGst}
                  handleChange={(obj) => {
                    setSelectedExclusiveGst(obj.value);
                  }}
                  inModal
                />
              </div>
            </div>
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Description{" "}
              </label>
              <textarea
                className="fs-7 form-control"
                name
                cols={30}
                rows={3}
                defaultValue={""}
                type="text"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>

            <div className="col-md-12">
              <div className="d-flex align-items-center ml-3">
                <div
                  className="form-check mb-3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      setIsB2BProduct(e.target.checked);
                    }}
                    defaultChecked={isB2BProduct}
                  />
                </div>
                <label htmlFor className=" fs-6 fw-bold mt-1">
                  B2B Product
                </label>
              </div>
            </div>

            <div className="col-md-6">
              <label
                htmlFor
                className={`fs-6 fw-bold mb-2 ${isB2BProduct && "required"}`}
              >
                Quantity Unit
              </label>
              <GeneralDropdown
                className="form-control"
                selectedOption={quantityUnit}
                list={quantityUnitArray}
                handleChange={(obj) => {
                  setQuantityUnit(obj.value);
                }}
                inModal
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor
                className={`fs-6 fw-bold mb-2 ${isB2BProduct && "required"}`}
              >
                Quantity
              </label>
              <input
                className="form-control"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </div>

            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Product Image
              </label>
              <div className="col-md-12 d-flex justify-content-center">
                {imagePreview ? (
                  <div className="">
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: "url(assets/media/users/blank.png)",
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          backgroundImage: `url("${imagePreview}")`,
                        }}
                      />
                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted" />
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          // onChange={(e) => handleFileRead(e)}
                          onChange={handleFileChange}
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>

                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        onClick={() => {
                          setFile(null);
                          setImagePreview("");
                        }}
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                    </div>
                    <span className="form-text text-muted">
                      Allowed file types: png, jpg, jpeg.
                    </span>
                  </div>
                ) : (
                  <input
                    className="form-control"
                    type="file"
                    onChange={handleFileChange}
                  />
                )}
              </div>
            </div>
            {showAdvanceOption && (
              <div className="row mt-5">
                <div className="col-md-6">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Time Slots
                  </label>
                  <GeneralDropdown
                    className="form-control"
                    // selectedOption={paymentMethod == 1 ? paymentModes[0] : paymentModes[1]}
                    list={timeSlots}
                    handleChange={(array) => {
                      setSelectedSlots(array);
                    }}
                    isMulti
                    inModal
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Book before (X) hours
                  </label>
                  <GeneralDropdown
                    className="form-control"
                    // selectedOption={paymentMethod == 1 ? paymentModes[0] : paymentModes[1]}
                    list={hours}
                    handleChange={(obj) => {
                      setSelectedHour(obj.value);
                    }}
                    inModal
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn custom-btn-light fs-7"
            onClick={onPressClose}
          >
            Close
          </button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
