import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getApiData } from "../../../Services";
import TableFilter from "./Filter";
import Table from "./Table";
import { orderTimeSlots } from "../../../config";
import { useLocation } from 'react-router-dom';

export default function () {
  const [filters, setFilters] = useState({
    building: "",
    startDate: "",
    endDate: "",
  });
  const [productList, setProductList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [wingList, setWingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [chhotuList, setChhotuList] = useState([]);

  const location = useLocation();
  const directFilter = location.state?.filter;

  // {building: '', startDate: '', endDate: '', order_status: '1'}

  useEffect(() => {
    if (directFilter) {
      // Assuming directFilter is an object with {label, value} structure
      setFilters((prevFilters) => ({
        ...prevFilters,
        order_status: directFilter.value
      }));
    }
  }, [directFilter]);

  useEffect(() => {
    console.log("directFilter >>>>", directFilter);

    getProductList();
    getBuildingList();
    getChhotuList();
  }, []);

  useEffect(() => {
    // if (filters.building) {
    //   getOfficeByBuilding();
    // }
  }, [filters.building]);

  const getProductList = () => {
    getApiData("products/v1/?limit=1000").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.name, value: item.id }];
        });
        setProductList(array);
      }
    });
  };

  const getBuildingList = () => {
    getApiData("cluster/v1/buildings").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [
            ...array,
            { label: item.name, value: item.id },
          ];
        });
        setBuildingList(array);
      }
    });
  };

  const getWingByBuilding = async (building) => {
    const body = {
      building_id: building,
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let wing = [];
        Object.keys(res.data).map((item) => {
          wing = [...wing, { label: item, value: item }];
        });
        return wing;
      }
    });
  }

  const getFloorByWing = async (wing) => {
    const body = {
      building_id: filters.building,
      wing: wing
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      console.log("res >>>>>", res);
      if (res.status) {
        let floor = [];
        Object.keys(res.data).map((item) => {
          floor = [...floor, { label: item, value: item }];
        });
        return floor;
      }
    });
  }

  const getOfficeByFloor = async (floor) => {
    const body = {
      building_id: filters.building,
      wing: filters.wing,
      floor: floor,
    }
    return getApiData(`building/v1/details/`, 'post', body).then((res) => {
      if (res.status) {
        let office = [];
        res.data.map((item) => {
          office = [...office, { label: item, value: item }];
        });
        return office;
      }
    });
  }

  const getChhotuList = () => {
    getApiData("delivery/v1/").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setChhotuList(array);
      }
    });
  };

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "building") {
      obj.wing = "";
      obj.floor = "";
      obj.office = "";
      setWingList([]);
      setFloorList([]);
      setOfficeList([]);
    } else if (key === "wing") {
      obj.floor = "";
      obj.office = "";
      setFloorList([]);
      setOfficeList([]);
    } else if (key === "floor") {
      obj.office = "";
      setOfficeList([]);
    }
    setFilters(obj);
  };

  console.log("filters >>>>>>", filters);

  return (
    <>
      <div>
        <TableFilter
          buildingList={buildingList}
          wingList={wingList}
          floorList={floorList}
          officeList={officeList}

          productList={productList}
          chhotuList={chhotuList}
          value={filters}
          orderTimeSlots={orderTimeSlots}

          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          onBuildingSelect={async (value) => {
            updateFilter(value, "building");
            const wing = await getWingByBuilding(value);
            setWingList(wing);
          }}

          onWingSelect={async (value) => {
            updateFilter(value, "wing");
            const floor = await getFloorByWing(value);
            setFloorList(floor);
          }}

          onFloorSelect={async (value) => {
            updateFilter(value, "floor");
            const office = await getOfficeByFloor(value);
            setOfficeList(office);
          }}

          onOfficeSelect={updateFilter}

          setFilters={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}

          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
              startDate: "",
              endDate: "",
            });
          }}

          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}
        />

        <Table filters={filters} setFilters={(val) => setFilters(val)} />
      </div>
    </>
  );
}
