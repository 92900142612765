import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getApiData } from "../../../Services";
import Table from "./Table";
import TableFilter from "./Table/Filter";

export default function () {
  const [filters, setFilters] = useState({
    name: "",
  });
  const [chhotuList, setChhotuList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    getChhotuList();
  }, []);

  const getChhotuList = () => {
    getApiData("delivery/v1/?is_active=1").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setChhotuList(array);
      }
    });
  };

  return (
    <div>
      <TableFilter
        setSearchString={(value) => {
          let obj = { ...filters };
          obj.name = value;
          setFilters(obj);
        }}
        onClearFilter={() => {
          setFilters({
            name: "",
          });
        }}
        togglePopup={togglePopup}
      />

      <Table
        filters={filters}
        setFilters={(value) => setFilters(value)}
        chhotuList={chhotuList}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
      />
    </div>
  );
}
