import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  convertDate,
  formateAmount,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../config";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../Services";
import {
  AddCreditModal,
  CreditModal,
  InvoiceHistoryModal,
  OfficeModal,
  OrderHistoryModal,
  TransactionHistoryModal,
  AddBonus,
  CSVModal,
} from "./../modals";
import Chart from "react-apexcharts";
import Pagination from "react-js-pagination";
import Loader from "../../../components/Loader";

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [addBonusModal, setAddBonusModal] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [addCreditModal, setAddCreditModal] = useState(false);
  const [officeModal, setOfficeModal] = useState(false);
  const [transactionHistoryModal, setTransactionHistoryModal] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const [invoiceHistoryModal, setInvoiceHistoryModal] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [customerData, setCustomerData] = useState();

  const [dates, setDates] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const [activeUser, setActiveUser] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`business/v1/customers/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        props.setTotalDues(res.total_dues);
        setData(apiData);
        setCustomerData(res);

        const datesArray = [];
        const newUserArray = [];
        const activeUserArray = [];

        Object.keys(res.graph_data).forEach(date => {
          datesArray.push(date);
          newUserArray.push(res.graph_data[date][0]);
          activeUserArray.push(res.graph_data[date][1]);
        });

        setDates(datesArray);
        setNewUser(newUserArray);
        setActiveUser(activeUserArray);
      }
    })
  };

  const changeIsDefault = async (item) => {
    return await getApiData(`business/v1/customers/${item.id}`, "get").then((res) => {
      if (!res.status) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        getTableRecords();
      }
    });
  };

  return (
    <>
      <CreditModal
        show={creditModal}
        data={selectedRow}
        onHide={() => setCreditModal(false)}
        onSuccess={(message) => {
          setCreditModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <AddCreditModal
        show={addCreditModal}
        data={selectedRow}
        onHide={() => setAddCreditModal(false)}
        onSuccess={(message) => {
          setAddCreditModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {transactionHistoryModal && (
        <TransactionHistoryModal
          show={transactionHistoryModal}
          data={selectedRow}
          onHide={() => setTransactionHistoryModal(false)}
        />
      )}
      {orderHistoryModal && (
        <OrderHistoryModal
          show={orderHistoryModal}
          data={selectedRow}
          onHide={() => setOrderHistoryModal(false)}
        />
      )}
      {invoiceHistoryModal && (
        <InvoiceHistoryModal
          show={invoiceHistoryModal}
          data={selectedRow}
          onHide={() => setInvoiceHistoryModal(false)}
        />
      )}
      {officeModal && (
        <OfficeModal
          show={officeModal}
          data={selectedRow}
          onHide={() => setOfficeModal(false)}
        />
      )}

      {addBonusModal && (
        <AddBonus
          show={addBonusModal}
          onHide={() => setAddBonusModal(false)}
          data={selectedRow}
          onSuccess={(message) => {
            toast.success(message);
            setAddBonusModal(false);
            setSelectedRow({});
            getTableRecords();
          }}
          onError={(message) => {
            toast.error(message);
          }}
        />
      )}

      <CSVModal
        show={showCSVModal}
        onHide={() => setShowCSVModal(false)}
        data={selectedRow}
      />

      <div className="row g-2 mb-5">
        <div className="col-12">
          <div className="card" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/wave-bg-dark.svg')})`, backgroundColor: '#eb3a22', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}>
            <div className="card-body p-3 d-flex  w-100">
              <div className="symbol symbol-45px me-4">
                <span className="symbol-label" style={{ backgroundColor: '#ffffff42' }}>
                  <i className="ki-outline ki-profile-user fs-3x text-100" />
                </span>
              </div>
              <div className=" w-75">
                <span className="fw-semibold d-block mb-1 text-200 fs-6">Account Creation</span>
                <h3 className="card-title fw-bolder mb-0 text-100 fs-2">{customerData?.account_creation ? customerData?.account_creation : 0}</h3>
              </div>
              {/* <small className="fw-medium text-200 mt-5 me-4"><i className="bx bx-up-arrow-alt" /> +72.80%</small> */}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-body p-3 d-flex  w-100">
              <div className="symbol symbol-45px me-4">
                <span className="symbol-label bg-skin-primary">
                  <i className="ki-outline ki-finance-calculator fs-3x text-primary" />
                </span>
              </div>
              <div className=" w-75">
                <span className="fw-semibold d-block mb-1 text-600 fs-6">Recharge Count</span>
                <h3 className="text-900 fw-bolder mb-0 fs-2">{customerData?.recharge_count ? customerData?.recharge_count : 0}</h3>
              </div>
              {/* <small className="text-blue fw-medium mt-5 me-4"><i className="bx bx-up-arrow-alt" /> +72.80%</small> */}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-body p-3 d-flex  w-100">
              <div className="symbol symbol-45px me-4">
                <span className="symbol-label bg-skin-primary">
                  <i className="ki-outline ki-wallet fs-3x text-primary" />
                </span>
              </div>
              <div className=" w-75">
                <span className="fw-semibold d-block mb-1 text-600 fs-6">Recharge Amount</span>
                <h3 className="text-900 fw-bolder mb-0 fs-2">{customerData?.recharge_amount ? `₹` + customerData?.recharge_amount : `₹` + 0}</h3>
              </div>
              {/* <small className="text-blue fw-medium mt-5 me-4"><i className="bx bx-up-arrow-alt" /> +72.80%</small> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header min-h-50px px-4">
              <h3 className="card-title fw-bold mb-0 fs-4 d-flex align-items-center ">Business OverView</h3>
              <div className="card-toolbar">
              </div>
            </div>
            <div className="card-body p-3 pe-5 pt-0 pb-0">
              <Chart
                options={{
                  chart: { id: "basic-bar", toolbar: { show: false } },
                  dataLabels: { enabled: false },
                  stroke: { curve: "smooth", width: 0 },
                  xaxis: {
                    categories: dates,
                  },
                  colors: ["rgba(235, 58, 34, 0.85)", "#000"],
                  plotOptions: {
                    bar: {
                      columnWidth: "70%",
                    }
                  }
                }}
                series={[
                  {
                    name: "New User",
                    type: 'column',
                    data: newUser,
                  },
                  {
                    name: "Active User",
                    type: 'column',
                    data: activeUser,
                  },
                ]}
                type="line"
                height={310}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border border-primary d-flex mb-5 flex-stack p-3 rounded border-dashed bg-skin-primary">
        <div>
          <div className="form-check ml-5">
            <input className="form-check-input border border-primary" style={{ marginTop: -1 }} type="checkbox" defaultValue id="flexCheckDefault" />
            <label className="form-check-label text-800 fw-bold" htmlFor="flexCheckDefault">
              Invoice Due Customers
            </label>
          </div>
        </div>
        <div className="fs-4 fw-bolder">Total Dues: <span className="text-primary">{formateAmount(props.totalDues)}</span></div>
      </div>

      <div className="d-flex align-items-center justify-content-end mb-5">
        <button className="btn custom-button py-2 ml-3" onClick={() => setShowCSVModal(true)}>
          Export CSV
        </button>
      </div>
      {
        loading ?
          (
            <Loader />
          ) : (
            <>
              {data.length !== 0 ?
                <>
                  <div className="row g-5 mb-5">
                    {data.map((row) => (
                      <div className="col-md-12">
                        <div className="card">
                          <div className="card-body p-5 position-relative">
                            <div className="d-flex flex-stack align-items-center">
                              <div className=" d-flex align-items-center min-w-150px">
                                <div className="ms-0 bg-gray-light rounded p-3">
                                  <div className="fs-6">Name: <b>{row.first_name}</b></div>
                                  <div className="fs-8">Phone: <a href><b>{row.phone_number}</b></a></div>
                                  <div className="fs-8 mt-2">Area: <b>{row?.office?.area ?? "-"}</b></div>
                                  <div className="fs-8">Building: <b>{row?.office?.building ?? "-"}</b></div>
                                  <div className="fs-8">Office Name: <b>{row?.office?.name ?? "-"}</b></div>
                                  <div className="fs-8">Wing : <b>{row?.office?.wing ?? "-"}</b>, Office No. : <b>
                                    {row?.office?.office ?? "-"}
                                    {row.office?.total_offices > 1 && (
                                      <>
                                        <br />
                                        <a
                                          className="cursor-pointer "
                                          onClick={() => {
                                            setOfficeModal(true);
                                            setSelectedRow(row);
                                          }}
                                          href="#"
                                        >
                                          {row?.office?.total_offices} more offices
                                        </a>
                                      </>
                                    )}
                                  </b></div>
                                </div>
                              </div>
                              <div className="min-w-150px">
                                <div className="rounded text-start mb-1 text-right">Wallet balance: <span className="fs-6 fw-bolder">₹{row.available_credit ? row.available_credit : '0'}</span></div>
                                <div className="rounded text-start mb-1 text-right">Used from khata: <span className="fs-6 fw-bolder">₹{row.used_credit}</span></div>
                                <div className="fs-8 text-right">Order:{" "}
                                  <a className="text-primary" href
                                    onClick={() => {
                                      setOrderHistoryModal(true);
                                      setSelectedRow(row);
                                    }}>
                                    <b>View</b>
                                  </a>
                                </div>
                                <div className="fs-8 text-right">Invoice:{" "}
                                  <a className="text-primary" href
                                    onClick={() => {
                                      setInvoiceHistoryModal(true);
                                      setSelectedRow(row);
                                    }}>
                                    <b>View</b>
                                  </a>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="custom-button btn-primary py-1 px-3 mt-2"
                                    onClick={() => {
                                      setAddBonusModal(true);
                                      setSelectedRow(row);
                                    }}
                                  >
                                    <span className="fs-8">Add Bonus</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed mt-4" />
                            <div className=" row mt-3 align-items-center">
                              <div className="col-10">
                                <div className="fs-8 ">Created At: <b>{convertDate(row.created_at)}</b> ({timeSince(row.created_at)})</div>
                              </div>
                              <div className="col-2 d-flex justify-content-end">
                                <div className="form-check form-switch form-check-custom form-check-solid me-3 ms-auto mt-0 mr-5">
                                  <input
                                    className="form-check-input h-20px w-30px"
                                    type="checkbox"
                                    onChange={(e) => {
                                      changeIsDefault(row);
                                    }}
                                    checked={row.is_active}
                                  />
                                  <label className="form-check-label" htmlFor="flexSwitch20x30" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 float-right mr-3">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={20}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={5}
                      itemClass="d-flex justify-content-between align-items-center flex-wrap"
                      linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                      activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                      onChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                    />
                  </div>
                </>
                :
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
                </div>
              }
            </>
          )
      }
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
