import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { WebSocketProvider } from "./config/WebSocketContext";
import { SOCKET_URL } from "./config";
// import { fetchToken } from "./config/firebase";
import {
  AreaMaster,
  Buildings,
  Customers,
  LiveOrders,
  ManageBuilding,
  RequestBuilding,
  UserDashboard,
  Notifications,
  CouponsMaster,
  Reviews,
  PayoutMaster,
  OrderHistoryMaster,
  Banner,
  DashboardSales,
  BusinessMaster,
  NotFound,
  LiveOrdersMaster,
  DriverPayout,
  DeliveryBoyMaster,
  Settlement,
  ProductsAnalytics,
  AreaManagers,
} from "./pages";

import {
  Cluster,
  OrderHistory,
  TapriChhotus,
  ProductBusiness,
  ProductCategory,
  CustomersBusiness,
  Support,
  PayoutSettings,
  CouponsBusiness,
  DriverPayoutHistory,
  NotificationBusiness,
  PayoutHistory,
  PayoutBill,
  ProductsAnalyticsBusiness,
} from "./pages/tapri";

import PersonaInformation from "./modules/UserProfile/PersonaInformation";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  React.useEffect(() => {
    if (user.user_type !== "admin") {
      let unauthorizedRoutes = [];
      // console.log(history.location.pathname)
      if (unauthorizedRoutes.includes(history.location.pathname)) {
        history.push("unauthorized");
      }
    }
  }, [user]);

  React.useEffect(() => {
    // fetchToken();
  }, []);
  let isAdmin = user && user.user_type === "admin" ? true : false;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isAdmin) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {isMobile && (
            <Redirect exact from={["/", "/live-orders"]} to="/live-orders/ " />
          )}
          <Redirect exact from={["/", "/dashboard"]} to="/dashboard/ " />
          <ContentRoute path="/user-profile" component={UserProfilepage} />

          <ContentRoute path="/dashboard/ " component={DashboardSales} />
          <ContentRoute path="/customers" component={Customers} />
          <ContentRoute path="/notifications" component={Notifications} />
          <ContentRoute path="/reviews" component={Reviews} />
          <ContentRoute path="/master/coupons" component={CouponsMaster} />
          <ContentRoute
            path="/master/products-analytics"
            component={ProductsAnalytics}
          />
          <ContentRoute path="/master/building" component={Buildings} exact />
          <ContentRoute
            path="/master/building/edit"
            component={ManageBuilding}
          />
          <ContentRoute
            path="/master/building/create"
            component={ManageBuilding}
          />
          <ContentRoute
            path="/master/building-request/:id?"
            component={RequestBuilding}
          />
          <ContentRoute
            path="/master/business-partner"
            component={BusinessMaster}
          />
          <ContentRoute path="/master/area" component={AreaMaster} />
          <ContentRoute path="/master/area-managers" component={AreaManagers} />
          <ContentRoute path="/order-history" component={OrderHistoryMaster} />
          <ContentRoute path="/business-payout" component={PayoutMaster} />
          <ContentRoute path="/driver-payout" component={DriverPayout} />
          <ContentRoute path="/banner" component={Banner} />
          <ContentRoute path="/live-orders" component={LiveOrdersMaster} />
          <ContentRoute path="/delivery-boy" component={DeliveryBoyMaster} />
          <ContentRoute
            path="/personal-information"
            component={PersonaInformation}
          />
          <ContentRoute path="/settlement" component={Settlement} />

          <ContentRoute path="*" component={NotFound} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <WebSocketProvider
          socketUrl={`${SOCKET_URL}/ws/taprocket/delivery/${user.id}/`}
        />
        <Switch>
          {isMobile && <Redirect exact from={"/"} to="/live-orders" />}
          <Redirect exact from={"/"} to="/dashboard" />
          <ContentRoute path="/dashboard" component={UserDashboard} />
          <ContentRoute path="/user-profile" component={UserProfilepage} />

          <ContentRoute path="/live-orders" component={LiveOrders} />
          <ContentRoute path="/delivery-boy" component={TapriChhotus} />
          <ContentRoute path="/order-history" component={OrderHistory} />
          <ContentRoute path="/cluster" component={Cluster} />
          <ContentRoute path="/products" component={ProductBusiness} />
          <ContentRoute path="/product-category" component={ProductCategory} />
          <ContentRoute
            path="/customers-business"
            component={CustomersBusiness}
          />
          <ContentRoute path="/payout-history" component={PayoutHistory} />
          <ContentRoute
            path="/driver-payout-history"
            component={DriverPayoutHistory}
          />
          <ContentRoute path="/support" component={Support} />
          <ContentRoute path="/payout-settings" component={PayoutSettings} />
          <ContentRoute path="/payout-bill" component={PayoutBill} />
          <ContentRoute path="/coupons" component={CouponsBusiness} />
          <ContentRoute
            path="/notification-business"
            component={NotificationBusiness}
          />
          <ContentRoute
            path="/personal-information"
            component={PersonaInformation}
          />
          <ContentRoute
            path="/products-analytics"
            component={ProductsAnalyticsBusiness}
          />
          <ContentRoute path="*" component={NotFound} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
}
