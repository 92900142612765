/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import Login from "./Login";
import { gradientColors } from "../../../../gradientColor";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root ">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid "
          id="kt_login"
          style={{ backgroundColor: "#fff" }}
        >
          <div
            className="d-flex bg-top-sign-in flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 min-h-250px overflow-hidden"
            style={{ background: '#eb3a2212' }}>
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <a href className="mb-0 mb-lg-12">
                <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-tap-rocket-dark.png')} className="h-100px h-lg-100px" />
              </a>
              <img src={toAbsoluteUrl('/media/logos/a.png')} style={{ maxWidth: '90%', marginBottom: '-80px' }} alt="" />
            </div>
          </div>

          <Switch>
            <ContentRoute path="/sign-in" component={Login} />
            <ContentRoute path="/sign-up" component={Login} />
            <Redirect to="/sign-in" />
          </Switch>
        </div>
      </div>
    </>
  );
}
