import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import { GeneralDropdown } from "../../../components";
import toast from "react-hot-toast";

export default function ({ data, show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const [selectedBusinessList, setSelectedBusinessList] = useState([]);

  useEffect(() => {
    if (data && show) {
      setLoading(true);
      getBusinessList();

      if (data?.pinned_business?.length) {
        const selectedCafe = data?.pinned_business.map((business) => {
          const key = Object.keys(business)[0];
          const { id, first_name } = business[key];
          return {
            value: id,
            label: first_name,
          };
        });

        setSelectedBusinessList(selectedCafe);
      }
    }
  }, [data]);

  const getBusinessList = () => {
    getApiData(`business/v1/?limit=1000`).then((res) => {
      setLoading(false);
      const newData = res?.data?.map((item) => ({
        value: item.id,
        label: item.first_name,
      }));
      setBusinessList(newData);
    });
  };

  const onSubmit = () => {
    setSending(true);
    const businesses = selectedBusinessList.reduce((acc, item, index) => {
      acc[index + 1] = item.value;
      return acc;
    }, {});

    const body = {
      building: data.id,
      businesses: businesses,
    };

    getApiData(`building/v1/pin-business/`, "POST", body).then((res) => {
      setSending(false);
      if (res.status) {
        onSuccess(res?.message);
      } else {
        onError(res?.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Pinned Business
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="row g-3">
          <div className="col-md-12">
            <label className=" fs-6 mb-2 required">Select Business</label>
            <GeneralDropdown
              selectedOption={selectedBusinessList}
              list={businessList}
              inModal
              isMulti
              handleChange={(business) => {
                setSelectedBusinessList(business);
              }}
            />
          </div>
        </div>
        <div className="mt-10">
          <button
            type="button"
            onClick={onHide}
            className="custom-custom-btn-light px-10"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            disabled={!sending ? false : true}
            className="custom-button-danger font-weight-bold px-10"
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
