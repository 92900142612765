import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LoaderPopup } from "../../../../components";
import { convertDate } from "../../../../config";
import { getApiData } from "../../../../Services";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export default function ({ data, show, onHide }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.id) {
      setLoading(true);
      getList(data.id);
    }
  }, [data]);

  const getList = (id) => {
    getApiData(`v1/customer/offices/?customer_id=${id}`).then((res) => {
      setLoading(false);
      if (res.status) {
        setList(res.data);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Office</Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
          <thead>
            <tr className="text-left text-uppercase">
              <th className="pl-4">#</th>
              <th>Office Name</th>
              <th>Office Number</th>
              <th>Building Name</th>
              <th>Building Address</th>
              <th>Floor</th>
              <th>Wing</th>
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td colSpan="9">
                <LoaderPopup />
              </td>
            </tr>
          ) : (
            <>
              {list.length ? (
                list.map((item, i) => {
                  return (
                    <tr>
                      <td className="pl-4">{++i}</td>
                      <td>{item.name}</td>
                      <td>{item.office_number}</td>
                      <td>{item.building_name}</td>
                      <td className="w-250px">{item.building_address}</td>
                      <td>{item.floor}</td>
                      <td>{item.wing}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn custom-btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
