import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components";
import {
  convertDate,
  formateAmount,
  getParams,
  PER_PAGE,
  timeSince,
  sizePerPageList,
  ORDER_CANCEL_TYPE,
} from "../../config";
// import { onMessageListener } from "../../../config/firebase";
import { getApiData } from "../../Services";
import { OrderDetails, CollectRefund } from "./modals";
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export default function ({ filters }) {
  const history = useHistory();
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isShowDetailPop, setIsShowDetailPop] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showRefundModal, setShowRefundModal] = useState(false);

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("Received foreground message ", payload);
  //     if (
  //       payload &&
  //       payload.data &&
  //       (payload.data.type === "UTENSILS_COLLECT_REQUEST" ||
  //         payload.data.type === "UTENSILS_REQUEST_CANCELLED")
  //     ) {
  //       setLoading(true);
  //       getRecords();
  //     }
  //   })
  //   .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  // useEffect(() => {
  //   console.log("filters >>>>", filters);
  //   if (currentPage !== 1) {
  //     setCurrentPage(1);
  //   } else if(filters){

  //   }
  // }, [filters]);

  // useEffect(() => {
  //   setLoading(true);
  //   getTableRecords();
  // }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(filters, currentPage, sizePerPage);

    getApiData(`order/v1/history/previous/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  const exportHistory = () => {
    toast.loading("Downloading...");
    getApiData(
      `order/v1/export?start_date=${filters.startDate}&end_date=${filters.endDate}`
    ).then((res) => {
      toast.dismiss();
      if (res.status) {
        toast.success("Downloaded");
        window.open(res.data);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  return (
    <>
      <OrderDetails
        show={isShowDetailPop}
        onHide={() => setIsShowDetailPop(!isShowDetailPop)}
        data={selectedRow}
      />
      <CollectRefund
        show={showRefundModal}
        onHide={() => setShowRefundModal(!showRefundModal)}
        data={selectedRow}
        onSuccess={(message) => {
          setShowRefundModal(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ? (
            <>
              <div className="row g-5 mt-5">
                <div className="col-md-12">
                  {data.map((item, index) => {
                    const delivered_at = item.delivered_at;
                    const placed_at = item.placed_at;

                    const createdDate = new Date(delivered_at);
                    const reachedDate = new Date(placed_at);

                    const timeDifference = reachedDate - createdDate;
                    const absoluteTimeDifference = Math.abs(timeDifference);

                    const hours = Math.floor(
                      absoluteTimeDifference / (1000 * 60 * 60)
                    );
                    const minutes = Math.floor(
                      (absoluteTimeDifference % (1000 * 60 * 60)) / (1000 * 60)
                    );
                    const seconds = Math.floor(
                      (absoluteTimeDifference % (1000 * 60)) / 1000
                    );

                    const formattedHours = hours > 0 ? `${hours}hr ` : "";
                    const formattedMinutes =
                      minutes > 0 ? `${minutes}min ` : "";
                    const formattedSeconds =
                      seconds > 0 ? `${seconds}sec` : "1 sec";

                    const formattedTimeDifference = `${formattedHours}${formattedMinutes}${formattedSeconds}`;

                    return (
                      <div className="card mb-5">
                        <div className="card-body p-5 position-relative">
                          <div className="d-flex flex-stack align-items-center">
                            <div className=" d-flex align-items-center min-w-150px ">
                              <span className="bg-primary ordno mb-0">
                                {item.sr_no}
                              </span>
                              <div className="ms-3  bg-gray-light rounded p-3">
                                <div className="fs-6">
                                  Cafe Name: <b>{item.business.first_name}</b>
                                </div>
                                <div className="fs-6">
                                  Name: <b>{item.customer.first_name}</b>
                                </div>
                                <div className="fs-8">
                                  Phone:{" "}
                                  <a href>
                                    <b>{item.customer.phone_number}</b>
                                  </a>
                                </div>
                                <div className="fs-8">
                                  Office Name: <b>{item?.office}</b>
                                </div>
                                <div className="fs-8">
                                  Office No.: <b>{item?.office_number}</b>
                                </div>
                              </div>
                            </div>
                            <div className="min-w-100px ml-3">
                              <div className="rounded fs-6 fw-bolder mb-1 text-right">
                                Total: ₹ {item.total}
                              </div>
                              <div className="fs-8 text-right">
                                Type:{" "}
                                <b>
                                  {item.order_type == 1 ? (
                                    <span>{"Scheduled, "}</span>
                                  ) : item.order_type == 2 ? (
                                    <span>{"Manual, "}</span>
                                  ) : item.order_type == 3 ? (
                                    <span>{"Pre Order, "}</span>
                                  ) : item.order_type == 4 ? (
                                    <span>{"Settlement, "}</span>
                                  ) : (
                                    <span></span>
                                  )}
                                  {item.is_on_tap && <span>{"On Tap, "}</span>}
                                  {item.is_custom && <span>{"Custom, "}</span>}
                                  {item.order_type == 3 && (
                                    <>
                                      {item.is_takeaway ? (
                                        <span>{"Take Away"}</span>
                                      ) : (
                                        <span>{"Dine In"}</span>
                                      )}
                                    </>
                                  )}
                                </b>
                              </div>
                              <div className="d-flex align-items-center justify-content-end fs-8 mt-2">
                                <i className="ki-outline ki-watch text-primary fs-2 me-2" />
                                <div>
                                  <div>
                                    <span>
                                      {convertDate(item.delivered_at)}
                                    </span>
                                    <br />
                                    {item?.order_status !== "Cancelled" && (
                                      <span className="timeStampColor text-right">
                                        (Delivery Time:{" "}
                                        {formattedTimeDifference})
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-end fs-8 mt-2">
                                <div className="fs-8">
                                  Order Id: <b>{item.order_id}</b>
                                </div>
                              </div>
                              {item.order_cancel_by !== 0 && (
                                <div className="fs-8 text-right">
                                  Order Cancel By:
                                  <b>
                                    {ORDER_CANCEL_TYPE[item?.order_cancel_by]}
                                  </b>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-4" />
                          <div className=" row mt-3">
                            <div className="col-3">
                              <div
                                className="fs-8 badge fs-base"
                                style={
                                  item?.order_status !== "Cancelled"
                                    ? {
                                        backgroundColor: "#DFFFEA",
                                        color: "#17C653",
                                      }
                                    : {
                                        backgroundColor: "#ffcccc",
                                        color: "#ff3333",
                                      }
                                }
                              >
                                {item?.order_status}
                              </div>
                            </div>
                            <div className="col">
                              <div className="fs-7">
                                Delivery Boy: <b>{item.delivery_boy}</b>
                              </div>
                            </div>
                            <div className="col">
                              <div className="fs-7">
                                Delivery Boy Phone Number:{" "}
                                <b>
                                  {item?.delivery_boy_phone_number
                                    ? item?.delivery_boy_phone_number
                                    : "-"}
                                </b>
                              </div>
                            </div>
                            <div className="col">
                              <div className="d-flex align-items-center justify-content-end text-end">
                                <a
                                  href
                                  onClick={() => {
                                    setShowRefundModal(true);
                                    setSelectedRow(item);
                                  }}
                                  className="fw-bolder d-flex align-items-center fs-7 justify-content-end"
                                >
                                  Create Refund
                                </a>
                                <a
                                  href
                                  onClick={() => {
                                    setIsShowDetailPop(true);
                                    setSelectedRow(item);
                                  }}
                                  className="fw-bolder d-flex align-items-center fs-7 justify-content-end ml-5"
                                >
                                  View Details
                                  <i className="ki-outline ki-arrow-right text-primary fs-4" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/logos/no-data.png")}
                height={"150px"}
                width={"150px"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
