import React from 'react'
import './loader.css'
// const Style = {}
export default function Loader() {
  return (
    <>
      <div>
        <div className="col-md-12">
          <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div>
          {/* <div className="row mb-3">
            <div className="col-md-12 col-sm-2 line"></div>
          </div> */}
        </div>
      </div>
    </>
  )
}
