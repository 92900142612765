import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Loader } from "../../../../components";
import {
  getParams,
  PER_PAGE,
  sizePerPageList,
} from "../../../../config";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

import { Pagination, CardBody, Card } from '../../../../../_metronic/_partials/controls';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

function UserTable(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [showPaymentPop, setShowPaymentPop] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showPayoutDetail, setShowPayoutDetail] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const statusList = [
    { label: 'Pending', value: 'pending' },
    { label: 'Success', value: 'success' },
  ]

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`products/v1/analytics/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalRecords,
    sizePerPage: sizePerPage,
    sizePerPageList: sizePerPageList,
    page: currentPage,
    onSizePerPageChange: (e) => {
      setCurrentPage(1);
      setSizePerPage(e);
    },
    onPageChange: (page) => {
      setCurrentPage(page);
    },
  };

  const changeStatus = (row) => {
    if (!row.payment_status) {
      toast.dismiss();
      setShowPaymentPop(true);
      setSelectedRow(row);
    }
  };

  const columns = [
    {
      dataField: "sr_no",
      text: "#",
      headerStyle: () => {
        return { minWidth: "80px" };
      },
      formatter: (_, row) => <span>{row.sr_no}</span>,
    },
    {
      dataField: "business",
      text: "Business Partner",
    },
    {
      dataField: "name",
      text: "Product Name",
    },
    {
      dataField: "image",
      text: "Product Image",
      headerStyle: () => {
        return { minWidth: "150px" };
      },
      formatter: (_, row) => (
        <div class="d-flex">
          <img
            src={row.image}
            style={{ width: "55px", objectFit: "cover" }}
            alt="photo"
            className="mr-3"
          />
        </div>
      ),
    },
    {
      dataField: "price",
      text: "Price",
      formatter: (_, row) => <span className="fw-bold">₹{row.price}</span>,
    },
    {
      dataField: "sold_quantity",
      text: "Sold Quantity",
    },
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Card>
            <CardBody>
              <div>
                {data.length !== 0 ?
                  <>
                    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                      {({ paginationProps, paginationTableProps }) => {
                        return (
                          <Pagination isLoading={false} paginationProps={paginationProps}>
                            <BootstrapTable
                              remote
                              onTableChange={() => { }}
                              wrapperClasses="table-responsive"
                              bordered={false}
                              classes="table table-head-custom table-vertical-center overflow-hidden"
                              id="userTable"
                              bootstrap4
                              keyField="id"
                              data={data === null ? [] : data}
                              columns={columns}
                              noDataIndication={() => {
                                return "No Data to Display";
                              }}
                              defaultSorted={[{ dataField: "id", order: "asc" }]}
                              {...paginationTableProps}
                            >
                            </BootstrapTable>
                          </Pagination>
                        );
                      }}
                    </PaginationProvider>
                  </>
                  :
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
                  </div>
                }
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));