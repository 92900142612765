import { API_URL } from "./config";

const getToken = (useToken = true, customToken = "") => {
  if (useToken) {
    let data = JSON.parse(localStorage.getItem("persist:userInfo"));

    if (customToken) {
      return `Token ${customToken}`;
    } else {
      return `Token ${JSON.parse(data.authToken)}`;
    }
  } else {
    return null;
  }
};

const getHeaders = (
  method = "get",
  body = {},
  useToken = true,
  customToken = ""
) => {
  const headers = {
    method: method === "delete_body" ? "delete" : method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getToken(useToken, customToken),
    },
  };

  if (method !== "get" && method !== "delete") {
    headers.body = JSON.stringify(body);
    // headers.body = body
  }

  return headers;
};

export const getApiData = (
  apiName,
  method = "get",
  data = {},
  useToken = true,
  customToken = ""
) => {
  let apiURL = API_URL;
  let isLive = false;
  if (window.location.host.includes("localhost") && isLive) {
    apiURL = "https://dev-api.taprocket.in/";
    // apiURL = "https://api.taprocket.in/";
  }

  if (!apiName.includes("tapri/v1/utensils")) {
    if (
      apiName.includes("user/device/add") ||
      apiName.includes("coupon") ||
      apiName.includes("tapri/v1") ||
      apiName.includes("logout") ||
      apiName.includes("v1/invoice") ||
      apiName.includes("order/v1/export") ||
      apiName.includes("location/update/manual")
    ) {
      apiURL = apiURL.replace("dashboard/", "");
    }
  }

  return fetch(
    `${apiURL}${apiName}`,
    getHeaders(method, data, useToken, customToken)
  )
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      return err;
    });
};
