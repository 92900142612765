import React, { useEffect, useState } from "react";
import { getStateList, getAreaByCity, getCategoryList } from "../../../config";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";
import { GeneralDropdown } from "../../../components";

export default function () {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const [sendTo, setSendTo] = useState();
  const [sendToId, setSendToId] = useState("");


  const [list, setList] = useState([]);
  const [sending, setSending] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);

      let list = [
        { value: 'a1b2', label: 'Customers' },
        { value: 'b2c3', label: 'Building' },
        // {value: 'c3d4', label: 'Business Partner'},
      ];

      setList(list);
    })();
  }, []);

  useEffect(() => {
    getBuildingList();
  }, []);

  const getBuildingList = () => {
    getApiData("building/v1/").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [
            ...array,
            { label: item.name, value: item.id },
          ];
        });
        setBuildingList(array);
      }
    });
  };

  const onClear = () => {
    let clearList = list[0];
    setTitle("");
    setMessage("");
    setSendTo(clearList);
    setSelectedState(null);
    setSelectedCity(null);
    setSelectedBuilding(null);
  };

  const onSubmit = () => {
    if (sendTo !== "customers" && !sendToId) {
      toast.error(`Please select ${sendTo}`);
    } else {
      const body = {
        title: title,
        message: message,
        send_to: sendTo,
      };
      if (sendToId) {
        body.send_to_id = sendToId;
      }
      console.log(body);
      setSending(true);

      getApiData("v1/send/message", "post", body).then((res) => {
        setSending(false);
        if (res.status) {
          onClear();
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        {/*begin::Content container*/}
        <div id="kt_app_content_container" className="app-container container-fluid">
          <div className="row g-5">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header px-7 border-bottom-0">
                  <h3 className="card-title fw-bold fs-2">Notifications Settings</h3>
                </div>
                <div className="card-body p-5 px-7 pt-0 position-relative">
                  <div className="row g-5">
                    <div className="col-md-12">
                      <label htmlFor className=" fs-6 fw-bold mb-2">Title</label>
                      <input
                        type="text"
                        className="form-control fs-7"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor className=" fs-6 fw-bold mb-2">Message</label>
                      <textarea
                        className="form-control fs-7"
                        value={message}
                        cols={30}
                        rows={3}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor className=" fs-6 fw-bold mb-2 ">Send to {sendTo ? sendTo?.label : ""}</label>
                      <GeneralDropdown
                        selectedOption={sendTo ? sendTo : list[0]}
                        list={list}
                        handleChange={(obj) => {
                          setSendTo(obj);
                        }}
                      />
                    </div>
                    {/* <div className="col-12">
                      <label htmlFor className=" fs-6 fw-bold mb-2 ">Select State</label>
                      <GeneralDropdown
                        placeholder="Select State"
                        selectedOption={selectedState}
                        list={stateList}
                        handleChange={(selectedState) => {
                          if (selectedState.city && selectedState.city.length) {
                            let city = [];
                            selectedState.city.map(
                              (item) =>
                                (city = [...city, { label: item, value: item }])
                            );
                            setSelectedState(selectedState.value);
                            setCityList(city);
                          }
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <label htmlFor className=" fs-6 fw-bold mb-2 ">Select City </label>
                      <GeneralDropdown
                        selectedOption={selectedCity}
                        placeholder="Select City"
                        list={cityList}
                        handleChange={(obj) => {
                          setSelectedCity(obj.value);
                        }}
                      />
                    </div> */}
                    {sendTo?.label == "Building" &&
                      <div className="col-12">
                        <label htmlFor className=" fs-6 fw-bold mb-2 ">Select Building </label>
                        <GeneralDropdown
                          list={buildingList}
                          handleChange={(obj) => {
                            setSelectedBuilding(obj.value);
                            // props.onStateSelect(obj);
                          }}
                        />
                      </div>
                    }
                    <div className="col-12 text-center">
                      <button className="btn custom-btn-light-primary fs-7 fw-bold mr-3" onClick={() => onClear()}>Clear</button>
                      <button className="custom-button btn-primary fs-7 fw-bold py-3" onClick={() => toast.error("Comming soon!")}>Send</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*end::Content container*/}
      </div>

    </div>
  );
}