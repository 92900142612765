import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {

  const [filters, setFilters] = useState({
    search: "",
    type: "",
    is_on_tap: "",
  });

  const orderTypes = [
    { label: 'Scheduled', value: '1' },
    { label: 'Manual', value: '2' },
  ];

  const onTapTypes = [
    { label: 'Custom', value: '0' },
    { label: 'On Tap', value: '1' },
  ];

  return (
    <>
      <div>
        <TableFilter
          orderTypes={orderTypes}
          onTapTypes={onTapTypes}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          setType={(value) => {
            let obj = { ...filters };
            obj.type = value;
            setFilters(obj);
          }}
          setOnTapType={(value) => {
            let obj = { ...filters };
            obj.is_on_tap = value;
            setFilters(obj);
          }}
          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
              is_on_tap: "",
            });
          }}
        />
        <Table filters={filters} setFilters={(val) => setFilters(val)} />
      </div>
    </>
  );
}
