import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";
import { gradientColors } from "../../../../gradientColor";

export default function ({ data, show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [bannerName, setBannerName] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  useEffect(() => {
    if (data.id) {
      setBannerName(data.name);
      setImagePreview(
        data.image
          ? data.image
          : "https://t4.ftcdn.net/jpg/05/35/51/85/360_F_535518598_fviKNslzN3NTzWTkAn5WpoH9FmiVciAu.jpg"
      );
    } else {
      setBannerName("");
      setImagePreview("");
    }
  }, [data]);

  const clearInput = () => {
    setBannerName("");
    setImagePreview("");
  };

  const onSubmit = () => {
    if (data.id) {
      setSending(true);
      getApiData(`banner/v1/${data.id}`, "delete").then((res) => {
        setSending(false);
        if (res.status) {
          clearInput();
          onHide();
          onSuccess(res.message);
        } else {
          onError(res.message);
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Delete Banner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="col-md-12">
          <div className="form-group is-invalid mb-0">
            <label>Banner Name</label>
            <input
              type="text"
              className="form-control"
              value={bannerName}
              disabled
              onChange={(e) => {
                setBannerName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group is-invalid mb-0 mt-5">
            <label>Banner Image</label>
            {imagePreview && (
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    height: "150px",
                    width: "100%",
                    objectFit: "unset",
                    // marginTop: 20
                  }}
                  className="mx-auto"
                />
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="custom-custom-btn-light px-10"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            disabled={!sending ? false : true}
            style={{
              width: "110px",
              backgroundColor: gradientColors.startColor,
              color: "#FFF",
              marginLeft: 7,
            }}
            className="btn-primary custom-button font-weight-bold"
          >
            Delete
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
