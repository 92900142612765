import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls";
import { Loader } from '../../../../components';
import { getParams, PER_PAGE, sizePerPageList } from "../../../../config";
import { AreaModal } from "./../Modal";
import { getApiData } from "../../../../Services";
import { getStateList } from "../../../../config";
import Pagination from "react-js-pagination";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [areaModal, setAreaModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`administration/v1/areas/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const changeStatus = (id) => {
    getApiData(`administration/v1/areas/${id}/`, "delete").then((res) => {
      toast.dismiss();
      if (res.status) {
        getTableRecords();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <AreaModal
        show={areaModal || props.isPopupOpen}
        onHide={() => {
          setAreaModal(false);
          props.setIsPopupOpen(false);
          setSelectedRow({});
        }}
        data={selectedRow}
        stateList={stateList}
        cityList={props.cityList}
        onSuccess={(message) => {
          setAreaModal(false);
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onFailed={(message) => {
          toast.error(message);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ?
            <>
              <div className="row g-5">
                {data.map((row) => (
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body p-5 position-relative">
                        <div className="ms-0 bg-gray-light rounded p-3 mb-2">
                          <div className="fs-6">Area Name: <b>{row.name}</b></div>
                          <div className="fs-7">Latitude: <a className="fw-bold">{row.latitude ? row.latitude : "-"}</a>, Longitude: <a className="ml-1 fw-bold">{row.longitude ? row.longitude : "-"}</a></div>
                        </div>
                        <div className="separator separator-dashed mt-4" />
                        <div className="d-flex flex-stack align-items-center mt-3">
                          <div>
                            <div className="d-flex">
                              <span className="fs-8">State: <b>{row.state}</b></span>, <span className="ml-1 fs-8">City: <b>{row.city}</b></span>
                            </div>
                            <div className="fs-8 mt-0 d-flex">Zip Code: <b className="ml-1">{row.zip_code}</b></div>
                          </div>
                          <div className="d-flex  align-items-center justify-content-end">
                            <div className="form-check form-switch form-check-custom form-check-solid me-3 mt-0">
                              <input
                                className="form-check-input h-20px w-30px"
                                type="checkbox"
                                onChange={(e) => {
                                  toast.loading("Please wait...");
                                  changeStatus(row.id, e.target.checked);
                                }}
                                checked={row.is_active}
                              />
                              <label className="form-check-label" htmlFor="flexSwitch20x30" />
                            </div>
                            <a
                              className="d-flex ml-8"
                              href
                              onClick={() => {
                                setAreaModal(true);
                                setSelectedRow(row);
                              }}
                            >
                              <i className="ki-outline ki-pencil text-primary fs-2x" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
            </div>
          }
        </div>
      )}
    </>
  );
}