import React from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";

export default function () {
    return (
        <>
            <div className=" d-flex gap-4 mb-5">
            </div>
            <div className="row g-5 mb-5">
                <div className="col-md-12">
                    <div className="card" style={{ borderTop: '2px solid #eb3a22' }}>
                        <div className="card-body p-5 position-relative">
                            <h4 className="fw-bold fs-2">Contact Details</h4>
                            <div className="separator separator-dashed mt-4" />
                            <div className="d-flex  gap-3 mt-3">
                                <div className="fs-6 d-flex align-items-center w-50"><i className="ki-outline ki-phone fs-1 text-gray-900" /> <a href className="fw-bolder">+91 9898578894</a></div>
                                <div className="fs-6 d-flex align-items-center w-50"><i className="ki-outline ki-phone fs-1 text-gray-900" /> <a href className="fw-bolder">+91 9898898989</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-5">
                    <div className="card">
                        <div className="card-header px-7 border-bottom-0">
                            <h3 className="card-title fw-bold fs-2">Taining Videos:</h3>
                        </div>
                        <div className="card-body p-5 px-7 pt-0 position-relative">
                            <div className="row g-5">
                                <div className="col-md-12">
                                    <video style={{ width: '100%', backgroundImage: 'url(assets/media/stock/600x400/img-1.jpg)', backgroundSize: 'cover' }} className="border border-dashed border-gray-300 rounded" height={240} controls><source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4" type="video/mp4" /></video>
                                </div>
                                <div className="col-md-12">
                                    <video style={{ width: '100%', backgroundImage: 'url(assets/media/stock/600x400/img-2.jpg)', backgroundSize: 'cover' }} className="border border-dashed border-gray-300 rounded" height={240} controls><source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4" type="video/mp4" /></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}