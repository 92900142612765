import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DateRangeSettings } from "../../../config";

export default function (props) {
  const handleApply = (event, picker) => {
    props.setDate(
      picker.startDate.format("MM/DD/YYYY"),
      picker.endDate.format("MM/DD/YYYY")
    );
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="btn btn-sm bg-white d-flex align-items-center px-4 w-50 fs-7">
          <DateRangePicker
            initialSettings={DateRangeSettings}
            onApply={handleApply}
            onCancel={handleCancel}
          >
            <input
              type="text"
              className="form-control w-100"
              style={{ borderWidth: 0, backgroundColor: 'transparent', height: '12px' }}
              placeholder="Select date range"
              id="date-range"
              autoComplete="off"
            />
          </DateRangePicker>
          <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
          </i>
        </div>
        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
          onClick={() => {
            props.onClearFilter();
            window.$("#date-range").val("");
          }}>
          <i className="ki-outline ki-arrows-circle fs-2" />
        </button>
      </div>
    </>
  );
}
