import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  CardBody,
  CardHeader,
  Card,
} from "../../../../../_metronic/_partials/controls";
import { GeneralDropdown, Loader } from "../../../../components";
import {
  convertDate,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../../config";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../../Services";
import { UserModal, UserStatus } from "../modals";
import { getStateList } from "../../../../config";
import Pagination from "react-js-pagination";
import { fetchToken } from "../../../../config/firebase";

function UserTable(props) {
  const { authToken } = useSelector((state) => state.auth);
  const history = useHistory();

  const [userModal, setUserModal] = useState(false);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`business/v1/${params}`, "get", {}, true, authToken).then(
      (res) => {
        setLoading(false);
        if (res.status) {
          let apiData = res.data;
          // let pagination = res.data.pagination;
          apiData.length &&
            apiData.map((item, i) => {
              let value = ++i;
              if (currentPage > 1)
                value = sizePerPage * currentPage - sizePerPage + value;
              item.sr_no = value;
            });
          setTotalRecords(res.count);
          setData(apiData);
        } else if (res.detail === "Invalid token.") {
          // alert(authToken);
          history.push("/logout");
        }
      }
    );
  };

  const changeIsDefault = async (id) => {
    // // setLoading(true);
    return await getApiData(`business/v1/profile/${id}`, "get").then((res) => {
      toast.dismiss();
      if (!res.status) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        getTableRecords();
      }
    });
  };

  const changeStatus = async (id) => {
    // // setLoading(true);
    const body = {
      business: id,
    };
    return await getApiData(
      `business/v1/temporarily-closed/`,
      "post",
      body
    ).then((res) => {
      toast.dismiss();
      if (!res.status) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        getTableRecords();
      }
    });
  };

  const onSwitch = async (row, type) => {
    toast.dismiss();
    if (row) {
      const data = {
        auth_token: row.token,
        user_data: {
          id: row.id,
          name: row.first_name,
          user_type: type,
          email: ``,
          photo: null,
          is_admin: row.user_type === 1 ? true : false,
        },
      };

      localStorage.removeItem("websocket");
      toast.success("Success");
      fetchToken(row.token, true);
      localStorage.setItem("userEvent", Date.now().toString());
      props.switchUser(data);
      history.push("/dashboard");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Error occurred while switching user:");
    }
  };

  const switchUser = async (row, type) => {
    if (!row.is_active) {
      setShowUserStatusModal(true);
      setSelectedRow(row);
    } else {
      toast.loading("Switching...");
      onSwitch(row, type);
    }
  };

  return (
    <>
      <UserModal
        show={userModal || props.isPopupOpen}
        groupList={props.groupList}
        data={selectedRow}
        onHide={() => {
          setUserModal(false);
          props.setIsPopupOpen(false);
          setSelectedRow({});
        }}
        stateList={stateList}
        // cityList={props.cityList}
        onSuccess={async (message) => {
          setUserModal(false);
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords(props.filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <UserStatus
        show={showUserStatusModal}
        onHide={() => setShowUserStatusModal(false)}
        data={selectedRow}
        onSuccess={(message) => {
          toast.success(message);
          setShowUserStatusModal(false);
          getTableRecords(props.filters);
          onSwitch(selectedRow, "tapri");
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="row g-5 mb-5">
            <div className="col-12 text-start">
              <button
                className="custom-button btn-primary fs-7 fw-bold"
                onClick={() => setUserModal(true)}
              >
                Add New Business Partner
              </button>
            </div>
          </div>
          {data.length !== 0 ? (
            <>
              <div>
                {data.map((row) => (
                  <div className="row g-5 mb-5">
                    <div className="col-xxl-12">
                      <div className="card card-xxl-stretch pb-5 ">
                        {/*begin::Body*/}
                        <div className="card-body p-3 pe-5">
                          <div className>
                            <div className="d-flex flex-stack">
                              <div
                                className=" d-flex align-items-center"
                                style={{ width: "200px" }}
                              >
                                <span className="bullet bullet-dot bg-primary h-10px w-10px mb-0" />
                                <div className="ms-3">
                                  <div className="fs-5 fw-bolder text-800">
                                    {row.first_name}
                                  </div>
                                  <span className="fs-7 fw-semibold">
                                    {row.phone_number}
                                  </span>
                                  <div className="fs-8 text-gray-500 mt-1">
                                    <div>
                                      <span>
                                        {convertDate(row.date_joined)}
                                      </span>
                                      <br />
                                      <span className="timeStampColor">
                                        ({timeSince(row.date_joined)})
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ml-2">
                                <small className="text-700">
                                  Monthly Order
                                </small>
                                <h5 className="mb-0">{row.monthly_order}</h5>
                              </div>
                              <div className="d-flex align-items-center">
                                <small className="text-700">
                                  Temporarily Closed
                                </small>
                                <div className="form-check  form-switch form-check-custom form-check-solid">
                                  <input
                                    className="form-check-input h-20px w-30px"
                                    type="checkbox"
                                    onChange={(e) => {
                                      toast.loading("updating...");
                                      changeStatus(row.id);
                                    }}
                                    checked={row.temporarily_closed}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitch20x30"
                                  />
                                </div>
                              </div>

                              <div className="d-flex ml-2">
                                <div className="form-check  form-switch form-check-custom form-check-solid me-0 mt-2">
                                  <input
                                    className="form-check-input h-20px w-30px"
                                    type="checkbox"
                                    onChange={(e) => {
                                      toast.loading("updating...");
                                      changeIsDefault(row.id);
                                    }}
                                    checked={row.is_active}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitch20x30"
                                  />
                                </div>
                                <button
                                  className="btn btn-icon btn-sm me-2 ml-8"
                                  onClick={() => {
                                    setSelectedRow(row);
                                    setUserModal(true);
                                  }}
                                >
                                  <i className="ki-outline ki-pencil text-primary fs-1" />
                                </button>
                                <button
                                  className="custom-btn btn-icon btn-primary btn-sm"
                                  style={{
                                    marginRight: "-25px",
                                    borderWidth: 0,
                                  }}
                                  onClick={() => {
                                    switchUser(row, "tapri");
                                  }}
                                >
                                  <i className="ki-outline ki-arrow-right fs-1" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/logos/no-data.png")}
                height={"150px"}
                width={"150px"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(UserTable));
