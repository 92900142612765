import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { SelectStyle } from "../../config";
import { getApiData } from "../../Services";

const ClearIndicator = (props) => {
  const {
    clearValue,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      onClick={clearValue}
      className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
    >
      <i className="ki-outline ki-cross" />
    </div>
  );
};

export default function CustomSelect(props) {
  const selectInputRef = useRef();

  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.list) {
      const list = props.list.map((item) => ({
        ...item,
        label: item.label.charAt(0).toUpperCase() + item.label.slice(1),
      }));
      setOptions(list);
    }
  }, [props.list]);

  useEffect(() => {
    if (props.clearFilter) {
      selectInputRef.current.select.clearValue();
      setSelectedValue(null);
    }
  }, [props.clearFilter]);

  useEffect(() => {
    if (props.selectedOption && options) {
      options.filter((item) => {
        if (item.value == props.selectedOption) {
          setSelectedValue(item);
          return;
        }
      });
    }
  }, [options]);

  useEffect(() => {
    if (props.selectedOption) {
      if (
        typeof props.selectedOption === "string" ||
        typeof props.selectedOption === "number"
      ) {
        options.filter((item) => {
          if (item.value === props.selectedOption.value) {
            setSelectedValue(item);
            return;
          }
        });
      } else {
        setSelectedValue(props.selectedOption);
      }
    } else {
      selectInputRef.current.select.clearValue();
      setSelectedValue(null);
    }
  }, [props.selectedOption]);

  const handleInputChange = (value) => {
    setInputValue(value);
    if (props.searchByQuery && value) {
      getApiData(`delivery/v1/?query=${value}`).then((res) => {
        if (res.status) {
          const list = res.data.map((item) => ({
            label:
              item.first_name.charAt(0).toUpperCase() +
              item.first_name.slice(1),
            value: item.id,
          }));
          setOptions(list);
        }
      });
    } else {
      const filteredOptions = props.list.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      );
      setOptions(filteredOptions);
    }
  };

  return (
    <Select
      ref={selectInputRef}
      isMulti={props.isMulti}
      options={options}
      placeholder={props.placeholder}
      value={selectedValue}
      {...(props.inModal && {
        menuPosition: "fixed",
      })}
      isDisabled={props.disabled}
      onChange={(data) => {
        if (data) {
          setSelectedValue(data);
          props.handleChange(data);
        } else if (data === null && props.isMulti) {
          setSelectedValue(null);
          props.handleChange([]);
        }
      }}
      isSearchable={props.isSearchable}
      onInputChange={handleInputChange}
      styles={props.styles ? props.styles : SelectStyle}
      components={
        props.showClearButton == false
          ? null
          : {
              ClearIndicator: (indicatorProps) => (
                <ClearIndicator
                  {...indicatorProps}
                  clearValue={() => {
                    setSelectedValue(null);
                    props.handleChange([]);
                  }}
                />
              ),
              DropdownIndicator: (indicatorProps) =>
                !props.isMulti && selectedValue ? (
                  <ClearIndicator
                    {...indicatorProps}
                    clearValue={() => {
                      setSelectedValue(null);
                      props.handleChange([]);
                    }}
                  />
                ) : (
                  <components.DropdownIndicator {...indicatorProps} />
                ),
            }
      }
    />
  );
}
