import React, { createContext, useContext, useState, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { actionTypes } from '../modules/Auth';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children, socketUrl }) => {
  const [ws, setWs] = useState(null);

  let notificationAudio;
  const storedWebSocket = JSON.parse(localStorage.getItem('websocket'));

  const preloadAudio = () => {
    notificationAudio = new Audio(toAbsoluteUrl('/media/sound/notification1.mp3'));
    notificationAudio.load();
  };
  
  const playNotificationSound = () => {
    if (notificationAudio) {
      notificationAudio.play().catch(error => {
        console.error('Failed to play notification sound:', error);
        // Handle the error or retry logic if needed
      });
    }
  };

  useEffect(() => {
    preloadAudio();

    if (storedWebSocket) {
      const socket = new WebSocket(storedWebSocket.socketUrl);

      socket.onopen = () => {
        console.log('WebSocket connection opened');
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        // console.log("WebSocket message", message);
        if (message.new_order) { 
          playNotificationSound();
          toast.success('New order received', {
            duration: 5000
          });
        }
      };

      // socket.onclose = () => {
      //   console.log('WebSocket connection closed');
      // };

      // setWs(socket);

      return () => {
        socket.close();
        socket.onmessage = null;
      };
  } else if (socketUrl) {
    const newWebSocket = new WebSocket(socketUrl);

    newWebSocket.onopen = () => {
      console.log('WebSocket connection opened');
      localStorage.setItem('websocket', JSON.stringify({ socketUrl }));
    };

    newWebSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // console.log("WebSocket message", message);
      if (message.new_order) {
        playNotificationSound();
        toast.success('New order received', {
          duration: 5000
        });
      }
    };

    // newWebSocket.onclose = () => {
    //   console.log('WebSocket connection closed');
    // };

    // setWs(newWebSocket);

    return () => {
      newWebSocket.close();
      newWebSocket.onmessage = null;
    };
  }
}, []);

return (
  <WebSocketContext.Provider value={ws}>
    <Toaster />
    {children}
  </WebSocketContext.Provider>
);
};

export const useWebSocket = () => useContext(WebSocketContext);