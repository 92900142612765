import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);

  const onSubmit = () => {
    if (data.cash_in_hand) {
      setSending(true);
      const body = {
        delivery_boy: data.id,
      };

      getApiData(`delivery/v1/cash-collection/`, "post", body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message);
        } else {
          onError(res.message);
        }
      });
    }
  };

  return (
    <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Collect Amount</h3>
        </Modal.Title>
        <button type="button" className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onHide}>
          <i className="ki-outline ki-cross fs-1" />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
            <div className="rounded fs-7 mb-1">Did you collect the cash amount of<b> ₹{data.cash_in_hand}?</b></div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn custom-btn-light fs-7" onClick={onHide}>Close</button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save{" "}
            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
          </button>
        </div>

      </Modal.Body>
    </Modal>
  );
}
