import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { getAreaByCity, getStateList } from "../../config";

import { getApiData } from "../../Services";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {
  const history = useHistory();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
    city: "",
    area: "",
    tapri: "",
  });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <div>
        <TableFilter
          value={filters}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          onClearFilter={() => {
            setFilters({
              search: "",
            });
          }}
          togglePopup={togglePopup}
        />
        <Table
          filters={filters}
          managerList={managerList}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
      </div>
    </>
  );
}
