import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import { API_URL, validEmail } from "../../../../config";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data }) {
    const [sending, setSending] = useState(false);
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const compressedFile = await compressImage(selectedFile);
            const finalFile = new File([compressedFile], selectedFile.name, { type: compressedFile.type });

            setFile(finalFile);

            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(finalFile);
        }
    };

    const compressImage = async (image) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        };

        try {
            const compressedImage = await imageCompression(image, options);
            return compressedImage;
        } catch (error) {
            console.error("Image compression failed:", error);
            return image; // Return original image if compression fails
        }
    };

    const clearInput = () => {
        setFile(null);
        setImagePreview('');
        setEmail('');
        setFirstName('');
        setPhoneNumber('');
    };

    const onSubmit = () => {
        if (!isValidEmail) {
            toast.error("Please enter valid email");
        } else if (phoneNumber.length < 10) {
            toast.error("Please enter valid phone number");
        } else {
            setSending(true);
            const body = new FormData();
            // body.append('username', userName);
            body.append('profile_image', file ? file : 'https://d1xd1xn08h7rcw.cloudfront.net/system_files/user_image.png');
            body.append('email', email);
            body.append('first_name', firstName);
            body.append('phone_number', phoneNumber);

            let dataToken = JSON.parse(localStorage.getItem("persist:userInfo"));
            let token = `${JSON.parse(dataToken.authToken)}`;

            fetch(`${API_URL}delivery/v1/`, { body: body, method: "POST", headers: { Authorization: `Token ${token}` } }).then(response => {
                setSending(false);
                if (response.status) {
                    response.json().then(data => {
                        if (data.status) {
                            clearInput();
                            onHide();
                            onSuccess(data.message);
                        } else {
                            // onHide();
                            onError(data.message);
                        }
                    })
                }
            }).catch(error => {
                console.error('Something went wrong!', error);
            });
        }
    };

    const onPressClose = () => {
        clearInput();
        onHide();
    }

    return (
        <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add New Delivery Boy
                </Modal.Title>
                <button type="button" className="close ml-auto" onClick={onHide}>
                    ×
                </button>
            </Modal.Header>
            <Modal.Body className="pt-3 scroll-popup">
                <div>
                    <div className="modal-body">
                        <div className="row g-5 mb-0 align-items-center">
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Profile Picture</label>
                                <div className="d-flex justify-content-center">
                                    {imagePreview ? (
                                        <div className="">
                                            <div
                                                className="image-input image-input-outline"
                                                id="kt_profile_avatar"
                                                style={{
                                                    backgroundImage: "url(assets/media/users/blank.png)",
                                                }}
                                            >
                                                <div
                                                    className="image-input-wrapper"
                                                    style={{
                                                        backgroundImage: `url("${(imagePreview)}")`,
                                                    }}
                                                />
                                                <label
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Change avatar"
                                                >
                                                    <i className="fa fa-pen icon-sm text-muted" />
                                                    <input
                                                        type="file"
                                                        name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg"
                                                        // onChange={(e) => handleFileRead(e)}
                                                        onChange={handleFileChange}
                                                    />
                                                    <input type="hidden" name="profile_avatar_remove" />
                                                </label>

                                                <span
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Cancel avatar"
                                                >
                                                    <i className="ki ki-bold-close icon-xs text-muted" />
                                                </span>
                                                <span
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="remove"
                                                    data-toggle="tooltip"
                                                    title="Remove avatar"
                                                    onClick={() => {
                                                        setFile(null);
                                                        setImagePreview("");
                                                    }}
                                                >
                                                    <i className="ki ki-bold-close icon-xs text-muted" />
                                                </span>

                                            </div>
                                            <span className="form-text text-muted">
                                                Allowed file types: png, jpg, jpeg.
                                            </span>
                                        </div>
                                    ) :
                                        <input className="form-control" type="file" onChange={handleFileChange} />
                                    }
                                </div>
                            </div>
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Full Name </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => {
                                        // setEmail(e.target.value);

                                        const { value } = e.target;
                                        setEmail(value);
                                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        setIsValidEmail(e.target.value == '' ? true : emailPattern.test(value));
                                    }}
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Phone Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10)
                                            setPhoneNumber(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn custom-btn-light fs-7" onClick={onPressClose}>Close</button>
                        <button
                            type="button"
                            className="custom-button btn-primary fs-7"
                            onClick={onSubmit}
                            disabled={firstName && phoneNumber && !sending ? false : true}
                        >
                            Save
                        </button>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
}
