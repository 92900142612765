import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { sortCaret, toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Loader } from "../../../../components";
import { PER_PAGE, convertDate, sizePerPageList, getParams } from "../../../../config";
import { getApiData } from "../../../../Services";
import { CouponModal, DeleteCoupon } from "../Modal";
import Pagination from "react-js-pagination";

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [couponModal, setCouponModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isShowDelete, setIsShowDelete] = useState(false);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = async () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    await getApiData(`business/v1/promo-code/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      }
    });
  };

  const changeIsDefault = async (id, value) => {
    // setLoading(true);
    return await getApiData(`business/v1/promo-code/${id}/`, "put", {
      is_active: value,
    }).then((res) => {
      if (!res.status) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        getTableRecords();
      }
    });
  };

  return (
    <>
      <CouponModal
        show={couponModal || props.isPopupOpen}
        onHide={() => {
          setCouponModal(false);
          props.setIsPopupOpen(false);
          setIsShowDelete(false);
          setSelectedRow({});
        }}
        data={selectedRow}
        onSuccess={(message) => {
          setCouponModal(false);
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
        isDelete={isShowDelete}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {data.length !== 0 ?
            <>
              <div className="mt-5">
                {data.map((row, index) => (
                  <div className="row g-5 mb-5">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body p-5 position-relative">
                          <div className="ms-0 bg-gray-light rounded p-3 mb-2 d-flex">
                            <div className="symbol symbol-40px me-4">
                              <span className="symbol-label bg-skin-primary">
                                <i className="ki-duotone ki-discount text-primary fs-3x">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                </i>
                              </span>
                            </div>
                            <div className="ms-3">
                              <div className="fs-5">Promo Code: <b className="text-primary">{row?.promo_code ? row?.promo_code : "-"}</b></div>
                              <div className="fs-7">{row?.description ? row?.description : "-"}</div>
                            </div>
                          </div>
                          {row.start_date && <div className="fs-8 "><b>{convertDate(row.start_date)}</b> To <b>{convertDate(row.end_date)}</b></div>}
                          <div className="separator separator-dashed mt-4" />
                          <div className="d-flex flex-stack align-items-center mt-3">
                            <div>
                              <div className="fs-7 me-3">Credit Type: <b>
                                {
                                  row?.promo_type == 1 ?
                                    <span>{"Recharge"}</span>
                                    : row?.promo_type == 2 ?
                                      <span>{"Percentage"}</span>
                                      : row?.promo_type == 3 ?
                                        <span>{"Buy X Get Y"}</span>
                                        :
                                        <span>{"-"}</span>
                                }
                              </b></div>
                              <div className="fs-7">Discount: <b>
                                {
                                  row?.promo_type == 1 ?
                                    <span>{`₹ ${row?.promo_value ? row?.promo_value.toFixed(2) : "-"}`}</span>
                                    : row?.promo_type == 2 ?
                                      <span>{`${row?.promo_value ? row?.promo_value.toFixed(2) : "-"}%`}</span>
                                      :
                                      <span>{row?.promo_value ? row?.promo_value : "-"}</span>
                                }
                              </b></div>
                              <div className="fs-7 me-3">Maximum Discount Amount: <b>
                                {
                                  row?.promo_type == 1 ?
                                    <span>{`₹ ${row?.max_discount ? row?.max_discount : "-"}`}</span>
                                    : row?.promo_type == 2 ?
                                      <span>{`${row?.max_discount ? row?.max_discount : "-"}`}</span>
                                      :
                                      <span>{row?.max_discount ? row?.max_discount : "-"}</span>
                                }
                              </b></div>
                              <div className="fs-7">Minimum order amount: <b>{row?.minimum_cart_value}</b></div>
                            </div>
                            <div className="d-flex  align-items-center justify-content-end">
                              <div className="form-check form-switch form-check-custom form-check-solid me-3 mt-0">
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    changeIsDefault(row.id, e.target.checked)
                                  }}
                                  defaultChecked={row.is_active}
                                />
                                <label className="form-check-label" htmlFor="flexSwitch20x30" />
                              </div>
                              <a
                                className="d-flex"
                                href
                                onClick={() => {
                                  setCouponModal(true);
                                  setSelectedRow(row);
                                }}
                              >
                                <i className="ki-outline ki-pencil text-primary fs-2x ml-7" />
                              </a>
                              <button
                                className="btn btn-icon custom-btn-light btn-sm ms-3" style={{}}
                                onClick={() => {
                                  setIsShowDelete(true);
                                  setCouponModal(true);
                                  setSelectedRow(row);
                                }}>
                                <i className="ki-outline ki-trash fs-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div> */}
            </>
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
            </div>
          }
        </>
      )}
    </>
  );
}
