// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getApiData } from "../Services";
import { module } from "./../config";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUfQrHwxsDd9jQIpgv0HeB9_7HUpsGT8o",
  authDomain: "redose-f4f14.firebaseapp.com",
  projectId: "redose-f4f14",
  storageBucket: "redose-f4f14.appspot.com",
  messagingSenderId: "363963052663",
  appId: "1:363963052663:web:bd399a1bbbcbd2b1900c72",
  measurementId: "G-7Q0S1RJ62Q",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (authToken, switchUser = false) => {
  return getToken(messaging, {
    vapidKey:
      "BOFua-1Ol7n56zDH4HBK7WCiASFLDQW780ioIfESc5GkVJ9uZJws-JudDH_VhPgMAzDBkWY5ljbZp02ihDU2p2o",
  })
    .then((currentToken) => {
      if (
        currentToken &&
        (currentToken !== localStorage.getItem("device_token") || switchUser)
      ) {
        console.log("current device token for client: ", currentToken);

        var e = module.init();
        // console.log(e.os.name);
        const body = {
          device_uid: e.browser.version,
          device_name: e.browser.name,
          device_token: currentToken,
          device_model: navigator.vendor,
          manufacturer: e.os.name,
          os_version: e.os.version,
          device_type: "Web",
        };
        getApiData("v1/user/device/add", "post", body, true, authToken).then(
          (res) => {
            if (res.status) {
              localStorage.setItem("device_token", currentToken);
              console.log("Device register successfully");
            } else {
              console.log(res);
            }
          }
        );
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        console.log(currentToken);
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ");
      localStorage.removeItem("device_token");
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };

      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications");
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        notification.onclick = function(event) {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.open(payload.notification.click_action, "_blank");
          notification.close();
        };
      }

      resolve(payload);
    });
  });
