import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { getAreaByCity, getTapriByArea, getStateList } from "../../../config";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
import { getApiData } from "../../../Services";

export default function () {
  const history = useHistory();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
    city: "",
    area: "",
    tapri: "",
  });

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "state") {
      obj.city = null;
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        <TableFilter
          stateList={stateList}
          cityList={cityList}
          value={filters}
          onStateSelect={(selectedState) => {
            if (selectedState.city && selectedState.city.length) {
              updateFilter(selectedState.value, "state");
              let city = [];
              selectedState.city.map(
                (item) => (city = [...city, { label: item, value: item }])
              );
              setCityList(city);
            }
          }}
          onCitySelect={async (value) => {
            updateFilter(value, "city");
            // const area = await getAreaByCity(value);
            // setAreaList(area);
          }}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          onClearFilter={() => {
            setFilters({
              search: "",
              status: "",
              state: "",
              city: "",
              area: "",
              tapri: "",
            });
          }}
          togglePopup={togglePopup}
        />
        <Table filters={filters} isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />
      </div>
    </>
  );
}