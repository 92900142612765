import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { GeneralDropdown } from "../../../../components";
import { getApiData } from "../../../../Services";
import { gsts } from "../../../../config";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [selectedGST, setSelectedGST] = useState("");
  const [selectedExclusiveGst, setSelectedExclusiveGst] = useState("0");

  const exclusiveGst = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const onSubmit = () => {
    if (selectedGST !== "") {
      setSending(true);
      getApiData(
        `products/v1/actions/${selectedGST}/?is_exclusive_gst=${
          selectedExclusiveGst === "1" ? true : false
        }`
      ).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message);
        } else {
          toast.error(res.message);
        }
      });
    } else {
      toast.error("Please select GST value.");
    }
  };

  const onPressClose = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between">
          <Modal.Title>
            Bulk GST
            <small className="fs-10">
              {"  "}(The selected GST value will be applied to all products.)
            </small>
          </Modal.Title>
        </div>
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                GST
              </label>
              <GeneralDropdown
                className="form-control"
                selectedOption={selectedGST}
                list={gsts}
                handleChange={(obj) => {
                  setSelectedGST(obj.value);
                }}
                inModal
              />
            </div>
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Exclusive GST{" "}
                <small className="fs-10">
                  (If yes, we will collect the GST percentage from the customer)
                </small>
              </label>
              <GeneralDropdown
                className="form-control"
                selectedOption={selectedExclusiveGst}
                list={exclusiveGst}
                handleChange={(obj) => {
                  setSelectedExclusiveGst(obj.value);
                }}
                inModal
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn custom-btn-light fs-7"
            onClick={() => {
              onPressClose();
              // clearInput();
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
