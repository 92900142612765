import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Loader } from "../../../../components";
import {
  convertDate,
  getParams,
  timeSince,
  PER_PAGE,
  sizePerPageList,
} from "../../../../config";
import { getApiData } from "../../../../Services";
import * as auth from "./../../../../modules/Auth/_redux/authRedux";
import { ClusterModal, DeleteCluster } from "./../modals";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";

function Table({
  filters,
  chhotuList,
  riderList,
  spotList,
  productList,
  isPopupOpen,
  setIsPopupOpen,
}) {
  const history = useHistory();

  const [clusterModal, setClusterModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [filters]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(filters, 0, 0);

    getApiData(`cluster/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const changeStatus = async (id) => {
    return await getApiData(`cluster/v1/${id}/`, "delete").then((res) => {
      if (res.status) {
        toast.success(res.message);
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  return (
    <>
      <ClusterModal
        show={isPopupOpen || clusterModal}
        data={selectedRow}
        chhotuList={chhotuList}
        riderList={riderList}
        spotList={spotList}
        productList={productList}
        onHide={() => {
          setIsPopupOpen(false);
          setClusterModal(false);
          setSelectedRow({});
        }}
        onSuccess={(message) => {
          setIsPopupOpen(false);
          setClusterModal(false);
          toast.success(message);
          getTableRecords(filters);
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />

      <DeleteCluster
        show={deleteModal}
        title="Delete Cluster"
        isReason={true}
        message="Are you sure to want delete this cluster?"
        onHide={() => setDeleteModal(!deleteModal)}
        id={selectedRow.id}
        onSuccess={(message) => {
          getTableRecords(filters);
          toast.success(message);
        }}
        onFailed={(message) => {
          toast.error(message);
        }}
      />

      {loading ? (
        <Loader />
      ) : (
        <div>
          {data.length !== 0 ? (
            <>
              <div className="row g-5">
                <div className="col-md-12">
                  {data.map((item) => (
                    <div className="card mb-5">
                      <div className="card-body p-5 position-relative">
                        <div className="d-flex flex-stack align-items-center">
                          <div className=" d-flex align-items-center min-w-150px ">
                            <div className="ms-0  bg-gray-light rounded p-3 ">
                              <div className="fs-6">
                                Name: <b>{item?.name}</b>
                              </div>
                              <div className="fs-8">
                                Buildings:{" "}
                                <b className="text-primary">
                                  {
                                    <div>
                                      {item.buildings && item.buildings.length
                                        ? item.buildings.map((building) => {
                                            let wings =
                                              item?.wings[building.id];
                                            return (
                                              <li>
                                                {building.name}
                                                <span className="text-dark ml-2">
                                                  (
                                                  {wings?.length > 0
                                                    ? wings.join(", ")
                                                    : "No wings selected"}
                                                  )
                                                </span>
                                              </li>
                                            );
                                          })
                                        : null}
                                    </div>
                                  }
                                </b>
                              </div>

                              {/* <div className="fs-8">
                                Buildings:
                                <b className="text-primary ml-1">
                                  {item.buildings && item.buildings.length
                                    ? item.buildings.map((i) => {
                                      let wings = "";
                                      if (i.wings && i.wings.length) {
                                        wings = i.wings.join(", ");
                                      }
                                      return `${i.name}${wings ? ` (${wings})` : ""}`;
                                    }).join(", ")
                                    : null}
                                </b>
                              </div> */}
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            {item.delivery_boys.length !== 0 && (
                              <div className="min-w-150px ">
                                <div className="rounded fs-7 mb-1 text-right">
                                  Delivery Boy:
                                </div>
                                <div className="fs-7 text-primary fw-bold text-right">
                                  {
                                    <div>
                                      {item.delivery_boys.map((i) => {
                                        return <li>{i.name}</li>;
                                      })}
                                    </div>
                                  }
                                </div>
                              </div>
                            )}
                            <div className="fs-7 text-right mt-1">
                              Using TapRocket Delivery Services:{" "}
                              <b>{item.tr_delivery_boys ? "Yes" : "No"}</b>
                            </div>
                          </div>
                        </div>
                        <div className="separator separator-dashed mt-4" />
                        <div className=" row mt-3 align-items-center">
                          <div className="col-9">
                            <div className="fs-8 mb-1">
                              Updated At: <b>{convertDate(item.created_at)}</b>
                            </div>
                            <div className="fs-8 ">
                              Created At:{" "}
                              <b>
                                (
                                {new Date(item.created_at).toLocaleDateString()}
                                {", "}
                                {timeSince(item.created_at)})
                              </b>
                            </div>
                          </div>
                          <div className="col-3 my-1">
                            <div className="d-flex  align-items-center justify-content-end">
                              {/* <div className="form-check form-switch form-check-custom form-check-solid me-3 mt-0">
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  defaultChecked={item.is_active}
                                  onChange={(e) => {
                                    changeStatus(item.id, e.target.checked)
                                  }}
                                />
                                <label className="form-check-label" htmlFor="flexSwitch20x30" />
                              </div> */}
                              <a
                                className="d-flex"
                                href
                                onClick={() => {
                                  setClusterModal(true);
                                  setSelectedRow(item);
                                }}
                              >
                                <i className="ki-outline ki-pencil text-primary fs-2x ml-8" />
                              </a>
                              <button
                                className="btn btn-icon custom-btn-light btn-sm ms-3"
                                onClick={() => {
                                  setSelectedRow(item);
                                  setDeleteModal(true);
                                }}
                              >
                                <i className="ki-outline ki-trash fs-1" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-2 float-right mr-3">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={20}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={5}
                  itemClass="d-flex justify-content-between align-items-center flex-wrap"
                  linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                  activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={toAbsoluteUrl("/media/logos/no-data.png")}
                height={"150px"}
                width={"150px"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(Table));
