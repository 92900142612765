import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import ProductFilter from "./addProduct/ProductFilter";
import Table from "./Table/index";
import AddProduct from "./addProduct/index";
import { getApiData } from "../../../Services";

export default function (props) {
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [salesFilters, setSalesFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [merchantFilters, setMerchantFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [khataFilters, setKhataFilters] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    console.log("stored >>>>", JSON.parse(localStorage.getItem('websocket')));
  }, []);

  return (
    <>
      {/* <div className="">
        <TableFilter
          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}

          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}

          onClearFilter={() => {
            setFilters({
              search: "",
              filter: ""
            });
          }}
          getSelectedDay={getSelectedDay}
        />
      </div> */}

      <Table
        filters={filters}
        setFilters={(val) => setFilters(val)}

        salesFilters={salesFilters}
        setSalesFilters={(val) => setSalesFilters(val)}

        merchantFilters={merchantFilters}
        setMerchantFilters={(val) => setMerchantFilters(val)}

        khataFilters={khataFilters}
        setKhataFilters={(val) => setKhataFilters(val)}

      />

      {/* <AddProduct /> */}
    </>
  )
}