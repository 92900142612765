import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";
import toast from "react-hot-toast";
import { getAreaByCity } from "../../../../config";

export default function ({
  show,
  onHide,
  onSuccess,
  onFailed,
  data,
  stateList,
}) {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [salary, setSalary] = useState("");
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [deliveryBoyList, setDeliveryBoyList] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [selectedDeliveryBoys, setSelectedDeliveryBoys] = useState([]);
  const [customerIncentives, setCustomerIncentives] = useState(null);
  const [businessIncentives, setBusinessIncentives] = useState(null);
  const [ordersIncentives, setOrdersIncentives] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (show) {
      if (data && data.token) {
        setName(data.first_name);
        setPhone(data.phone_number);
        setSalary(data.basic_salary);
        setCustomerIncentives(JSON.stringify(data.customer_incentives));
        setBusinessIncentives(JSON.stringify(data.business_incentives));
        setOrdersIncentives(JSON.stringify(data.orders_incentives));
        // setSelectedState(data.state[0]);
        // setSelectedCity({ label: data.city[0], value: data.city[0] });
        // setSelectedCity(data.city);
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  const clearInput = () => {
    setName("");
    setPhone("");
    setSalary("");
    setCityList([]);
    setAreaList([]);
    setBuildingList([]);
    setBusinessList([]);
    setDeliveryBoyList([]);
    setSelectedAreas([]);
    setSelectedBuildings([]);
    setSelectedBusinesses([]);
    setSelectedDeliveryBoys([]);
    setCustomerIncentives("");
    setBusinessIncentives("");
    setOrdersIncentives("");
    setSelectedState(null);
    setSelectedCity(null);
  };

  useEffect(() => {
    if (stateList.length && data.state?.[0]) {
      stateList.map((item) => {
        if (item.value === data.state[0]) {
          // console.log(item);
          setSelectedState(item);
          return;
        }
      });
    }
  }, [stateList, data]);

  useEffect(() => {
    if (selectedState && selectedState.city && selectedState.city.length) {
      let city = [];
      selectedState.city.map((item) => {
        city = [...city, { label: item, value: item }];
      });
      setCityList(city);
    }
  }, [selectedState]);

  useEffect(() => {
    if (cityList.length && data.city?.[0]) {
      cityList.map((item) => {
        if (item.value === data.city[0]) {
          // console.log("City ");
          // console.log(item);
          setSelectedCity(item);
          return;
        }
      });
    }
  }, [cityList, data]);

  useEffect(() => {
    if (areaList?.length && data?.area?.length) {
      let selected = [];
      data.area.map((item) => {
        areaList.map((area) => {
          // console.log("area ");
          if (item.id === area.value) {
            // console.log(item.id + " === " + area.value);
            selected = [...selected, area];
          }
        });
      });
      // console.log("selected area");
      // console.log(selected);
      setSelectedAreas(selected);
    }
  }, [areaList, data]);

  useEffect(() => {
    if (buildingList?.length && data?.buildings?.length) {
      let selected = [];
      data.buildings.map((item) => {
        buildingList.map((elem) => {
          if (item.id === elem.value) {
            selected = [...selected, elem];
          }
        });
      });
      setSelectedBuildings(selected);
    }
  }, [buildingList, data]);

  useEffect(() => {
    if (businessList?.length && data?.business?.length) {
      let selected = [];
      data.business.map((item) => {
        businessList.map((elem) => {
          if (item.id === elem.value) {
            selected = [...selected, elem];
          }
        });
      });
      setSelectedBusinesses(selected);
    }
  }, [businessList, data]);

  useEffect(() => {
    if (deliveryBoyList?.length && data?.delivery_boy?.length) {
      let selected = [];
      data.delivery_boy.map((item) => {
        deliveryBoyList.map((elem) => {
          if (item.id === elem.value) {
            selected = [...selected, elem];
          }
        });
      });
      setSelectedDeliveryBoys(selected);
    }
  }, [deliveryBoyList, data]);

  useEffect(() => {
    if (selectedCity?.value) {
      (async () => {
        const area = await getAreaByCity(
          selectedCity.value,
          selectedState.value
        );
        setAreaList(area);
      })();
    } else {
      setAreaList([]);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedAreas?.length > 0) {
      getBuildings();
      getBusinesses();
      getDeliveryBoys();
    } else {
      setBuildingList([]);
      setBusinessList([]);
      setDeliveryBoyList([]);
      setSelectedBuildings([]);
      setSelectedBusinesses([]);
      setSelectedDeliveryBoys([]);
    }
  }, [selectedAreas]);

  const getArray = async (list) => {
    let array = list.map((item) => item.value);
    // console.log("array", array);
    return array;
  };

  const getBuildings = async () => {
    const areas = await getArray(selectedAreas);
    getApiData(`building/v1/?area_list=${JSON.stringify(areas)}`).then(
      (res) => {
        if (res.status) {
          let list = [];
          res.data.map((item) => {
            list = [...list, { label: item.name, value: item.id }];
          });
          setBuildingList(list);
        }
      }
    );
  };
  const getBusinesses = async () => {
    const areas = await getArray(selectedAreas);
    getApiData(`business/v1/?area_list=${JSON.stringify(areas)}`).then(
      (res) => {
        if (res.status) {
          let list = [];
          res.data.map((item) => {
            list = [...list, { label: item.first_name, value: item.id }];
          });
          setBusinessList(list);
        }
      }
    );
  };
  const getDeliveryBoys = async () => {
    const areas = await getArray(selectedAreas);
    getApiData(`delivery/v1/?area_list=${JSON.stringify(areas)}`).then(
      (res) => {
        if (res.status) {
          let list = [];
          res.data.map((item) => {
            list = [...list, { label: item.first_name, value: item.id }];
          });
          setDeliveryBoyList(list);
        }
      }
    );
  };

  const isValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (
      !name ||
      !phone ||
      phone.length !== 10 ||
      !salary ||
      !selectedAreas?.length ||
      !selectedBuildings?.length ||
      !selectedBusinesses?.length ||
      !selectedDeliveryBoys?.length ||
      !customerIncentives ||
      !isValidJson(customerIncentives) ||
      !businessIncentives ||
      !isValidJson(businessIncentives) ||
      !ordersIncentives ||
      !isValidJson(ordersIncentives)
    ) {
      if (!name) {
        toast.error("Please enter name");
      } else if (!phone) {
        toast.error("Please enter phone number");
      } else if (phone.length !== 10) {
        toast.error("Phone number must be 10 digits");
      } else if (!salary) {
        toast.error("Please enter salary");
      } else if (!selectedAreas?.length) {
        toast.error("Please select at least one area");
      } else if (!selectedBuildings?.length) {
        toast.error("Please select at least one building");
      } else if (!selectedBusinesses?.length) {
        toast.error("Please select at least one business");
      } else if (!selectedDeliveryBoys?.length) {
        toast.error("Please select at least one delivery boy");
      } else if (!customerIncentives) {
        toast.error("Please add customer incentives");
      } else if (!isValidJson(customerIncentives)) {
        toast.error("Customer incentives must be valid JSON");
      } else if (!businessIncentives) {
        toast.error("Please add business incentives");
      } else if (!isValidJson(businessIncentives)) {
        toast.error("Business incentives must be valid JSON");
      } else if (!ordersIncentives) {
        toast.error("Please add orders incentives");
      } else if (!isValidJson(ordersIncentives)) {
        toast.error("Orders incentives must be valid JSON");
      }
    } else {
      setSending(true);
      const areas_ = await getArray(selectedAreas);
      const buildings_ = await getArray(selectedBuildings);
      const business_ = await getArray(selectedBusinesses);
      const dBoys_ = await getArray(selectedDeliveryBoys);
      const body = {
        phone_number: phone,
        first_name: name,
        basic_salary: salary,
        area: areas_,
        buildings: buildings_,
        business: business_,
        delivery_boy: dBoys_,
        customer_incentives: JSON.parse(customerIncentives),
        business_incentives: JSON.parse(businessIncentives),
        orders_incentives: JSON.parse(ordersIncentives),
      };

      let method = data.id ? "put" : "post";
      let param = data.id ? data.id + "/" : "";
      getApiData(`manager/v1/${param}`, method, body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message, res.data);
        } else {
          onFailed(res.message);
        }
      });
    }
  };

  function onPressClose() {
    onHide();
  }

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Manage Area Manager</h3>
        </Modal.Title>
        <div
          className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onHide}
        >
          <i className="ki-outline ki-cross fs-1" />
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0">
                <label>
                  Manager Name <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0">
                <label>
                  Phone <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0">
                <label>
                  Basic Salary <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={salary}
                  onChange={(e) => {
                    setSalary(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Select State <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  selectedOption={selectedState}
                  list={stateList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedState(obj);
                    setSelectedCity(null);
                    setSelectedAreas(null);
                    if (obj && obj.city && obj.city.length) {
                      let city = [];
                      obj.city.map((item) => {
                        city = [...city, { label: item, value: item }];
                      });
                      setCityList(city);
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Select City <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  selectedOption={selectedCity}
                  list={cityList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedAreas(null);
                    setSelectedCity(obj);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Select Area <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  isMulti
                  selectedOption={selectedAreas}
                  list={areaList}
                  inModal
                  handleChange={(obj) => {
                    // console.log(obj);
                    setSelectedAreas(obj);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Select Buildings <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  isMulti
                  selectedOption={selectedBuildings}
                  list={buildingList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedBuildings(obj);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Select Businesses <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  isMulti
                  selectedOption={selectedBusinesses}
                  list={businessList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedBusinesses(obj);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Select Delivery boys <span style={{ color: "red" }}>*</span>
                </label>
                <GeneralDropdown
                  isMulti
                  selectedOption={selectedDeliveryBoys}
                  list={deliveryBoyList}
                  inModal
                  handleChange={(obj) => {
                    setSelectedDeliveryBoys(obj);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Customer Incentives <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={customerIncentives}
                  onChange={(e) => {
                    setCustomerIncentives(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Business Incentives <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={businessIncentives}
                  onChange={(e) => {
                    setBusinessIncentives(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group is-invalid mb-0 mt-5">
                <label>
                  Orders Incentives <span style={{ color: "red" }}>*</span>{" "}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  value={ordersIncentives}
                  onChange={(e) => {
                    setOrdersIncentives(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
          <button
            type="button"
            className="btn custom-btn-light fs-7"
            onClick={onPressClose}
          >
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
