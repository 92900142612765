import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { GeneralDropdown } from "../../../../components";
import { DateRangeSettings } from "../../../../config";

export default function (props) {
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [clearFilter, setClearFilter] = React.useState(null);

  const BusinessPartnerStatus = [
    { value: '0', label: 'Inactive' },
    { value: '1', label: 'Active' },
  ];

  const handleApply = (event, picker) => {
    props.setDate(
      picker.startDate.format("MM/DD/YYYY"),
      picker.endDate.format("MM/DD/YYYY")
    );
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  return (
    <>
      <div className="row g-2 mb-5">
        <div className="col-md-2">
          <div className="position-relative me-0 mb-0 mb-md-0 d-flex">
            <input
              type="text"
              className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              placeholder="Search by name or phone..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props.setSearchString(search);
                }
              }}
            />
            <div className="position-relative mb-0 mb-md-0 d-flex">
              <button className="btn custom-btn-light-primary btn-icon"
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onClick={() => {
                  props.setSearchString(search);
                }}>
                <i className="ki-outline ki-magnifier fs-2" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <GeneralDropdown
            className="form-select border-0 fs-7"
            placeholder="Status..."
            list={BusinessPartnerStatus}
            handleChange={(obj) => {
              props.setValue("status", obj.value);
            }}
            clearFilter={clearFilter}
          />
        </div>
        <div className="col-md-2">
          <GeneralDropdown
            placeholder="State..."
            clearFilter={clearFilter}
            selectedOption={props?.value?.state ? props?.value?.state : null}
            list={props.stateList}
            handleChange={(obj) => {
              props.onStateSelect(obj);
            }}
            inModal
          />
        </div>
        <div className="col-md-2">
          <GeneralDropdown
            placeholder="City..."
            clearFilter={clearFilter}
            selectedOption={props?.value?.city ? props?.value?.city : null}
            list={props.cityList}
            handleChange={(obj) => {
              props.onCitySelect(obj.value);
            }}
            inModal
          />
        </div>
        <div className="col-md-2">
          <GeneralDropdown
            placeholder="Area..."
            clearFilter={clearFilter}
            list={props.areaList}
            selectedOption={props?.value?.area ? props?.value?.area : null}
            handleChange={(obj) => {
              props.onAreaSelect(obj.value);
            }}
            inModal
          />
        </div>
        <div className="col-md-2">
          <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setClearFilter(Math.random());
              window.$("#date-range").val("");
            }}>
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
      </div>
    </>
  );
}
