import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import toast from "react-hot-toast";

export default function ({
    id,
    show,
    onHide,
    onSuccess,
    onFailed,
    title = "Delete Record",
    message = "Are you sure to permanently delete this Record?",
}) {
    const [sending, setSending] = useState(false);
    const [reason, setReason] = useState("");

    useEffect(() => {

    }, [id]);

    const deleteRecord = () => {
        setSending(true);
        if (id) {
            //   getApiData(`order/v1/history/${id}`, 'delete').then((res)=> {
            //     if(res.status){
            // setSending(false);
            // onSuccess(res.message);
            //     onHide();
            //     } else{
            //       onFailed();
            //     }
            //   })
            getApiData(`cluster/v1/${id}/`, "delete").then((res) => {
                if (res.status) {
                    setSending(false);
                    onSuccess(res.message);
                    onHide();
                } else {
                    onFailed();
                }
            })
        };
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="bottom-popup"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{message}</span>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="custom-custom-btn-light px-10"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={deleteRecord}
                        disabled={sending}
                        // className="btn btn-danger btn-elevate"
                        className="custom-button btn-primary font-weight-bold"
                    >
                        Delete
                        {sending && (
                            <Spinner animation="border" size="sm" className="ml-1" />
                        )}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
