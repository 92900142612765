import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [incentivePR, setIncentivePR] = useState("");
  const [maxIncentive, setMaxIncentive] = useState("");

  useEffect(() => {
    if (show) {
      if (data && data.id) {
        if (data.incentive) {
          let value = data.incentive.toString();
          value = value.replace("%", "");
          let numericValue = parseFloat(value);
          if (isNaN(numericValue)) {
            numericValue = "";
          } else if (numericValue > 100) {
            numericValue = 100;
          }
          setIncentivePR(numericValue !== "" ? `${numericValue}%` : "");
        } else {
          setIncentivePR("");
        }
        if (data.max_incentive) {
          let value = data.max_incentive.toString();
          value = value.replace("₹", "");
          let numericValue = parseFloat(value);
          if (isNaN(numericValue)) {
            numericValue = "";
          }
          setMaxIncentive(numericValue !== "" ? `${numericValue}₹` : "");
        } else {
          setMaxIncentive("");
        }
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  const clearInput = () => {
    setIncentivePR("");
    setMaxIncentive("");
  };

  const handlePRChange = (e) => {
    let value = e.target.value;
    value = value.replace("%", "");
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      numericValue = "";
    } else if (numericValue > 100) {
      numericValue = 100;
    }
    setIncentivePR(numericValue !== "" ? `${numericValue}%` : "");
  };

  const handlePRKeyDown = (e) => {
    if (e.key === "Backspace") {
      let value = incentivePR.replace("%", "");

      if (value.length === 1) {
        setIncentivePR("");
      } else {
        value = value.slice(0, -1);
        setIncentivePR(value ? `${value}%` : "");
      }
      e.preventDefault();
    }
  };

  const handleMaxChange = (e) => {
    let value = e.target.value;
    value = value.replace("₹", "");
    let numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      numericValue = "";
    }
    setMaxIncentive(numericValue !== "" ? `${numericValue}₹` : "");
  };

  const handleMaxKeyDown = (e) => {
    if (e.key === "Backspace") {
      let value = maxIncentive.replace("₹", "");
      if (value.length === 1) {
        setMaxIncentive("");
      } else {
        value = value.slice(0, -1);
        setMaxIncentive(value ? `${value}₹` : "");
      }
      e.preventDefault();
    }
  };

  const onSubmit = () => {
    if (!data.id) {
      toast.error("Something went wrong!");
    } else if (!incentivePR) {
      toast.error("Please add Incentive Percentage");
    } else if (!maxIncentive) {
      toast.error("Please add Max Incentive Amount");
    } else {
      setSending(true);
      const body = {
        incentive: incentivePR.slice(0, -1),
        max_incentive: maxIncentive.slice(0, -1),
      };
      getApiData(`delivery/v1/info/${data.id}/`, "put", body).then((res) => {
        setSending(false);
        if (res.status) {
          onHide();
          onSuccess(res.message);
        } else {
          onError(res.message);
        }
      });
    }
  };

  const onPressClose = () => {
    onHide();
  };

  return (
    <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Add Incentive</h3>
        </Modal.Title>
        <button
          type="button"
          className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2"
          onClick={onHide}
        >
          <i className="ki-outline ki-cross fs-1" />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Order Percentage (%){" "}
              </label>
              <input
                className="form-control"
                value={incentivePR}
                onChange={handlePRChange}
                onKeyDown={handlePRKeyDown}
                placeholder="Enter Order Percentage"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2 required">
                Max Amount (₹){" "}
              </label>
              <input
                className="form-control col-md-12"
                value={maxIncentive}
                onChange={handleMaxChange}
                onKeyDown={handleMaxKeyDown}
                placeholder="Enter Max Amount"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn custom-btn-light fs-7"
            onClick={onPressClose}
          >
            Close
          </button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onSubmit}
            disabled={!sending ? false : true}
          >
            Save{" "}
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
