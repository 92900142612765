import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";

export default function({ data, show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [sequence, setSequence] = useState("");

  useEffect(() => {
    if (data.index) {
      setSequence(data.index);
    }
  }, [data]);

  const onSubmit = () => {
    setSending(true);
    const body = {
      index: sequence,
    };

    getApiData(`banner/v1/${data.id}/?index=${sequence}`, "get", body).then((res) => {
      setSending(false);
      if (res.status) {
        onSuccess(res.message);
        onHide();
      } else {
        onError(res.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Change Sequence
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="row">
          <div className="form-group is-invalid mb-0">
            <label>Sequence</label>
            <input
              type="number"
              className="form-control"
              value={sequence}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue <= data.bannerLength) {
                    setSequence(inputValue);
                }
            }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="custom-custom-btn-light px-10"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            disabled={sequence && !sending ? false : true}
            style={{ width: "100px" }}
            className="btn-primary custom-button font-weight-bold"
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
