import React, { useState } from "react";
import { gradientColors } from "../../../../gradientColor";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { GeneralDropdown } from "../../../components";
import { DateRangeSettings } from "../../../config";

export default function (props) {
  const [search, setSearch] = useState("");
  const [clearFilter, setClearFilter] = React.useState(null);
  const [isShowAdvance, setIsShowAdvance] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleApply = (event, picker) => {
    props.setDate(
      picker.startDate.format("MM/DD/YYYY"),
      picker.endDate.format("MM/DD/YYYY")
    );
    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const onClickAdvance = () => {
    setIsShowAdvance(!isShowAdvance);
  }

  return (
    <>
      <div className=" d-flex gap-4 mb-5">
        <div className="min-w-125px">
          <div className="d-flex justify-content-center align-items-center bg-white py-2 ps-2" style={{ borderRadius: 5 }}>
            <div className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                onChange={(e) => {
                  props.setValue("credit_users", e.target.checked);
                  setIsChecked(prevState => !prevState);
                }}
                checked={isChecked}
              />
              <label className="fs-7 mb-0 mt-1" style={{ color: '#7e8299', marginLeft: '5px' }}>Credit User</label>
            </div>
          </div>
        </div>

        <div className="min-w-125px ">
          <div className="input-group">
            <DateRangePicker
              initialSettings={DateRangeSettings}
              onApply={handleApply}
              onCancel={handleCancel}
              max
            >
              <input
                type="text"
                className="form-control"
                placeholder="Select date range"
                id="date-range"
                autoComplete="off"
              />
            </DateRangePicker>
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="far fa-calendar-check" />
              </span>
            </div>
          </div>
        </div>
        <div className="position-relative me-0 mb-0 mb-md-0 w-100 d-flex">
          <input
            type="text"
            className="form-control border-0 text-dark fs-7 all-search border border-gray-300"
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            placeholder="Search by customer name or phone..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setSearchString(search);
              }
            }}
          />
          <div className="position-relative mb-0 mb-md-0 d-flex">
            <button className="btn custom-btn-light-primary btn-icon"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => {
                props.setSearchString(search);
              }}>
              <i className="ki-outline ki-magnifier fs-2" />
            </button>
          </div>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button
            className="btn custom-btn-light-primary btn-icon w-35px h-35px"
            onClick={() => {
              props.onClearFilter();
              setSearch("");
              setClearFilter(Math.random());
              setIsChecked(false);
              window.$("#date-range").val("");
            }}
          >
            <i className="ki-outline ki-arrows-circle fs-2" />
          </button>
        </div>
        <div className="position-relative mb-0 mb-md-0 d-flex">
          <button className="btn btn-primary btn-icon w-35px h-35px" onClick={onClickAdvance}>
            <i className="ki-outline ki-text-align-right fs-2" />
          </button>
        </div>
        {isShowAdvance &&
          <div className="sidebar-container">
            <div className="sidebar" style={{}}>
              <div className="card w-100 rounded-0">
                <div className="card-header pe-5">
                  <div className="card-title">
                    <div className="d-flex justify-content-center flex-column me-3">
                      <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1">Advanced Filter</a>
                    </div>
                  </div>
                  <div className="card-toolbar">
                    <button onClick={onClickAdvance} className="btn btn-sm btn-icon btn-active-light-primary">
                      <i className="ki-outline ki-cross fs-1" />
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Business Partner</label>
                    <GeneralDropdown
                      className="form-select border-0 fs-7"
                      list={props.businessList}
                      handleChange={(obj) => {
                        props.setValue("business", obj.value);
                      }}
                      selectedOption={props?.value?.business ? props?.value?.business : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">State</label>
                    <GeneralDropdown
                      clearFilter={clearFilter}
                      selectedOption={props?.value?.state ? props?.value?.state : null}
                      list={props.stateList}
                      handleChange={(obj) => {
                        props.onStateSelect(obj);
                      }}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">City</label>
                    <GeneralDropdown
                      clearFilter={clearFilter}
                      selectedOption={props?.value?.city ? props?.value?.city : null}
                      list={props.cityList}
                      handleChange={(obj) => {
                        props.onCitySelect(obj.value);
                      }}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Area</label>
                    <GeneralDropdown
                      clearFilter={clearFilter}
                      list={props.areaList}
                      selectedOption={props?.value?.area ? props?.value?.area : null}
                      handleChange={(obj) => {
                        props.onAreaSelect(obj.value);
                      }}
                      inModal
                    />
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Building</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.buildingList}
                      handleChange={(obj) => {
                        props.onBuildingSelect(obj.value);
                      }}
                      selectedOption={props.value.building ? props.value.building : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Wing</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.wingList}
                      handleChange={(obj) => {
                        props.onWingSelect(obj.value);
                      }}
                      selectedOption={props.value.wing ? props.value.wing : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Floor</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.floorList}
                      handleChange={(obj) => {
                        props.onFloorSelect(obj.value);
                      }}
                      selectedOption={props.value.floor ? props.value.floor : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">Office</label>
                    <GeneralDropdown
                      className="form-select fs-7 "
                      list={props.officeList}
                      handleChange={(obj) => {
                        props.onOfficeSelect(obj.value, "office");
                      }}
                      selectedOption={props.value.office ? props.value.office : null}
                      clearFilter={clearFilter}
                      inModal
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
