import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { getStateList } from "../../config";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    state: "",
    city: "",
  });

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "state") {
      obj.city = null;
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        {/* <Card className="card-spaces">
          <CardBody className="p-4">
            <TableFilter
              stateList={stateList}
              cityList={cityList}
              value={filters}
              onStateSelect={(selectedState) => {
                if (selectedState.city && selectedState.city.length) {
                  updateFilter(selectedState.value, "state");
                  let city = [];
                  selectedState.city.map((item) => {
                    city = [...city, { label: item, value: item }];
                  });
                  setCityList(city);
                }
              }}
              onCitySelect={async (value) => {
                updateFilter(value, "city");
              }}
              setSearchString={(value) => {
                let obj = { ...filters };
                obj.search = value;
                setFilters(obj);
              }}
              onClearFilter={() => {
                setFilters({
                  search: "",
                  state: "",
                  city: "",
                });
              }}
            />
          </CardBody>
        </Card> */}
        <Table filters={filters} />
      </div>
    </>
  );
}
