import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
import { getApiData } from "../../Services";

export default function () {
  const [filters, setFilters] = useState({
    search: "",
    filter: "",
    business: "",
  });
  const [businessList, setBusinessList] = useState([]);

  useEffect(() => {
    getBusinessList();
  }, []);

  const getBusinessList = () => {
    getApiData(`business/v1`).then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setBusinessList(array);
      }
    })
  }

  return (
    <>
      <div>
        <TableFilter
          businessList={businessList}

          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}

          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}

          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
              business: "",
            });
          }}
        />
        <Table filters={filters} />
      </div>
    </>
  );
}
