import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";

export default function ({
    show,
    onHide,
    data,
    onSuccess,
    onError,
}) {
    const [sending, setSending] = useState(false);
    const [amount, setAmount] = useState("");

    useEffect(() => {
        console.log("data >>>>", data);
    }, [data]);

    const AddBonus = () => {
        if (data) {
            if (amount == 0 || amount < 0 || !amount) {
                toast.error("Please enter valid amount");
            } else if (amount > 500) {
                toast.error("Please enter an amount of ₹500 or less.")
            } else {
                setSending(true);

                const body = {
                    bonus_amount: amount
                };
                getApiData(`customer/v1/credit/${data.id}/`, 'put', body).then((res) => {
                    setSending(false);
                    if (res.status) {
                        onSuccess(res.message);
                        setAmount("");
                    } else {
                        onError(res.message);
                    }
                })
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
            dialogClassName="bottom-popup"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">{"Add Bonus"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <label className="fs-8 fw-semibold">Amount</label>
                        <input
                            className="form-control"
                            placeholder="Please enter amount"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e?.target?.value);
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="custom-custom-btn-light px-10"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={AddBonus}
                        disabled={sending}
                        className="custom-button btn-primary font-weight-bold"
                    >
                        Save
                        {sending && (
                            <Spinner animation="border" size="sm" className="ml-1" />
                        )}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
