import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { useHistory } from 'react-router-dom';
import { PER_PAGE, sizePerPageList, getParams, NumberWithCommas } from "../../../../config";
import Chart from "react-apexcharts";
import { getApiData } from "../../../../Services";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DateRangeSettings } from "../../../../config";
import { LoaderPopup } from '../../../../components';
import { CSVModal } from "../modals";

function UserTable(props) {
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dashboardData, setDashboardData] = useState({});

  const [salesLoading, setSalesLoading] = useState(false);
  const [totalSales, setTotalSales] = useState(null);

  const [merchantLoading, setMerchantLoading] = useState(false);
  const [merchantSummary, setMerchantSummary] = useState(null);

  const [khataLoading, setKhataLoading] = useState(false);
  const [khataBalance, setKhataBalance] = useState(null);

  const [showCSVModal, setShowCSVModal] = useState(false);

  const [dates, setDates] = useState([]);
  const [cashColumn, setCashColumn] = useState([]);
  const [creditColumn, setCreditColumn] = useState([]);
  const [onlineColumn, setOnlineColumn] = useState([]);
  const [salesTotal, setSalesTotal] = useState([]);

  const [selectedDay, setSelectedDay] = useState("");

  const user = useSelector((state) => state.auth.user, shallowEqual);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [props.filters]);

  useEffect(() => {
    setSalesLoading(true);
    getSalesRecords();
  }, [props.salesFilters]);

  useEffect(() => {
    setMerchantLoading(true);
    getMerchantRecords();
  }, [props.merchantFilters]);

  useEffect(() => {
    setKhataLoading(true);
    getKhataRecords();
  }, [props.khataFilters]);

  const getTableRecords = () => {

    const params = getParams(props.filters, currentPage, sizePerPage);
    getApiData(`business/v1/dashboard/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(res.data);
        setDashboardData(res);

        const datesArray = [];
        const cashArray = [];
        const creditArray = [];
        const onlineArray = [];
        const salesArray = [];

        Object.keys(res.graph_data).forEach(date => {
          datesArray.push(date);
          cashArray.push(res.graph_data[date].cash);
          creditArray.push(res.graph_data[date].credit);
          onlineArray.push(res.graph_data[date].online);
          salesArray.push(res.graph_data[date].total_sales);
        });

        setDates(datesArray);
        setCashColumn(cashArray.map((i) => Math.floor(i)));
        setCreditColumn(creditArray.map((i) => Math.floor(i)));
        setOnlineColumn(onlineArray.map((i) => Math.floor(i)));
        setSalesTotal(salesArray.map((i) => Math.floor(i)));
      }
    });
  };

  const getSalesRecords = () => {
    const params = getParams(props.salesFilters, currentPage, sizePerPage);
    getApiData(`business/v1/admin-dashboard/total_sales${params}`).then((res) => {
      setSalesLoading(false);
      if (res.status) {
        setTotalSales(res?.data);
      }
    });
  }

  const getMerchantRecords = () => {
    const params = getParams(props.merchantFilters, currentPage, sizePerPage);
    getApiData(`business/v1/admin-dashboard/merchant_summary${params}`).then((res) => {
      setMerchantLoading(false);
      if (res.status) {
        setMerchantSummary(res?.data);
      }
    });
  }

  const getKhataRecords = () => {
    const params = getParams(props.khataFilters, currentPage, sizePerPage);
    getApiData(`business/v1/admin-dashboard/khata_balance${params}`).then((res) => {
      setKhataLoading(false);
      if (res.status) {
        setKhataBalance(res?.data);
      }
    });
  }

  const handleApplyTotalSales = (event, picker) => {
    let obj = { ...props.salesFilters };
    obj.startDate = picker.startDate.format("MM/DD/YYYY");
    obj.endDate = picker.endDate.format("MM/DD/YYYY");
    props.setSalesFilters(obj);

    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };

  const handleApplyEarning = (event, picker) => {
    let obj = { ...props.merchantFilters };
    obj.startDate = picker.startDate.format("MM/DD/YYYY");
    obj.endDate = picker.endDate.format("MM/DD/YYYY");
    props.setMerchantFilters(obj);

    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };

  const handleApplyOrders = (event, picker) => {
    let obj = { ...props.khataFilters };
    obj.startDate = picker.startDate.format("MM/DD/YYYY");
    obj.endDate = picker.endDate.format("MM/DD/YYYY");
    props.setKhataFilters(obj);

    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };

  const handleApply = (event, picker) => {
    let customDate = picker.chosenLabel === "Custom Range" && `${picker.startDate.format("MM/DD/YYYY")} To ${picker.endDate.format("MM/DD/YYYY")}`;

    getSelectedDay(picker.chosenLabel === "Custom Range" ? customDate : picker.chosenLabel);


    let obj = { ...props.filters };
    obj.startDate = picker.startDate.format("MM/DD/YYYY");
    obj.endDate = picker.endDate.format("MM/DD/YYYY");
    props.setFilters(obj);

    picker.element.val(
      picker.startDate.format("MM/DD/YYYY") +
      " - " +
      picker.endDate.format("MM/DD/YYYY")
    );
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  }

  const getSelectedDay = (day) => {
    setSelectedDay(day);
  }

  return (
    <>
      <CSVModal
        show={showCSVModal}
        onHide={() => setShowCSVModal(false)}
        data={user}
      />
      <div id="kt_app_content_container" className="">
        <div className="d-flex align-items-center justify-content-end">
          <button
            className="custom-button btn mb-3 py-2"
            onClick={() => setShowCSVModal(true)}
          >
            Export CSV
          </button>
        </div>
        <div className="row g-2">
          <div className="col-12">
            <div className="flex-column w-100">
              <div className="row g-5 mb-5">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-3 d-flex flex-column w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Total Sales</h3>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-4 mb-5">
                            <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                              <div className="input-group">
                                <DateRangePicker
                                  initialSettings={DateRangeSettings}
                                  onApply={handleApplyTotalSales}
                                  onCancel={handleCancel}
                                  max
                                >
                                  <input
                                    type="text"
                                    className="form-control z-index-9"
                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                    placeholder="Select date range"
                                    id="date-range"
                                    autoComplete="off"
                                  />
                                </DateRangePicker>
                                <div className="input-group-append">
                                  <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                      <span className="path1" />
                                      <span className="path2" />
                                      <span className="path3" />
                                      <span className="path4" />
                                      <span className="path5" />
                                      <span className="path6" />
                                    </i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="position-relative mb-0 mb-md-0 d-flex">
                              <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                onClick={() => {
                                  props.setSalesFilters({
                                    search: "",
                                    filter: "",
                                    startDate: "",
                                    endDate: "",
                                  });
                                  window.$("#date-range").val("");
                                }}>
                                <i className="ki-outline ki-arrows-circle fs-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {salesLoading ? (
                        <LoaderPopup />
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-2 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Online</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{totalSales?.online ? totalSales.online.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">COD (SD)</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{totalSales?.cod_sd ? totalSales?.cod_sd.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">COD (T/R)</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{totalSales?.cod_tr ? totalSales?.cod_tr.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">khata</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{totalSales?.business_credit ? totalSales?.business_credit.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" {...props}>
                                  <path d="M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 00-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 00-11.3 0L266.3 586.7a8.03 8.03 0 000 11.3l39.5 39.7z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6"><b>Total Sales</b></span>
                            </div>
                            <h3 className="fw-bold text-dark fs-2">
                              ₹{totalSales?.total_sales ? totalSales.total_sales.toLocaleString() : 0}
                            </h3>
                          </div>
                        </>
                      )
                      }
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="card" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/wave-bg-dark.svg')})`, backgroundColor: '#eb3a22', backgroundPosition: 'right', backgroundRepeat: 'no-repeat' }}>
                      <div className="card-body p-3 d-flex  w-100">
                        <div className="symbol symbol-45px me-4">
                          <span className="symbol-label" style={{ backgroundColor: '#ffffff42' }}>
                            <i className="ki-outline ki-wallet fs-3x text-gray-200" />
                          </span>
                        </div>
                        <div className="w-50">
                          <span className="fw-semibold d-block text-gray-200 mb-1 fs-6">{`Sales ${selectedDay ? selectedDay : 'Today'}`}</span>
                          <h3 className="card-title fw-bolder text-gray-200 mb-0 fs-2 mb-1">{`₹${dashboardData?.today_sales ? dashboardData?.today_sales.toLocaleString() : 0}`}</h3>
                          <span className="text-gray-200">{"("}<span className="text-gray-200 fw-bold">{`₹${dashboardData?.previous_day_sales ? dashboardData?.previous_day_sales.toLocaleString() : 0}`}</span>{" Yesterday)"}</span>
                        </div>
                        <div className="w-50">
                          <div className="d-flex justify-content-end">
                            <div className="flex-column text-right">
                              <span className="fw-semibold d-block mb-1 text-gray-200 fs-6">Sales This Month</span>
                              <h3 className="card-title fw-bolder text-gray-200 mb-0 fs-2 mb-1">{`₹${dashboardData?.current_month_sales ? dashboardData?.current_month_sales.toLocaleString('en-US') : 0}`}</h3>
                              <span className="text-light mt-1">
                                <span className="text-gray-200">{"("}<span className="text-gray-200 fw-bold">{`₹${dashboardData?.previous_month_sales ? dashboardData?.previous_month_sales.toLocaleString('en-US') : 0}`}</span>{" Last month)"}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-3 d-flex flex-column w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Merchant Summary</h3>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-4 mb-5">
                            <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                              <div className="input-group">
                                <DateRangePicker
                                  initialSettings={DateRangeSettings}
                                  onApply={handleApplyEarning}
                                  onCancel={handleCancel}
                                  max
                                >
                                  <input
                                    type="text"
                                    className="form-control z-index-9"
                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                    placeholder="Select date range"
                                    id="date-range-earning"
                                    autoComplete="off"
                                  />
                                </DateRangePicker>
                                <div className="input-group-append">
                                  <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                      <span className="path1" />
                                      <span className="path2" />
                                      <span className="path3" />
                                      <span className="path4" />
                                      <span className="path5" />
                                      <span className="path6" />
                                    </i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="position-relative mb-0 mb-md-0 d-flex">
                              <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                onClick={() => {
                                  props.setMerchantFilters({
                                    search: "",
                                    filter: "",
                                    startDate: "",
                                    endDate: "",
                                  });
                                  window.$("#date-range-earning").val("");
                                }}>
                                <i className="ki-outline ki-arrows-circle fs-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {merchantLoading ? (
                        <LoaderPopup />
                      ) : (
                        <>
                          <div className="pb-13">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6">Total Sales</span>
                              </div>
                              <h3 className="fw-bold text-700 fs-3">
                                ₹{merchantSummary?.total_sales ? merchantSummary?.total_sales.toLocaleString() : 0}
                              </h3>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                    <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6">Refunds Initiated</span>
                              </div>
                              <h3 className="fw-bold text-700 fs-3">
                                ₹{merchantSummary?.refunds_initiated ? merchantSummary?.refunds_initiated.toLocaleString() : 0}
                              </h3>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                    <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6">Self Delivery Earning</span>
                              </div>
                              <h3 className="fw-bold text-700 fs-3">
                                ₹{merchantSummary?.self_delivery_earning ? merchantSummary?.self_delivery_earning.toLocaleString() : 0}
                              </h3>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                    <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6">Taprocket Fees</span>
                              </div>
                              <h3 className="fw-bold text-700 fs-3">
                                ₹{merchantSummary?.taprocket_fees ? merchantSummary?.taprocket_fees.toLocaleString() : 0}
                              </h3>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                    <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6">Direct Cash Collection</span>
                              </div>
                              <h3 className="fw-bold text-700 fs-3">
                                ₹{merchantSummary?.direct_cash_collection ? merchantSummary?.direct_cash_collection.toLocaleString() : 0}
                              </h3>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                                  <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                    <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6">Khata Earning (Incl. Delivery Charge)</span>
                              </div>
                              <h3 className="fw-bold text-700 fs-3">
                                ₹{merchantSummary?.business_credit ? merchantSummary?.business_credit.toLocaleString() : 0}
                              </h3>
                            </div>
                          </div>

                          <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
                                  <path d="M13 6h2v11h-2zm4-3h2v14h-2zM9 9h2v8H9zM4 19h16v2H4zm1-7h2v5H5z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6"><b>Next Settlement Amount</b></span>
                            </div>
                            <h3 className="fw-bold text-dark fs-2">
                              ₹{merchantSummary?.next_settlement_amount ? merchantSummary?.next_settlement_amount.toLocaleString() : 0}
                            </h3>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body p-3 d-flex flex-column w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="card-title fw-bold mb-0 fs-4 d-flex align-items-center me-4 mb-5">Khata Summary</h3>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex gap-4 mb-5">
                            <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7 " style={{ backgroundColor: "#F5F5F5" }}>
                              <div className="input-group">
                                <DateRangePicker
                                  initialSettings={DateRangeSettings}
                                  onApply={handleApplyOrders}
                                  onCancel={handleCancel}
                                  max
                                >
                                  <input
                                    type="text"
                                    className="form-control z-index-9"
                                    style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                                    placeholder="Select date range"
                                    id="date-range-order"
                                    autoComplete="off"
                                  />
                                </DateRangePicker>
                                <div className="input-group-append">
                                  <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                      <span className="path1" />
                                      <span className="path2" />
                                      <span className="path3" />
                                      <span className="path4" />
                                      <span className="path5" />
                                      <span className="path6" />
                                    </i>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="position-relative mb-0 mb-md-0 d-flex">
                              <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                                onClick={() => {
                                  props.setKhataFilters({
                                    search: "",
                                    filter: "",
                                    startDate: "",
                                    endDate: "",
                                  });
                                  window.$("#date-range-order").val("");
                                }}>
                                <i className="ki-outline ki-arrows-circle fs-2" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {khataLoading ? (
                        <LoaderPopup />
                      ) : (
                        <>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Opening Khata Balance</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{khataBalance?.opening_khata_balance ? khataBalance?.opening_khata_balance.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Khata Limit Added</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{khataBalance?.khata_limit_added ? khataBalance?.khata_limit_added.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Khata Settlement Received</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{khataBalance?.khata_settlement_received ? khataBalance?.khata_settlement_received.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Refund Balance Added</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{khataBalance?.refund_balance_added ? khataBalance?.refund_balance_added.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Khata Limit Removed</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{khataBalance?.khata_limit_removed ? khataBalance?.khata_limit_removed.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-center">
                              <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'red' }}>
                                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                  <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                                </svg>
                              </span>
                              <span className="fw-semibold d-block mb-1 text-800 fs-6">Khata Limit Utilised</span>
                            </div>
                            <h3 className="fw-bold text-700 fs-3">
                              ₹{khataBalance?.khata_limit_utilised ? khataBalance?.khata_limit_utilised.toLocaleString() : 0}
                            </h3>
                          </div>

                          <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                          <Link to="/order-history" className="navi-item">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center justify-content-center">
                                <span className="mr-2 mb-2 fs-3 w-15" style={{ color: 'green' }}>
                                  <svg
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    height="1em"
                                    width="1em"
                                    {...props}
                                  >
                                    <path d="M4 6H2v14a2 2 0 002 2h14v-2H4V6m14.5 8.25c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V15h9M14 10.25c1.24 0 2.25-1.01 2.25-2.25S15.24 5.75 14 5.75 11.75 6.76 11.75 8s1.01 2.25 2.25 2.25M20 2H8c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12c1.11 0 2-.89 2-2V4a2 2 0 00-2-2m0 14H8V4h12v12z" />
                                  </svg>
                                </span>
                                <span className="fw-semibold d-block mb-1 text-800 fs-6"><b>Net Fundflow</b></span>
                              </div>
                              <h3 className="fw-bold text-dark fs-2">
                                ₹{khataBalance?.net_fundflow ? khataBalance?.net_fundflow.toLocaleString() : 0}
                              </h3>
                            </div>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <Link to="/customers-business" className="navi-item">
                      <div className="card">
                        <div className="card-body p-3 d-flex  w-100 align-items-center justify-content-center">
                          <div className="symbol symbol-45px me-4">
                            <span className="symbol-label bg-gray-light-common fs-3x text-primary">
                              <svg
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                height="1em"
                                width="1em"
                              >
                                <path d="M4 6H2v14a2 2 0 002 2h14v-2H4V6m14.5 8.25c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V15h9M14 10.25c1.24 0 2.25-1.01 2.25-2.25S15.24 5.75 14 5.75 11.75 6.76 11.75 8s1.01 2.25 2.25 2.25M20 2H8c-1.1 0-2 .9-2 2v12a2 2 0 002 2h12c1.11 0 2-.89 2-2V4a2 2 0 00-2-2m0 14H8V4h12v12z" />
                              </svg>
                            </span>
                          </div>
                          <div className=" w-50">
                            <span className="fw-semibold d-block mb-1 text-600 fs-6">Customers</span>
                            <h3 className="card-title fw-bolder mb-0 fs-2">{dashboardData?.customers ? dashboardData?.customers : 0}</h3>
                          </div>
                          <div className=" w-50">
                            <div className="d-flex justify-content-end">
                              <div className="flex-column text-right">
                                <span className="fw-semibold d-block mb-1 text-600 fs-6">Khata Customers</span>
                                <h3 className="card-title fw-bolder mb-0 fs-2">{dashboardData?.credit_customers ? dashboardData?.credit_customers : 0}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header min-h-50px px-4 border-0">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title fw-bold mb-0 fs-4 d-flex align-items-center ">Line Graph</h3>
                  <div className="d-flex align-items-center justify-content-between ">
                    <div className="d-flex">
                      <div className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7" style={{ backgroundColor: "#F5F5F5" }}>
                        <div className="input-group">
                          <DateRangePicker
                            initialSettings={DateRangeSettings}
                            onApply={handleApply}
                            onCancel={handleCancel}
                            max
                          >
                            <input
                              type="text"
                              className="form-control z-index-9"
                              style={{ borderWidth: 0, backgroundColor: 'transparent', height: "12px", }}
                              placeholder="Select date range"
                              id="date-range-graph"
                              autoComplete="off"
                            />
                          </DateRangePicker>
                          <div className="input-group-append">
                            <span className="input-group-text" style={{ borderWidth: 0, backgroundColor: 'transparent' }}>
                              <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                                <span className="path1" />
                                <span className="path2" />
                                <span className="path3" />
                                <span className="path4" />
                                <span className="path5" />
                                <span className="path6" />
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="position-relative mb-0 mb-md-0 d-flex ml-2">
                        <button className="btn custom-btn-light-primary btn-icon w-35px h-35px"
                          onClick={() => {
                            props.setFilters({
                              search: "",
                              filter: "",
                              startDate: "",
                              endDate: "",
                            });
                            window.$("#date-range-graph").val("");
                          }}>
                          <i className="ki-outline ki-arrows-circle fs-2" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0 pb-0" style={{ padding: 0 }}>
                <div className="card-body pt-0 pb-0">
                  {loading ? (
                    <LoaderPopup />
                  ) : (
                    <Chart
                      options={{
                        chart: {
                          height: 350, type: 'line',
                          dropShadow: { enabled: true, color: '#000', top: 18, left: 7, blur: 10, opacity: 0.2, },
                          zoom: { enabled: false },
                          toolbar: { show: false },
                        },
                        colors: ["#ccc", "#071437", "rgb(120, 130, 157)", "rgb(235, 58, 34)"],
                        dataLabels: { enabled: true, },
                        stroke: { curve: 'smooth' },
                        grid: {
                          borderColor: '#e7e7e7',
                          row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 },
                          padding: { left: 20, right: 20, },
                        },
                        markers: { size: 1 },
                        xaxis: { categories: dates, },
                      }}
                      series={[
                        { name: 'Cash', data: cashColumn },
                        { name: 'Credit', data: creditColumn },
                        { name: 'Online', data: onlineColumn },
                        { name: 'Total Sales', data: salesTotal },
                      ]}
                      type="line"
                      height={350}
                    />)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-2 mb-5 px-4">
          <div className="" style={{ width: "33.33%" }}>
            <Link to="/delivery-boy" className="navi-item">
              <div className="card card-flush h-md-100" style={{ borderTop: '2px solid #eb3a22' }}>
                <div className="card-body p-3 text-center pt-5 pb-4">
                  <div className="symbol symbol-45px me-0">
                    <span className="symbol-label bg-gray-light-common">
                      <i className="ki-outline ki-profile-user fs-3x text-primary" />
                    </span>
                  </div>
                  <h3 className="card-title fw-bolder mb-0 fs-2 mt-2 mb-1">{dashboardData ? dashboardData?.delivery_boys : 0}</h3>
                  <a href className="fw-bold text-center">Manage <br />Delivery Boys</a>
                </div>
              </div>
            </Link>
          </div>

          <div className="" style={{ width: "33.33%" }}>
            <Link to="/products" className="navi-item">
              <div className="card card-flush h-md-100" style={{ borderTop: '2px solid #eb3a22' }}>
                <div className="card-body p-3 text-center pt-5 pb-4">
                  <div className="symbol symbol-45px me-0">
                    <span className="symbol-label bg-gray-light-common">
                      <i className="ki-outline ki-lots-shopping fs-3x text-primary" />
                    </span>
                  </div>
                  <h3 className="card-title fw-bolder mb-0 fs-2 mt-2 mb-1">{dashboardData ? dashboardData?.products : 0}</h3>
                  <a href className="fw-bold text-center">Manage <br />Products</a>
                </div>
              </div>
            </Link>
          </div>

          <div className="" style={{ width: "33.33%" }}>
            <Link to="/cluster" className="navi-item">
              <div className="card card-flush h-md-100" style={{ borderTop: '2px solid #eb3a22' }}>
                <div className="card-body p-3 text-center pt-5 pb-4">
                  <div className="symbol symbol-45px me-0">
                    <span className="symbol-label bg-gray-light-common">
                      <i className="ki-outline ki-grid-frame fs-3x text-primary" />
                    </span>
                  </div>
                  <h3 className="card-title fw-bolder mb-0 fs-2 mt-2 mb-1">{dashboardData ? dashboardData?.buildings : 0}</h3>
                  <a href className="fw-bold text-center">Manage <br />Buildings</a>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row g-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header min-h-50px px-4">
                <h3 className="card-title fw-bold mb-0 fs-4 d-flex align-items-center ">Schedule Order</h3>
                <div className="card-toolbar">
                </div>
              </div>

              <div className="card-body p-3 pe-5">
                {data.length !== 0 ?
                  <>
                    <div className id>
                      {data.map((item) => (
                        <div className="p-3 mb-3 rounded border border-gray-300 border-dashed">
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center min-w-150px">
                              <span className="bg-primary ordno mb-0 mr-3">{item.sr_no}</span>
                              <div className="ms-0">
                                <div className="fs-6 fw-bolder text-primary">{item?.customer ? item?.customer : '-'}</div>
                                <span className="fs-7 fw-semibold">{item?.office ? item?.office : '-'}</span>
                              </div>
                            </div>
                            <div className=" min-w-150px d-flex flex-column justify-content-end">
                              <div className="text-700 d-flex justify-content-end">Price: <span className="mb-0 fw-bolder ml-1">
                                {
                                  item.order_details.length !== 0 ?
                                    item.order_details.map((i, index) => (
                                      <span key={index} style={{ display: "block", marginTop: 2, fontWeight: '800', fontSize: 12 }}>{i.price ? `₹${i.price}` : '-'}</span>
                                    ))
                                    :
                                    <span>{"-"}</span>
                                }
                              </span></div>
                              <div className="text-700 fs-8 text-right">Time Slot: <span className="mb-0 fw-bolder">{item?.time_slot ? item?.time_slot : '-'}</span></div>
                              <div className="d-flex justify-content-end">
                                <div className="text-700 fs-8">Scheduled Products: <span className="mb-0 fw-bolder">
                                  {
                                    item.order_details.length !== 0 ?
                                      item.order_details.map((i, index) => (
                                        <span className="text-right" key={index} style={{ display: "block", marginTop: 2 }}>{i.name ? `${i.name} X ${i.quantity}` : '-'}</span>
                                      ))
                                      :
                                      <span>{"-"}</span>
                                  }
                                </span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <div className="mt-2 float-right mr-3">
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={20}
                        totalItemsCount={totalRecords}
                        pageRangeDisplayed={5}
                        itemClass="d-flex justify-content-between align-items-center flex-wrap"
                        linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                        activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                        onChange={(pageNumber) => {
                          setCurrentPage(pageNumber);
                        }}
                      />
                    </div> */}
                  </>
                  :
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
                  </div>
                }
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
        </div>
      </div>
    </>
  )
}
export default injectIntl(connect(null, auth.actions)(UserTable));