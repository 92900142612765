import React, { useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
export default function () {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
  });

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <div>
        <TableFilter
          value={filters}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
            });
          }}
          togglePopup={togglePopup}
        />
        <Table
          filters={filters}
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
        />
      </div>
    </>
  );
}
