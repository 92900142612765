import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getApiData } from "../Services";
import { GeneralDropdown } from ".";
import toast from "react-hot-toast";

export default function ({
  show,
  onHide,
  deliveryBoy,
  keyName,
  onSuccess,
  api,
}) {
  const [selectedDBoy, setSelectedDBoy] = useState(null);
  const [list, setList] = useState([]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    getList();
  }, [deliveryBoy]);

  const getList = () => {
    getApiData(`delivery/v1/`).then((res) => {
      if (res.status) {
        let list = [];
        res.data.map((item) => {
          list = [...list, { label: item.first_name, value: item.id }];
        });
        setList(list);
      }
    });
  };

  useEffect(() => {
    if (list.length && deliveryBoy) {
      list.map((item) => {
        if (item.value === deliveryBoy) {
          setSelectedDBoy(item);
          return;
        }
      });
    }
  }, [list, deliveryBoy]);

  const onSubmit = () => {
    const body = {
      [keyName]: selectedDBoy?.value ? selectedDBoy.value : "",
    };
    setSending(true);
    getApiData(api, "put", body).then((res) => {
      setSending(false);
      if (res.status) {
        setSelectedDBoy(null);
        onHide();
        onSuccess(res.message);
      } else {
        toast.error(res.message);
      }
    });

    // toast.success("Coming Soon...")
  };

  return (
    <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Assign Delivery Boy
        </Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div style={{ Display: "block" }}>
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <label htmlFor className=" fs-6 fw-bold mb-2">
                Select Delivery Boy
              </label>
              {/*begin::Input group*/}
              <div className="input-group mb-0">
                <span className="input-group-text" id="basic-addon1">
                  <i className="ki-outline ki-courier fs-2x" />
                </span>
                <div style={{ width: "85%" }}>
                  <GeneralDropdown
                    selectedOption={selectedDBoy}
                    inModal
                    list={list}
                    handleChange={(obj) => {
                      setSelectedDBoy(obj);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer mt-3">
            <button
              type="button"
              className="btn custom-btn-light fs-7"
              onClick={onHide}
            >
              Close
            </button>
            <button
              type="button"
              className="custom-button btn-primary fs-7"
              onClick={onSubmit}
              disabled={selectedDBoy && !sending ? false : true}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
