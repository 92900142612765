import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { API_URL, validEmail, getStateList, getAreaByCity, getBuildingByArea, getAllBuildingsByArea } from "../../../config";
import toast from "react-hot-toast";
import { GeneralDropdown } from "../../../components";
import { getApiData } from "../../../Services";
import imageCompression from "browser-image-compression";

export default function ({ show, onHide, onSuccess, onError, data }) {
    const [sending, setSending] = useState(false);
    const [file, setFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [buildingList, setBuildingList] = useState([]);
    const [filteredBuildingList, setFilteredBuildingList] = useState([]);

    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);
    const [selectedBuildings, setSelectedBuildings] = useState([]);

    useEffect(() => {
        if (data.id) {
            // setUserName(data.username);
            setEmail(data.email);
            setFirstName(data.first_name);
            setPhoneNumber(data.phone_number);
            setSelectedState(data.state);
            setSelectedCity(data.city);
        } else {
            setEmail('');
            setFirstName('');
            setPhoneNumber('');
            setSelectedState(null);
            setSelectedCity(null);
            setSelectedArea(null);
            setSelectedBuildings([]);
        }
    }, [data]);

    useEffect(() => {
        (async () => {
            const state = await getStateList();
            setStateList(state);
        })();
    }, []);

    useEffect(() => {
        if (stateList.length) {
            stateList.map((item) => {
                if (item.value === data.state) {
                    setSelectedState(item);
                    return;
                }
            });
        }
    }, [stateList, data]);

    useEffect(() => {
        if (selectedState && selectedState.city && selectedState.city.length) {
            let city = [];
            selectedState.city.map((item) => {
                city = [...city, { label: item, value: item }];
            });
            setCityList(city);
        }
    }, [selectedState]);

    useEffect(() => {
        if (cityList.length) {
            cityList.map((item) => {
                if (item.value === data.city) {
                    setSelectedCity(item);
                    return;
                }
            });
        }
    }, [cityList, data]);

    useEffect(() => {
        const getAreas = async () => {
            if (selectedCity && selectedCity.value) {
                const area = await getAreaByCity(selectedCity.value);
                setAreaList(area);
            }
        }
        getAreas();
    }, [selectedCity]);

    useEffect(() => {
        if (areaList.length && data.area) {
            areaList.map((item) => {
                if (item.value === data.area.id) {
                    setSelectedArea(item);
                    return
                }
            })
        }
    }, [areaList, data]);

    useEffect(() => {
        const getBuildings = async () => {
            if (selectedArea && selectedArea.value) {
                const buildings = await getAllBuildingsByArea(selectedArea.value);
                setBuildingList(buildings);
            }
        }
        getBuildings();
    }, [selectedArea]);

    function generateRandomId() {
        return Math.random().toString(36).substring(2, 10);
    }

    useEffect(() => {
        if (data.buildings) {
            let buildings_ = [];
            data.buildings.forEach(building => {
                const buildingId = building.id;
                const buildingName = building.name;
                buildings_ = [
                    ...buildings_,
                    { label: buildingName, value: buildingId, id: generateRandomId() }
                ];
            });
            setSelectedBuildings(buildings_);
        }
    }, [buildingList, data]);

    useEffect(() => {
        const filteredBuildingList = buildingList.filter(building => {
            if (selectedBuildings) {
                return !selectedBuildings.some(selected => selected.label === building.label);
            } else {
                return buildingList
            }
            // 
        });

        const newArray = filteredBuildingList.map(obj => ({
            id: generateRandomId(),
            label: obj.label,
            value: obj.value
        }));

        setFilteredBuildingList(newArray);
    }, [buildingList, selectedBuildings]);

    const handleFileChange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const compressedFile = await compressImage(selectedFile);
            const finalFile = new File([compressedFile], selectedFile.name, { type: compressedFile.type });

            setFile(finalFile);

            const reader = new FileReader();
            reader.onload = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(finalFile);
        }
    };

    const compressImage = async (image) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        };

        try {
            const compressedImage = await imageCompression(image, options);
            return compressedImage;
        } catch (error) {
            console.error("Image compression failed:", error);
            return image; // Return original image if compression fails
        }
    };

    const clearInput = () => {
        setEmail('');
        setFirstName('');
        setPhoneNumber('');
        setSelectedState(null);
        setSelectedCity(null);
        setSelectedArea(null);
        setSelectedBuildings([]);
    };

    const onSubmit = () => {
        if (!isValidEmail) {
            toast.error("Please enter valid email");
        } else if (phoneNumber.length < 10) {
            toast.error("Please enter valid phone number");
        } else if (!selectedState) {
            toast.error("Please Select State");
        } else if (!selectedCity) {
            toast.error("Please Select City");
        } else if (!selectedArea) {
            toast.error("Please Select Area");
        } else {
            let buildings_ = [];
            if (selectedBuildings.length !== 0) {
                buildings_ = selectedBuildings.map((i) => i.value);
            }

            setSending(true);
            const formAreas = [selectedArea.value];

            const body = {
                // profile_image: file ? file : 'https://d1xd1xn08h7rcw.cloudfront.net/system_files/user_image.png',
                email: email,
                first_name: firstName,
                phone_number: phoneNumber,
                area: formAreas,
                buildings: buildings_
            }

            getApiData(`delivery/v1/`, 'post', body).then((res) => {
                setSending(false);
                if (res.status) {
                    clearInput();
                    onHide();
                    onSuccess(res.message);
                } else {
                    onError(res.message);
                }
            })
        }
    };

    const onPressClose = () => {
        onHide();
    }

    return (
        <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Add Delivery Boy
                </Modal.Title>
                <button type="button" className="close ml-auto" onClick={onHide}>
                    ×
                </button>
            </Modal.Header>
            <Modal.Body className="pt-3 scroll-popup">
                <div>
                    <div className="modal-body">
                        <div className="row g-5 mb-0 align-items-center">
                            {/* <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Profile Picture</label>
                                <div className="d-flex justify-content-center">
                                    {imagePreview ? (
                                        <div className="">
                                            <div
                                                className="image-input image-input-outline"
                                                id="kt_profile_avatar"
                                                style={{
                                                    backgroundImage: "url(assets/media/users/blank.png)",
                                                }}
                                            >
                                                <div
                                                    className="image-input-wrapper"
                                                    style={{
                                                        backgroundImage: `url("${(imagePreview)}")`,
                                                    }}
                                                />
                                                <label
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Change avatar"
                                                >
                                                    <i className="fa fa-pen icon-sm text-muted" />
                                                    <input
                                                        type="file"
                                                        name="profile_avatar"
                                                        accept=".png, .jpg, .jpeg"
                                                        // onChange={(e) => handleFileRead(e)}
                                                        onChange={handleFileChange}
                                                    />
                                                    <input type="hidden" name="profile_avatar_remove" />
                                                </label>

                                                <span
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="cancel"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Cancel avatar"
                                                >
                                                    <i className="ki ki-bold-close icon-xs text-muted" />
                                                </span>
                                                <span
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="remove"
                                                    data-toggle="tooltip"
                                                    title="Remove avatar"
                                                    onClick={() => {
                                                        setFile(null);
                                                        setImagePreview("");
                                                    }}
                                                >
                                                    <i className="ki ki-bold-close icon-xs text-muted" />
                                                </span>

                                            </div>
                                            <span className="form-text text-muted">
                                                Allowed file types: png, jpg, jpeg.
                                            </span>
                                        </div>
                                    ) :
                                        <input className="form-control" type="file" onChange={handleFileChange} />
                                    }
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Full Name </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => {
                                        // setEmail(e.target.value);

                                        const { value } = e.target;
                                        setEmail(value);
                                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                        setIsValidEmail(e.target.value == '' ? true : emailPattern.test(value));
                                    }}
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor className=" fs-6 fw-bold mb-2 required">Phone Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 10)
                                            setPhoneNumber(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="row col-md-12 mt-5">
                                <div className="col-md-6">
                                    <label className=" fs-6 fw-bold mb-2 required">Select State</label>
                                    <GeneralDropdown
                                        selectedOption={selectedState}
                                        list={stateList}
                                        handleChange={(obj) => {
                                            setSelectedState(obj);
                                            setSelectedCity(null);
                                            setSelectedArea(null);
                                            setSelectedBuildings([]);
                                            if (obj && obj.city && obj.city.length) {
                                                let city = [];
                                                obj.city.map((item) => {
                                                    city = [...city, { label: item, value: item }];
                                                });
                                                setCityList(city);
                                            }
                                            //   props.onStateSelect(obj);
                                        }}
                                        inModal
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className=" fs-6 fw-bold mb-2 required">Select City</label>
                                    <GeneralDropdown
                                        selectedOption={selectedCity}
                                        list={cityList}
                                        handleChange={(obj) => {
                                            setSelectedCity(obj);
                                            setSelectedArea(null);
                                            setSelectedBuildings([]);
                                        }}
                                        inModal
                                    />
                                </div>
                            </div>
                            <div className="row col-md-12 mt-5">
                                <div className="col-md-6">
                                    <label className=" fs-6 fw-bold mb-2 required">Select Area</label>
                                    <GeneralDropdown
                                        selectedOption={selectedArea}
                                        list={areaList}
                                        handleChange={(obj) => {
                                            setSelectedArea(obj);
                                            setSelectedBuildings([]);
                                        }}
                                        inModal
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className=" fs-6 fw-bold mb-2">Select Building</label>
                                    <GeneralDropdown
                                        selectedOption={selectedBuildings}
                                        list={filteredBuildingList}
                                        handleChange={(obj) => {
                                            setSelectedBuildings(obj);
                                        }}
                                        isMulti
                                        inModal
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn custom-btn-light fs-7" onClick={onPressClose}>Close</button>
                        <button
                            type="button"
                            className="custom-button btn-primary fs-7"
                            onClick={onSubmit}
                            disabled={firstName && phoneNumber && !sending ? false : true}
                        >
                            Save
                            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
