import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../../Services";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { gradientColors } from "../../../../../gradientColor";
import { GeneralDropdown } from "../../../../components";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data, isDelete }) {

  const today = new Date();
  const [sending, setSending] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState({
    format: moment(today).format("yyyy-M-D HH:mm:ss"),
    value: today,
  });
  const [endDate, setEndDate] = useState({
    format: moment(today).format("yyyy-M-D HH:mm:ss"),
    value: today,
  });
  const [minCartValue, setMinCartValue] = useState(0);
  const [promoValue, setPromoValue] = useState(0);
  const [maxDiscount, setMaxDiscount] = useState(0);
  const [promoType, setPromoType] = useState(2);
  const [paymentMethod, setPaymentMethod] = useState(1);

  const promoList = [
    { label: 'Recharge', value: 1 },
    { label: 'Percentage', value: 2 },
    { label: 'Buy X Get Y', value: 3 },
  ];

  const paymentModes = [
    { value: '1', label: 'Online' },
    { value: '2', label: 'Pay on Delivery' },
  ]

  useEffect(() => {
    if (show) {
      if (data && data.id) {
        setPromoCode(data.promo_code);
        setPromoType(data.promo_type);
        setDescription(data.description);
        if (data.start_date) {
          setStartDate({
            value: new Date(data.start_date),
            format: moment(data.start_date).format("yyyy-M-D HH:mm:ss"),
          });
        } else {
          setStartDate({ value: "", format: "" });
        }
        if (data.end_date) {
          setEndDate({
            value: new Date(data.end_date),
            format: moment(data.end_date).format("yyyy-M-D HH:mm:ss"),
          });
        } else {
          setEndDate({ value: "", format: "" });
        }
        setMinCartValue(data.minimum_cart_value);
        setPromoValue(data.promo_value);
        setMaxDiscount(data.max_discount);
        setPaymentMethod(data.payment_method);
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  const clearInput = () => {
    setPromoCode("");
    setPromoType(2);
    setDescription("");
    setStartDate({ value: "", format: "" });
    setEndDate({ value: "", format: "" });
    setMinCartValue("");
    setPromoValue("");
    setMaxDiscount("");
    setPaymentMethod(1);
  };

  const createPromo = () => {
    console.log("startDate.format >>>", startDate);
    setSending(true);
    const body = {
      promo_code: promoCode,
      promo_type: promoType,
      description: description,
      ...(minCartValue && { minimum_cart_value: minCartValue }),
      ...(promoValue && { promo_value: promoValue }),
      ...(maxDiscount && { max_discount: maxDiscount }),
      ...(startDate.value && { start_date: startDate.value ? startDate.format : '' }),
      ...(endDate.value && { end_date: endDate.value ? endDate.format : ''}),
      ...(paymentMethod && { payment_method: Number(paymentMethod) }),
    };

    let method = data.id ? "put" : "post";
    let param = data.id ? data.id + "/" : "";

    getApiData(`business/v1/promo-code/${param}`, method, body).then((res) => {
      setSending(false);
      if (res.status) {
        onSuccess(res.message, res.data);
      } else {
        if (typeof res.message === "string") {
          onError(res.message);
        } else {
          onError("Something went wrong!");
        }
      }
    });
  }

  const onSubmit = () => {
    if (!promoCode) {
      toast.error("Please enter promo code");
    } else if (!promoType) {
      toast.error("Please select promo type");
    } else if (!description) {
      toast.error("Please enter description");
    } else if (!maxDiscount) {
      toast.error("Please enter max discount amount");
    } else if (!promoValue) {
      toast.error("Please enter discount percentage");
    }
    //  else if (!paymentMethod) {
    //   toast.error("Please select payment method");
    // }
    else if (promoType == 2) {
      if (!minCartValue) {
        toast.error("Please enter Minimum order amount");
      } else {
        createPromo();
      }
    } else {
      createPromo();
    }
  }

  const onDelete = () => {
    if (isDelete && data.id) {
      setSending(true);
      const body = {
        promo_code: promoCode,
      }
      getApiData(`business/v1/promo-code/${data.id}`, 'delete', body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message);
        } else {
          onError(res.message);
        }
      })
    }
  }

  const handlePromoCodeChange = (e) => {
    const input = e.target.value.slice(0, 8);
    const regex = /^[a-zA-Z0-9-]*$/;
    if (regex.test(input)) {
      setPromoCode(input);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Manage Promo Code</h3>
        </Modal.Title>
        <div className="d-flex ml-auto">
          <span className="mr-3 mt-1" style={{ fontWeight: '600', }}>Recharge Promo</span>
          <span class="switch switch-sm mr-3">
            <label>
              <input
                type="checkbox"
                onChange={(e) => {
                  setPromoType(promoType === 1 ? 2 : 1);
                }}
                checked={promoType === 1}
              />
              <span></span>
            </label>
          </span>
          <div className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onHide}>
            <i className="ki-outline ki-cross fs-1" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-6 form-group">
              <label>Promo Code</label>
              <input
                className="form-control"
                value={promoCode}
                onChange={handlePromoCodeChange}
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Maximum Discount Amount</label>
              <input
                className="form-control"
                value={maxDiscount}
                onChange={(e) => {
                  setMaxDiscount(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="row">
            {promoType == 2 &&
              <div className={`col-md-6 form-group`}>
                <label>Minimum order amount</label>
                <input
                  className="form-control"
                  type={"number"}
                  value={minCartValue}
                  onChange={(e) => {
                    setMinCartValue(e.target.value);
                  }}
                />
              </div>
            }
            <div className="col-md-6 form-group">
              <label>{`${promoType == 1 ? "Discount Amount" : "Discount Percentage"}`}</label>
              <input
                className="form-control"
                type="number"
                value={promoValue}
                onChange={(e) => {
                  setPromoValue(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 form-group">
              <label>Description</label>
              <input
                className="form-control"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Valid From</label>
              <br />
              <DatePicker
                selected={
                  startDate.value
                }
                className="form-control"
                onChange={(date) => {
                  setStartDate({
                    format: moment(date).format("yyyy-M-D HH:mm:ss"),
                    value: date,
                  });
                }}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
                minDate={today}
              />
            </div>
            <div className="col-md-6 form-group">
              <label>Valid Until</label>
              <br />
              <DatePicker
                selected={
                  endDate.value
                }
                className="form-control"
                onChange={(date) => {
                  setEndDate({
                    format: moment(date).format("yyyy-M-D HH:mm:ss"),
                    value: date,
                  });
                }}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                showTimeInput
                minDate={startDate.value || today}
              />
            </div>
            {/* <div className="col-md-4 form-group">
              <label>Payment Method</label>
              <br />
              <GeneralDropdown
                className="form-control"
                selectedOption={paymentMethod == 1 ? paymentModes[0] : paymentModes[1]}
                list={paymentModes}
                handleChange={(obj) => {
                  setPaymentMethod(obj.value);
                }}
                inModal
              />
            </div> */}
          </div>
        </div>
        <div className="modal-footer">
          {!isDelete ?
            <button
              unit="button"
              className="custom-button btn-primary fs-7"
              onClick={onSubmit}
              disabled={!sending ? false : true}
            >
              Submit
              {sending && <Spinner animation="border" size="sm" className="ml-1" />}
            </button>
            :
            <button
              unit="button"
              style={{ backgroundColor: "#EB1C26", color: "#FFF" }}
              className="custom-button btn-primary fs-7"
              onClick={onDelete}
              disabled={!sending ? false : true}
            >
              Delete
              {sending && <Spinner animation="border" size="sm" className="ml-1" />}
            </button>
          }
          <button unit="button" className="btn custom-btn-light fs-7" onClick={() => {
            onHide();
          }}>
            Close
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
