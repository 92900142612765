import React, { useEffect, useState } from "react";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";

export default function () {
  const [filters, setFilters] = useState({
    search: "",
    filter: "",
    dues: false,
  });

  return (
    <>
      <div>
        {/* <TableFilter
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          setFilterValue={(value) => {
            let obj = { ...filters };
            obj.filter = value;
            setFilters(obj);
          }}
          onClearFilter={() => {
            setFilters({
              search: "",
              filter: "",
            });
          }}
        /> */}
        <Table filters={filters} />
      </div>
    </>
  );
}