import styled from "styled-components";
export const Wrapper = styled.div`
    body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #7c8087;
    overflow-x: hidden;
    margin: 0;
    background-color: #FFFFFF;
}

a {
    outline: medium none !important;
    color: #EB4721;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none !important;
}

a:focus {
    color: #EB4721;
    text-decoration: none !important;
}

a:hover {
    color: #EB4721;
    text-decoration: none !important;
}

input {
    outline: medium none !important;
    color: #6a26da;
}

hr {
    border-top-color: #e7e7e7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    color: #1d293f;
    margin-top: 0px;
    text-transform: capitalize;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
}

label {
    font-weight: normal;
}

h1 {
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    -letter-spacing: -1px;
}

h2 {
    font-size: 40px;
    font-weight: 500;
    -letter-spacing: -2px;
}

h3 {
    font-size: 34px;
    font-weight: 500;
    -letter-spacing: -2px;
}

h4 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1px;
}

h5 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1px;
}

h6 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
}

p {
    font-weight: normal;
    line-height: 26px;
}

*::-moz-selection {
    background: #EB4721;
    color: #ffffff;
    text-shadow: none;
}

::-moz-selection {
    background: #EB4721;
    color: #ffffff;
    text-shadow: none;
}

::selection {
    background: #EB4721;
    color: #ffffff;
    text-shadow: none;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

/* container-fluid */
.container-fluid.container-space {
    padding: 0 100px;
}

/* form-control */
.form-control {
    border: none;
    border-radius: 0px;
    padding: 10px 20px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 500;
    color: #1d293f;
    background-clip: inherit !important;
    background: #f5f9ff;
    border-radius: 4px;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #6a26da;
    background: #f5f9ff;
}

.form-control.round {
    border-radius: 30px;
}

.form-light .form-control {
    color: #1d293f;
    background: #ffffff;
}

.form-light .form-control:focus {
    background: #ffffff;
}

.form-control::-moz-placeholder {
    color: #1d293f;
}

.form-control::-ms-input-placeholder {
    color: #1d293f;
}

.form-control::-webkit-input-placeholder {
    color: #1d293f;
}

input.form-control {
    height: 50px;
}

/* Inner Header Shape */
.cls-1 {
    fill: #fff;
    fill-rule: evenodd;
}

.cls-1-dark {
    fill: #18191c;
    fill-rule: evenodd;
}

/* Dark Form */
.dark-form h6 {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
}

.dark-form .form-control {
    background-color: #bbbbbb;
    border-color: #bbbbbb;
    color: rgba(255, 255, 255, 0.4);
    font-weight: normal;
}

.dark-form .form-control::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.dark-form .form-control::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.dark-form .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.dark-form .select2-container--default .select2-selection--single {
    background-color: #bbbbbb;
}

.dark-form .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: rgba(255, 255, 255, 0.4);
    font-weight: normal;
}

.dark-form .select2-container--default .select2-selection--single .select2-selection__arrow:before {
    color: rgba(255, 255, 255, 0.4);
}

.dark-page .select2-container--open .select2-dropdown {
    background: #bbbbbb;
    border-color: #bbbbbb;
}

.dark-page .select2-container--default .select2-results>.select2-results__options {
    color: rgba(255, 255, 255, 0.4);
}

.dark-page .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #000000;
    color: rgba(255, 255, 255, 0.4);
}

.dark-page .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #000000;
    color: rgba(255, 255, 255, 0.4);
}

.dark-page .select2-container--default .select2-search--dropdown .select2-search__field {
    background-color: #000000;
    border-color: #000000;
    color: rgba(255, 255, 255, 0.4);
}

/* custom-file */
.custom-file {
    height: 50px;
}

.custom-file .custom-file-input {
    height: 50px;
    color: #1d293f;
    border-radius: 50px;
    border-color: #e7e7e7;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    z-index: 99;
}

.custom-file .custom-file-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #6a26da;
}

.custom-file .custom-file-label {
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #1d293f;
    border-color: #e7e7e7;
    padding: 15px 90px 15px 20px;
    border-radius: 50px;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-file .custom-file-label:after {
    height: 48px;
    padding: 14px 25px;
}

.custom-file .btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    line-height: 25px;
}

.custom-file-input:focus~.custom-file-label {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #6a26da;
}

.form-search .form-group {
    position: relative;
    border-radius: 50px;
}

.form-search .form-group button {
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
}

.form-search .form-group .form-control {
    padding: 10px 150px 10px 20px;
    background: #ffffff;
}

/* input-group-prepend */
.input-group-prepend .input-group-text,
.input-group-append .input-group-text {
    width: 50px;
    background: transparent;
    border-color: #e7e7e7;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/* checkbox */
.custom-control-input:checked~.custom-control-label:before {
    background: #6a26da;
    border-color: #6a26da;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
    background: transparent;
    border-color: transparent;
}

.custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
    border-color: #e7e7e7;
}

.custom-control-label:before {
    top: 2px;
}

.custom-control-label:after {
    top: 2px;
}

.custom-checkbox .custom-control-label:before {
    border-radius: 50px;
    border: 2px solid #dfdfdf;
}

/* border  */
.border {
    border-color: #e7e7e7 !important;
}

.border-top {
    border-top-color: #e7e7e7 !important;
}

.border-left {
    border-left-color: #e7e7e7 !important;
}

.border-right {
    border-right-color: #e7e7e7 !important;
}

.border-bottom {
    border-bottom-color: #e7e7e7 !important;
}

/* badge  */
.badge {
    border-radius: 50px;
}

.badge+.badge {
    margin-left: 6px;
}

.badge-md {
    padding: 5px 8px;
    font-size: 13px;
    font-weight: normal;
}

.badge-primary {
    background: #6a26da;
}

/* back-to-to */
.back-to-top a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    // bottom: 50px;
    right: 40px;
    width: 50px;
    height: 50px;
    margin: 0px;
    color: #ffffff;
    font-size: 18px;
    background: #EB4721;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 999;
    border-radius: 5px;
}

.back-to-top a:hover {
    color: #ffffff;
}

.back-to-top a:focus {
    color: #ffffff;
}

/* lead */
.lead {
    font-size: 20px;
    font-weight: 400;
}

/* pagination */
.pagination .page-item .page-link {
    padding: 14px 28px;
    color: #7c8087;
    border-color: #e7e7e7;
    margin: 0 4px;
}

.pagination .page-item .page-link span {
    display: block;
}

.pagination .page-item .page-link:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pagination .page-item.active .page-link {
    background: #6a26da;
    border-color: #6a26da;
    color: #ffffff;
}

.pagination .page-item:first-child .page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pagination .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/* blockquote */
.blockquote {
    font-size: 16px;
    font-style: italic;
    display: block;
    padding: 50px;
    background: #f5f9ff;
    border: 1px solid #e7e7e7;
    position: relative;
}

.blockquote i {
    z-index: 0;
    font-size: 30px;
    line-height: 30px;
}

/*case-studies-detail*/
.clients-detail .social li {
    padding: 0;
    margin: 0 10px 0 0;
}

.clients-detail .social li a {
    color: #bbbbbb;
}

.clients-detail .social li:hover a {
    color: #6a26da;
}

iframe {
    width: 100%;
}

/* table */
.table {
    border-spacing: 0 1px;
    font-size: 16px;
    font-weight: 600;
}

.table td {
    padding: 14px 20px;
}

.table th {
    padding: 14px 20px;
}

.table-bordered td {
    border-color: #e7e7e7;
}

.table-bordered th {
    border-color: #e7e7e7;
}

.table-striped tbody tr:nth-of-type(odd) {
    background: #040404;
}

.table-striped tbody tr:nth-of-type(odd) td {
    color: #ffffff;
}

.table-striped .avatar {
    margin-right: 15px;
}

.table-striped tr td {
    color: #ffffff;
}

.table td,
.table th {
    vertical-align: middle;
}

.table-skew {
    padding: 0 30px;
}

@media (max-width: 767px) {

    .table td,
    .table th {
        white-space: nowrap;
    }
}

@media (max-width: 575px) {
    h1 {
        font-size: 40px;
    }
}

/*****************************
    Helper Classes
*****************************/
.text-primary {
    color: #EB4721 !important;
}

.text-secondary {
    color: #7c8087 !important;
}

.text-white {
    color: #ffffff;
}

.text-dark {
    color: #000000 !important;
}

.text-light {
    color: #7c8087 !important;
}

.text-success {
    color: #0fbc49 !important;
}

.text-danger {
    color: #f52626 !important;
}

.text-primary-soft {
    color: rgba(106, 38, 218, 0.5) !important;
}

.text-stroke-white {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    display: block;
}

.text-stroke-primary {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #6a26da;
    display: block;
}

.text-stroke-dark {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #18191c;
    display: block;
}

.shadow {
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05) !important;
}

.rounded {
    border-radius: 0.5rem !important;
}

.bg-primary {
    background: #EB4721 !important;
}

.bg-dark {
    background: #000000 !important;
}

.bg-dark-02 {
    background: #bbbbbb !important;
}

.bg-secondary {
    background: #7c8087 !important;
}

.bg-gray-light {
    background: #f5f9ff !important;
}

.bg-facebook {
    background: #5d82d1 !important;
}

.bg-pinterest {
    background: #e13138 !important;
}

.bg-linkedin {
    background: #238cc8 !important;
}

.bg-white-soft {
    background: rgba(255, 255, 255, 0.1);
}

.bg-dark-soft {
    background: rgba(0, 0, 0, 0.1);
}

.bg-primary-soft {
    background: rgba(106, 38, 218, 0.1);
}

.bg-success-soft {
    background: rgba(15, 188, 73, 0.1);
}

.bg-orange-soft {
    background: rgba(255, 107, 15, 0.1);
}

.bg-warning-soft {
    background: rgba(255, 193, 7, 0.1);
}

.bg-danger-soft {
    background: rgba(245, 38, 38, 0.1);
}

.space-ptb80 {
    padding: 80px 0;
}

.space-ptb {
    padding: 90px 0;
}

.space-pt {
    padding: 90px 0 0;
}

.space-lg-pt {
    padding: 100px 0 0;
}

.space-pb {
    padding: 0 0 90px;
}

/* Height */
.h-100vh {
    height: 100vh !important;
}

.h-950 {
    height: 950px;
}

.h-900 {
    height: 900px;
}

.h-800 {
    height: 800px;
}

.h-700 {
    height: 700px;
}

.h-600 {
    height: 600px;
}

.h-500 {
    height: 500px;
}

.h-400 {
    height: 400px;
}

.h-300 {
    height: 300px;
}

.h-200 {
    height: 200px;
}

.h-100 {
    height: 100px;
}

.h-80 {
    height: 80px;
}

/* Sticky column */
.is-sticky {
    position: sticky;
    top: 100px;
}

hr {
    border-top-color: rgba(255, 255, 255, 0.1);
}

hr.hr-dark {
    border-top-color: rgba(0, 0, 0, 0.1);
}

.footer-border-top {
    border: 1px solid #18191c;
}

.grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.grayscale:hover {
    -webkit-filter: none;
    filter: none;
}

.bg-holder {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
}

.bg-holder-bottom {
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 100%;
}

/*form-group*/
.form-group {
    margin-bottom: 1rem;
}

.shadow-hover {
    position: relative;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.shadow-hover:hover {
    -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.01);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.01);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.action-box-style-01 {
    position: relative;
    top: 150px;
}

.feature-box-position {
    position: relative;
    top: 100px;
}

.bg-overlay-black-10 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-10:before {
    background: rgba(0, 0, 0, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-20 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-20:before {
    background: rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-30 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-30:before {
    background: rgba(0, 0, 0, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-40 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-40:before {
    background: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-50 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-50:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-60 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-60:before {
    background: rgba(0, 0, 0, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-70 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-70:before {
    background: rgba(0, 0, 0, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-80 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-80:before {
    background: rgba(0, 0, 0, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-black-90 {
    position: relative;
    z-index: 1;
}

.bg-overlay-black-90:before {
    background: rgba(0, 0, 0, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-10 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-10:before {
    background: rgba(255, 255, 255, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-20 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-20:before {
    background: rgba(255, 255, 255, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-30 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-30:before {
    background: rgba(255, 255, 255, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-40 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-40:before {
    background: rgba(255, 255, 255, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-50 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-50:before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-60 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-60:before {
    background: rgba(255, 255, 255, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-70 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-70:before {
    background: rgba(255, 255, 255, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-80 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-80:before {
    background: rgba(255, 255, 255, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-white-90 {
    position: relative;
    z-index: 1;
}

.bg-overlay-white-90:before {
    background: rgba(255, 255, 255, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-theme-20 {
    position: relative;
    z-index: 1;
}

.bg-overlay-theme-20:before {
    background: rgba(106, 38, 218, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-theme-50 {
    position: relative;
    z-index: 1;
}

.bg-overlay-theme-50:before {
    background: rgba(106, 38, 218, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-theme-70 {
    position: relative;
    z-index: 1;
}

.bg-overlay-theme-70:before {
    background: rgba(106, 38, 218, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-theme-90 {
    position: relative;
    z-index: 1;
}

.bg-overlay-theme-90:before {
    background: rgba(106, 38, 218, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-secondary-20 {
    position: relative;
    z-index: 1;
}

.bg-overlay-secondary-20:before {
    background: rgba(124, 128, 135, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-secondary-50 {
    position: relative;
    z-index: 1;
}

.bg-overlay-secondary-50:before {
    background: rgba(124, 128, 135, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-secondary-70 {
    position: relative;
    z-index: 1;
}

.bg-overlay-secondary-70:before {
    background: rgba(124, 128, 135, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-secondary-90 {
    position: relative;
    z-index: 1;
}

.bg-overlay-secondary-90:before {
    background: rgba(124, 128, 135, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-overlay-left-100 {
    position: relative;
}

.bg-overlay-left-100:before {
    position: absolute;
    content: "";
    top: 0;
    left: -100%;
    background-color: #6a26da;
    width: 100%;
    height: 100%;
}

.bg-overlay-right-100 {
    position: relative;
}

.bg-overlay-right-100:before {
    position: absolute;
    content: "";
    top: 0;
    right: -100%;
    background-color: #7c8087;
    width: 100%;
    height: 100%;
}

.bg-overlay-left {
    position: relative;
}

.bg-overlay-left:before {
    position: absolute;
    content: "";
    top: -20px;
    left: 0;
    background-color: #7c8087;
    width: 46%;
    height: calc(100% - -20px);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.half-overlay-left {
    position: relative;
}

.half-overlay-left:before {
    position: absolute;
    content: "";
    top: -40px;
    background-color: #6a26da;
    width: 106%;
    height: 100%;
    right: -5%;
}

.half-overlay-left:after {
    position: absolute;
    content: "";
    top: -40px;
    background-color: #6a26da;
    width: 100%;
    height: 100%;
    right: 100%;
    z-index: -1;
}

.half-overlay-right {
    position: relative;
}

.half-overlay-right:before {
    position: absolute;
    content: "";
    top: 0;
    background-color: #7c8087;
    width: 106%;
    left: -5%;
    height: 100%;
    z-index: -1;
}

.half-overlay-right:after {
    position: absolute;
    content: "";
    top: 0;
    background-color: #7c8087;
    width: 100%;
    left: 100%;
    height: 100%;
    z-index: -1;
}

/*our-since*/
.our-since {
    padding: 30px;
    width: 42%;
    background: #7c8087;
    position: absolute;
    top: 0;
}

.media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.media .media-body span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.media .media-body h6 {
    font-size: 16px;
}

.avatar {
    width: 50px;
    height: 50px;
    display: inline-block;
}

.avatar.avatar-sm {
    width: 30px;
    height: 30px;
}

.avatar.avatar-md {
    width: 40px;
    height: 40px;
}

.avatar.avatar-lg {
    width: 75px;
    height: auto;
}

.avatar.avatar-xl {
    width: 80px;
    height: 80px;
}

.avatar.avatar-xll {
    width: 100px;
    height: 100px;
}

.avatar.avatar-xlll {
    width: 120px;
    height: 120px;
}

.avatar.avatar-xllll {
    width: 160px;
    height: 160px;
}

.avatar.avatar-xlllll {
    width: 180px;
    height: 180px;
}

.font-sm {
    font-size: 13px;
}

.font-md {
    font-size: 16px;
}

.font-lg {
    font-size: 17px;
}

.font-xl {
    font-size: 21px;
}

.font-xxl {
    font-size: 30px;
}

.font-xxxl {
    font-size: 40px;
    line-height: 40px;
}

.line-h-sm {
    line-height: 13px;
}

.line-h-md {
    line-height: 16px;
}

.line-h-lg {
    line-height: 17px;
}

.line-h-xl {
    line-height: 21px;
}

.line-h-xxl {
    line-height: 30px;
}

.line-h-xxxl {
    line-height: 40px;
}

.b-radius-none {
    border-radius: 0 !important;
}

.b-radius-left-none {
    border-radius: 0 3px 3px 0 !important;
}

.b-radius-right-none {
    border-radius: 3px 0 0 3px !important;
}

.sticky-top {
    z-index: 8;
}

.z-index-1 {
    z-index: 1;
}

.z-index-9 {
    z-index: 9;
}

.z-index-99 {
    z-index: 99;
}

.z-index-999 {
    z-index: 999;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.bottom-0 {
    bottom: 0;
}

.top-0 {
    top: 0;
}

.overflw-x-hidden {
    overflow-x: hidden;
}

.overflw-y-hidden {
    overflow-y: hidden;
}

.position-center {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
    text-align: center;
}

.fill-primary {
    fill: #6a26da;
}

.fill-light {
    fill: #f5f9ff;
}

.fill-secondary {
    fill: #7c8087;
}

.svg-fill-gradient {
    fill: #f5f9ff;
    -webkit-filter: url(#gradient-overlay-1);
    filter: url(#gradient-overlay-1);
    fill-rule: evenodd;
}

.half-section-prl {
    padding: 0px 250px 0px 100px;
}

.p-lg-6 {
    padding: 5rem !important;
}

.px-lg-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.py-lg-6 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
}

.pt-lg-6 {
    padding-top: 5rem !important;
}

.pb-lg-6 {
    padding-bottom: 5rem !important;
}

.ps-lg-6 {
    padding-left: 5rem !important;
}

.pr-lg-6 {
    padding-right: 5rem !important;
}

.list ul {
    padding: 0px;
}

.list ul li {
    list-style: none;
    position: relative;
    margin-bottom: 12px;
}

.author-text {
    border-left: 4px solid #6a26da !important;
    padding: 5px 0px 0px 20px;
}

.social ul li {
    margin-right: 20px;
    display: inline-block;
}

.social ul li:last-child {
    margin-right: 0px;
}

/*blog-overlay*/
.blog-overlay {
    position: relative;
    text-align: left;
    z-index: 2;
}

.blog-overlay:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(5%, black), to(rgba(0, 0, 0, 0.09)));
    background: linear-gradient(0deg, black 5%, rgba(0, 0, 0, 0.09) 100%);
    content: "";
    left: 0;
    right: 0;
    z-index: 1;
}

.blog-overlay .blog-name {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    padding: 15px 15px 0;
}

.blog-overlay .blog-name a.tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.blog-overlay .blog-name h5 a {
    font-size: 24px;
}

.blog-overlay .blog-name h5:hover a {
    color: #ffffff;
}

.blog-overlay.white-bg:before,
.blog-overlay.dark-theme-bg:before,
.blog-overlay.theme-bg:before {
    display: none;
}

.blog-overlay .blog-image {
    overflow: hidden;
    position: relative;
}

/*blog-overlay-01*/
.blog-overlay-02 {
    position: relative;
    text-align: left;
    z-index: 2;
}

.blog-overlay-02:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    left: 0;
    right: 0;
    z-index: 1;
}

.blog-overlay-02 .blog-name {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    width: 90%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 30px;
}

.blog-overlay-02 .blog-name a.tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.blog-overlay-02 .blog-name h5 a {
    font-weight: 500;
    font-size: 24px;
}

.blog-overlay-02.white-bg:before,
.blog-overlay-02.dark-theme-bg:before,
.blog-overlay-02.theme-bg:before {
    display: none;
}

.blog-overlay-02 .blog-image {
    overflow: hidden;
    position: relative;
}

.blog-post.blog-overlay-02 .blog-post-footer a {
    color: #18191c;
}

.blog-post.blog-overlay-02 .blog-post-author span {
    color: #18191c;
}

/*blog-overlay-01*/
.blog-overlay-01 {
    position: relative;
    text-align: left;
    z-index: 2;
}

.blog-overlay-01 .blog-name {
    padding-left: 30px;
    position: absolute;
    bottom: -30px;
    right: 0;
    z-index: 2;
    background: #ffffff;
    -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    width: 60%;
    text-align: right;
    padding: 15px 25px;
}

.blog-overlay-01 .blog-name a.tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}

.blog-overlay-01 .blog-name h5 a {
    font-weight: 500;
    font-size: 24px;
}

.blog-overlay-01.white-bg:before,
.blog-overlay-01.dark-theme-bg:before,
.blog-overlay-01.theme-bg:before {
    display: none;
}

.blog-overlay-01 .blog-image {
    overflow: hidden;
    position: relative;
    padding-right: 20px;
}

.blog-overlay-03 {
    text-align: center;
    position: relative;
}

.blog-overlay-03 .blog-name h5 {
    font-size: 20px;
}

.blog-overlay-03 .blog-name h5 a {
    text-align: left;
    background: #ffffff;
    display: inline-block;
    position: relative;
    padding: 15px 20px;
    top: -28px;
    width: 85%;
    margin: 0 auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.blog-overlay-03 .blog-name h5 a img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.swiper-container.editor .blog-post .blog-image img {
    height: auto;
}

.swiper-container.editor .blog-post .blog-name {
    padding-left: 0px;
}

.swiper-container.editor .blog-post .blog-name h1 {
    font-size: 16px;
    line-height: 26px;
    padding: 0px 15px;
}

.swiper-container.editor .blog-post .blog-name .blog-post-footer {
    padding-bottom: 0px;
    padding-top: 10px;
}

.swiper-container.editor .blog-post .blog-name a.tag {
    padding: 8px 15px;
}

.advertise {
    padding: 90px 30px;
    position: relative;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.navigation.post-navigation .blog-post-nav-media img {
    border-radius: 50%;
    width: 85px;
    height: 85px;
    -o-object-fit: cover;
    object-fit: cover;
}

.navigation.post-navigation .nav-previous .nav-left {
    width: 70%;
}

.navigation.post-navigation .nav-previous .nav-left .pagi-text {
    padding-bottom: 0px;
    text-decoration: none;
}

.navigation.post-navigation .nav-previous .nav-left .nav-title {
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 16px;
    font-weight: 500;
}

.navigation.post-navigation .nav-next .nav-right {
    width: 70%;
}

.navigation.post-navigation .nav-next .nav-right .pagi-text {
    padding-bottom: 0px;
    text-decoration: none;
}

.navigation.post-navigation .nav-next .nav-right .nav-title {
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 16px;
    font-weight: 500;
}

.blog-post-share-box .badges a {
    border: 1px solid #6a26da;
    padding: 8px 10px;
    color: #6a26da !important;
}

.blog-post-slider {
    position: relative;
}

.blog-post-slider .owl-dots {
    position: absolute;
    bottom: 10px;
}

.grid-post {
    padding-bottom: 50px;
}

.grid-post ul {
    padding-left: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.grid-post ul li {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
}

  /*blog-entry-audio*/
  .blog-post-image .blog-entry-audio.audio-video {
    padding: 200px 20px;
    // background: url(../images/travel/blog/07.jpg) no-repeat;
    background-position: center center;
    background-size: cover; }

  .banner.blog-single-04 .blog-image {
    height: 100%;
    max-width: 100%; }

  .position-all-center {
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

  .sign-in-img img {
    height: 100vh;
    -o-object-fit: cover;
       object-fit: cover; }

  @media (max-width: 1599px) {
    .bmi-form {
      overflow-x: hidden;
      padding-top: 40px;
      margin-top: -40px; }
    .blog-overlay-03 .blog-name a {
      padding: 15px 30px; } }

  @media (max-width: 991px) {
    .bmi-form .col-lg-6.half-overlay-right,
    .bmi-form .col-lg-6.half-overlay-left {
      padding: 0 30px; }
    .bmi-form .half-overlay-left:after,
    .bmi-form .half-overlay-right:after {
      content: none; }
    .bmi-form {
      padding-top: 0px;
      margin-top: 40px; }
    .banner-03 .swiper-slide {
      height: 600px !important; }
    .blog-overlay-01 .blog-image {
      padding-right: 0px !important; }
    .blog-overlay-01 .blog-name {
      position: inherit;
      width: 100%;
      bottom: 0px; }
    .banner.blog-single-04 .blog-image {
      height: 500px !important; } }

  @media (max-width: 767px) {
    .banner-03 .swiper-slide {
      height: 550px !important; }
    .navigation.post-navigation .nav-next .nav-right,
    .navigation.post-navigation .nav-previous .nav-left {
      width: 100%; }
    .blog-post .blog-content {
      padding: 0px 0px 10px 0px !important; }
    .mt-n9,
    .my-n9 {
      margin-top: 0rem !important; }
    .ml-n6,
    .mx-n6 {
      margin-left: 0rem !important; } }

  @media (max-width: 575px) {
    /*our-since*/
    .our-since {
      padding: 20px;
      width: 70%; }
    .widget-content .social {
      display: block; }
    .grid-post ul li {
      width: 100%; }
    .blockquote {
      padding: 30px; }
    .banner.blog-single-04 .blog-image {
      height: 350px !important; } }

/* Margin negative */
.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-n6 {
    margin: -5rem !important;
}

.mt-n6,
.my-n6 {
    margin-top: -5rem !important;
}

.mr-n6,
.mx-n6 {
    margin-right: -5rem !important;
}

.mb-n6,
.my-n6 {
    margin-bottom: -5rem !important;
}

.ml-n6,
.mx-n6 {
    margin-left: -5rem !important;
}

.m-n7 {
    margin: -7rem !important;
}

.mt-n7,
.my-n7 {
    margin-top: -7rem !important;
}

.mr-n7,
.mx-n7 {
    margin-right: -7rem !important;
}

.mb-n7,
.my-n7 {
    margin-bottom: -7rem !important;
}

.ml-n7,
.mx-n7 {
    margin-left: -7rem !important;
}

.m-n8 {
    margin: -10rem !important;
}

.mt-n8,
.my-n8 {
    margin-top: -10rem !important;
}

.mr-n8,
.mx-n8 {
    margin-right: -10rem !important;
}

.mb-n8,
.my-n8 {
    margin-bottom: -10rem !important;
}

.ml-n8,
.mx-n8 {
    margin-left: -10rem !important;
}

.m-n9 {
    margin: -12rem !important;
}

.mt-n9,
.my-n9 {
    margin-top: -12rem !important;
}

.mr-n9,
.mx-n9 {
    margin-right: -12rem !important;
}

.mb-n9,
.my-n9 {
    margin-bottom: -12rem !important;
}

.ml-n9,
.mx-n9 {
    margin-left: -12rem !important;
}

.m-n10 {
    margin: -14rem !important;
}

.mt-n10,
.my-n10 {
    margin-top: -14rem !important;
}

.mt-n11,
.my-n11 {
    margin-top: -18rem !important;
}

.mr-n10,
.mx-n10 {
    margin-right: -14rem !important;
}

.mb-n10,
.my-n10 {
    margin-bottom: -14rem !important;
}

.ml-n10,
.mx-n10 {
    margin-left: -14rem !important;
}

@media (min-width: 576px) {

    /* Margin negative */
    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-n6 {
        margin: -5rem !important;
    }

    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -5rem !important;
    }

    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -5rem !important;
    }

    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -5rem !important;
    }

    .m-sm-n7 {
        margin: -7rem !important;
    }

    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -7rem !important;
    }

    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -7rem !important;
    }

    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -7rem !important;
    }

    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -7rem !important;
    }

    .m-sm-n8 {
        margin: -10rem !important;
    }

    .mt-sm-n8,
    .my-sm-n8 {
        margin-top: -10rem !important;
    }

    .mr-sm-n8,
    .mx-sm-n8 {
        margin-right: -10rem !important;
    }

    .mb-sm-n8,
    .my-sm-n8 {
        margin-bottom: -10rem !important;
    }

    .ml-sm-n8,
    .mx-sm-n8 {
        margin-left: -10rem !important;
    }

    .m-sm-n9 {
        margin: -12rem !important;
    }

    .mt-sm-n9,
    .my-sm-n9 {
        margin-top: -12rem !important;
    }

    .mr-sm-n9,
    .mx-sm-n9 {
        margin-right: -12rem !important;
    }

    .mb-sm-n9,
    .my-sm-n9 {
        margin-bottom: -12rem !important;
    }

    .ml-sm-n9,
    .mx-sm-n9 {
        margin-left: -12rem !important;
    }

    .m-sm-n10 {
        margin: -14rem !important;
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -14rem !important;
    }

    .mt-sm-n11,
    .my-sm-n11 {
        margin-top: -18rem !important;
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -14rem !important;
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -14rem !important;
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -14rem !important;
    }
}

@media (min-width: 768px) {

    /* Margin negative */
    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-n6 {
        margin: -5rem !important;
    }

    .mt-md-n6,
    .my-md-n6 {
        margin-top: -5rem !important;
    }

    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -5rem !important;
    }

    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -5rem !important;
    }

    .m-md-n7 {
        margin: -7rem !important;
    }

    .mt-md-n7,
    .my-md-n7 {
        margin-top: -7rem !important;
    }

    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -7rem !important;
    }

    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -7rem !important;
    }

    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -7rem !important;
    }

    .m-md-n8 {
        margin: -10rem !important;
    }

    .mt-md-n8,
    .my-md-n8 {
        margin-top: -10rem !important;
    }

    .mr-md-n8,
    .mx-md-n8 {
        margin-right: -10rem !important;
    }

    .mb-md-n8,
    .my-md-n8 {
        margin-bottom: -10rem !important;
    }

    .ml-md-n8,
    .mx-md-n8 {
        margin-left: -10rem !important;
    }

    .m-md-n9 {
        margin: -12rem !important;
    }

    .mt-md-n9,
    .my-md-n9 {
        margin-top: -12rem !important;
    }

    .mr-md-n9,
    .mx-md-n9 {
        margin-right: -12rem !important;
    }

    .mb-md-n9,
    .my-md-n9 {
        margin-bottom: -12rem !important;
    }

    .ml-md-n9,
    .mx-md-n9 {
        margin-left: -12rem !important;
    }

    .m-md-n10 {
        margin: -14rem !important;
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -14rem !important;
    }

    .mt-md-n11,
    .my-md-n11 {
        margin-top: -18rem !important;
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -14rem !important;
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -14rem !important;
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -14rem !important;
    }
}

@media (min-width: 992px) {

    /* Margin negative */
    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-n6 {
        margin: -5rem !important;
    }

    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -5rem !important;
    }

    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -5rem !important;
    }

    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -5rem !important;
    }

    .m-lg-n7 {
        margin: -7rem !important;
    }

    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -7rem !important;
    }

    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -7rem !important;
    }

    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -7rem !important;
    }

    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -7rem !important;
    }

    .m-lg-n8 {
        margin: -10rem !important;
    }

    .mt-lg-n8,
    .my-lg-n8 {
        margin-top: -10rem !important;
    }

    .mr-lg-n8,
    .mx-lg-n8 {
        margin-right: -10rem !important;
    }

    .mb-lg-n8,
    .my-lg-n8 {
        margin-bottom: -10rem !important;
    }

    .ml-lg-n8,
    .mx-lg-n8 {
        margin-left: -10rem !important;
    }

    .m-lg-n9 {
        margin: -12rem !important;
    }

    .mt-lg-n9,
    .my-lg-n9 {
        margin-top: -12rem !important;
    }

    .mr-lg-n9,
    .mx-lg-n9 {
        margin-right: -12rem !important;
    }

    .mb-lg-n9,
    .my-lg-n9 {
        margin-bottom: -12rem !important;
    }

    .ml-lg-n9,
    .mx-lg-n9 {
        margin-left: -12rem !important;
    }

    .m-lg-n10 {
        margin: -14rem !important;
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -14rem !important;
    }

    .mt-lg-n11,
    .my-lg-n11 {
        margin-top: -18rem !important;
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -14rem !important;
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -14rem !important;
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -14rem !important;
    }
}

@media (min-width: 1200px) {

    /* Margin negative */
    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-n6 {
        margin: -5rem !important;
    }

    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -5rem !important;
    }

    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -5rem !important;
    }

    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -5rem !important;
    }

    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -5rem !important;
    }

    .m-xl-n7 {
        margin: -7rem !important;
    }

    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -7rem !important;
    }

    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -7rem !important;
    }

    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -7rem !important;
    }

    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -7rem !important;
    }

    .m-xl-n8 {
        margin: -10rem !important;
    }

    .mt-xl-n8,
    .my-xl-n8 {
        margin-top: -10rem !important;
    }

    .mr-xl-n8,
    .mx-xl-n8 {
        margin-right: -10rem !important;
    }

    .mb-xl-n8,
    .my-xl-n8 {
        margin-bottom: -10rem !important;
    }

    .ml-xl-n8,
    .mx-xl-n8 {
        margin-left: -10rem !important;
    }

    .m-xl-n9 {
        margin: -12rem !important;
    }

    .mt-xl-n9,
    .my-xl-n9 {
        margin-top: -12rem !important;
    }

    .mr-xl-n9,
    .mx-xl-n9 {
        margin-right: -12rem !important;
    }

    .mb-xl-n9,
    .my-xl-n9 {
        margin-bottom: -12rem !important;
    }

    .ml-xl-n9,
    .mx-xl-n9 {
        margin-left: -12rem !important;
    }

    .m-xl-n10 {
        margin: -14rem !important;
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -14rem !important;
    }

    .mt-xl-n11,
    .my-xl-n11 {
        margin-top: -18rem !important;
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -14rem !important;
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -14rem !important;
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -14rem !important;
    }
}

/*****************************
  Shortcodes
*****************************/
.accordion .accordion-item {
    border: none;
    background: none;
}

.accordion .accordion-button {
    border: none !important;
    border-radius: 50px !important;
    background: #f5f9ff;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.accordion .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.accordion .accordion-collapse {
    border: none !important;
}

.accordion .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #6a26da;
    border-radius: 50px !important;
    font-weight: 500;
}

.accordion .accordion-header .accordion-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #f5f9ff;
    font-size: 18px;
    color: #1d293f;
    padding: 22px 30px 22px 42px;
    width: 100%;
    text-align: left;
    border: none;
    font-weight: normal;
    position: relative;
    border-radius: 50px;
    font-weight: 500;
    text-transform: capitalize;
}

.accordion .accordion-header .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #e7e7e7;
}

.accordion .accordion-header .btn:after {
    content: "\f078";
    margin-left: auto;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
}

.accordion .accordion-header .btn:hover {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.accordion .accordion-header .accordion-button:not(.collapsed) {
    background: #6a26da;
    color: #ffffff;
}

.accordion .accordion-header .accordion-button:not(.collapsed):after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.accordion-button::after {
    background-image: none !important;
}

/* accordion-big */
.accordion.accordion-big .accordion-button {
    padding: 1.6rem 2.25rem;
    font-size: 20px;
}

.accordion.accordion-success .accordion-header .accordion-button.collapsed {
    color: #1d293f;
    background-color: #f5f9ff;
}

.accordion.accordion-success .accordion-header .accordion-button {
    color: #ffffff;
    background-color: #0fbc49;
}

.accordion.accordion-success .accordion-header .accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #0fbc49;
}

/*****************************
  Counter
*****************************/
.counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0px;
}

.counter .counter-icon i {
    color: #6a26da;
    margin-right: 25px;
    font-size: 70px;
    line-height: 70px;
}

.counter .counter-content {
    -ms-flex-item-align: center;
    align-self: center;
}

.counter .counter-content .timer {
    position: relative;
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    font-family: "DM Sans", sans-serif;
    color: #ffffff;
    margin-bottom: 10px;
    display: block;
}

.counter .counter-content label {
    display: block;
    color: #ffffff;
    margin-bottom: 0;
}

.counter.counter-01 .counter-content span {
    font-size: 55px;
}

/* Counter 02 */
.counter.counter-02 .counter-content .timer {
    color: #1d293f;
}

.counter.counter-02 .counter-content label {
    color: #7c8087;
}

.counter.counter-02 .counter-icon i {
    color: #dfdfdf;
}

/*****************************
    Portfolio
  *****************************/
.filters-group {
    display: table;
    margin: 0 auto 30px;
    text-align: center;
}

.filters-group button {
    margin: 0 4px 0;
    padding: 10px 25px;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    border-radius: 4px;
    background-color: #ffffff;
    color: #18191c;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: none;
}

.filters-group button:hover {
    background-color: #6a26da;
    color: #ffffff;
}

.filters-group button.active {
    background-color: #6a26da;
    color: #ffffff;
}

.my-shuffle-container {
    margin-left: -15px;
    margin-right: -15px;
}

.my-shuffle-container .grid-item {
    width: 33.33%;
    padding: 15px;
}

.my-shuffle-container .portfolio {
    margin-bottom: 0;
}

/*************************************
           shuffle
   *************************************/
.my-shuffle-container.columns-1 .grid-item {
    width: 100%;
    padding: 15px;
}

.my-shuffle-container.columns-2 .grid-item {
    width: 50%;
    padding: 15px;
    float: left;
}

.my-shuffle-container.columns-3 .grid-item {
    width: 33.33333333%;
    padding: 15px;
}

.my-shuffle-container.columns-4 .grid-item {
    width: 25%;
    padding: 10px;
}

.my-shuffle-container.columns-5 .grid-item {
    width: 20%;
    padding: 15px;
}

.my-shuffle-container.columns-6 .grid-item {
    width: 16.666666%;
    padding: 0px;
}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
    width: 100%;
}

/* Responsive */
@media (max-width: 991px) {
    .filters-group button {
        margin: 0 5px 10px;
    }

    .filters-group button:first-child {
        margin-left: 0;
    }

    .filters-group button:last-child {
        margin-right: 0;
    }
}

@media (max-width: 1199px) {

    .my-shuffle-container .grid-item,
    .my-shuffle-container.columns-5 .grid-item {
        width: 25%;
    }
}

@media (max-width: 991px) {

    .my-shuffle-container .grid-item,
    .my-shuffle-container.columns-4 .grid-item,
    .my-shuffle-container.columns-5 .grid-item,
    .my-shuffle-container.columns-6 .grid-item {
        width: 33.333%;
    }
}

@media (max-width: 767px) {

    .my-shuffle-container .grid-item,
    .my-shuffle-container.columns-3 .grid-item,
    .my-shuffle-container.columns-4 .grid-item,
    .my-shuffle-container.columns-5 .grid-item,
    .my-shuffle-container.columns-6 .grid-item {
        width: 50%;
    }
}

@media (max-width: 575px) {

    .my-shuffle-container .grid-item,
    .my-shuffle-container.columns-2 .grid-item,
    .my-shuffle-container.columns-3 .grid-item,
    .my-shuffle-container.columns-4 .grid-item,
    .my-shuffle-container.columns-5 .grid-item,
    .my-shuffle-container.columns-6 .grid-item {
        width: 100%;
    }

    .my-shuffle-container {
        margin: 0;
    }
}

.portfolio {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.portfolio .portfolio-title {
    display: block;
    color: #1d293f;
    font-size: 18px;
    font-weight: 500;
}

.portfolio .portfolio-title:hover {
    color: #6a26da;
}

.portfolio .portfolio-services {
    display: block;
    margin-bottom: 10px;
}

.portfolio .portfolio-services:hover {
    color: #18191c;
}

.portfolio .portfolio-info {
    padding: 20px;
    width: calc(100% - 30px);
    position: absolute;
    left: 50%;
    bottom: 15px;
    z-index: 2;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background: #ffffff;
}

.portfolio .portfolio-info p {
    max-height: 66px;
    overflow: auto;
}

.portfolio:hover .portfolio-info {
    opacity: 1;
    bottom: 20px;
}

.portfolio .portfolio-lg+.portfolio-info p {
    max-height: 340px;
    overflow: auto;
}

/* style 03 */
.portfolio-style-01 {
    position: relative;
    border-radius: 8px;
}

.portfolio-style-01:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.portfolio-style-01:hover:before {
    height: auto;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.portfolio-style-01 .portfolio-info {
    background: #ffffff;
    border-radius: 3px;
    opacity: 0;
}

.portfolio-style-01 .portfolio-info p {
    color: #ffffff;
}

.portfolio-style-01 .portfolio-info .portfolio-services {
    color: #ffffff;
}

.portfolio-style-01 .portfolio-info .portfolio-services:hover {
    color: #ffffff;
}

.portfolio-style-01 .portfolio-info .portfolio-title {
    color: #ffffff;
}

.portfolio-style-01 .portfolio-info .portfolio-title:hover {
    color: #ffffff;
}

.portfolio-style-01 .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px;
}

.portfolio-style-03 {
    position: relative;
}

.portfolio-style-03 .portfolio-images {
    position: relative;
}

.portfolio-style-03 .portfolio-info-02 {
    background: transparent;
}

.portfolio-style-03 .portfolio-info-02 .popup-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    margin: 0 auto;
    top: 40%;
    opacity: 0;
    visibility: hidden;
    right: 0;
    left: 0;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #ffffff;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.portfolio-style-03:hover .portfolio-info-02 .popup-icon {
    opacity: 1;
    top: 50%;
    visibility: visible;
}

/* Grid Style 01 */
.category-grid-style-01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-radius: 50px;
}

.category-grid-style-01 .category-item {
    padding: 0px 30px;
    text-align: center;
    width: 16.6666%;
    position: relative;
    cursor: pointer;
}

.category-grid-style-01 .category-item:nth-child(6n+0) {
    border-right: none;
}

.category-grid-style-01 .category-item:before {
    content: "";
    background: #ffffff;
    width: 110%;
    height: 110%;
    display: inline-block;
    position: absolute;
    left: -5%;
    top: -5%;
    z-index: 1;
    opacity: 0;
    -webkit-transform: scale(0.96);
    transform: scale(0.96);
}

.category-grid-style-01 .category-item .category-icon {
    color: #000000;
    font-size: 44px;
    line-height: 1;
    margin-bottom: 15px;
    position: relative;
    z-index: 2;
    border-radius: 15px;
    padding: 16px;
}

.category-grid-style-01 .category-item .category-title {
    color: #1d293f;
    font-weight: bold;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    font-size: 16px;
}

.category-grid-style-01 .show-more-cat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 60px 0 -20px 0;
    width: 100%;
}

.category-grid-style-01 .show-more-cat a {
    color: #1d293f;
    font-size: 12px;
    font-weight: bold;
}

.category-grid-style-01 .show-more-cat a:hover {
    color: #6a26da;
}

.category-grid-style-01 .show-more-cat i {
    margin: 5px auto 0 auto;
}

.category-grid-style-01 .icon-danger {
    background: rgba(220, 53, 69, 0.1);
}

.category-grid-style-01 .icon-warning {
    background: rgba(255, 193, 7, 0.1);
}

.category-grid-style-01 .icon-info {
    background: rgba(23, 162, 184, 0.1);
}

.category-grid-style-01 .icon-success {
    background: rgba(15, 188, 73, 0.1);
}

.category-grid-style-01 .icon-primary {
    background: rgba(106, 38, 218, 0.1);
}

.category-grid-style-01 .icon-dark {
    background: rgba(29, 41, 63, 0.1);
}

/*************************
         991px
  *************************/
@media (max-width: 991px) {
    .category-grid-style-01 .category-item {
        padding: 0px 15px;
        width: auto;
        margin-bottom: 20px;
    }

    .category-grid-style-01 {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

/*****************************
  Tabs
*****************************/
.tabs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.tabs .nav-link {
    background: #ffffff;
    padding: 30px;
    -webkit-box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-left: 6px solid #ffffff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}

.tabs i {
    font-size: 60px;
    margin-right: 15px;
}

.tabs .contant p {
    color: #18191c;
}

.tabs .nav-link.active {
    background: #ffffff;
    padding: 30px;
    -webkit-box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-left: 6px solid #6a26da;
}

.tabs .nav-link.active p {
    color: #18191c;
}

.tabs .nav-link.active i {
    color: #6a26da;
}

.tabs .nav-link i {
    color: #18191c;
}

.tab-content .tab-pane img {
    border: 6px solid #6a26da;
    border-radius: 4px;
}

#nav-tabContent #nav-reviews img {
    border: inherit;
}

.nav-tabs-02 .nav-item a {
    color: #6a26da;
}

/*****************************
    team
  *****************************/
.team {
    background: #ffffff;
    padding: 20px 45px;
    text-align: center;
}

.team .team-img img {
    border-radius: 50%;
}

.team .team-info {
    text-align: center;
    margin-top: 40px;
}

.team .team-info ul {
    margin-bottom: 0px;
}

.team .team-info ul li {
    display: inline-block;
    margin: 0px 10px;
}

.team .team-info a.team-name {
    font-size: 22px;
}

.team .team-info .team-leader {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
}

.team.team-hover {
    background-color: #f5f9ff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.team.team-hover:hover {
    background-color: #ffffff;
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
}

@media (max-width: 991px) {
    .team {
        padding: 20px;
    }
}

/*****************************
    Owl carousel
  *****************************/
.owl-carousel .owl-item img {
    width: auto;
    display: inherit;
}

.owl-carousel .owl-nav {
    cursor: pointer;
    z-index: 999;
    width: 100%;
}

.owl-carousel .owl-nav .owl-prev {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    left: 10px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0;
}

.owl-carousel .owl-nav .owl-next {
    display: block;
    position: absolute;
    text-indent: inherit;
    width: auto;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    right: 10px;
    display: block;
    position: absolute;
    text-indent: inherit;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 !important;
    display: inline-block;
    margin: 14px 2px 0;
}

.owl-carousel .owl-nav i {
    font-size: 16px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    color: #6a26da;
    background: #7c8087;
    border: 0px solid #e7e7e7;
    text-align: center;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.owl-carousel .owl-nav i:before {
    display: block;
}

.owl-carousel .owl-nav i:hover {
    background: #6a26da;
    color: #7c8087;
}

.owl-carousel .owl-controls .owl-dot {
    margin: 0;
    display: inline-block;
}

.owl-carousel .owl-dots {
    display: inline-block;
    margin-top: 20px;
    text-indent: inherit;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.owl-carousel .owl-dots .owl-dot span {
    background: #ddd;
    display: inline-block;
    width: 12px;
    min-height: 12px;
    margin-top: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.owl-carousel .owl-dots .owl-dot:hover span {
    background: #6a26da;
}

.owl-carousel .owl-dots .owl-dot.active span {
    background: #6a26da;
}

.owl-carousel .owl-dot {
    display: inline-block;
    margin: 14px 2px 0;
}

.owl-nav-bottom .owl-nav {
    bottom: 0;
    top: inherit;
}

.owl-nav-bottom .owl-nav .owl-next {
    right: 45%;
    bottom: -70px;
    top: inherit;
}

.owl-nav-bottom .owl-nav .owl-prev {
    left: 45%;
    bottom: -70px;
    top: inherit;
}

.owl-nav-top-left .owl-nav {
    bottom: 0;
    top: inherit;
}

.owl-nav-top-left .owl-nav .owl-next {
    right: 0;
    top: -80px;
    -webkit-transform: inherit;
    transform: inherit;
}

.owl-nav-top-left .owl-nav .owl-prev {
    left: inherit;
    right: 50px;
    top: -80px;
    -webkit-transform: inherit;
    transform: inherit;
}

.owl-nav-top-left .owl-nav i {
    border: 1px solid transparent;
}

.owl-nav-top-left .owl-nav i:hover {
    border-color: #e7e7e7;
}

.owl-nav-top-right .owl-nav {
    top: 0;
    bottom: inherit;
}

.owl-nav-top-right .owl-nav .owl-next {
    right: 20px;
    top: 20px;
    bottom: inherit;
    -webkit-transform: inherit;
    transform: inherit;
}

.owl-nav-top-right .owl-nav .owl-prev {
    left: inherit;
    right: 80px;
    top: 20px;
    bottom: inherit;
    -webkit-transform: inherit;
    transform: inherit;
}

.owl-dots-bottom-center .owl-dots {
    bottom: 30px !important;
    position: absolute;
}

.owl-dots-bottom-center .owl-dots .owl-dot span {
    background: #ffffff;
}

.owl-dots-bottom-right .owl-dots {
    bottom: 30px !important;
    position: absolute;
    right: 30px;
    width: auto;
}

.owl-dots-bottom-right .owl-dots .owl-dot span {
    background: #ffffff;
}

.owl-dots-bottom-left .owl-dots {
    position: absolute;
    left: 0;
    width: auto;
    margin-top: 0;
}

.owl-dots-bottom-left .owl-dots .owl-dot span {
    background: #ffffff;
}

/* Client Logo */
.our-clients .owl-carousel .owl-stage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.owl-carousel .owl-item .property-item {
    margin-bottom: 0;
}

.carousel-control-next,
.carousel-control-prev {
    opacity: .6;
}

/*****************************
  Date time Picker
*****************************/
.bootstrap-datetimepicker-widget table td.active {
    background-color: #6a26da;
}

.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #6a26da;
}

.bootstrap-datetimepicker-widget table td.day {
    height: 30px;
    line-height: 30px;
    width: 40px;
    font-size: 14px;
}

.bootstrap-datetimepicker-widget table th {
    font-size: 14px;
}

.bootstrap-datetimepicker-widget table td span.active {
    background: #6a26da;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
    width: 19rem;
}

/*****************************
  Feature Info
*****************************/
.feature-info {
    background: #ffffff;
    padding: 30px;
    -webkit-box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.07);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.feature-info .feature-icon i {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: rgba(106, 38, 218, 0.6);
    display: inline-block;
    font-size: 36px;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.feature-info .feature-icon .bg-success {
    background-color: rgba(15, 188, 73, 0.6) !important;
}

.feature-info .feature-icon .bg-danger {
    background-color: rgba(245, 38, 38, 0.6) !important;
}

.feature-info:hover .feature-icon i {
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.feature-info-style-01 {
    background: #ffffff;
    padding: 40px;
    padding-right: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.feature-info-style-01 .feature-icon {
    margin-right: 20px;
}

.feature-info-style-01 .feature-icon i {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #6a26da;
    display: inline-block;
    font-size: 62px;
    border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.feature-info-style-01 .feature-contant h6 {
    margin-bottom: 15px;
}

.feature-info-style-01 .feature-contant p {
    font-size: 16px;
    margin-top: 5px;
}

.step .icon h6 {
    background: #6a26da;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: auto;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 24px;
    text-align: center;
    font-weight: 400;
}

.step .contant {
    margin-left: 60px;
}

.bg-warning-light {
    background: rgba(255, 193, 7, 0.03);
}

.bg-success-light {
    background: rgba(15, 188, 73, 0.03);
}

.bg-primary-light {
    background: rgba(106, 38, 218, 0.03);
}

  .info-box {
    padding: 0px 10px;
    position: relative; }
    .info-box .icon i {
      font-size: 70px; }
    .info-box:before {
      content: "";
      position: absolute;
      z-index: -1;
      // background-image: url(../images/svg/shape.svg);
      width: 100px;
      height: 97px;
      top: 0px;
      left: 40%; }
    .info-box a {
      opacity: 0; }
    .info-box:hover a {
      opacity: 1; }

.info-box-03 {
    margin: 10px 0;
    padding: 20px 20px 20px 0px;
    border-radius: 12px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.info-box-03 .icon-xl {
    line-height: 35px;
    background: #0fbc49;
    color: #ffffff;
    font-size: 16px;
    border-radius: 50%;
    text-align: center;
    margin-right: 20px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35px;
    flex: 0 0 35px;
}

.info-box-03 .icon-lg {
    line-height: 25px;
    background: #0fbc49;
    color: #ffffff;
    font-size: 12px;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25px;
    flex: 0 0 25px;
}

.info-box-03 p {
    margin: 0px;
}

.info-box-style-02 {
    padding: 40px 30px 40px 30px;
    background: #ffffff;
    position: relative;
    -webkit-box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.055);
    box-shadow: -1px 4px 12px rgba(0, 0, 0, 0.055);
    z-index: 1;
}

.info-box-style-02 .icon {
    margin-bottom: 20px;
}

.info-box-style-02 .icon i {
    font-size: 55px;
}

.info-box-style-02:before {
  content: "";
  position: absolute;
  z-index: -1;
  // background-image: url(../images/svg/shape.svg);
  width: 100px;
  height: 97px;
  top: 90px;
  left: 10%; }
.info-box-style-02 a {
    opacity: 0;
}

.info-box-style-02:hover a {
    opacity: 1;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.info-box:before,
.info-box-style-02:before {
    -webkit-animation: rotating 8s linear infinite;
    animation: rotating 8s linear infinite;
}

.feature-step-timeline {
    position: relative;
}

.feature-step-timeline:before {
    position: absolute;
    top: 0;
    left: 35px;
    content: "";
    width: 8px;
    height: 100%;
    background: #f5f9ff;
    -webkit-transform: translate(-4px, 0);
    transform: translate(-4px, 0);
}

.feature-step-timeline .feature-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 30px;
}

.feature-step-timeline .feature-step .feature-step-number {
    width: 70px;
    height: 70px;
    line-height: 70px;
    -webkit-box-flex: 70px;
    -ms-flex: 70px 0 0px;
    flex: 70px 0 0;
    text-align: center;
    color: #6a26da;
    background: #f5f9ff;
    font-size: 24px;
    border-radius: 50px;
    position: relative;
}

.feature-step-timeline .feature-step .feature-step-number span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    color: #ffffff;
    background: #6a26da;
    border-radius: 50px;
}

.feature-step-timeline .feature-step .feature-info-content {
    padding-left: 30px;
}

.feature-step-timeline .feature-step:last-child {
    padding-bottom: 0;
}

/*************************
        Progress Bar
*************************/
.progress {
    position: relative;
    overflow: visible;
    height: .5rem;
}

.progress .progress-bar {
    border-radius: 50px;
    background-color: #6a26da;
}

.progress .progress-bar .progress-bar-title {
    position: absolute;
    left: 0;
    top: -30px;
    color: #7c8087;
    font-size: 14px;
}

.progress .progress-bar .progress-bar-number {
    position: absolute;
    right: 0;
    top: -30px;
    color: #7c8087;
    font-size: 14px;
}

/*****************************
    List
  *****************************/
ul.list {
    padding: 0;
    margin: 0;
}

ul.list li {
    list-style: none;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

ul.list li i {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    font-size: 12px;
    text-align: center;
    color: #6a26da;
    background: rgba(106, 38, 218, 0.1);
    border-radius: 50px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22px;
    flex: 0 0 22px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

ul.list li:last-child {
    margin-bottom: 0;
}

/*****************************
    Countdown
  *****************************/
.countdown {
    display: inline-block;
    text-align: center;
    margin: 0 20px;
}

.countdown span {
    font-size: 36px;
    line-height: 1;
    color: #6a26da;
    font-weight: 500;
}

.countdown p {
    font-size: 18px;
    text-transform: capitalize;
}

/*****************************
    Testimonial
  *****************************/
.testimonial-item {
    padding: 40px;
    background: #ffffff;
}

.testimonial-item .testimonial-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.testimonial-item .testimonial-info .testimonial-avatar-img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.testimonial-item .testimonial-content p {
    font-size: 18px;
    line-height: 30px;
}

.testimonial-item.shadow {
    -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
    margin: 15px 20px 30px;
}

.testimonial-style-01 {
    position: relative;
}

.testimonial-style-01 .quote i {
    position: absolute;
    z-index: -1;
    left: 40px;
    top: 10px;
    font-size: 100px;
    color: rgba(255, 255, 255, 0.1) !important;
}

.testimonial-style-01 .testimonial-item {
    background: inherit;
}

.testimonial-style-01 .testimonial-item .testimonial-content p {
    font-size: 22px;
    line-height: 32px;
}

.testimonial-style-01 .owl-dots {
    text-align: left;
    padding-left: 0px;
    margin-top: 0px;
}

.testimonial-style-01 .testimonial-img img {
    border-radius: 50%;
    border: 30px solid #ffffff;
}

.testimonial-style-01 .testimonial-img-01 img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    bottom: 200px;
    right: -200px;
}

.testimonial-style-01 .testimonial-img-02 img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    bottom: 40px;
    right: -150px;
}

.testimonial-style-01 .testimonial-img-03 img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    top: -400px;
    left: -180px;
}

.testimonial-style-01 .testimonial-quote {
    margin-bottom: 20px;
    position: relative;
}

.testimonial-style-01 .testimonial-quote i {
    font-size: 28px;
    display: inline-block;
    line-height: 28px;
    background: #18191c;
    border-radius: 50%;
    position: absolute;
    bottom: -200px;
    left: 145px;
    padding: 15px;
}

.owl-dots-dark .owl-dots .owl-dot.active span {
    background-color: #18191c;
}

/*****************************
    Button
  *****************************/
.btn {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
}

button {
    outline: medium none !important;
    color: #6a26da;
}

/* btn */
.btn {
    font-weight: 600;
    font-size: 13px;
    padding: 12px 26px;
    border-radius: 50px;
}

.btn:not(:disabled):not(.disabled).active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.btn:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.btn:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    color: #ffffff;
}

.btn:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    color: #ffffff;
}

.show>.btn.dropdown-toggle:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    color: #ffffff;
}

.show>.btn-primary.dropdown-toggle {
    background: #EB4721;
    border-color: #EB4721;
}

.btn-primary {
    background: #EB4721;
    border-color: #EB4721;
    box-shadow: 0px 5px 19px #EB472147;
    color: #ffffff;
    position: relative;
}

.btn-primary:hover {
    background: #1d293f;
    border-color: #1d293f;
    color: #ffffff;
}

.btn-primary:hover:not(:disabled):not(.disabled).active {
    background: #1d293f;
    border-color: #1d293f;
    color: #ffffff;
}

.btn-primary:hover:not(:disabled):not(.disabled):active {
    background: #1d293f;
    border-color: #1d293f;
    color: #ffffff;
}

.btn-primary:focus {
    background: #1d293f;
    border-color: #1d293f;
    color: #ffffff;
}

.btn-primary:focus {
    background: #1d293f;
    border-color: #1d293f;
    color: #ffffff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    background: #1d293f;
    border-color: #1d293f;
}

.btn-secondary {
    background: #7c8087;
    border-color: #7c8087;
    color: #ffffff;
    position: relative;
}

.btn-secondary:hover {
    background: #6d7177;
    border-color: #6d7177;
}

.btn-secondary:hover:not(:disabled):not(.disabled).active {
    background: #7c8087;
    border-color: #7c8087;
}

.btn-secondary:hover:not(:disabled):not(.disabled):active {
    background: #7c8087;
    border-color: #7c8087;
}

.btn-secondary:focus {
    background: #6d7177;
    border-color: #6d7177;
}

.btn-secondary:focus {
    background: #6d7177;
    border-color: #6d7177;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
    background: #7c8087;
    border-color: #7c8087;
}

.btn-dark {
    background: #18191c;
    border-color: #18191c;
}

.btn-dark:hover {
    background: #000000;
    border-color: #000000;
    color: #ffffff;
}

.btn:not(:disabled):not(.disabled).active:focus {
    color: #ffffff;
}

.btn i {
    padding-right: 10px;
}

.btn+.btn {
    margin-left: 3px;
}

.btn-link {
    padding: 0;
    color: #6a26da;
}

.btn-link:hover {
    color: #18191c;
    text-decoration: none;
    outline: none;
}

.btn-link:focus {
    color: #18191c;
    text-decoration: none;
    outline: none;
}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
    color: #18191c;
}

.btn-white {
    background: #ffffff;
    border-color: #ffffff;
    color: #18191c;
}

.btn-white:hover {
    background: #dfdfdf;
    border-color: #dfdfdf;
    color: #18191c;
}

.btn-white:active {
    color: #18191c;
}

.btn-white:focus {
    color: #18191c;
}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
    color: #18191c;
}

.custom-btn-light:active {
    color: #18191c;
}

.custom-btn-light:focus {
    color: #18191c;
}

.btn.custom-btn-light:not(:disabled):not(.disabled):active:focus {
    color: #18191c;
}

.btn.btn-sm {
    padding: 6px 24px;
}

.btn.btn-md {
    padding: 9px 26px;
}

.btn.btn-lg {
    padding: 13px 30px;
}

.btn.btn-xl {
    padding: 16px 40px;
}

.btn-app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.btn-app i {
    font-size: 40px;
}

.btn-outline-secondary {
    border: 2px solid #e7e7e7;
    color: #1d293f;
}

.btn-outline-secondary:hover {
    background: #1d293f;
    color: #ffffff;
    border-color: #18191c;
}

.btn-outline-secondary:focus {
    background: #18191c;
    color: #ffffff;
    border-color: #18191c;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
    background: #18191c;
    color: #ffffff;
    border-color: #18191c;
}

/* Button Outline */
.btn-outline-light {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
}

.btn-outline-light:hover {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

.btn-outline-light:focus {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

.btn-outline-light:not(:disabled):not(.disabled).active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

.btn-outline-light:not(:disabled):not(.disabled):active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

/* Button Outline */
.btn-outline-primary {
    background-color: transparent;
    color: #6a26da;
    border-color: #6a26da;
}

.btn-outline-primary:hover {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
}

.play-video-lg {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 16px;
    background: #ffffff;
    color: #6a26da;
    display: block;
    border-radius: 50%;
    text-align: center;
}

.play-video-md {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 16px;
    background: #ffffff;
    color: #6a26da;
    display: block;
    border-radius: 50%;
    text-align: center;
}

.play-video-02 {
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 16px;
    background: #6a26da;
    color: #ffffff;
    display: block;
    border-radius: 0;
    text-align: center;
}

.play-video-02:hover {
    color: #6a26da;
    background: #ffffff;
}

.play-video-02:focus {
    color: #6a26da;
    background: #ffffff;
}

.video-btn.btn-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/*****************************
    Pricing Table
  *****************************/
.pricing-tab {
    text-align: center;
}

.pricing-tab span {
    vertical-align: middle;
}

.pricing-tab .monthly-tab {
    font-weight: 700;
    font-size: 18px;
    color: #1d293f;
}

.pricing-tab .yearly-tab {
    font-weight: 700;
    font-size: 18px;
    color: #1d293f;
}

.pricing {
    padding: 0px 10px 40px 10px;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.pricing .pricing-plan {
    padding: 40px 30px;
}

.pricing .pricing-plan .pricing-title {
    margin-bottom: 10px;
}

.pricing .pricing-price {
    display: inline-block;
    position: relative;
    color: #7c8087;
    font-weight: 600;
    margin-top: 30px;
}

.pricing .pricing-price sup {
    position: absolute;
    top: 15px;
    left: -20px;
    font-size: 24px;
    color: #1d293f;
}

.pricing .pricing-price strong {
    color: #1d293f;
    font-size: 50px;
    line-height: 50px;
    padding-right: 0px;
    font-weight: 500;
}

.pricing .yearly-price {
    display: none;
}

.pricing .change-pricing-price .yearly-price {
    display: block;
}

.pricing .change-pricing-price .monthly-price {
    display: none;
}

.pricing .pricing-list {
    margin-top: 30px;
    margin-bottom: 30px;
}

.pricing .pricing-list li {
    padding: 6px 0;
    color: #7c8087;
    font-weight: 500;
}

.pricing .pricing-list li:last-child {
    padding-bottom: 0;
}

.pricing.active {
    background: #EB4721;
}

.pricing.pricing-01 {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #ffffff;
    text-align: left;
    padding: 30px;
}

.pricing.pricing-01 .pricing-plan {
    padding: 0 0 30px;
}

.pricing.pricing-01 .pricing-plan .pricing-price {
    padding-left: 20px;
}

.pricing.pricing-01 .pricing-plan .pricing-price sup {
    left: 0;
}

.pricing.pricing-01 .pricing-plan .pricing-title {
    margin-bottom: 15px;
}

.pricing.pricing-01 .pricing-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pricing.pricing-01 .pricing-list li i {
    padding: 4px;
    font-size: 8px;
    background: #dfdfdf;
    color: #7c8087;
    border-radius: 50px;
}

.pricing.pricing-01:hover {
    background-color: #EB4721;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.pricing.pricing-01:hover .pricing-list li {
    color: #ffffff;
}

.pricing.pricing-01:hover .pricing-list li i {
    background: #ffffff;
    color: #EB4721;
}

.pricing.pricing-01:hover .btn-primary {
    background: #ffffff;
    color: #EB4721;
}

.pricing.pricing-01:hover .pricing-plan .pricing-title {
    color: #ffffff;
}

.pricing.pricing-01:hover .pricing-plan .pricing-description p {
    color: #ffffff;
}

.pricing.pricing-01:hover .pricing-plan .pricing-price {
    color: #ffffff;
}

.pricing.pricing-01:hover .pricing-plan .pricing-price sup {
    color: #ffffff;
}

.pricing.pricing-01:hover .pricing-plan .pricing-price strong {
    color: #ffffff;
}

/*****************************
  Select
  *****************************/
.select2-container .select2-selection--single {
    height: auto;
    outline: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0px;
}

.select2-container--default .select2-selection--single {
    border-radius: 0px;
    border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #1d293f;
    font-weight: normal;
    line-height: inherit;
    text-transform: capitalize;
}

.bigdrop {
    min-width: 196px !important;
}

.select2-results__options {
    padding: 8px 2px;
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-results__option--highlighted {
    border-radius: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    padding-left: 10px;
    font-weight: 600;
}

input[type="radio"]:radio {
    background-color: #6a26da;
}

/* Select Dropdown Arrow */
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 14px;
    right: 10px;
    height: 21px;
    width: 14px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    color: #6a26da;
}

.select2-container--default .select2-search--dropdown {
    padding: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #e7e7e7;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #f5f9ff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: rgba(106, 38, 218, 0.1);
    color: #6a26da;
}

.select2-dropdown {
    border: none;
    border-radius: 0px;
    z-index: 8;
}

.select-border .select2-container--default .select2-selection--single {
    border: none;
    height: 50px;
    padding: 15px 20px;
    border-radius: 4px;
    background: #f5f9ff;
}

.select-border .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 16px;
    right: 20px;
}

.select2-results__option[aria-selected] {
    text-transform: capitalize;
}

.select2-container--default .select2-results>.select2-results__options {
    border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #7c8087;
}

.select2-container--open .select2-dropdown {
    background: #f5f9ff;
    padding: 7px;
    border: none;
}

.select2-search--dropdown .select2-search__field {
    border: 1px solid #e7e7e7;
    -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
}

.select2-container--default .select2-selection--single {
    height: 50px;
    padding: 15px 20px;
}

.custom-control-input:radio {
    background-color: #6a26da;
}

.newsletter .search input::-webkit-input-placeholder {
    color: #ffffff;
}

.newsletter .search input::-moz-placeholder {
    color: #ffffff;
}

.newsletter .search input:-ms-input-placeholder {
    color: #ffffff;
}

.newsletter .search input::-ms-input-placeholder {
    color: #ffffff;
}

.newsletter .search input::placeholder {
    color: #ffffff;
}

.form-check .form-check-input {
    float: inherit;
}

.form-check-input:checked {
    background-color: #6a26da;
    border-color: #6a26da;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}

/*****************************
      Range Slider
  *****************************/
.irs--flat .irs-line {
    height: 6px;
}

.irs--flat .irs-bar {
    background: #6a26da;
    height: 6px;
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    background: transparent;
    color: #6a26da;
    font-size: 13px;
    font-weight: 600;
}

.irs--flat .irs-handle>i:first-child,
.irs--flat .irs-handle>i:first-child {
    background: #ffffff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #6a26da;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
    display: none;
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle:hover>i:first-child {
    background: #6a26da;
}

/*************************
       Badge
*************************/
.badge-rounded-circle {
    height: 1.5rem;
    width: 1.5rem;
    line-height: 1.40rem;
    border-radius: 10rem;
    text-align: center;
    padding: .1rem;
}

.badge-rounded-circle>* {
    margin-left: -1px;
}

.badge-primary-soft {
    background-color: rgba(106, 38, 218, 0.1);
    color: #6a26da;
}

.badge-secondary-soft {
    background-color: rgba(124, 128, 135, 0.1);
    color: #7c8087;
}

.badge-success-soft {
    background-color: rgba(15, 188, 73, 0.1);
    color: #0fbc49;
}

.badge-danger-soft {
    background-color: rgba(245, 38, 38, 0.1);
    color: #f52626;
}

.badge-warning-soft {
    background-color: rgba(255, 193, 7, 0.1);
    color: #ffc107;
}

/*****************************
      Header
  *****************************/
.header {
    z-index: 999;
    position: relative;
}

.header .container-fluid {
    padding: 0 60px;
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: initial;
}

/* Header default */
.header .is-sticky.navbar {
    background: #ffffff;
}

/* topbar */
.header .topbar {
    padding: 8px 0;
}

.header.bg-dark .topbar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.header .topbar a {
    color: #6a26da;
    font-size: 13px;
}

.header .topbar a:hover {
    color: #1d293f;
}

.header .topbar .dropdown {
    margin-right: 20px;
}

.header .topbar .dropdown .dropdown-toggle {
    padding: 12px 0;
}

.header .topbar .dropdown .dropdown-toggle i {
    font-size: 10px;
}

.header .topbar .dropdown .dropdown-menu a {
    color: #1d293f;
}

.header .topbar .dropdown .dropdown-menu a:hover {
    color: #6a26da;
}

.header .topbar .social {
    display: inline-block;
}

.header .topbar .social ul {
    margin: 0;
}

.header .topbar .social ul li {
    display: inline-block;
    padding-right: 40px;
}

.header .topbar .social ul li a {
    color: #1d293f;
}

.header .topbar .social ul li a:hover {
    color: #6a26da;
}

.header .topbar .login {
    display: inline-block;
}

.header .topbar .login a {
    color: #1d293f;
}

.header .topbar .login a:hover {
    color: #6a26da;
}

.topbar .dropdown .dropdown-menu {
    border-radius: 0;
    border: none;
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.topbar .dropdown .dropdown-menu a {
    font-size: 14px;
    padding: 10px 20px;
    font-weight: 500;
    border-radius: 3px;
}

.topbar .dropdown .dropdown-menu a:hover {
    color: #6a26da;
}

.navbar {
    padding: 0;
}

.navbar .navbar-nav .nav-link {
    font-weight: 500;
    font-size: 17px;
    padding: 10px 20px;
    color: #1d293f;
    font-family: "DM Sans", sans-serif;
}

.navbar .navbar-nav .nav-link i {
    font-weight: bold;
}

.navbar .navbar-nav .nav-link:hover {
    color: #6a26da;
}

.dropdown-menu {
    z-index: 9999;
}

.navbar-collapse {
    position: relative;
}

.navbar-light {
    color: #ffffff;
    background-color: #f5f9ff;
    border-color: #e7e7e7;
}

.navbar-light .navbar-nav>li>a {
    color: #ffffff;
}

.navbar-light .navbar-nav>.dropdown>a .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}

.navbar-brand {
    padding: 25px 0px;
    margin-right: 0;
}

.navbar-brand img {
    height: 55px;
}

.navbar .navbar-brand {
    color: #ffffff;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 220px;
    flex: 0 0 220px;
}

.header .navbar-nav>li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header .navbar-nav li>a {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 3px 0px;
    color: #1d293f;
    text-transform: capitalize;
    font-size: 14px;
}

.header .navbar-nav li>a:hover {
    color: #6a26da;
}

.header .navbar-nav li>a i {
    margin-left: 5px;
    font-size: 10px;
}

.header .add-listing {
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 135px;
    flex: 0 0 135px;
    text-align: right;
}

.megamenu {
    padding: 20px 20px;
    width: 100%;
}

.megamenu>div>li>ul {
    padding: 0;
    margin: 0;
}

.megamenu>div>li>ul>li {
    list-style: none;
}

.megamenu .card {
    outline: none;
}

.megamenu .card:hover,
.megamenu .card:focus {
    outline: 1px solid #000000;
}

.megamenu>div>li>ul>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #18191c;
    white-space: normal;
}

.megamenu>div>li>ul>li>a:hover,
.megamenu>div>li>ul>li>a:focus {
    text-decoration: none;
    color: #18191c;
    background-color: #f5f9ff;
}

.megamenu.disabled>a,
.megamenu.disabled>a:hover,
.megamenu.disabled>a:focus {
    color: #f5f9ff;
}

.megamenu.disabled>a:hover,
.megamenu.disabled>a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: not-allowed;
}

.megamenu.dropdown-header {
    color: #6a26da;
    font-size: 18px;
}

.header li>.dropdown-item:focus,
.header li>.dropdown-item:hover {
    color: #6a26da;
    background: none;
}

.header .dropdown-item.active,
.header .dropdown-item:active,
.header .dropdown-item:focus,
.header .dropdown-item:hover {
    background: none;
}

.header .dropdown-toggle::after {
    content: none;
}

.header .navbar-collapse {
    -webkit-box-align: inherit;
    -ms-flex-align: inherit;
    align-items: inherit;
    width: auto;
}

.header .megamenu .dropdown-item {
    padding: 0px;
}

.header .navbar .dropdown-menu a.dropdown-item {
    min-width: 180px;
    font-family: "DM Sans", sans-serif;
}

.navbar .navbar-nav .dropdown-menu li.active>a {
    padding-left: 0px !important;
}

.header .navbar .dropdown>.dropdown-menu li>a:hover {
    padding-left: 10px !important;
}

.header .navbar .dropdown-menu {
    padding: 0;
}

.header .navbar .dropdown-menu li a i {
    margin-left: auto;
}

.navbar .dropdown>.dropdown-menu li>a {
    font-size: 14px;
    padding: 10px 20px 10px 0px;
    border-radius: 3px;
    position: relative;
}

.navbar .dropdown>.dropdown-menu li>a:hover,
.navbar .dropdown>.dropdown-menu li.active>a {
    color: #6a26da;
    padding-left: 15px;
}

/* Header default */
.header.default {
    background: #000000;
}

.header.default .navbar {
    padding: 0px 0;
}

.header.default .navbar .navbar-brand {
    margin-right: 0px;
}

.header.default .navbar .navbar-nav .nav-link {
    color: #000000;
}

.header.default .navbar .navbar-nav .nav-link:hover {
    color: #EB4721;
}

.header.default .navbar .navbar-nav .nav-item.active .nav-link {
    color: #EB4721;
}

.header.default .navbar .navbar-nav .dropdown-menu li.active>a {
    color: #EB4721;
    padding-left: 10px;
}

.header.default .topbar .search ul {
    padding-left: 0px;
}

.header.default .topbar .search ul li {
    list-style: none;
}

.header.default .topbar .search ul li i {
    font-size: 24px;
    font-weight: 900;
}

.header.default .topbar .dropdown {
    margin-right: 0px;
}

/* Header center */
.header.header-center .navbar .navbar-brand {
    display: none;
}

.header.header-center .is-sticky.navbar {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.header.header-center .is-sticky.navbar .navbar-brand {
    display: block;
    text-align: left;
}

.header.header-center.bg-dark .is-sticky.navbar {
    background: #18191c !important;
}

/* Header transparent */
.header.default.header-transparent {
    position: absolute;
    background: transparent !important;
    width: 100%;
    z-index: 99;
}

.header.default.header-transparent.sticky-top {
    position: fixed !important;
    width: 100%;
    background: #ffffff !important;
    top: 0;
    z-index: 99;
    -webkit-box-shadow: 0 2px 7px rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.06);
}

.header.default.header-transparent.sticky-top .topbar {
    display: none !important;
}

.header.default.header-transparent .search ul {
    padding-left: 0px;
    margin-bottom: 0px;
}

.header.default.header-transparent .search ul li {
    list-style: none;
}

.header.default.header-transparent .search ul li a {
    color: #6a26da;
}

.header.default.header-transparent.header-transparent-light .search ul li a {
    color: #ffffff;
}

.header.default.header-transparent .social ul {
    margin-bottom: 0px;
}

.header.default.header-transparent .social ul li {
    margin-right: 20px;
    display: inline-block;
}

.header.default.header-transparent .social ul li a {
    color: #000000;
}

.header.default.header-transparent .social ul li:hover a {
    color: #6a26da;
}

.navbar .navbar-nav .dropdown-menu li.active>a {
    color: #6a26da;
    padding-left: 10px;
}

.header-transparent .add-listing {
    -ms-flex-item-align: inherit;
    align-self: inherit;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
    text-align: right;
}

.header-transparent .login a {
    color: #ffffff;
}

.header-transparent .login a:hover {
    color: #6a26da;
}

.header.default.bg-dark .navbar .navbar-nav .nav-link {
    color: #ffffff;
}

.header.default.bg-dark .navbar .navbar-nav .nav-link:hover {
    color: #6a26da;
}

.header.default.bg-dark .topbar .social ul li a {
    color: #ffffff;
}

.header.default.bg-dark .topbar .social ul li:hover a {
    color: #6a26da;
}

/* Header transparent light */
.header.default.header-transparent.header-transparent-light .navbar .navbar-nav .nav-link {
    color: #ffffff;
}

.header.default.header-transparent.header-transparent-light .navbar .navbar-nav .nav-link:hover {
    color: #ffffff;
}

.header.default.header-transparent.header-transparent-light .social ul li a {
    color: #ffffff;
}

.header.default.header-transparent.header-transparent-light.sticky-top {
    position: fixed !important;
    width: 100%;
    background: #6a26da !important;
    top: 0;
    z-index: 99;
}

/* Search */
.search .search-btn {
    display: block;
    height: 22px;
    line-height: 32px;
    position: relative;
    text-indent: -9999px;
    width: 14px;
    color: #6a26da;
    -webkit-transition: color 300ms ease 0s;
    transition: color 300ms ease 0s;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.search .search-btn:before {
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-size: 14px;
    font-weight: 900;
    right: 0;
    position: absolute;
    text-indent: 0;
    top: 0;
}

.search .search-box button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.search .form-control {
    -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border: none;
}

.search.search-open .search-btn:before {
    content: "\f00d";
}

.search.search-open .search-box {
    opacity: 1 !important;
    visibility: visible !important;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
}

.search.search-open .search-box .form-control {
    padding-right: 50px;
}

.search-box {
    display: block;
    margin: 0;
    opacity: 0 !important;
    position: absolute;
    right: 0;
    top: 70%;
    -webkit-transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
    transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
    visibility: hidden;
    width: 327px;
    z-index: 888;
    border-radius: 3px;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
}

.search-box.open {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
}

.header .is-sticky.navbar {
    position: fixed !important;
    width: 100%;
    background: #ffffff !important;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    top: 0;
    left: 0;
    z-index: 9;
    padding: 0 100px;
}

.header .is-sticky.navbar .navbar-brand {
    display: block;
}

.header .is-sticky.navbar .header-contact-info {
    display: none !important;
}

.header .is-sticky.navbar .navbar-nav .nav-link {
    padding: 10px 20px;
}

.header-transparent .is-sticky.navbar {
    position: fixed !important;
    width: 100%;
    background: #18191c;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    top: 0;
    z-index: 9;
}

.header-transparent .is-sticky.navbar .header-contact-info {
    display: none !important;
}

.header-transparent .is-sticky.navbar .navbar {
    position: relative;
    top: 0px;
    right: 0px;
    left: 0;
    border-top: none !important;
    width: 100%;
}

.nav-title {
    line-height: 1.6;
}

.header.default.header-transparent.mobile-aap .navbar .navbar-nav .nav-link {
    color: #ffffff;
}

.header.default.header-transparent.mobile-aap .navbar .navbar-nav .nav-link:hover {
    color: #000000;
}

.header.default.header-transparent.mobile-aap .social.light ul li a {
    color: #ffffff;
}

@media (min-width: 992px) {
    .header .navbar .dropdown-menu {
        margin: 0px;
        font-size: 14px;
        border-radius: 0px;
        padding: 16px;
        border-color: transparent;
        left: 100%;
        -webkit-transform: translate3d(-50%, 10px, 0);
        transform: translate3d(-50%, 10px, 0);
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transition-property: opacity, visibility, -webkit-transform;
        transition-property: opacity, visibility, -webkit-transform;
        transition-property: opacity, visibility, transform;
        transition-property: opacity, visibility, transform, -webkit-transform;
        -webkit-transform-origin: top center;
        transform-origin: top center;
        -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        visibility: hidden;
        opacity: 0;
        display: block !important;
        border-top: 4px solid #6a26da;
    }

    .header .navbar .dropdown-menu.megamenu {
        left: 50%;
    }

    .navbar-nav .dropdown-menu.dropdown-menu-lg {
        min-width: 630px;
    }

    .navbar-nav .dropdown-menu.dropdown-menu-md {
        min-width: 450px;
    }

    .navbar-nav .mega-menu {
        position: static;
    }

    .header .navbar .dropdown>.dropdown-menu {
        top: 100%;
    }

    .header .navbar .dropdown:hover>.dropdown-menu {
        -webkit-transform: translate3d(-50%, 0, 0);
        transform: translate3d(-50%, 0, 0);
        visibility: visible;
        opacity: 1;
    }

    .header .navbar .dropdown>.dropdown-menu {
        margin-top: 20px;
    }

    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
        left: 100%;
        right: auto;
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
    }

    .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
        right: 100%;
        left: auto;
        -webkit-transform: translate3d(-15px, 10px, 0);
        transform: translate3d(-15px, 10px, 0);
    }

    .navbar-nav li:hover>ul.dropdown-menu {
        visibility: visible;
        opacity: 1;
    }

    .dropdown-submenu {
        position: relative;
    }

    .dropdown-submenu>.dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
    }

    .dropdown-menu>li>a:hover:after {
        text-decoration: underline;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}

.place-card-large {
    display: none !important;
}

@media (max-width: 1199px) {
    .header .container {
        max-width: 96%;
    }

    .header.default .navbar.is-sticky {
        padding: 0 30px;
    }

    .header.default.header-transparent .social ul li {
        margin-right: 15px;
    }

    .header.default.header-transparent .navbar .navbar-nav .nav-link {
        padding: 10px 11px;
    }
}

@media (max-width: 991px) {
    .header .is-sticky .navbar {
        top: 0;
        right: 0;
    }

    .header-right-bg:before {
        left: 0;
        height: 100%;
        -webkit-transform: inherit;
        transform: inherit;
    }

    .header .is-sticky.navbar {
        padding: 0 20px;
    }

    .header.default {
        padding: 0;
    }

    .header.default .navbar-brand {
        display: block;
    }

    .header.default .navbar-toggler {
        color: #1d293f;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        position: absolute;
        height: 75px;
        right: 0px;
        padding: 0;
    }

    .header .navbar-collapse {
        position: absolute;
        top: 100%;
        z-index: 999;
        background: #ffffff;
        width: 100%;
        max-height: 350px;
        overflow: auto;
        left: 0;
        margin-left: 0px;
        border-top: 1px solid #e7e7e7;
        border-bottom: 1px solid #e7e7e7;
        -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }

    .header.default.header-transparent .social {
        display: none;
    }

    .header.default .navbar {
        padding: 0px 0px !important;
    }

    .header.default .is-sticky.navbar {
        padding: 0px 20px !important;
    }

    /*header-center*/
    .header.header-center.default .topbar {
        display: none;
    }

    .header.header-center .navbar .navbar-brand {
        display: block;
        text-align: left;
    }

    .header.header-center.default .navbar {
        padding: 40px 0;
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    /*header-dark*/
    .header.header-center.default.bg-dark .navbar-toggler {
        color: #ffffff;
    }

    .header.header-center.default.bg-dark .navbar .navbar-nav .nav-link {
        color: #1d293f;
    }

    .header.header-center.default.bg-dark .navbar .navbar-nav .nav-item.active .nav-link {
        color: #6a26da;
    }

    .header .navbar .navbar-nav .nav-item.active .nav-link:after,
    .header .navbar .navbar-nav .nav-item.active .nav-link:before {
        display: none;
    }

    .header .navbar .navbar-nav .nav-item:hover .nav-link:after,
    .header .navbar .navbar-nav .nav-item:hover .nav-link:before {
        display: none;
    }

    .header.default.header-transparent .search ul {
        display: none !important;
    }

    .navbar .navbar-nav .nav-item {
        display: block;
    }

    .navbar .navbar-nav .nav-link {
        display: block;
        width: 100%;
    }

    .header .navbar-collapse {
        padding: 10px;
    }

    .navbar .dropdown>.dropdown-menu li>a {
        padding: 10px !important;
    }

    .navbar .navbar-nav .dropdown-menu li.active>a,
    .navbar .navbar-nav .nav-title {
        padding-left: 10px !important;
    }

    .navbar .navbar-nav .nav-title {
        margin-top: 10px;
    }

    .header.default.header-transparent.header-transparent-light .navbar .navbar-nav .nav-link,
    .header.default.header-transparent.header-transparent-light .navbar .navbar-nav .nav-link:hover {
        color: #18191c;
    }

    .header.default.header-transparent.header-transparent-light .navbar-toggler {
        color: #ffffff;
    }
}

@media (max-width: 575px) {
    .header.default .navbar-toggler {
        padding: 0;
        height: 60px;
    }

    .header.default .navbar-brand {
        padding: 20px 0px;
    }

    .header.default .navbar-brand img {
        height: 24px;
    }

    .banner.slider-02 {
        padding: 0;
    }

    .header .topbar {
        padding: 0px;
    }
}

#search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

#search-overlay.open {
    z-index: 9999999;
    visibility: visible;
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.95);
    -webkit-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
}

#search-overlay.open {
    background-color: rgba(255, 255, 255, 0.95);
}

.info-box-02 {
    margin: 10px 0;
    padding: 20px;
    border-radius: 12px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.info-box-02:hover {
    background: #ffffff;
}

.info-box-02 .icon-xl {
    width: 60px;
    height: 60px;
    line-height: 60px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
    background: #6a26da;
    color: #fff !important;
    font-size: 30px;
    border-radius: 50%;
    text-align: center;
    margin-right: 20px;
}

.info-box-02:hover {
    -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

.info-box-02.active {
    background: #ffffff;
    -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
}

/*****************************
            Sidebar
  *****************************/
.categorize li a {
    background: #f5f9ff;
    color: #7c8087;
    padding: 16px 30px;
    display: block;
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: 600;
}

.categorize li a span {
    display: block;
}

.categorize li a.active {
    border-left: 5px solid #6a26da;
    color: #6a26da;
}

/*****************************
      Banner
  *****************************/
/* Banner 01 */
.banner .contant {
    position: relative;
    z-index: 9;
}

.banner .contant h1 {
    font-family: Roboto;
    font-weight: 900;
}

.banner .banner-img {
    position: relative;
    z-index: 99;
    bottom: 0px;
    right: 0px;
}

/* Mobile Banner */
.mobile-banner .swiper-slide {
    padding-top: 115px;
}

.rating ul {
    padding: 0;
    margin: 0;
}

.rating ul li {
    display: inline-block;
}

/* Banner 02 */
.banner.banner-02 .banner-img {
    position: absolute;
    z-index: 1;
    bottom: inherit;
    top: 80px;
    right: 0px;
}

/* Banner 03 */
.banner.banner-03 .banner-img {
    position: absolute;
    z-index: 1;
    bottom: inherit;
    top: 180px;
    right: 100px;
}

.banner-logo {
    padding: 10px 20px;
    background: #6a26da;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    border-radius: 7px;
    color: #ffffff;
    font-size: 70px;
    line-height: 60px;
    position: relative;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center;
    display: inline-block;
}

.banner-logo:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 21px;
    right: -30px;
    border-top: 20px solid rgba(106, 38, 218, 0);
    border-left: 20px solid #6a26da;
    top: 15px;
}

/* Banner Shop */
.slider-shop .swiper-slide {
    height: 680px;
}

.slider-shop .swiper-slide .banner-border {
    background: #000000;
    height: 1px;
    width: 120px;
    position: absolute;
    top: 16px;
    left: -125px;
}

.slider-shop .swiper-slide h1 {
    font-weight: 400;
    color: #000000;
}

.slider-shop .swiper-slide h5 {
    font-weight: 300;
    color: #000000;
    letter-spacing: 1px;
}

.slider-shop .swiper-button-prev,
.slider-shop .swiper-button-next {
    background-image: none !important;
}

@media (max-width: 1366px) {
    .banner.banner-02 {
        background: #6a26da;
    }

    .banner.banner-02 .banner-img {
        top: 120px;
    }

    .banner.banner-03 .banner-img {
        right: 0;
    }
}

@media (max-width: 1199px) {
    .slider-shop .swiper-slide {
        height: 450px;
    }

    .banner.banner-03 .banner-img {
        position: relative;
        z-index: 1;
        bottom: inherit;
        top: 0;
        right: 0px;
    }

    .banner.banner-03 {
        padding: 100px 0px 0px 0px;
    }

    .banner.banner-03 #main-slider {
        height: inherit;
    }

    .banner.banner-02 .banner-img {
        right: -190px;
    }
}

@media (max-width: 991px) {
    .slider-shop .swiper-slide {
        padding: 50px 0 0 0;
        height: inherit;
    }

    .banner-02 {
        padding: 100px 0 60px;
    }

    .banner-02 .h-900 {
        height: auto;
    }

    .info-box-03 {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .slider-shop .swiper-slide h1 {
        font-weight: 400;
        color: #000000;
        font-size: 38px;
    }

    .mobile-banner {
        padding: 40px 0px 80px 0;
    }

    .mobile-banner #main-slider {
        height: inherit;
    }

    .banner.marketing-banner {
        padding: 80px 0px 80px 0;
    }

    .banner.marketing-banner #main-slider {
        height: inherit;
    }

    .banner.marketing-banner .hero-app-1,
    .banner.marketing-banner .hero-app-2,
    .banner.marketing-banner .hero-app-3,
    .banner.marketing-banner .hero-app-4,
    .banner.marketing-banner .hero-app-5,
    .banner.marketing-banner .hero-app-6,
    .banner.marketing-banner .hero-app-7 {
        display: none;
    }
}

@media (max-width: 575px) {
    .slider-shop .swiper-slide {
        padding: 100px 0px;
    }

    .slider-shop .swiper-slide h1 {
        font-size: 26px;
    }

    .slider-shop .swiper-slide .banner-content h5 {
        font-size: 18px;
    }

    .banner-logo {
        font-size: 40px;
        line-height: 50px;
    }
}

/*****************************
      Layout
  *****************************/
/* section title */
.section-title {
    margin-bottom: 50px;
}

.section-title .title {
    margin-bottom: 20px;
}

.section-title .sub-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
}

.section-title .pre-title {
    font-size: 16px;
}

.section-title .title-shape {
    display: inline-block;
    width: 16px;
}

.section-title .title-shape:before {
    content: "";
    background: #7c8087;
    height: 20px;
    width: 8px;
    left: 0;
    top: 0;
    position: absolute;
}

.section-title .title-shape:after {
    content: "";
    background: #6a26da;
    height: 20px;
    width: 8px;
    left: 8px;
    top: 0;
    position: absolute;
}

.section-title .dark-title .title-shape:before {
    background-color: #ffffff;
}

.section-title .theme-title .title-shape:after {
    background-color: #ffffff;
}

/*search-smooth */
.search-smooth {
    position: relative;
}

.search-smooth a {
    position: absolute;
    right: 0;
    top: 0;
    margin: 6px;
    padding: 18px 20px 17px;
    color: #000000;
}

.search-smooth input {
    padding-left: 30px;
    padding-right: 130px;
    height: 70px;
    background: #ffffff;
}

.page-header {
    padding: 200px 0px;
}

/* Client Logo */
.our-clients .owl-item {
    text-align: center;
}

.our-clients .owl-item img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.our-clients .owl-item:hover img {
    -webkit-filter: inherit;
    filter: inherit;
}

.our-clients.clients-shadow .owl-item a {
    padding: 30px 10px;
    width: 100%;
    display: block;
}

.our-clients.clients-shadow .owl-item .clients-logo {
    padding: 30px;
    height: 100px;
    margin: 0px 0;
    display: block;
    width: 100%;
}

.our-clients.clients-shadow .owl-item:hover .clients-logo {
    -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
}

canvas {
    position: absolute;
    top: 0;
    width: 100%;
    height: 800px;
}

/*****************************
    Case Study
  *****************************/
.case-study {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.case-study .case-study-title {
    display: block;
    color: #1d293f;
    font-size: 18px;
    font-weight: 500;
}

.case-study .case-study-title:hover {
    color: #6a26da;
}

.case-study .case-study-services {
    display: block;
    margin-bottom: 10px;
}

.case-study .case-study-services:hover {
    color: #18191c;
}

.case-study .case-study-img {
    background-size: cover;
    min-height: 249px;
    background-position: center center;
}

.case-study .case-study-img.case-study-lg {
    min-height: 530px;
}

.case-study .case-study-info {
    padding: 20px;
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: 20px;
    z-index: 2;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background: #ffffff;
}

.case-study .case-study-info p {
    max-height: 66px;
    overflow: auto;
}

.case-study:hover .case-study-info {
    opacity: 1;
    bottom: 40px;
}

.case-study .case-study-lg+.case-study-info p {
    max-height: 340px;
    overflow: auto;
}

/* style 02 */
.case-study-style-02 {
    position: relative;
}

.case-study-style-02:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.case-study-style-02:hover:before {
    height: auto;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.case-study-style-02 .case-study-info {
    background: #ffffff;
    border-radius: 3px;
}

.case-study-style-02 .case-study-info p {
    color: #ffffff;
}

.case-study-style-02 .case-study-info .case-study-services {
    color: #ffffff;
}

.case-study-style-02 .case-study-info .case-study-services:hover {
    color: #ffffff;
}

.case-study-style-02 .case-study-info .case-study-title {
    color: #ffffff;
}

.case-study-style-02 .case-study-info .case-study-title:hover {
    color: #ffffff;
}

.case-study-style-02 .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px;
}

/* style 03 */
.case-study-style-03 {
    position: relative;
    border-radius: 8px;
}

.case-study-style-03:before {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    top: 0;
    content: "";
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.case-study-style-03:hover:before {
    height: auto;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.case-study-style-03 .case-study-info {
    background: #ffffff;
    border-radius: 3px;
    opacity: 0;
}

.case-study-style-03 .case-study-info p {
    color: #ffffff;
}

.case-study-style-03 .case-study-info .case-study-services {
    color: #ffffff;
}

.case-study-style-03 .case-study-info .case-study-services:hover {
    color: #ffffff;
}

.case-study-style-03 .case-study-info .case-study-title {
    color: #ffffff;
}

.case-study-style-03 .case-study-info .case-study-title:hover {
    color: #ffffff;
}

.case-study-style-03 .icon-btn {
    opacity: 1;
    position: absolute;
    top: 10px;
    z-index: 99;
    right: 5px;
}

.case-study-style-03 .case-study-img.case-study-lg {
    min-height: 400px;
}

.slick-slide img {
    display: block !important;
}

svg {
    margin: 0 auto;
    width: 100%;
    height: auto;
}

img.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

img.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.custom-animation {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping 9s ease-in-out 2s infinite alternate;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all .9s ease 5s;
    transition: all .9s ease 5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation2 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping2 9s ease-in-out 2s infinite alternate;
    animation: jumping2 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 1s ease 3s;
    transition: all 1s ease 3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation3 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 7s ease 2s;
    transition: all 7s ease 2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation4 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping4 9s ease-in-out 2s infinite alternate;
    animation: jumping4 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 8s ease 4s;
    transition: all 8s ease 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation10 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 8s ease 4s;
    transition: all 8s ease 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation11 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping11 9s ease-in-out 2s infinite alternate;
    animation: jumping11 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 8s ease 4s;
    transition: all 8s ease 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@-webkit-keyframes jumping {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .8;
    }

    25% {
        -webkit-transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        opacity: .9;
    }

    50% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        opacity: .6;
    }

    85% {
        -webkit-transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        opacity: .85;
    }
}

@keyframes jumping {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .8;
    }

    25% {
        -webkit-transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        opacity: .9;
    }

    50% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        opacity: .6;
    }

    85% {
        -webkit-transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        opacity: .85;
    }
}

@-webkit-keyframes jumping2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .5;
    }

    25% {
        -webkit-transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        opacity: .9;
    }
}

@keyframes jumping2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .5;
    }

    25% {
        -webkit-transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        opacity: .9;
    }
}

@-webkit-keyframes jumping3 {
    0% {
        -webkit-transform: translateY(10px) translateX(0) rotate(0) scale(1);
        transform: translateY(10px) translateX(0) rotate(0) scale(1);
        opacity: .9;
    }

    20% {
        -webkit-transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        opacity: .6;
    }

    100% {
        -webkit-transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        opacity: .7;
    }
}

@keyframes jumping3 {
    0% {
        -webkit-transform: translateY(10px) translateX(0) rotate(0) scale(1);
        transform: translateY(10px) translateX(0) rotate(0) scale(1);
        opacity: .9;
    }

    20% {
        -webkit-transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        opacity: .6;
    }

    100% {
        -webkit-transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        opacity: .7;
    }
}

@-webkit-keyframes jumping4 {
    0% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        opacity: .7;
    }

    25% {
        -webkit-transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        opacity: .9;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        opacity: .9;
    }
}

@keyframes jumping4 {
    0% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        opacity: .7;
    }

    25% {
        -webkit-transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        opacity: .9;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        opacity: .9;
    }
}

@-webkit-keyframes jumping10 {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        display: block;
    }
}

@keyframes jumping10 {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        display: block;
    }
}

@-webkit-keyframes jumping11 {
    0% {
        -webkit-transform: rotate(180deg) translate(-20px, 20px);
        transform: rotate(180deg) translate(-20px, 20px);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg) translate(0px, 0px);
        transform: rotate(30deg) translate(0px, 0px);
        display: block;
    }
}

@keyframes jumping11 {
    0% {
        -webkit-transform: rotate(180deg) translate(-20px, 20px);
        transform: rotate(180deg) translate(-20px, 20px);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg) translate(0px, 0px);
        transform: rotate(30deg) translate(0px, 0px);
        display: block;
    }
}

.about-pattern img {
    position: relative;
    left: -100px;
    top: -50px;
}

.hero-app-1 {
    position: absolute;
    left: 4%;
    top: 10%;
}

@media (max-width: 576px) {
    .hero-app-1 {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

    /* line 420, E:/167 Bizcon Business/Bizcon_html/sass/_common.scss */
    .hero-app-1 {
        padding: 8px 25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-app-1 {
        padding: 10px 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-app-1 {
        padding: 10px 25px;
    }
}

.hero-app-2 {
    position: absolute;
    left: 5%;
    bottom: 35%;
}

@media (max-width: 576px) {
    .hero-app-2 {
        bottom: 5%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-app-2 {
        bottom: 20%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-app-2 {
        padding: 10px 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-app-2 {
        padding: 10px 25px;
    }
}

.hero-app-3 {
    position: absolute;
    top: 20%;
    left: 25%;
}

@media (max-width: 576px) {
    .hero-app-3 {
        left: 5%;
    }
}

.hero-app-4 {
    position: absolute;
    bottom: 20%;
    left: 50%;
}

.hero-app-5 {
    position: absolute;
    bottom: 40%;
    left: 50%;
}

.hero-app-6 {
    position: absolute;
    right: 5%;
    top: 40%;
}

.hero-app-7 {
    position: absolute;
    top: 13%;
    left: 51%;
}

.hero-app-8 {
    position: absolute;
    top: 85% !important;
    right: 20% !important;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-app-7 {
        top: 5%;
        left: 73%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-app-7 {
        padding: 10px 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-app-7 {
        padding: 10px 25px;
    }
}

.hero-app-8 {
    position: absolute;
    left: 68%;
    top: 60%;
}

@media (max-width: 576px) {
    .hero-app-8 {
        top: 80%;
    }

    .page-header {
        padding: 150px 0px 0px 0;
    }
}

@media (max-width: 575px) {

    .testimonial-style-01 .testimonial-img-03 img,
    .testimonial-img-01 img,
    .testimonial-img-02 img {
        display: none;
    }
}

@media (max-width: 991px) {
    .page-header {
        padding: 150px 0px 100px 0;
    }
}

.rotate-animation {
    -webkit-animation: rotation 99s infinite linear;
    animation: rotation 99s infinite linear;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/*****************************
  Shop
*****************************/
/* Product */
.product {
    padding-top: 15px;
    padding-bottom: 15px; }
    .product .add-to-cart {
      padding-top: 20px; }
      .product .add-to-cart a {
        background: #6a26da;
        color: #ffffff;
        text-transform: capitalize;
        padding: 10px 20px;
        font-weight: bold;
        border-radius: 50px; }
    .product .product-description .product-title {
      margin: 20px 0px 10px; }
      .product .product-description .product-title a {
        font-size: 16px;
        font-weight: bold;
        color: #1d293f; }
        .product .product-description .product-title a:hover {
          color: #6a26da; }
    .product .product-description .product-rating {
      margin-bottom: 10px; }
    .product .product-description .product-price {
      font-size: 14px;
      font-weight: bold; }
      .product .product-description .product-price del {
        color: #dfdfdf; }
      .product .product-description .product-price ins {
        color: #6a26da;
        text-decoration: none; }
  
  /* Product Detail */
  .shop-single .nav-tabs-02 .nav-item .nav-link {
    font-size: 16px; }
  
  .shop-single .product-detail .product-price-rating .product-price {
    margin-bottom: 20px; }
    .shop-single .product-detail .product-price-rating .product-price h4 {
      font-size: 20px;
      margin-bottom: 0; }
      .shop-single .product-detail .product-price-rating .product-price h4 span {
        font-size: 24px;
        color: #6a26da;
        margin-left: 8px; }
  
  .shop-single .product-detail .product-price-rating .product-rating {
    margin: 20px 0; }
    .shop-single .product-detail .product-price-rating .product-rating i {
      color: #6a26da; }
  
  .shop-single .product-detail .product-summary {
    margin-top: 30px; }
    .shop-single .product-detail .product-summary a {
      color: #1d293f;
      margin-right: 10px; }
      .shop-single .product-detail .product-summary a i {
        color: #6a26da;
        margin-right: 10px; }
      .shop-single .product-detail .product-summary a:hover {
        color: #6a26da; }
  
  .shop-single .product-detail hr {
    margin: 30px 0; }
  
  .shop-single .product-detail .product-detail-meta {
    margin-bottom: 20px; }
    .shop-single .product-detail .product-detail-meta span {
      display: block;
      margin: 10px 0; }
  
  .shop-single .product-detail .product-detail-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
    padding: 10px 0; }
    .shop-single .product-detail .product-detail-social span {
      font-weight: 800; }
    .shop-single .product-detail .product-detail-social ul {
      padding-left: 15px;
      margin-bottom: 0; }
      .shop-single .product-detail .product-detail-social ul li {
        display: inline-block;
        list-style-type: none;
        float: left;
        margin-right: 20px;
        font-size: 16px; }
  
  .shop-single .product-detail .input-group {
    width: 90px;
    float: left;
    margin-right: 20px; }
  
  .form-group .product-rating {
    color: #6a26da; }
  
  .slider-slick .slider-nav .slick-next:before {
    position: absolute;
    right: 5px;
    top: 50%;
    z-index: 999;
    color: #ffffff;
    font-size: 20px;
    -webkit-transform: translatey(-50%);
            transform: translatey(-50%); }
  
  .slider-slick .slider-nav .slick-prev:before {
    position: absolute;
    left: 5px;
    top: 50%;
    z-index: 999;
    color: #ffffff;
    font-size: 20px;
    -webkit-transform: translatey(-50%);
            transform: translatey(-50%); }
  
  .slider-slick .slider-nav:hover .slick-prev {
    left: 0;
    z-index: 99;
    opacity: 1;
    border-radius: 4px; }
  
  .slider-slick .slider-nav:hover .slick-next {
    right: 0;
    opacity: 1;
    border-radius: 4px; }
  
  .slider-slick .slider-nav .slick-prev {
    opacity: 0;
    width: 30px;
    height: 50%;
    background: #6a26da;
    color: #1d293f;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  
  .slider-slick .slider-nav .slick-next {
    opacity: 0;
    width: 30px;
    height: 50%;
    background: #6a26da;
    color: #1d293f;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  
  .slider-slick .slider-nav .slick-track .slick-slide {
    margin-left: 5px;
    margin-right: 5px; }
  
  /* commentlist */
  .commentlist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .commentlist .comment-author img {
      width: 70px; }
    .commentlist .comment-content {
      border: 1px solid #e7e7e7;
      padding: 15px;
      margin-left: 20px;
      margin-bottom: 20px;
      width: 100%;
      border-radius: 4px; }
      .commentlist .comment-content .reviews {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .commentlist .comment-content .reviews .rating {
          margin-left: auto; }
          .commentlist .comment-content .reviews .rating i {
            color: #6a26da; }
  
  .form-check-label {
    padding-left: 20px; }
  
  /* Shop Sidebar */
  .shop-sidebar {
    border-radius: 4px; }
    .shop-sidebar .widget {
      margin-top: 0px !important;
      padding: 0px 20px;
      border-radius: 4px; }
      .shop-sidebar .widget .widget-title {
        margin-bottom: 30px; }
      .shop-sidebar .widget .price-filter {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .shop-sidebar .widget .price-filter a {
          margin-left: auto; }
      .shop-sidebar .widget .product-rating {
        margin-bottom: 10px; }
        .shop-sidebar .widget .product-rating:last-child {
          margin-bottom: 0; }
        .shop-sidebar .widget .product-rating .product-rating-nav {
          display: inline-block; }
        .shop-sidebar .widget .product-rating span {
          float: right; }
      .shop-sidebar .widget .product-banner-thumb {
        position: relative; }
        .shop-sidebar .widget .product-banner-thumb .product-banner-badge {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 1;
          background: #7c8087;
          color: #ffffff;
          height: 70px;
          width: 70px;
          border-radius: 50%;
          text-align: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column; }
          .shop-sidebar .widget .product-banner-thumb .product-banner-badge .badge-value {
            font-size: 20px;
            font-weight: 700;
            line-height: 1; }
      .shop-sidebar .widget .form-check {
        display: block; }
        .shop-sidebar .widget .form-check span {
          float: right; }
      .shop-sidebar .widget .social ul li {
        display: inline-block;
        list-style-type: none;
        margin-right: 20px;
        font-size: 16px;
        border-radius: 4px; }
      .shop-sidebar .widget .tagcloud ul li {
        margin-bottom: 10px;
        display: inline-block; }
        .shop-sidebar .widget .tagcloud ul li a {
          color: #1d293f;
          line-height: 32px;
          border: 1px solid #e7e7e7;
          padding: 0 12px;
          border-radius: 50px;
          display: block; }
          .shop-sidebar .widget .tagcloud ul li a:hover {
            background: #6a26da;
            border: 1px solid #6a26da;
            color: #ffffff; }
      .shop-sidebar .widget .widget-categories ul li a {
        font-size: 14px;
        color: #1d293f;
        line-height: 32px;
        display: block; }
        .shop-sidebar .widget .widget-categories ul li a:hover {
          color: #6a26da; }
  
  .search-field {
    padding-left: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
    background: #f5f9ff;
    border: none;
    height: 50px;
    border-radius: 4px;
    width: 100%; }
  
  /* Checkout */
  .checkout-info {
    padding: 25px 25px 25px 120px;
    background: #f5f9ff;
    position: relative;
    border-radius: 4px; }
    .checkout-info:before {
      position: absolute;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      top: 50%;
      left: 25px;
      content: "\f2f6";
      font-size: 60px;
      font-family: "Font Awesome 5 Free";
      z-index: 9;
      color: #dfdfdf;
      font-weight: 600; }
  
  .checkout-info-coupon .checkout-info:before {
    content: "\f3ff"; }
  
  .checkout-info-coupon .checkout-coupon {
    padding: 20px;
    border: 2px dashed #e7e7e7;
    border-radius: 4px; }
  
  .checkout-review {
    padding: 15px 30px 30px;
    border: 1px solid #e7e7e7;
    border-radius: 4px; }
    .checkout-review .table tr th {
      padding: 15px 0;
      font-size: 18px; }
    .checkout-review .table tr th.shipping {
      font-size: 14px; }
    .checkout-review .table tr td {
      text-align: left;
      padding: 15px 0;
      font-size: 14px;
      font-weight: 500; }
    .checkout-review .table tr td.amount {
      font-size: 18px; }
  
  /* Cart */
  .cart-table .table {
    border: 1px solid #e7e7e7;
    margin-bottom: 0;
    font-size: 14px; }
    .cart-table .table tr th {
      border: none;
      padding: 15px 10px;
      color: #ffffff;
      background-color: #6a26da;
      text-align: left;
      vertical-align: middle; }
    .cart-table .table tr td {
      border: none;
      border-top: 1px solid #e7e7e7;
      padding: 15px 10px;
      color: #1d293f;
      font-weight: 500;
      text-align: left; }
      .cart-table .table tr td.product-remove {
        padding: 15px 25px;
        text-align: center; }
      .cart-table .table tr td.product-quantity .form-control {
        width: 100px; }
      .cart-table .table tr td a {
        color: #1d293f; }
        .cart-table .table tr td a:hover {
          color: #6a26da; }
      .cart-table .table tr td .form-group {
        margin-bottom: 0px; }
  
  .cart-table .actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #e7e7e7;
    border-top: none;
    padding: 15px 10px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .cart-table .actions .coupon form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
    .cart-table .actions .coupon .input-text {
      border: 1px solid #dfdfdf;
      border-radius: 0px;
      height: 48px;
      padding: 10px 20px;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      font-weight: 500;
      color: #1d293f;
      border-radius: 50px;
      margin-right: 10px; }
    .cart-table .actions .coupon .btn {
      padding: 12px 20px; }
    .cart-table .actions .update-cart {
      margin-left: auto; }
  
  .cart-table .table td.product-thumbnail img {
    width: 70px;
    height: auto; }
  
  .cart-totals {
    background: #f5f9ff;
    padding: 20px; }
    .cart-totals .cart-totals-title {
      margin-bottom: 20px;
      text-align: center; }
    .cart-totals .table {
      font-size: 14px; }
      .cart-totals .table tr th {
        text-align: left;
        border: none;
        border-top: 1px solid #e7e7e7;
        vertical-align: top;
        background: #ffffff; }
      .cart-totals .table tr td {
        text-align: right;
        border: none;
        border-top: 1px solid #e7e7e7;
        background: #ffffff; }
      .cart-totals .table tr.shipping .form-group {
        margin-bottom: 0; }
      .cart-totals .table tr.shipping .form-check-label {
        padding-left: 0px; }
      .cart-totals .table tr.shipping p {
        margin-bottom: 0; }
      .cart-totals .table tr.order-total th {
        font-size: 18px;
        font-weight: bold; }
      .cart-totals .table tr.order-total td {
        font-size: 26px;
        font-weight: bold; }
      .cart-totals .table .cart-subtotal th {
        font-size: 18px;
        font-weight: bold;
        border: none; }
      .cart-totals .table .cart-subtotal td {
        font-size: 18px;
        font-weight: bold;
        border: none; }
    .cart-totals .checkout-button {
      width: 100%;
      text-align: center;
      padding: 15px 25px; }
  
  /* shop-category */
  .shop-category {
    padding: 25px 15px;
    border-right: 1px solid #f5f9ff; }
    .shop-category h5 {
      margin-bottom: 20px; }
    .shop-category ul {
      margin-bottom: 0; }
      .shop-category ul li a {
        padding: 5px 0;
        display: block;
        color: #1d293f; }
  
  @media (max-width: 768px) {
    .slider-slick .slider-nav .slick-prev {
      display: none !important; }
    .slider-slick .slider-nav .slick-next {
      display: none !important; }
    .shop-single .nav-tabs-02 .nav-item {
      width: 33% !important; }
    .shop-single .nav-tabs-02 .nav-item .nav-link {
      font-size: 14px; }
    .shop-sidebar .widget {
      padding: 0; } }
  
  @media (max-width: 575px) {
    .cart-table .actions .coupon form {
      display: block; }
    .cart-table .actions .coupon #coupon_code,
    .cart-table .actions .coupon .btn {
      margin-bottom: 10px; }
    .cart-table .actions {
      display: block; } }
  
  /*****************************
      Blog
  *****************************/
  .blog {
    border-radius: 15px;
    position: relative; }
    .blog .blog-shape {
      position: relative;
      bottom: 17px;
      z-index: 9; }
    .blog h4 {
      font-size: 22px;
      line-height: 32px;
      font-weight: 500; }
    .blog .card-body .d-flex {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
  
  /* Blog 02 */
  .blog-02 .card-body {
    padding: 25px 20px 10px 0px; }
    .blog-02 .card-body h4 {
      font-size: 24px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 15px; }
  
  .blog-02 .badge {
    padding: 10px 20px;
    margin-bottom: 10px; }
    .blog-02 .badge:hover {
      color: #ffffff; }
  
  .blog-02 .blog-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .blog-02 .blog-info .blog-avatar-img {
      width: 50px;
      height: 50px;
      margin-right: 25px; }
  
  /* Blog Sidebar */
  .sidebar .widget {
    margin-bottom: 50px;
    background-color: #ffffff; }
    .sidebar .widget:last-child {
      margin-bottom: 0; }
    .sidebar .widget .widget-title {
      margin-bottom: 20px; }
    .sidebar .widget .search {
      position: relative; }
      .sidebar .widget .search input {
        padding-right: 64px;
        height: 50px;
        padding-left: 20px;
        -webkit-box-shadow: none;
                box-shadow: none; }
        .sidebar .widget .search input:focus {
          border: 1px solid #6a26da; }
      .sidebar .widget .search i {
        position: absolute;
        right: 0;
        top: 0;
        background: #6a26da;
        color: #ffffff;
        padding: 18px 20px;
        cursor: pointer;
        margin: 0;
        border-radius: 3px; }
    .sidebar .widget .recent-posts a {
      color: #1d293f; }
      .sidebar .widget .recent-posts a:hover {
        color: #6a26da; }
    .sidebar .widget .recent-posts .date {
      color: #707173;
      font-size: 13px; }
    .sidebar .widget .widget-contact-info ul li {
      margin-bottom: 30px; }
      .sidebar .widget .widget-contact-info ul li i {
        font-size: 16px; }
      .sidebar .widget .widget-contact-info ul li span {
        margin-left: 20px; }
    .sidebar .widget .widget-follow ul {
      margin-bottom: 0; }
      .sidebar .widget .widget-follow ul li {
        display: inline-block;
        margin-right: 20px; }
        .sidebar .widget .widget-follow ul li:last-child {
          margin-right: 0px; }
        .sidebar .widget .widget-follow ul li a {
          font-size: 18px;
          color: #7c8087; }
          .sidebar .widget .widget-follow ul li a:hover {
            color: #6a26da; }
    .sidebar .widget ul.list-style li a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: #7c8087;
      width: 100%; }
      .sidebar .widget ul.list-style li a:hover {
        color: #6a26da; }
    .sidebar .widget ul.list-style li:last-child {
      margin-bottom: 0; }
    .sidebar .widget .gallery ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .sidebar .widget .gallery ul li {
        -webkit-box-flex: 0;
            -ms-flex: 0 0 19%;
                flex: 0 0 19%;
        margin: 0 1% 1% 0; }
        .sidebar .widget .gallery ul li:nth-child(5n+5) {
          margin-right: 0; }
    .sidebar .widget .social ul li {
      padding: 10px 10px 10px 20px;
      border: 1px solid #e7e7e7;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 10px;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .sidebar .widget .social ul li a {
        color: #1d293f;
        font-weight: 600;
        text-transform: capitalize; }
        .sidebar .widget .social ul li a i {
          width: 15px; }
      .sidebar .widget .social ul li .follow {
        background: #f5f9ff;
        padding: 3px 18px;
        font-size: 12px; }
        .sidebar .widget .social ul li .follow:hover {
          background: #1d293f;
          color: #ffffff; }
      .sidebar .widget .social ul li:last-child {
        margin-bottom: 0; }
    .sidebar .widget .social ul .facebook a {
      color: #466ca9; }
    .sidebar .widget .social ul .facebook .follow:hover {
      background: #466ca9; }
    .sidebar .widget .social ul .twitter a {
      color: #20b5e6; }
    .sidebar .widget .social ul .twitter .follow:hover {
      background: #20b5e6; }
    .sidebar .widget .social ul .youtube a {
      color: #d92c20; }
    .sidebar .widget .social ul .youtube .follow:hover {
      background: #d92c20; }
    .sidebar .widget .social ul .linkedIn a {
      color: #13799f; }
    .sidebar .widget .social ul .linkedIn .follow:hover {
      background: #13799f; }
    .sidebar .widget .popular-tag ul li {
      display: inline-block;
      margin-bottom: 10px; }
      .sidebar .widget .popular-tag ul li a {
        padding: 6px 20px;
        display: block;
        border: 1px solid #e7e7e7;
        color: #7c8087;
        border-radius: 4px; }
        .sidebar .widget .popular-tag ul li a:hover {
          color: #6a26da;
          border-color: #6a26da; }
    .sidebar .widget .widget-categories .list-style-underline li a {
      padding: 14px 0;
      border-bottom: 1px solid #e7e7e7; }
    .sidebar .widget .widget-categories .list-style-underline li:last-child a {
      border-bottom: 0;
      padding-bottom: 0; }
  
  /*****************************
    Footer
  *****************************/
  .footer .footer-link ul li {
    margin-bottom: 10px; }
    .footer .footer-link ul li a {
      color: #18191c; }
  
  .footer .social-icon ul {
    display: inline-block;
    margin-bottom: 0;
    padding-left: 0; }
    .footer .social-icon ul li {
      display: inline-block;
      list-style-type: none; }
      .footer .social-icon ul li:last-child a {
        margin-right: 0; }
      .footer .social-icon ul li a {
        display: block;
        background-color: #6a26da;
        color: #ffffff;
        height: 35px;
        width: 35px;
        line-height: 36px;
        text-align: center;
        border-radius: 50px;
        margin-right: 7px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease; }
        .footer .social-icon ul li a:hover {
          background-color: #18191c;
          color: #ffffff; }
        .footer .social-icon ul li a i {
          font-size: 16px; }
  
  /*************************
         1700px
  *************************/
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      max-width: 96%; } }
  
  /*************************
         1199px
  *************************/
  @media (max-width: 1199px) {
    .navbar .navbar-nav .nav-link {
      padding: 10px 13px; }
    .action-box-style-01 {
      top: 100px; } }
  
  /*************************
         991px
  *************************/
  @media (max-width: 991px) {
    .space-ptb {
      padding: 60px 0; }
    .space-pt {
      padding-top: 60px; }
    .space-pb {
      padding-bottom: 60px; }
    .pricing-title-bg svg {
      background: #6a26da !important; }
    .action-box-style-01 {
      top: 80px; } }
  
  /*************************
         767px
  *************************/
  @media (max-width: 767px) {
    .space-ptb {
      padding: 50px 0; }
    .space-pt {
      padding-top: 50px; }
    .space-pb {
      padding-bottom: 50px; }
    .pricing-title-bg svg {
      padding-bottom: 30px; }
    .action-box-style-01 {
      top: 60px; } }
  
  /*************************
         575px
  *************************/
  @media (max-width: 575px) {
    .section-title {
      margin-bottom: 40px; }
    .space-ptb {
      padding: 40px 0; }
    .space-pt {
      padding-top: 40px; }
    .space-pb {
      padding-bottom: 40px; }
    .pricing-title-bg svg {
      height: 320px; } }
  
  /*# sourceMappingURL=../css/style.css.map */
  
  
  .bdr{border:1px solid red !important}
  .from-purple-1 {
    background: -webkit-linear-gradient(#d3e7bc, #EB4721);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  .btn-success {
    color: #fff;
    background-color: #EB4721;
    border-color: #EB4721;
  }
  .lead1 {
    font-size: 17px;
    font-weight: 300;
    line-height: 28px;
  }
  .h-640px {
    height: 640px;
  }
  .cus-header-text{
    font-size: 45px;
    line-height: 65px;
    font-weight: 900;
    font-family: Roboto;
    letter-spacing: 0;
  }
  
  .hd-1{
    font-weight: 900;
    font-size: 44px;
  }
  
  
  .section-title .sub-title {
    font-size: 46px;
    font-weight: 900;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    -webkit-text-stroke: 1px #EB4721;
    color: #fff !important;
  }
  
  a.footer-logo img {
    width: 210px;
  }
  .banner .contant h1{
    /* font-size: 67px; */
    font-size: 49px;
    text-transform: math-auto;
    line-height: 70px;
  }
  
  /* .footer{
    position: absolute;
    bottom: 0;
    width: 100%;
  } */
`