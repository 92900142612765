import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { LoaderPopup } from "../../../components";
import { getApiData } from "../../../Services";
import { convertDate } from "../../../config";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ({ data, show, onHide }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.id) {
      setLoading(true);
      getList(data.id);
    }
  }, [data]);

  const getList = (id) => {
    getApiData(`order/v1/invoice/?customer=${data.id}`).then(
      (res) => {
        setLoading(false);
        if (res.status) {
          setList(res.data);
        }
      });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Body className="scroll-popup" closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Invoice History
        </Modal.Title>
        <>
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3 mt-5">
            <thead>
              <tr className="text-left text-uppercase">
                <th className="pl-4">#</th>
                <th>Month</th>
                <th>Invoice Id</th>
                <th>Payment Method</th>
                <th>Total</th>
                <th>Date</th>
              </tr>
            </thead>
            {loading ? (
              <tr>
                <td colSpan="9">
                  <LoaderPopup />
                </td>
              </tr>
            ) : (
              <>
                {Object.keys(list).length ? Object.keys(list).map((month, index) => (
                  list[month].map((order, subIndex) => {
                    return (
                      <>
                        <tr key={`${index}_${subIndex}`}>
                          <td className="pl-4">{index * list[month].length + subIndex + 1}</td>
                          <td>{month}</td>
                          <td>{order.order_id}</td>
                          <td>{order.payment_method == 1 ? 'Online' : 'Pay on Delivery'}</td>
                          <td style={{ fontWeight: '700' }}>{`₹${order.total}`}</td>
                          <td>{convertDate(order.created_at)}</td>
                        </tr>
                      </>
                    )
                  })
                )) :
                  <tr>
                    <td colSpan="9">
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                      </div>
                    </td>
                  </tr>
                }
              </>
            )}
          </table>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn custom-btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
