import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GeneralDropdown } from "../../../components";
import { getApiData } from "../../../Services";

export default function({ data, show, onHide, onSuccess, onError }) {
  const [sending, setSending] = useState(false);
  const [credits, setCredits] = useState("");
  const [creditType, setCreditType] = useState("");

  useEffect(() => {
    setCredits("");
    setCreditType("");
  }, [data]);

  const onSubmit = () => {
    if (credits && creditType) {
      setSending(true);
      const body = {
        phone_number: data.phone,
        credit_type: creditType,
        credits: parseInt(credits),
      };
      getApiData(`v1/credit/add`, "post", body).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message);
          onHide();
        } else {
          if (typeof res.message === "string") {
            onError(res.message);
          } else {
            onError("Something went wrong!");
          }
        }
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add Credit to <b>{data.first_name}</b> <small>({data.phone})</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <div className="row">
          <div className="form-group mb-3">
            <label>Credit Type</label>
            <GeneralDropdown
              list={[
                {
                  label: "Promotion",
                  value: "promotion",
                },
                {
                  label: "Cash",
                  value: "cash",
                },
                {
                  label: "Cheque",
                  value: "cheque",
                },
              ]}
              inModal
              handleChange={(obj) => {
                setCreditType(obj.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group is-invalid mb-0">
            <label>Credits</label>
            <input
              type="number"
              className="form-control"
              value={credits}
              onChange={(e) => {
                setCredits(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && credits > 0) {
                  onSubmit();
                }
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="custom-custom-btn-light px-10"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onSubmit}
            disabled={credits && creditType && !sending ? false : true}
            className="custom-button-danger font-weight-bold px-10"
          >
            Save
            {sending && (
              <Spinner animation="border" size="sm" className="ml-1" />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
