import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Loader, GeneralDropdown } from "../../../components";
import {
  convertDate,
  getParams,
  PER_PAGE,
  sizePerPageList,
  timeSince,
} from "../../../config";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import { getApiData } from "../../../Services";
import { getStateList } from "../../../config";
import { BannerModal } from "../modals";
import { SequenceModal, DeleteBanner } from "../modals";
import Pagination from "react-js-pagination";
import "../../../../my-style.css";

function BannerTable(props) {
  const { authToken } = useSelector((state) => state.auth);
  const history = useHistory();

  const [userModal, setUserModal] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState([]);
  const [isShowSequence, setIsShowSequence] = useState(false);
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [indexList, setIndexList] = useState([]);

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);
    // setLoading(true);
    getApiData(`banner/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        const selected = apiData.filter((item) => item.is_selected);
        setSelectedBanner(selected);
        setTotalRecords(res.count);
        setData(apiData);
        let list = res.data.map((item) => ({
          label: String(item.index),
          value: item.index,
        }));
        setIndexList(list);
      }
    });
  };

  const handleRowSelect = (row, selectRow) => {
    const isSelected = selectedBanner.map((item) => item.id);
    if (isSelected && selectedBanner.length >= 5) {
      toast.error("Maximum 5 Banners Allowd");
    } else {
      const updatedData = data.map((item) => {
        if (item.id === row.id) {
          return { ...item, is_selected: selectRow };
        }
        return item;
      });

      setData(updatedData);

      if (isSelected && selectRow) {
        setSelectedBanner((prevSelectedItems) => [
          ...prevSelectedItems,
          { ...row, is_selected: isSelected },
        ]);
      } else {
        setSelectedBanner((prevSelectedItems) =>
          prevSelectedItems.filter((item) => item.id !== row.id)
        );
      }
    }
  };

  const onPressSave = () => {
    if (!selectedBanner.length == 0) {
      let selectedBannerIds = selectedBanner.map((item) => item.id);
      let excludedBannerArray = data.filter((item) => !item.is_selected);
      let excludedBannerIds = excludedBannerArray.map((item) => item.id);

      const body = {
        included_banner_ids: selectedBannerIds,
        exluded_banner_ids: excludedBannerIds,
      };

      getApiData(`banner/v1/select/`, "post", body).then((res) => {
        if (res.status) {
          toast.success(res.message);
          getTableRecords();
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const onChangeIndex = (sequence, id) => {
    setLoading(true);
    const body = {
      index: sequence,
    };

    getApiData(`banner/v1/${id}/?index=${sequence}`, "get", body).then(
      (res) => {
        setLoading(false);
        if (res.status) {
          toast.success(res.message);
          getTableRecords();
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  return (
    <>
      <BannerModal
        show={userModal || props.isPopupOpen}
        groupList={props.groupList}
        data={selectedRow}
        onHide={() => {
          setUserModal(false);
          props.setIsPopupOpen(false);
          setSelectedRow({});
        }}
        stateList={stateList}
        onSuccess={(message) => {
          setUserModal(false);
          props.setIsPopupOpen(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
        onPressDelete={() => {
          setUserModal(false);
          props.setIsPopupOpen(false);
          setIsShowDelete(true);
        }}
      />
      <SequenceModal
        show={isShowSequence}
        data={{ ...selectedRow, bannerLength: data.length }}
        onHide={() => setIsShowSequence(false)}
        onSuccess={(message) => {
          setIsShowSequence(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <DeleteBanner
        show={isShowDelete}
        data={{ ...selectedRow, bannerLength: data.length }}
        onHide={() => setIsShowDelete(false)}
        onSuccess={(message) => {
          setIsShowDelete(false);
          toast.success(message);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div className="d-flex flex-column">
            <div>
              {data.length !== 0 ? (
                <>
                  <div className="row g-5 mb-5">
                    {data.map((row, index) => {
                      return (
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body p-5 position-relative">
                              <div
                                className="ms-0 rounded p-3 d-flex"
                                style={{ backgroundColor: "#F9F9F9" }}
                              >
                                <div className="symbol symbol-45px">
                                  <img
                                    src={
                                      row.image
                                        ? row.image
                                        : "https://t4.ftcdn.net/jpg/05/35/51/85/360_F_535518598_fviKNslzN3NTzWTkAn5WpoH9FmiVciAu.jpg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="ms-3">
                                  <div className="fs-5">
                                    Banner Name: <b>{row.name}</b>
                                  </div>
                                  {row?.banner_redirection === 0 && (
                                    <div className="fs-5">
                                      Coupon: <b>{row.coupon_code}</b>
                                    </div>
                                  )}
                                  {/* <div className="fs-5">
                                    State: <b>{row.state}</b>
                                    City: <b>{row.city}</b>
                                  </div> */}
                                  {row?.banner_redirection === 1 && (
                                    <div className="fs-5">
                                      Business:{" "}
                                      <b>{row.business?.first_name}</b>
                                    </div>
                                  )}
                                  {row?.banner_redirection === 2 && (
                                    <div className="fs-5">
                                      Screen: <b>{row.custom_screen}</b>
                                    </div>
                                  )}
                                  {row?.banner_redirection === 3 && (
                                    <div className="fs-5">
                                      Mobile: <b>{row.phone_number}</b>
                                    </div>
                                  )}
                                  {row?.banner_redirection === 4 && (
                                    <div className="fs-5">
                                      Link: <b>{row.link}</b>
                                    </div>
                                  )}
                                  {/* <div className="rounded text-start mb-0 fs-7">Details: <span className="fs-7 fw-bolder ">{row.detail}</span></div> */}
                                </div>
                              </div>
                              <div className="d-flex flex-stack align-items-center mt-2 ps-3">
                                <div className=" d-flex align-items-center ">
                                  <div className="form-check form-check-sm">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      // onSelect={handleRowSelect}
                                      onChange={(e) => {
                                        handleRowSelect(row, e.target.checked);
                                      }}
                                      defaultChecked={row.is_selected}
                                    />
                                    <label
                                      className="form-check-label text-gray-800 fs-6 mt-1"
                                      htmlFor="flexCheckDefaultas"
                                    >
                                      Select Banner
                                    </label>
                                  </div>
                                </div>
                                <div className>
                                  <div className="d-flex align-items-center">
                                    <div className="fs-6 me-2 min-w-100px">
                                      Sequence No:
                                    </div>
                                    <GeneralDropdown
                                      className="form-select form-select-transparent text-primary fs-base lh-1 fw-bold py-0 ps-3 w-auto w-150px"
                                      selectedOption={
                                        row.index ? row.index : null
                                      }
                                      list={indexList}
                                      handleChange={(obj) => {
                                        onChangeIndex(obj.value, row.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="separator separator-dashed mt-2" />
                              <div className="d-flex flex-stack   gap-3 mt-3">
                                <div className>
                                  <div className="fs-8 ">
                                    Created At:{" "}
                                    <b>{convertDate(row.created_at)}</b> (
                                    {timeSince(row.created_at)})
                                  </div>
                                </div>
                                <div className>
                                  <div className="d-flex  align-items-center justify-content-end">
                                    <a
                                      className="d-flex me-3"
                                      href
                                      onClick={() => {
                                        setSelectedRow(row);
                                        setUserModal(true);
                                      }}
                                    >
                                      <i className="ki-outline ki-pencil text-primary fs-2x" />
                                    </a>
                                    <a
                                      className="d-flex"
                                      href
                                      onClick={() => {
                                        setIsShowDelete(true);
                                        setSelectedRow(row);
                                      }}
                                    >
                                      <i className="ki-outline ki-trash text-gray-900 fs-2x" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-2 float-right mr-3">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={20}
                      totalItemsCount={totalRecords}
                      pageRangeDisplayed={5}
                      itemClass="d-flex justify-content-between align-items-center flex-wrap"
                      linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                      activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                      onChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={toAbsoluteUrl("/media/logos/no-data.png")}
                    height={"150px"}
                    width={"150px"}
                  />
                </div>
              )}
            </div>
            {data?.length > 0 && (
              <div className="row g-5 mt-5 align-items-center">
                <div className="col-md-12 row justify-content-end">
                  <button
                    style={{ width: "140px", marginRight: 20 }}
                    className="btn custom-btn-light px-10"
                    onClick={() => {
                      setLoading(true);
                      getTableRecords();
                    }}
                  >
                    {"Discard"}
                  </button>
                  <button
                    onClick={() => {
                      onPressSave();
                    }}
                    style={{ width: "160px", textAlign: "center" }}
                    className="btn-primary custom-button"
                    disabled={selectedBanner.length == 0 ? true : false}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(BannerTable));
