import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Loader } from "../../../components";
import { getParams, PER_PAGE, sizePerPageList } from "../../../config";
import { getApiData } from "../../../Services";
import Pagination from "react-js-pagination";
import { PinnedCafe } from '../modals';

export default function Table(props) {
  const history = useHistory();

  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showCafeModal, setShowCafeModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {
    const params = getParams(props.filters, currentPage, sizePerPage);

    getApiData(`building/v1/${params}`).then((res) => {
      setLoading(false);
      if (res.status) {
        let apiData = res.data;
        // let pagination = res.data.pagination;
        apiData.length &&
          apiData.map((item, i) => {
            let value = ++i;
            if (currentPage > 1)
              value = sizePerPage * currentPage - sizePerPage + value;
            item.sr_no = value;
          });
        setTotalRecords(res.count);
        setData(apiData);
      } else if (res.detail === "Invalid token.") {
        history.push("/logout");
      }
    });
  };

  const changeIsDefault = async (id, status) => {
    // setLoading(true);
    return await getApiData(`building/v1/${id}/`, "put", {
      is_active: status ? true : false,
    }).then((res) => {
      if (!res.status) {
        toast.error(res.message);
      } else {
        getTableRecords();
      }
    });
  };

  return (
    <>
      <PinnedCafe
        show={showCafeModal}
        onHide={() => setShowCafeModal(false)}
        data={selectedRow}
        onSuccess={(message) => {
          toast.success(message);
          setShowCafeModal(false);
          getTableRecords();
        }}
        onError={(message) => {
          toast.error(message);
        }}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          {
            data.length !== 0 ?
              <>
                <div className="row g-5">
                  {data.map((row) => (
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body p-5 position-relative">
                          <div className="ms-0 bg-gray-light rounded p-3 mb-2">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <div className="fs-6">Name: <b>{row.name}</b></div>
                                <div className="fs-7">Address: <a className="fw-bold">{row.address}</a></div>
                              </div>
                              <button
                                className="bg-transparent d-flex align-items-center justify-content-center"
                                style={{ borderWidth: 0 }}
                                onClick={() => {
                                  setShowCafeModal(true);
                                  setSelectedRow(row);
                                }}
                              >
                                {row?.pinned_business.length == 0 &&
                                  <span className="svg-icon text-blue">
                                    <svg viewBox="0 0 1024 1024" fill="currentColor" height="1em" width="1em" >
                                      <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z" />
                                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
                                    </svg>
                                  </span>
                                }
                                <span className="ml-1 text-blue">{row?.pinned_business.length !== 0 && row?.pinned_business.length} Pinned Business</span>
                              </button>
                            </div>
                          </div>
                          <div className="separator separator-dashed mt-4" />
                          <div className="d-flex flex-stack align-items-center mt-3">
                            <div>
                              <div className="d-flex">
                                <span className="fs-8">State: <b>{row.state}</b></span>, <span className="fs-8 ml-1">City: <b>{row.city}</b></span>
                              </div>
                              <div className="fs-8 mt-0 d-flex">Area: <b className="ml-1">{row.area_name}</b></div>
                            </div>
                            <div className="d-flex  align-items-center justify-content-end">
                              <div className="form-check form-switch form-check-custom form-check-solid me-3 mt-0">
                                <input
                                  className="form-check-input h-20px w-30px"
                                  type="checkbox"
                                  onChange={(e) => {
                                    toast.promise(changeIsDefault(row.id, e.target.checked), {
                                      loading: "Updating...",
                                      success: "Success!",
                                      error: "Failed.",
                                    });
                                  }}
                                  checked={row.is_active} />
                                <label className="form-check-label" htmlFor="flexSwitch20x30" />
                              </div>
                              <Link
                                to={{
                                  pathname: "/master/building/edit",
                                  state: row,
                                }}
                              >
                                <a className="d-flex ml-8" href>
                                  <i className="ki-outline ki-pencil text-primary fs-2x" />
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-2 float-right mr-3">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={20}
                    totalItemsCount={totalRecords}
                    pageRangeDisplayed={5}
                    itemClass="d-flex justify-content-between align-items-center flex-wrap"
                    linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
                    activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
                    onChange={(pageNumber) => {
                      setCurrentPage(pageNumber);
                    }}
                  />
                </div>
              </>
              :
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img src={toAbsoluteUrl('/media/logos/no-data.png')} height={"150px"} width={"150px"} />
              </div>
          }
        </>
      )}
    </>
  );
}
