import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import * as auth from "../../../../modules/Auth/_redux/authRedux";
import { useHistory } from 'react-router-dom';
import { PER_PAGE, getParams } from "../../../../config";
import { getApiData } from "../../../../Services";
import { useSelector, shallowEqual, connect } from "react-redux";

function UserTable(props) {
  const [sizePerPage, setSizePerPage] = useState(PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dashboardData, setDashboardData] = useState({});

  const printRef = useRef();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      setLoading(true);
      getTableRecords();
    }
  }, [props.filters]);

  useEffect(() => {
    setLoading(true);
    getTableRecords();
  }, [sizePerPage, currentPage]);

  const getTableRecords = () => {

    // const params = getParams(props.filters, currentPage, sizePerPage);
    // getApiData(`business/v1/dashboard/${params}`).then((res) => {
    setLoading(false);
    //   if (res.status) {
    //     let apiData = res.data;
    //     apiData.length &&
    //       apiData.map((item, i) => {
    //         let value = ++i;
    //         if (currentPage > 1)
    //           value = sizePerPage * currentPage - sizePerPage + value;
    //         item.sr_no = value;
    //       });
    //     setTotalRecords(res.count);
    //     setData(res.data);
    //     setDashboardData(res);
    //   }
    // });
  };

  const handleDownloadPdf = async () => {
    const printContent = printRef.current.innerHTML;

    const printWindow = window.open('', '', 'height=600,width=800');

    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join('');
        } catch (error) {
          console.warn('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
          return '';
        }
      })
      .join('\n');

    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            ${styles}
            /* Additional styles for the print content */
            body {
              font-family: Arial, sans-serif;
            }
            .print-section {
              padding: 20px; 
            }
          </style>
        </head>
        <body>
          <div class="print-section">${printContent}</div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div id="kt_app_content_container" className="">
        <div className="row g-2">
          <div className="col-12">
            <div className="flex-column w-100">
              <div className={`${isMobile ? 'col-12' : 'col-6'}`}>
                <div className="d-flex justify-content-end mb-2">
                  <button className="custom-button btn py-2 px-6" onClick={handleDownloadPdf}>
                    Print
                  </button>
                </div>
                <div ref={printRef} className="card">
                  <div className="card-body p-6">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <span className="fw-semibold d-block mb-1 text-700">Payout Cycle</span>
                        <span className="fw-bolder d-block mb-1 card-title">19th June-2024</span>
                      </div>
                      <div>
                        <span className="fw-semibold d-block mb-1 text-700">Payout Date</span>
                        <span className="fw-bolder d-block mb-1 card-title">20th June-2024</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div>
                        <span className="fw-semibold d-block mb-1 text-700">Net Payout</span>
                        <h3 className="card-title fw-bolder mb-2">₹{2212}</h3>
                        <span className="fw-semibold d-block mb-1 text-700">110 Orders</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <div className={`flex-column ${isMobile ? 'w-100' : 'w-50'}`}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-1 fs-2" style={{ color: 'green' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                {/* <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" /> */}
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">Total Sales</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹100000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-1 fs-4" style={{ color: 'green' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">Self Delivery Earnings</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹10000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-1 fs-4" style={{ color: 'green' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">GST on Sales</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹5000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-1 fs-4" style={{ color: 'green' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">GST Delivery Earnings</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹1800</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-2 fs-4 w-15" style={{ color: 'red' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">Refunds</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹1000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-1 fs-4" style={{ color: 'green' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M12 4a1 1 0 00-1 1v6H5a1 1 0 100 2h6v6a1 1 0 102 0v-6h6a1 1 0 100-2h-6V5a1 1 0 00-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">GST on Refunds</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹50</span>
                        </div>
                        <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="fw-bolder d-block mb-1 card-title">Net Customer Payable</span>
                          <span className="fw-bolder d-block mb-1 card-title text-right ml-2">₹1,15,850</span>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mt-5">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-2 fs-4 w-15" style={{ color: 'red' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">T/R Fees</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹2000</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-2 fs-4 w-15" style={{ color: 'red' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">GST on T/R Fees</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹360</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-2 fs-4 w-15" style={{ color: 'red' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">GST Deduction</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹6800</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2 mb-2 fs-4 w-15" style={{ color: 'red' }}>
                              <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
                                <path fill="currentColor" d="M4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" />
                              </svg>
                            </span>
                            <span className="fw-semibold d-block mb-1 text-700">TDS @ 1%</span>
                          </div>
                          <span className="fw-semibold d-block mb-1 text-700 text-right ml-2">₹1100</span>
                        </div>
                        <div className="separator separator-dashed border-gray-300 mt-2 mb-2" />
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="fw-bolder d-block mb-1 card-title">Net Payout</span>
                          <span className="fw-bolder d-block mb-1 card-title ml-2">₹1,05,590</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default injectIntl(connect(null, auth.actions)(UserTable));