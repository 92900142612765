import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { getAreaByCity, getStateList, getTapriByArea } from "../../config";
import TableFilter from "./Table/Filter";
import Table from "./Table/index";
import { gradientColors } from "../../../gradientColor";
import { getApiData } from "../../Services";

export default function () {
  const history = useHistory();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [tapriList, setTapriList] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    status: "",
    state: "",
    city: "",
    area: "",
    tapri: "",
  });

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);
    })();
  }, []);

  const updateFilter = (value, key) => {
    let obj = { ...filters };
    obj[key] = value;
    if (key === "state") {
      obj.city = null;
      obj.area_name = "";
      obj.tapri = "";
      setAreaList([]);
      setTapriList([]);
    } else if (key === "city") {
      obj.area_name = "";
      obj.tapri = "";
      setTapriList([]);
    } else if (key === "area_name") {
      obj.tapri = "";
      setTapriList([]);
    }
    setFilters(obj);
  };

  return (
    <>
      <div>
        <TableFilter
          stateList={stateList}
          cityList={cityList}
          areaList={areaList}
          tapriList={tapriList}
          value={filters}
          onStateSelect={(selectedState) => {
            if (selectedState.city && selectedState.city.length) {
              updateFilter(selectedState.value, "state");
              let city = [];
              selectedState.city.map(
                (item) => (city = [...city, { label: item, value: item }])
              );
              setCityList(city);
            }
          }}
          onCitySelect={async (value) => {
            updateFilter(value, "city");
            const area = await getAreaByCity(value);
            setAreaList(area);
          }}
          onAreaSelect={async (value) => {
            updateFilter(value, "area");
            const tapri = await getTapriByArea(value);
            setTapriList(tapri);
          }}
          setSearchString={(value) => {
            let obj = { ...filters };
            obj.search = value;
            setFilters(obj);
          }}
          setValue={(key, value) => {
            let obj = { ...filters };
            obj[key] = value;
            setFilters(obj);
          }}
          setStatusFilter={updateFilter}
          setTapriFilter={updateFilter}
          onClearFilter={() => {
            setFilters({
              search: "",
              status: "",
              state: "",
              city: "",
              area: "",
              tapri: "",
            });
          }}
        />
        {/* <div className="row col-sm-12 pr-0 ">
          <div>
            <Link
              to="/master/building/create"
              // className="btn btn-primary float-right"
              className="btn-primary custom-button float-right"
              style={{ width: "160px", textAlign: "center" }}
            >
              Add New Building
            </Link>
          </div>
        </div> */}
        <Table filters={filters} />
      </div>
    </>
  );
}