import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Layout } from '../_metronic/layout'
import BasePage from './BasePage'
import { Logout, AuthPage } from './modules/Auth'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import { LandingPage } from './modules/Auth'

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual,
  )

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (!isAuthorized) {
    return (
      <Switch>
        <Route>
          <AuthPage />
        </Route>
        <Redirect to="/sign-in" />

        <Route path="/unauthorized" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />
      </Switch>
    )
  } else {
    return (
      <Switch>
        <Redirect from={['/sign-in', '/sign-up']} to={ isMobile ? "/live-orders" : "/dashboard"} />
        <Layout>
          <BasePage />
        </Layout>

        <Route path="/unauthorized" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />
      </Switch>
    )
  }
}