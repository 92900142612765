import React, { useEffect, useState } from "react";
import TableFilter from './Table/Filter'
import Table from "./Table";

export default function () {
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [salesFilters, setSalesFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [merchantFilters, setMerchantFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [cashInFilters, setCashInFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [earningFilters, setEarningFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [khataFilters, setKhataFilters] = useState({
    startDate: "",
    endDate: "",
  });

  const [creditFilters, setCreditFilters] = useState({
    startDate: "",
    endDate: "",
  });

  return (
    <>
      <div>
        <TableFilter
          value={filters}

          onClearFilter={() => {
            setFilters({
              filter: "",
              startDate: "",
              endDate: "",
            });
          }}

          setDate={(startDate, endDate) => {
            let obj = { ...filters };
            obj.startDate = startDate;
            obj.endDate = endDate;
            setFilters(obj);
          }}
        />

        <Table
          filters={filters}
          setFilters={(val) => setFilters(val)}

          salesFilters={salesFilters}
          setSalesFilters={(val) => setSalesFilters(val)}
          
          merchantFilters={merchantFilters}
          setMerchantFilters={(val)=> setMerchantFilters(val)}
          
          cashInFilters={cashInFilters}
          setCashInFilters={(val)=> setCashInFilters(val)}

          earningFilters={earningFilters}
          setEarningFilters={(val)=> setEarningFilters(val)}

          khataFilters={khataFilters}
          setKhataFilters={(val)=> setKhataFilters(val)}

          creditFilters={creditFilters}
          setCreditFilters={(val)=> setCreditFilters(val)}
          />
      </div>
    </>
  );
}
