/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import * as auth from "./../../../../app/modules/Auth/_redux/authRedux";
// import { QuickActions } from './components/QuickActions'
import { BreadCrumbs } from "./components/BreadCrumbs";

function SubHeader({ updateShowing }) {
  const uiService = useHtmlClassService();
  const location = useLocation();

  // console.log(location)
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  const getBtnName = (path) => {
    let name = "Add ";

    // if (path.includes("/wastage")) {
    //   name = "Add Wastage";
    // } else

    if (path.includes("/batch")) {
      name = "Add Batch";
    }

    return name;
  };

  return (
    <>
      {/* <div
        id="kt_subheader"
        className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
      >
        <div
          className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          <div className="d-flex align-items-center flex-wrap mr-1">
            {layoutProps.subheaderMobileToggle && (
              <button
                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5 pt-0 pt-lg-0">
                <>
                  {subheader.title.replace(
                    `<small style="margin-left: 5px;"> (BETA)</small>`,
                    ""
                  )}
                </>
              </h5>
            </div>

            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>

          {location.pathname.includes("/batch") && (
            <div class="d-flex align-items-center">
              <a
                onClick={() => {
                  updateShowing(Math.random());
                }}
                class="btn btn-danger px-8 font-weight-bolder btn-sm"
              >
                {getBtnName(location.pathname)}
              </a>
            </div>
          )}
        </div>
      </div> */}


      <div id="kt_app_toolbar" className="app-toolbar mb-5 mt-3">
        <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
          <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                {subheader.title.replace(
                  `<small style="margin-left: 5px;"> (BETA)</small>`,
                  ""
                )}
              </h1>
              {/* <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0" style={{ backgroundColor: 'transparent', padding: 0 }}>
                <li className="breadcrumb-item text-muted">
                  <a href="index.html" className="text-primary text-hover-primary">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-500 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-muted">Pages</li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-500 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-muted">
                <BreadCrumbs items={subheader.breadcrumbs} />
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(SubHeader));
