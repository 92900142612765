import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { API_URL, SelectStyle, convertBase64 } from "../../../../config";
import { getApiData } from "../../../../Services";
import { GeneralDropdown } from "../../../../components";
import { gradientColors } from "../../../../../gradientColor";
import Select from "react-select";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data }) {
    const [sending, setSending] = useState(false);
    const [rows, setRows] = useState([{ name: '', price: '' }]);

    useEffect(() => {
        resetRowsToInitialData();
    }, [data, show]);

    const handleAddRow = () => {
        setRows([...rows, { name: '', price: '' }]);
    };

    const handleRemoveRow = () => {
        if (rows.length > 0) {
            setRows(rows.slice(0, -1));
        }
    };

    const handleSizeNameChange = (index, value) => {
        setRows(rows.map((row, i) => i === index ? { ...row, name: value } : row));
    };

    const handleSizePriceChange = (index, value) => {
        if (value.length <= 5) {
            setRows(rows.map((row, i) => i === index ? { ...row, price: value } : row));
        }

    };

    const resetRowsToInitialData = () => {
        if (data.id) {
            if (data.variants && data.variants.length > 0) {
                setRows(data.variants);
            } else {
                setRows([{ name: '', price: '' }]);
            }
        } else {
            setRows([{ name: '', price: '' }]);
        }
    };

    const onPressClose = () => {
        onHide();
        setRows([{ name: '', price: '' }]);
    };


    const clearInput = () => {
        setRows([{ name: '', price: '' }]);
    };

    const onSubmit = () => {
        const hasBlankValues = (array) => {
            return array.some(item => item.name === "" || item.price === "");
        };

        const isBlank = hasBlankValues(rows);

        if (isBlank) {
            toast.error("Please Add variants");
        } else {
            setSending(true);
            const body = {
                variants: rows
            }

            getApiData(`products/v1/actions/${data.id}/`, 'put', body).then((res) => {
                setSending(false);
                if (res.status) {
                    clearInput();
                    onHide();
                    onSuccess(res.message);
                } else {
                    onError(res.message);
                }
            })
        }
    }

    return (
        <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
            <Modal.Body className="scroll-popup">
                <div className="d-flex align-items-center justify-content-between">
                    <Modal.Title>Manage Variant</Modal.Title>
                </div>
                <div className="modal-body">
                    <div className="row g-5 mb-0 align-items-center">
                        <div className="col-md-12">
                            <label htmlFor className=" fs-6 mb-2 fw-bold">Product Name</label>
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-105px">
                                    <img src={data.image ? data.image : 'https://t4.ftcdn.net/jpg/05/35/51/85/360_F_535518598_fviKNslzN3NTzWTkAn5WpoH9FmiVciAu.jpg'} alt="" />
                                </div>
                                <div className="ms-3">
                                    <div className="fs-5"><b>{data.name}</b></div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5 px-5">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <label htmlFor className=" fs-6 fw-bold mb-2">Variant</label>
                                    </div>
                                    <div className="position-relative w-lg-150px text-center ml-2">
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0"
                                            onClick={handleRemoveRow}
                                        >
                                            <span className="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                                    <rect x="6.01041" y="10.9247" width={12} height={2} rx={1} fill="black" />
                                                </svg>
                                            </span>
                                        </button>
                                        <input
                                            type="text"
                                            className="form-control fs-5 text-center ps-0"
                                            value={rows.length}
                                            readOnly
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0"
                                            onClick={handleAddRow}
                                        >
                                            <span className="svg-icon svg-icon-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                                    <rect x="10.8891" y="17.8033" width={12} height={2} rx={1} transform="rotate(-90 10.8891 17.8033)" fill="black" />
                                                    <rect x="6.01041" y="10.9247" width={12} height={2} rx={1} fill="black" />
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                {rows.map((row, index) => (
                                    <div className="row mt-3" key={index}>
                                        <div className="col-md-5">
                                            <label className="fs-6 fw-bold mb-2 required">Variant Name</label>
                                            <input
                                                type="text"
                                                className="form-control fs-7"
                                                placeholder="Variant name"
                                                value={row.name}
                                                onChange={(e) => handleSizeNameChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <label className="fs-6 fw-bold mb-2 required">Price</label>
                                            <input
                                                type="number"
                                                className="form-control fs-7"
                                                placeholder="Price"
                                                value={row.price}
                                                onChange={(e) => handleSizePriceChange(index, e.target.value)}
                                                maxLength={5}
                                            />
                                        </div>
                                        <div className="col-md-2">
                                            <label className="fs-6 fw-bold mb-9">{""}</label>
                                            <div>
                                                <a
                                                    className=""
                                                    onClick={handleRemoveRow}
                                                    color="red">
                                                    Remove
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn custom-btn-light fs-7"
                        onClick={onPressClose}
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        className="custom-button btn-primary fs-7"
                        onClick={onSubmit}
                    // disabled={productName && !sending ? false : true}
                    >
                        Save
                        {sending && <Spinner animation="border" size="sm" className="ml-1" />}
                    </button>
                </div>
            </Modal.Body>
        </Modal >
    );
}