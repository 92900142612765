import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LoaderPopup } from "../../../../components";
import { convertDate, formateAmount, PER_PAGE } from "../../../../config";
import { getApiData } from "../../../../Services";
import Pagination from "react-js-pagination";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export default function ({ data, show, onHide }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    if (data.id) {
      setLoading(true);
      getList();
    }
  }, [data, activePage]);

  const getList = () => {
    let param = "";
    let offset = (activePage - 1) * PER_PAGE;
    param += `?limit=${PER_PAGE}&offset=${offset}`;

    // getApiData(`v1/customers/transaction/history${param}&user_id=${data.id}`).then((res) => {
    //   setLoading(false);
    //   if (res.status) {
    //     let apiData = res.data;
    //     apiData.length &&
    //       apiData.map((item, i) => {
    //         let value = ++i;
    //         if (activePage > 1)
    //           value = PER_PAGE * activePage - PER_PAGE + value;
    //         item.sr_no = value;
    //       });
    //     setTotalRecords(res.count);
    //     setList(apiData);
    //   }
    // });
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Payout History
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="scroll-popup">
        <table className="table table-head-custom table-head-bg table-borderless table-vertical-center py-3">
          <thead>
            <tr className="text-left text-uppercase">
              <th className="pl-4">#</th>
              <th>Date Time</th>
              <th>Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          {loading ? (
            <tr>
              <td colSpan="9">
                <LoaderPopup />
              </td>
            </tr>
          ) : (
            <>
              {list.length ? (
                list.map((item, i) => {
                  return (
                    <tr>
                      <td className="pl-4">{++i}</td>
                      <td><span>{convertDate(item.created_at)}</span></td>
                      <td>{item.first_name}</td>
                      <td><b>{formateAmount(item.amount)}</b></td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="9">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <img src={toAbsoluteUrl('/media/logos/no-data.png')} height="80px" width="80px" alt="No Data" />
                    </div>
                  </td>
                </tr>
              )}
            </>
          )}
        </table>
        {totalRecords > 0 && (
          <div className="mt-2 float-right mr-3">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={totalRecords}
              pageRangeDisplayed={5}
              itemClass="d-flex justify-content-between align-items-center flex-wrap"
              linkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1"
              activeLinkClass="btn btn-icon btn-sm border-0 custom-btn-light  btn-hover-primary mr-2 my-1 active"
              onChange={(pageNumber) => {
                setActivePage(pageNumber);
              }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn custom-btn-light btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}