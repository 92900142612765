import React, { useEffect, useState } from "react";
import {
  getStateList,
  getAreaByCity,
  getCategoryList,
  redirection,
} from "../../config";
import { getApiData } from "../../Services";
import toast from "react-hot-toast";
import { GeneralDropdown } from "../../components";

export default function () {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const [sendTo, setSendTo] = useState();
  const [sendToId, setSendToId] = useState("");

  const [list, setList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [sending, setSending] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedBPartner, setSelectedBPartner] = useState(null);
  const [selectedRedirection, setSelectedRedirection] = useState("");

  useEffect(() => {
    (async () => {
      const state = await getStateList();
      setStateList(state);

      let list = [
        { value: "a1b2", label: "Customers" },
        { value: "b2c3", label: "Building" },
        { value: "c3d4", label: "Business Partner" },
      ];

      setList(list);
    })();
  }, []);

  useEffect(() => {
    getBuildingList();
    getBusinessList();
  }, []);

  const getBuildingList = () => {
    getApiData("building/v1/").then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.name, value: item.id }];
        });
        setBuildingList(array);
      }
    });
  };

  const getBusinessList = () => {
    getApiData(`business/v1`).then((res) => {
      if (res.status) {
        let array = [];
        res.data.map((item) => {
          array = [...array, { label: item.first_name, value: item.id }];
        });
        setBusinessList(array);
      }
    });
  };

  const onCitySelect = async (obj) => {
    setSelectedCity(obj.value);
    const area = await getAreaByCity(obj.value);
    setAreaList(area);
  };

  const onClear = () => {
    let clearList = list[0];
    setTitle("");
    setMessage("");
    setSendTo(clearList);
    setSelectedState(null);
    setSelectedCity(null);
    setSelectedArea([]);
  };

  const onSubmit = () => {
    if (!title) {
      toast.error("Please enter the title");
    } else if (!message) {
      toast.error("Please enter the message");
    } else if (!selectedState) {
      toast.error("Please select the state");
    } else if (!selectedCity) {
      toast.error("Please select the city");
    } else {
      setSending(true);
      const areas = selectedArea.map((item) => item.value);
      const body = {
        title: title,
        body: message,
        city: selectedCity,
        areas: areas,
      };
      getApiData("notification/v1/", "post", body).then((res) => {
        setSending(false);
        if (res.status) {
          toast.success(res.message);
          onClear();
          setSelectedState("");
          setSelectedCity("");
          setSelectedArea([]);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <div>
      <div className="row g-5">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header px-7 border-bottom-0">
              <h3 className="card-title fw-bold fs-2">
                Notifications Settings
              </h3>
            </div>
            <div className="card-body p-5 px-7 pt-0 position-relative">
              <div className="row g-5">
                <div className="col-md-12">
                  <label htmlFor className=" fs-6 fw-bold mb-2 required">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control fs-7"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <label htmlFor className=" fs-6 fw-bold mb-2 required">
                    Message
                  </label>
                  <textarea
                    className="form-control fs-7"
                    value={message}
                    cols={30}
                    rows={3}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="col-12">
                  <label htmlFor className=" fs-6 fw-bold mb-2 ">Send to {sendTo ? sendTo?.label : ""}</label>
                  <GeneralDropdown
                    selectedOption={sendTo ? sendTo : list[0]}
                    list={list}
                    handleChange={(obj) => {
                      setSendTo(obj);
                    }}
                    isSearchable={false}
                  />
                </div> */}
                <div className="row mt-5">
                  <div className="col-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 required">
                      Select State
                    </label>
                    <GeneralDropdown
                      placeholder="Select State"
                      list={stateList}
                      selectedOption={selectedState}
                      handleChange={(selectedState) => {
                        if (selectedState.city && selectedState.city.length) {
                          let city = [];
                          selectedState.city.map(
                            (item) =>
                              (city = [...city, { label: item, value: item }])
                          );
                          setSelectedState(selectedState.value);
                          setCityList(city);
                        }
                      }}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 required">
                      Select City{" "}
                    </label>
                    <GeneralDropdown
                      selectedOption={selectedCity}
                      placeholder="Select City"
                      list={cityList}
                      handleChange={(obj) => {
                        onCitySelect(obj);
                      }}
                      isSearchable={false}
                    />
                  </div>
                  <div className="col-4">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">
                      Area
                    </label>
                    <GeneralDropdown
                      selectedOption={selectedArea}
                      placeholder="Select Area"
                      list={areaList}
                      handleChange={(area) => {
                        setSelectedArea(area);
                      }}
                      inModal
                      isMulti
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group is-invalid mb-0 mt-5">
                    <label className="fs-6 fw-bold mb-2 required">
                      Redirection
                    </label>
                    <GeneralDropdown
                      className="form-control"
                      selectedOption={selectedRedirection}
                      list={redirection}
                      handleChange={(obj) => {
                        setSelectedRedirection(obj.value);
                      }}
                      inModal
                    />
                  </div>
                </div>
                {sendTo?.label == "Building" ? (
                  <div className="col-12">
                    <label htmlFor className=" fs-6 fw-bold mb-2 ">
                      Select Building{" "}
                    </label>
                    <GeneralDropdown
                      list={buildingList}
                      handleChange={(obj) => {
                        setSelectedBuilding(obj.value);
                      }}
                      isSearchable={false}
                    />
                  </div>
                ) : (
                  sendTo?.label == "Business Partner" && (
                    <div className="col-12">
                      <label htmlFor className=" fs-6 fw-bold mb-2 ">
                        Select Business Partner{" "}
                      </label>
                      <GeneralDropdown
                        list={businessList}
                        handleChange={(obj) => {
                          setSelectedBPartner(obj.value);
                        }}
                      />
                    </div>
                  )
                )}
                <div className="col-12 text-center">
                  <button
                    className="btn custom-btn-light-primary fs-7 fw-bold mr-3"
                    onClick={() => onClear()}
                  >
                    Clear
                  </button>
                  <button
                    className="custom-button btn-primary fs-7 fw-bold py-3"
                    onClick={() => onSubmit()}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
