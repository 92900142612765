import React, { useState, useRef, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import { DateRangeSettings } from "../../../config";
import toast from "react-hot-toast";
import { getApiData } from "../../../Services";
import moment from "moment";

export default function ({ show, onHide, data }) {
  const today = moment().format("MM/DD/YYYY");

  const [date, setDate] = useState([today, today]);
  const [sending, setSending] = useState(false);

  const DateRangeSettings = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    locale: {
      format: "MM/DD/YYYY",
    },
  };

  useEffect(() => {
    console.log("data >>>>", data);
  }, [data]);

  const handleApply = (event, picker) => {
    const selectedDate = picker.startDate.format("MM/DD/YYYY");
    setDate([selectedDate, selectedDate]);
    picker.element.val(selectedDate);
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
    setDate([null, null]);
  };

  const downloadCSV = () => {
    const isDateBlank = date[0] === null && date[1] === null;

    if (isDateBlank) {
      toast.error("Please select the date range");
    } else {
      setSending(true);

      const daterange = date[0].replace(/\//g, "-");
      getApiData(`order/v1/business-payouts/${daterange}/`, "put").then(
        (res) => {
          setSending(false);
          if (res.status) {
            const csvUrl = res.data.csv_url;
            const link = document.createElement("a");

            link.href = csvUrl;
            link.download = csvUrl.split("/").pop();

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDate([null, null]);
            onHide();
          } else {
            toast.error("Something went wrong!");
          }
        }
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      dialogClassName="bottom-popup align-items-center"
    >
      <Modal.Header closeButton>
        <Modal.Title>Download CSV</Modal.Title>
        <button type="button" className="close ml-auto" onClick={onHide}>
          ×
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body p-4 py-5 pb-7">
          <label className=" fs-6 fw-bold mb-2 required">
            Select Date Range
          </label>
          <div className="d-flex gap-4 mb-5 w-50">
            <div
              className="btn btn-sm d-flex align-items-center px-4 w-100 fs-7"
              style={{ backgroundColor: "#F5F5F5" }}
            >
              <div className="input-group">
                <DateRangePicker
                  initialSettings={DateRangeSettings}
                  onApply={handleApply}
                  onCancel={handleCancel}
                >
                  <input
                    type="text"
                    className="form-control z-index-9"
                    style={{
                      borderWidth: 0,
                      backgroundColor: "transparent",
                      height: "12px",
                    }}
                    placeholder="Select date"
                    id="date-range"
                    autoComplete="off"
                    value={date[0]}
                    readOnly
                  />
                </DateRangePicker>
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    style={{ borderWidth: 0, backgroundColor: "transparent" }}
                  >
                    <i className="ki-duotone ki-calendar-8 text-gray-500 lh-0 fs-2 ms-auto me-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="custom-button btn-sm btn-primary font-weight-bold px-10"
          onClick={downloadCSV}
        >
          Download
          {sending && <Spinner animation="border" size="sm" className="ml-1" />}
        </button>
        <button
          type="button"
          className="custom-custom-btn-light px-10"
          onClick={onHide}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
