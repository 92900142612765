import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { getApiData } from "../../../Services";
import toast from "react-hot-toast";

export default function ({ show, onHide, onSuccess, onError, data }) {
  const [sending, setSending] = useState(false);
  const [refundAmount, setRefundAmount] = useState('');

  useEffect(() => {
    if (show) {
      if (data.order_id) {
        console.log("data >>>>>", data);
        setRefundAmount(data.total);
      } else {
        clearInput();
      }
    }
  }, [data, show]);

  const clearInput = () => {
    setRefundAmount('');
  }

  const onClickRefund = () => {
    if (refundAmount > data.total) {
      toast.error('Please enter valid amount');
    } else {
      setSending(true);
      getApiData(`order/v1/refund/${data.order_id}/?refund_amount=${refundAmount}`).then((res) => {
        setSending(false);
        if (res.status) {
          onSuccess(res.message);
        } else {
          onError(res.message);
        }
      });
    }
  }

  return (
    <Modal size="md" show={show} onHide={onHide} dialogClassName="bottom-popup">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <h3 className="modal-title">Create Refund</h3>
        </Modal.Title>
        <button type="button" className="ml-auto btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={onHide}>
          <i className="ki-outline ki-cross fs-1" />
        </button>
      </Modal.Header>
      <Modal.Body className="pt-3 scroll-popup">
        <div className="modal-body">
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <div className="rounded fs-7 mb-1">Could you please confirm the creation of the refund amount of <b>₹{data.total}</b> or add amount manually?</div>
            </div>
            <div className="col-md-12">
              <label className="fs-6 fw-bold mb-2 required">Refund Amount</label>
              <input
                className="form-control"
                placeholder="Please enter refund amount"
                value={refundAmount}
                onChange={(e) => {
                  setRefundAmount(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn custom-btn-light fs-7" onClick={onHide}>Close</button>
          <button
            type="button"
            className="custom-button btn-primary fs-7"
            onClick={onClickRefund}
            disabled={refundAmount && !sending ? false : true}
          >
            Save{" "}
            {sending && <Spinner animation="border" size="sm" className="ml-1" />}
          </button>
        </div>

      </Modal.Body>
    </Modal>
  );
}
